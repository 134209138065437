import styled from "styled-components";
import { colors } from "../../colors";
import { down } from "../../media";
import { LeadSmallStyle, SmallStyle } from "../../mixins";
import IconDelete from "./delete_icon.svg";
import IconFile from "./file_icon.svg";
import IconFileHover from "./file_icon_hover.svg";

export const FileItemImage = styled.div`
  background-image: url(${IconFile});
  margin-right: 8px;
  width: 15px;
  height: 16px;

  background-repeat: no-repeat;
  background-position: center;
`;

export const FilesListContainer = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 4px;

  ${down("tabletSmall")} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

export const FileItem = styled.a`
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  text-decoration: none;
  border-radius: 4px;

  :hover {
    background-color: ${colors.secondary.pure_blue_transparent};

    > ${FileItemImage} {
      background-image: url(${IconFileHover});
    }
  }
`;

export const FileItemNameContainer = styled.div`
  overflow: hidden;
  flex: 1;

  /* To change hint container properties: */
  > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const FileItemName = styled.span`
  ${SmallStyle};
  color: ${colors.primary.frosted_black};

  ${down("mobile")} {
    ${LeadSmallStyle()};
  }
`;

export const FileItemDelete = styled.button`
  padding: 8px;
  border: none;
  cursor: pointer;
  background-image: url(${IconDelete});
  background-position: center;
  background-color: transparent;
  background-repeat: no-repeat;
  transition: transform 100ms ease-in-out;
  :hover {
    transform: scale(1.25);
  }
`;
