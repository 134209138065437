import React, { FunctionComponent, Suspense } from "react";
import { Routes, Route } from "react-router";
import NotFound from "../NotFound/NotFound";
import Loader from "../shared/components/Loader";
import { AppProvider } from "./AppContext/AppProvider";
import AppRoutes from "./AppRoutes";
import GlobalStyles from "../shared/global";
import { ModalProvider } from "../shared/components/Modal";
import useMetadata from "../../common/helpers/useMetadata";
import useScrollToTop from "../../common/helpers/useScrollToTop";

const App: FunctionComponent = () => {
  useMetadata();
  useScrollToTop();

  return (
    <>
      <GlobalStyles />
      <AppProvider>
        <ModalProvider>
          <Suspense fallback={<Loader />}>
            <Routes>
              {AppRoutes}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </ModalProvider>
      </AppProvider>
    </>
  );
};

export default App;
