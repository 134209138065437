import styled from "styled-components";
import { colors } from "../shared/colors";
import { down } from "../shared/media";

export const TermsAndConditionsContactWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > :last-child {
    margin-top: 32px;
  }

  > h5 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${colors.primary.frosted_black};
    margin-top: 8px;
    > span {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: ${colors.grayscale._600};
    }
    > a {
      font-weight: 400;
      color: ${colors.secondary.pure_blue_light};
    }
  }
  ${down("mobile")} {
    > :last-child {
      margin-top: 24px;
    }
    > h5 {
      font-size: 16px;
      margin-top: 4px;
      > span {
        font-size: 16px;
      }
    }
  }
`;
