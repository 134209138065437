import React, { FunctionComponent, useEffect } from "react";
import { ChartHeader } from "../Charts.components";
import {
  CrimeRateBlurLayer,
  CrimeRateChartCaption,
  CrimeRateChartCaptionWrapper,
  CrimeRateChartContainer,
  CrimeRateChartStatus,
  CrimeRateChartWrapper,
  CrimeRateContainer,
  CrimeRateDescription,
  CrimeRateDescriptionWrapper,
  CrimeRateHeadingWrapper,
  CrimeRateWhiteDot,
  FindOutMoreButton,
} from "./CrimeRate.components";
import { ReactComponent as ArrowRed } from "../icons/ArrowRed.svg";
import { ReactComponent as ArrowGreen } from "../icons/ArrowGreen.svg";
import "chart.js";
import "chartjs-chart-radial-gauge";
import { colors } from "../../../../../../shared/colors";
import {
  GrowthIndexType,
  LevelType,
} from "../../../../../../../generated/schema";
import urls from "../../../../../../../common/constants/urls";

type CrimeRateProps = {
  crimeRateLevel: LevelType;
  crimeIndex: number;
  crimeTrend: GrowthIndexType;
  postcode: string;
};

const CrimeRate: FunctionComponent<CrimeRateProps> = ({
  crimeRateLevel,
  crimeIndex,
  crimeTrend,
  postcode,
}) => {
  useEffect(() => {
    //@ts-ignore
    const ctx = document.getElementById("chart-area").getContext("2d");
    const gradient = ctx.createLinearGradient(0, 100, 170, 0);
    gradient.addColorStop(1, "#97BEF7");
    gradient.addColorStop(0, "#4F94F4");

    const config = {
      type: "radialGauge",
      data: {
        datasets: [
          {
            data: [crimeIndex],
            backgroundColor: [gradient],
            borderWidth: 0,
            label: "Score",
          },
        ],
      },
      options: {
        responsive: true,
        trackColor: colors.grayscale._200,
        displayText: false,
      },
    };
    //@ts-ignore
    new Chart(ctx, config);
  }, [crimeIndex]);

  const getGrowthIcon = () => {
    switch (crimeTrend) {
      case GrowthIndexType.Increase:
        return <ArrowRed />;
      case GrowthIndexType.Decrease:
        return <ArrowGreen />;
    }
  };

  const formattedCrimeRate = `${crimeTrend
    .toLowerCase()
    .slice(0, crimeTrend.length - 1)}ing`;

  return (
    <CrimeRateContainer>
      <ChartHeader>Crime rate</ChartHeader>
      <CrimeRateChartContainer>
        <CrimeRateChartWrapper>
          <CrimeRateBlurLayer />
          <canvas
            id="chart-area"
            style={{ width: "180px !important", height: 180 }}
          />
          <CrimeRateWhiteDot />
          <CrimeRateChartCaptionWrapper>
            <CrimeRateChartCaption>
              {crimeIndex}
              <span>%</span>
            </CrimeRateChartCaption>
            <CrimeRateChartStatus>
              {crimeRateLevel.toLowerCase()}
            </CrimeRateChartStatus>
          </CrimeRateChartCaptionWrapper>
        </CrimeRateChartWrapper>
        <CrimeRateDescriptionWrapper>
          <CrimeRateHeadingWrapper>
            <h5>{formattedCrimeRate}</h5>
            {getGrowthIcon()}
          </CrimeRateHeadingWrapper>
          <CrimeRateDescription>
            This property is in an area of {crimeRateLevel.toLowerCase()} crime.
            However, there is a visible {/* Add ing suffix to displayed data */}
            {formattedCrimeRate} trend.
          </CrimeRateDescription>
          <FindOutMoreButton
            onClick={() =>
              window.open(`${urls.STATS_BASE_URL}/${postcode.replace(" ", "")}`)
            }
          >
            Find out more
          </FindOutMoreButton>
        </CrimeRateDescriptionWrapper>
      </CrimeRateChartContainer>
    </CrimeRateContainer>
  );
};

export default CrimeRate;
