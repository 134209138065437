import { css } from "styled-components";
import { colors } from "./colors";
import { down } from "./media";
import { isChrome } from "react-device-detect";

export const H4Style = () => css`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: ${colors.primary.frosted_black};
`;

export const H2Style = () => css`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 44px;
  color: ${colors.primary.pure_white};
`;

export const H5Style = () => css`
  margin: 0px;
  padding: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.primary.frosted_black};
`;

export const BodyMediumStyle = () => css`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.grayscale._600};
`;

export const TinyStyle = () => css`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 10px;
  letter-spacing: 0.01em;
`;

export const LeadMediumStyle = () => css`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.grayscale._600};
`;
export const LeadSmallStyle = () => css`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.grayscale._600};
`;

export const MainHeaderStyle = () => css`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.primary.frosted_black};
`;

export const ButtonSmallStyle = () => css`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const H6Style = () => css`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
`;

export const Unselectable = () => css`
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const SmallStyle = () => css`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${colors.primary.frosted_black};
`;

export const BodySmallStyle = () => css`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${colors.grayscale._400};
`;

export const ScrollBarStyle = () => css`
  padding-right: 8px;

  /* Scrolled bar needs no space on Chrome. */
  ${isChrome &&
  css`
    padding-right: 0px;
  `}

  ::-webkit-scrollbar {
    width: 18px;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px ${colors.grayscale._300};
    border: solid 5px transparent;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px ${colors.grayscale._400};
    border: solid 5px transparent;
    border-radius: 100px;

    :hover {
      box-shadow: inset 0 0 10px 10px ${colors.grayscale._500};
    }
  }

  scrollbar-color: ${colors.grayscale._400} ${colors.grayscale._300};
  scrollbar-width: thin;
`;

export const DefaultScaleTransform = (factor?: number) => css`
  transition: transform 0.3s ease;

  :hover {
    cursor: pointer;
    transform: ${`scale(${factor || 1.05})`};
  }
`;

export const VerticalGap = (gap: number) => css`
  > * {
    margin-bottom: ${gap}px;
  }

  > :last-child {
    margin-bottom: 0px;
  }
`;

export const HorizontalGap = (gap: number) => css`
  > * {
    margin-right: ${gap}px;
  }

  > :last-child {
    margin-right: 0px;
  }
`;

export const MaxLines = (lines: number) => css`
  display: -webkit-box;
  line-clamp: ${lines};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AppLayoutStyle = () => css`
  height: 100%;
  margin-top: 64px;

  ${down("mobile")} {
    margin-top: 56px;
  }
`;
