import styled from "styled-components";
import { colors } from "../../../colors";
import { down } from "../../../media";

export const PropertyPriceLabel = styled.h4`
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: ${colors.primary.frosted_black};

  ${down("tablet")} {
    font-size: 18px;
    line-height: 30px;
  }

  ${down("tablet")} {
    font-size: 16px;
  }
`;

export const PropertyPriceSpan = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${colors.grayscale._600};
  margin-left: 4px;
`;
