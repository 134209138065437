import styled from "styled-components";
import { down } from "../../../shared/media";
import { HorizontalGap, VerticalGap } from "../../../shared/mixins";

export const LocationSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${VerticalGap(24)};
`;

export const LocationAddressContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${HorizontalGap(12)};

  ${down("tablet")} {
    flex-direction: column;
    ${VerticalGap(24)};
  }
`;

export const LargeInputContainer = styled.div`
  flex: 1;
  max-width: 396px;
`;

export const SmallInputsContainer = styled.div`
  display: flex;
  ${HorizontalGap(12)};
`;

export const SmallInputContainer = styled.div`
  width: 94px;
`;

export const PostcodeInputContainer = styled.div`
  * input {
    text-transform: uppercase;
  }
`;
