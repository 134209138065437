import styled from "styled-components";
import { colors } from "../../shared/colors";
import { down } from "../../shared/media";
import { LeadSmallStyle } from "../../shared/mixins";
import Background from "./images/BackgroundRight.svg";

export const ListingPropertiesContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 144px;
  align-self: center;
  justify-content: center;

  ${down("tablet")} {
    margin-top: 112px;
  }

  ${down("mobile")} {
    margin-top: 92px;
  }
`;

export const ListingPropertiesInnerContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1088px;
  margin: 0 112px;

  ${down("tablet")} {
    margin: 0 56px;
  }

  ${down("mobile")} {
    flex-direction: column;
    margin: 0 16px;
  }
`;

export const ListingPropertiesImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListingPropertiesIndicator = styled.span`
  margin: 0;
  padding: 0;
  ${LeadSmallStyle()}
  padding-bottom: 8px;
  white-space: nowrap;
  border-bottom: 1px solid rgba(59, 122, 247, 0.12);
  margin-bottom: 16px;
  max-width: 258px;
  text-transform: uppercase;
  > span {
    color: ${colors.secondary.pure_blue_dark};
  }

  ${down("tablet")} {
    max-width: 208px;
  }

  ${down("mobile")} {
    font-size: 14px;
    line-height: 16px;
    max-width: 156px;
  }
`;

export const ListingPropertiesImage = styled.img`
  min-width: 350px;
  width: 100%;
  height: 544px;
  border-radius: 16px 16px 0px 16px;
  object-fit: cover;

  ${down("tablet")} {
    max-width: unset;
    width: 100%;
    height: 520px;
    min-width: 136px;
  }

  ${down("mobile")} {
    height: 208px;
    object-fit: cover;
    border-radius: 16px 16px 0px 0px;
  }
`;

export const ListingPropertiesDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: flex-end;
  background: url(${Background});
  background-position: bottom right;
  background-size: cover;
  border-radius: 0 16px 16px 0;
  padding: 48px 148px 48px 88px;
  box-sizing: border-box;
  ${down("tablet")} {
    padding: 48px;
    min-width: 252px;
  }

  ${down("mobile")} {
    min-width: 200px;
    padding: 40px 24px;
    border-radius: 0 0px 16px 16px;
    height: 100%;
  }
`;

export const ListingPropertiesDescriptionHeading = styled.h2`
  position: relative;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.01em;
  min-width: 400px;
  color: ${colors.primary.pure_white};

  ::after {
    position: absolute;
    bottom: 8px;
    content: "FOR FREE";
    display: inline-flex;
    font-size: 14px;
    line-height: 120%;
    width: 91px;
    height: 28px;
    text-transform: uppercase;
    background-color: ${colors.secondary.pure_blue_light};
    margin-left: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  ${down("tablet")} {
    font-size: 32px;
    line-height: 44px;
    ::after {
      height: 26px;
    }
  }

  ${down("tablet")} {
    font-size: 28px;
    line-height: 40px;
    ::after {
      height: 26px;
    }
  }

  ${down("mobile")} {
    font-size: 32px;
    line-height: 44px;
    min-width: 100px;
    max-width: 279px;
    ::after {
      height: 24px;
    }
  }
`;

export const ListingPropertiesDescription = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 29px;
  color: ${colors.grayscale._300};
  margin-top: 8px;

  ${down("mobile")} {
    max-width: unset;
    font-size: 16px;
    line-height: 160%;
    width: 100%;
  }
`;

export const ListingPropertiesDescriptionButtonWrapper = styled.div`
  margin-top: 36px;
`;
