import React, { FunctionComponent } from "react";
import {
  PropertyCardContainer,
  PropertyCardDetailsHeader,
  PropertyCardDetailsWrapper,
  PropertyCardImage,
  PropertyCardImageWrapper,
  PropertyCardOuterContainer,
  PropertyCardVariant,
  PropertyCreationDate,
  PropertyDescription,
  PropertyName,
  PropertyVisualDetail,
  PropertyVisualDetailsWrapper,
} from "./PropertyCard.components";
import PropertyPrice from "./PropertyPrice";
import { ReactComponent as CategoryIcon } from "./icons/CategoryIcon.svg";
import { ReactComponent as SquareFtIcon } from "./icons/SquareFtIcon.svg";
import PropertyActions from "./PropertyActions";
import { PropertyAction } from "./PropertyActions/PropertyActions";
import PropertyBadge from "./PropertyBadge";
import { PropertyBadgeType } from "./PropertyBadge/PropertyBadge";
import {
  BillingType,
  ListingType,
  PropertyUseType,
} from "../../../../generated/schema";
import { format } from "date-fns";
import { PROPERTY_USE_TYPE_OPTIONS } from "../../../../common/helpers/enumNamesHelper";

type PropertyCardProps = {
  variant?: PropertyCardVariant;
  img: string;
  availableActions?: PropertyAction[];
  badgeType?: PropertyBadgeType;
  onActionClick?: (action: PropertyAction) => void;
  name: string;
  price?: number | null;
  listingType: ListingType;
  billingType?: BillingType | null;
  propertyUseType: PropertyUseType[];
  updatedAt: string;
  description?: string;
  sqFootage: number;
  loading?: boolean;
  url: string;
  isExternal?: boolean | null;
};

const PropertyCard: FunctionComponent<PropertyCardProps> = ({
  variant = "compact",
  img,
  availableActions,
  badgeType,
  propertyUseType,
  price,
  name,
  billingType,
  listingType,
  updatedAt,
  sqFootage,
  description,
  url,
  isExternal,
  loading = false,
  onActionClick,
}) => {
  const date = format(new Date(updatedAt), "DD.MM.YYYY");
  const categories = propertyUseType
    .map(
      (type) => PROPERTY_USE_TYPE_OPTIONS.find((o) => o.type === type)?.value,
    )
    .join(", ");

  const displayName = isExternal ? "External property" : name;

  return (
    <PropertyCardOuterContainer isLoading={loading}>
      <PropertyCardContainer variant={variant} to={url}>
        <PropertyCardImageWrapper variant={variant}>
          <PropertyCardImage src={img} variant={variant} />
          {badgeType !== undefined && <PropertyBadge type={badgeType} />}
        </PropertyCardImageWrapper>

        <PropertyCardDetailsWrapper variant={variant}>
          <PropertyCardDetailsHeader>
            <PropertyName variant={variant}>{displayName}</PropertyName>
            {price && (
              <PropertyPrice
                listingType={listingType}
                billingType={billingType}
                price={price}
              />
            )}
          </PropertyCardDetailsHeader>
          {description && (
            <PropertyDescription>{description}</PropertyDescription>
          )}
          <PropertyVisualDetailsWrapper variant={variant}>
            <PropertyVisualDetail variant={variant}>
              <CategoryIcon />
              <span>{categories}</span>
            </PropertyVisualDetail>
            <PropertyVisualDetail variant={variant}>
              <SquareFtIcon />
              <span>{sqFootage} sq ft</span>
            </PropertyVisualDetail>
          </PropertyVisualDetailsWrapper>
          <PropertyCreationDate>{date}</PropertyCreationDate>
        </PropertyCardDetailsWrapper>
      </PropertyCardContainer>
      {availableActions && (
        <PropertyActions
          loading={loading}
          actions={availableActions}
          onActionClick={(action) => onActionClick && onActionClick(action)}
        />
      )}
    </PropertyCardOuterContainer>
  );
};

export default PropertyCard;
