import routesPaths from "../../../modules/Routing/routesPaths";
import React, { FunctionComponent } from "react";
import { Navigate, useParams } from "react-router";
import { useGetPropertyByUidQuery } from "../../../generated/schema";
import PropertyDetails from "../PropertyDetails/PropertyDetails";
import { PropertyDetailsPageLoader } from "../PropertyDetails/PropertyDetails.components";
import PageLoader from "../../shared/components/PageLoader/PageLoader";

const PublicPropertyPreview: FunctionComponent = () => {
  const { slug } = useParams<{ slug: string }>();

  const splittedSlug = slug?.split("-");
  const uid = splittedSlug![splittedSlug!.length - 1];

  const { data, loading, error } = useGetPropertyByUidQuery({
    variables: {
      uid: uid || "",
    },
  });

  if (loading)
    return (
      <PropertyDetailsPageLoader>
        <PageLoader />
      </PropertyDetailsPageLoader>
    );
  if (error)
    return <Navigate to={{ pathname: routesPaths.ERROR404 }} replace />;

  return <PropertyDetails property={data?.getPropertyByUid} />;
};

export default PublicPropertyPreview;
