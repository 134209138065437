import styled, { css } from "styled-components";
import { colors } from "../../../colors";

export const FakeCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FakeBox = styled.div<{ checked: boolean; error?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  width: 18px;
  height: 18px;
  border: 2px solid
    ${(props) =>
      props.error && !props.checked
        ? colors.semantic.red
        : colors.secondary.pure_blue};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${colors.primary.pure_white};
  transition: transform 0.5s ease, background-color 0.5s ease;
  cursor: pointer;

  :hover {
    transform: scale(1.05);
  }
  :focus {
    outline: none;
    box-shadow: 0px 0px 0px 4px #a1c4f7;
  }

  ${(p) =>
    p.checked &&
    css`
      background-color: ${colors.secondary.pure_blue};
    `}
`;
