import React, { FunctionComponent, useCallback } from "react";
import routesPaths from "../Routing/routesPaths";
import Button from "../shared/components/Button";
import {
  BottomDescription,
  ButtonContainer,
  ButtonLoginContainer,
  CommitLogo,
  Description,
  FormContainer,
  FormContent,
  Header,
  EmailSentImage,
  BackToLoginLink,
  BackIcon,
} from "./Register.components";
import CommigLogoBlue from "../shared/images/commit_logo_blue.svg";
import EmailSent from "./email-sent.svg";
import ArrowLeft from "./arrow_left.svg";
import { useResendActivationLinkLazyQuery } from "../../generated/schema";

type AccountActivationProps = {
  email: string;
};

const AccountActivation: FunctionComponent<AccountActivationProps> = ({
  email,
}) => {
  const [rensedActivationLink, { loading }] =
    useResendActivationLinkLazyQuery();

  const resendEmail = useCallback(() => {
    rensedActivationLink({ variables: { email } });
  }, [email, rensedActivationLink]);

  return (
    <FormContainer>
      <FormContent>
        <CommitLogo src={CommigLogoBlue} alt="Commit logo" />
        <EmailSentImage src={EmailSent} />
        <Header>Account Activation</Header>
        <Description>
          Thanks for registering to Commit, great to have you! To activate your
          account, please check your mailbox, we've sent there a message.
        </Description>

        <ButtonLoginContainer>
          <BottomDescription>Didn't get a message?</BottomDescription>
          <ButtonContainer>
            <Button
              type="button"
              size="block"
              onClick={resendEmail}
              isLoading={loading}
            >
              Re-send email
            </Button>
          </ButtonContainer>
          <BackToLoginLink to={routesPaths.LOGIN}>
            <BackIcon src={ArrowLeft} />
            Back to home
          </BackToLoginLink>
        </ButtonLoginContainer>
      </FormContent>
    </FormContainer>
  );
};

export default AccountActivation;
