import React from "react";
import { LoadingContainer } from "./Loader.components";

const Loader = () => {
  return (
    <LoadingContainer>
      {[...Array(12)].map((_, index) => (
        <div key={index} />
      ))}
    </LoadingContainer>
  );
};

export default Loader;
