import React, { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useValidateTokenQuery } from "../../../generated/schema";
import routesPaths from "../../Routing/routesPaths";
import SetNewPasswordForm from "./SetNewPasswordForm";

export interface SetNewPasswordProps {
  token: string;
  onLinkExpired: () => void;
}

const SetNewPassword: FunctionComponent<SetNewPasswordProps> = ({
  token,
  onLinkExpired,
}) => {
  const navigate = useNavigate();
  const { data, refetch } = useValidateTokenQuery({
    variables: {
      token,
    },
  });

  useEffect(() => {
    // To validate token on browser back button.
    refetch();
  });

  useEffect(() => {
    switch (data?.validateResetPasswordToken.__typename) {
      case "ValidateResetTokenSuccessfulResponse":
        break;
      case "ValidateResetTokenTokenExpiredResponse":
        onLinkExpired();
        break;
      case "ValidateResetTokenTokenNotFoundResponse":
        navigate(routesPaths.LOGIN);
        break;
    }
  }, [data, navigate, onLinkExpired]);

  return (
    <>
      <SetNewPasswordForm token={token} onLinkExpired={onLinkExpired} />
    </>
  );
};

export default SetNewPassword;
