import styled from "styled-components";
import { colors } from "../../colors";

export const FilesDropdownContainer = styled.div``;

export const FilesInputContainer = styled.div<{ empty: boolean }>`
  margin-bottom: ${(p) => (p.empty ? 0 : 16)}px;
`;

export const ErrorSpan = styled.span`
  height: 32px;
  margin-top: 8px;
  background: ${colors.semantic.red_light};
  border-radius: 8px;
  color: ${colors.semantic.red_dark};
  font-size: 12px;
  line-height: 32px;
  width: 100%;
  display: block;
  text-align: center;
  letter-spacing: 0.01em;
`;
