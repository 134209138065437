import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  FunctionComponent,
  Ref,
  useEffect,
  useState,
} from "react";
import Label from "../Input/Label";
import ErrorMessage from "../shared/ErrorMessage";
import {
  Counter,
  StyledTextarea,
  TextareaErrorWrapper,
  TextareaWrapper,
} from "./Textarea.components";

export interface TextareaProps extends ComponentPropsWithoutRef<"textarea"> {
  maxLength?: number;
  label?: string;
  error?: string;
  errors?: any;
  showError?: boolean;
  hint?: JSX.Element | string;
}

export const Textarea: FunctionComponent<
  TextareaProps & {
    forwardedRef?: Ref<HTMLTextAreaElement>;
  }
> = ({
  maxLength = 250,
  showError = false,
  forwardedRef,
  rows = 4,
  value,
  hint,
  label,
  error,
  errors,
  ...textareaProps
}) => {
  const [charCount, setCharCount] = useState(0);
  useEffect(() => {
    setCharCount(value?.toString().length || 0);
  }, [value]);

  return (
    <TextareaWrapper>
      <Label hint={hint}>{label}</Label>
      <StyledTextarea
        {...textareaProps}
        ref={forwardedRef}
        isError={!!error}
        rows={rows}
        value={value}
        maxLength={maxLength}
      />
      <TextareaErrorWrapper>
        {error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <Counter>
            {charCount}/{maxLength} characters
          </Counter>
        )}
      </TextareaErrorWrapper>
    </TextareaWrapper>
  );
};

export default forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => (
  <Textarea {...props} forwardedRef={ref} />
));
