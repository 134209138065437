import React, { FunctionComponent } from "react";
import { Navigate, useNavigate } from "react-router";
import { useAccountType } from "../../../../common/helpers/useAccountType";
import { AccountType, PropertyStatus } from "../../../../generated/schema";
import routesPaths from "../../../Routing/routesPaths";
import Button from "../Button";
import {
  FakeSpan,
  TopBarButton,
  TopBarButtonsWrapper,
  TopBarContainer,
  TopBarHeader,
  TopBarHeaderWrapper,
} from "./TopBar.components";

const estateAgentRoutes = {
  baseUrl: routesPaths.MY_PROPERTIES_ROOT,
  tabs: ["ACTIVE", "PENDING", "DECLINED"],
};

const adminRoutes = {
  baseUrl: routesPaths.LISTINGS_ROOT,
  tabs: ["PENDING", "DECLINED", "ACTIVE"],
};

type TopBarProps = {
  heading: string;
  status: PropertyStatus;
};

const isRouteValid = (routes: string[], status: string) =>
  routes.includes(status);

const TopBar: FunctionComponent<TopBarProps> = ({ heading, status }) => {
  const isEstateAgent = useAccountType([AccountType.EstateAgent]);
  const routes = isEstateAgent ? estateAgentRoutes : adminRoutes;
  const navigate = useNavigate();

  if (!isRouteValid(routes.tabs, status))
    return (
      <Navigate
        to={{ pathname: `${routes.baseUrl}/${routes.tabs[0].toLowerCase()}` }}
      />
    );

  return (
    <TopBarContainer>
      <TopBarHeaderWrapper>
        <TopBarHeader>{heading}</TopBarHeader>
        {isEstateAgent && (
          <Button
            size="medium"
            buttonType="light"
            onClick={() => navigate(routesPaths.ADD_PROPERTY)}
          >
            Add new listing
          </Button>
        )}
      </TopBarHeaderWrapper>
      <TopBarButtonsWrapper>
        {routes.tabs.map((route, index) => {
          const normalizedRoute = route.toLowerCase();
          return (
            <TopBarButton
              key={`property-${normalizedRoute}-${index}`}
              to={`${routes.baseUrl}/${normalizedRoute}`}
              active={(route === status).toString()}
            >
              {normalizedRoute}
              <FakeSpan>{normalizedRoute}</FakeSpan>
            </TopBarButton>
          );
        })}
      </TopBarButtonsWrapper>
    </TopBarContainer>
  );
};

export default TopBar;
