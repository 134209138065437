import React, { FunctionComponent, useEffect, useState } from "react";
import FilesInput from "../FilesInput/FilesInput";
import { IFileSelection } from "../FilesInput/IFileSelection";
import FilesList from "../FilesList/FilesList";
import {
  ErrorSpan,
  FilesDropdownContainer,
  FilesInputContainer,
} from "./FilesDropdown.components";

type FilesInputTypes = "images" | "pdf";

type FilesDropdownProps = {
  type: FilesInputTypes;
  error?: string;
  maxFileSizeMB?: number;
  maxFiles?: number;
  filesChanged: (files: IFileSelection[]) => void;
  initialValues?: IFileSelection[];
};

export const getMimes = (type: FilesInputTypes) => {
  switch (type) {
    case "images":
      return ["image/jpeg", "image/png", "image/webp"];
    case "pdf":
      return ["application/pdf"];
  }
};

const getTypeDescription = (
  type: FilesInputTypes,
  fileSizeMB: number,
  maxFiles: number,
) => {
  let extensions = "";
  switch (type) {
    case "images":
      extensions = "JPEG, PNG, WebP";
      break;
    case "pdf":
      extensions = "PDF";
      break;
  }
  return `${extensions}, max ${maxFiles} files, max ${fileSizeMB}MB each`;
};

const FilesDropdown: FunctionComponent<FilesDropdownProps> = ({
  type,
  error,
  maxFiles = 10,
  maxFileSizeMB = 2,
  initialValues = [],
  filesChanged,
}) => {
  const [inputError, setInputError] = useState("");
  const [filesSelections, setFilesSelections] =
    useState<IFileSelection[]>(initialValues);

  useEffect(() => {
    setInputError("");
  }, [filesSelections]);

  return (
    <FilesDropdownContainer>
      <FilesInputContainer empty={filesSelections.length === 0}>
        <FilesInput
          maxFiles={maxFiles}
          maxSizeMB={maxFileSizeMB}
          mimes={getMimes(type)}
          onFiles={(newFiles) => {
            if (newFiles.length + filesSelections.length > maxFiles) {
              setInputError("Too many files.");
            } else {
              const newFilesWithoutDuplicatedNames = newFiles.map((file) => {
                const extension = file.name.substring(
                  file.name.lastIndexOf(".") + 1,
                );
                const originalName = file.name.substring(
                  0,
                  file.name.lastIndexOf("."),
                );

                let name = file.name;
                let index = 1;

                // eslint-disable-next-line no-loop-func
                while (filesSelections.find((s) => s.name === name)) {
                  name = `${originalName}(${index}).${extension}`;
                  index += 1;
                }

                return { ...file, name };
              });

              const files = [
                ...filesSelections,
                ...newFilesWithoutDuplicatedNames,
              ];
              setFilesSelections(files);
              filesChanged(files);
            }
          }}
          onFileRejected={(error) => setInputError(error)}
          description={getTypeDescription(type, maxFileSizeMB, maxFiles)}
        />
        {(inputError !== "" || error) && (
          <ErrorSpan>{inputError || error}</ErrorSpan>
        )}
      </FilesInputContainer>
      <FilesList
        files={filesSelections}
        onDelete={(selection) => {
          const newFiles = filesSelections.filter((s) => s.id !== selection.id);
          setFilesSelections(newFiles);
          filesChanged(newFiles);
        }}
      />
    </FilesDropdownContainer>
  );
};

export default FilesDropdown;
