import { AccountType } from "../../generated/schema";
import useAppContext from "../../modules/App/AppContext/useAppContext";

export const useAccountType = (accountTypes?: AccountType[]): boolean => {
  const { authToken } = useAppContext();

  if (!accountTypes || accountTypes.length === 0) return true;

  return accountTypes.some((accountType) =>
    authToken?.accountType.includes(accountType),
  );
};
