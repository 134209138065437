import { useCallback, useEffect } from "react";
import useAppContext from "../../modules/App/AppContext/useAppContext";
import { useModal } from "../../modules/shared/components/Modal";
import AccountDetailsModal from "../../modules/shared/components/Modals/AccountDetailsModal/AccountDetailsModal";

export const useShowAccoutnDetailsModal = () => {
  const { openModal } = useModal();
  const { currentUser } = useAppContext();
  const openAccountDetailsModal = useCallback(() => {
    openModal(<AccountDetailsModal />, false);
  }, [openModal]);
  useEffect(() => {
    if (currentUser !== null && !currentUser?.name) {
      openAccountDetailsModal();
    }
  }, [openAccountDetailsModal, currentUser]);
};
