import React, { FunctionComponent, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Footer from "../../shared/components/Footer/Footer";
import Gallery from "../../shared/components/Gallery/Gallery";
import { useIsMediaDown } from "../../shared/media";
import AgentDetails from "./components/AgentDetails";
import Attachments from "./components/Attachments";
import ListingAggregated from "./components/ListingAggregated/ListingAggregated";
import Location from "./components/Location";
import Property from "./components/Property";
import {
  AgentDetailsSectionWrapper,
  ContentContainer,
  PropertyDetailsBackContainer,
  PropertyDetailsBackDummyView,
  PropertyDetailsBackImageContainer,
  PropertyDetailsBackSpan,
  PropertyDetailsContainer,
  PropertyDetailsContent,
  PropertyDetailsFooterContainer,
  PropertyDetailsWrapper,
} from "./PropertyDetails.components";
import BackIcon from "./back_icon.svg";
import AreaStatistics from "./components/AreaStatistics";
import { PropertyType } from "../PropertyType";
import MessageSent from "./components/MessageSent/MessageSent";
import { propertyDetailsRootPaths } from "../../../../../backend/src/shared/routes/routes";

type PropertyDetailsType = {
  property?: PropertyType;
};

const PropertyDetails: FunctionComponent<PropertyDetailsType> = ({
  property,
}) => {
  const [messageSent, setMessageSent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isTabletDown = useIsMediaDown("tablet");

  return (
    <PropertyDetailsContainer>
      <PropertyDetailsWrapper>
        <PropertyDetailsContent>
          {!location.pathname.includes(
            propertyDetailsRootPaths.PROPERTY_ACCEPT_ROOT,
          ) ? (
            <PropertyDetailsBackContainer onClick={() => navigate(-1)}>
              <PropertyDetailsBackImageContainer>
                <img src={BackIcon} alt="Back" />
              </PropertyDetailsBackImageContainer>
              <PropertyDetailsBackSpan>
                Back to listings
              </PropertyDetailsBackSpan>
            </PropertyDetailsBackContainer>
          ) : (
            <PropertyDetailsBackDummyView />
          )}

          {isTabletDown && messageSent && (
            <MessageSent contactName={property?.contactName!} />
          )}
          <Gallery propertyPhotos={property?.propertyPhotos || []} />
          <ContentContainer>
            <Location {...property!} />
            <AreaStatistics {...property!} />
            <Property {...property!} />
            <Attachments
              heading="Brochures &amp; Documents"
              files={property?.brochuresDocuments || []}
            />
            <Attachments
              heading="Floor plans"
              files={property?.floorPlans || []}
            />
            {isTabletDown && property?.externalPropertyUrl && (
              <ListingAggregated
                externalPropertyUrl={property?.externalPropertyUrl!}
              />
            )}
          </ContentContainer>
        </PropertyDetailsContent>
        <AgentDetailsSectionWrapper>
          {!property?.isExternal && (
            <AgentDetails {...property!} setMessageSent={setMessageSent} />
          )}
          {!isTabletDown && (
            <>
              {messageSent && (
                <MessageSent contactName={property?.contactName!} />
              )}
              {property?.externalPropertyUrl && (
                <ListingAggregated
                  externalPropertyUrl={property?.externalPropertyUrl!}
                />
              )}
            </>
          )}
        </AgentDetailsSectionWrapper>
      </PropertyDetailsWrapper>
      <PropertyDetailsFooterContainer>
        <Footer />
      </PropertyDetailsFooterContainer>
    </PropertyDetailsContainer>
  );
};

export default PropertyDetails;
