export const colors = {
  primary: {
    pure_white: "#ffffff",
    pure_white_transparent: "rgba(255, 255, 255, 0.13)",
    frosted_black: "#0A101E",
    frosted_black_transparent: "rgba(10, 16,30, 0.7)",
    frosted_black_transparent_25: "rgba(10, 16,30, 0.25)",
  },
  secondary: {
    pure_blue: "#2074E9",
    pure_blue_transparent: "rgba(59, 122, 247, 0.12)",
    pure_blue_light: "#5195f6",
    pure_blue_dark: "#166BDA",
    blue_extra_light: "#90CBFE",
    blue_extra_dark: "#0F56B3",
  },
  grayscale: {
    _100: "#F9FBFE",
    _200: "#EFF4FA",
    _300: "#E4ECF6",
    _400: "#D4DCED",
    _500: "#96a0b6",
    _600: "#606C85",
    _700: "#434E65",
    _800: "#242833",
    _900: "#25272E",
  },
  semantic: {
    red: "#F44E64",
    red_dark: "#F20D42",
    green: "#31C4AB",
    green_dark: "#0A9981",
    red_light: "#FFE6E9",
    orange_dark: "#ED7B12",
  },
};
