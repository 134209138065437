import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { ModalContext } from "..";
import useAppContext from "../../../../App/AppContext/useAppContext";
import ModalContainer from "../ModalContainer";
import {
  ContainerProps,
  ModalContainerChild,
} from "../ModalContainer.components";
import { ModalContextType } from "./ModalContext";

export type ModalContentType = ReactElement<ContainerProps>;

export interface ModalState {
  content?: ModalContentType;
  dismissable?: boolean;
  isOpen: boolean;
}

const ModalProvider: FunctionComponent = ({ children }) => {
  const location = useLocation();
  const { currentUser } = useAppContext();
  const closeModal = useRef<() => void>();
  const [state, setState] = useState<ModalState>({
    isOpen: false,
  });

  const contextValue = useMemo<ModalContextType>(
    () => ({
      openModal(content, dismissable) {
        setState({
          content,
          dismissable,
          isOpen: true,
        });
      },
      closeModal() {
        setState((prevState) => ({
          ...prevState,
          dismissable: false,
          isOpen: false,
        }));
      },
    }),
    [],
  );

  useEffect(() => {
    closeModal.current = () => {
      if (state.dismissable !== false) {
        contextValue.closeModal();
      }
    };
  }, [contextValue, state.dismissable]);

  useEffect(() => {
    closeModal.current!();
  }, [location.pathname, currentUser]);

  const handleClose = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (state.dismissable && event.target === event.currentTarget) {
        contextValue.closeModal();
      }
    },
    [contextValue, state.dismissable],
  );

  return (
    <ModalContext.Provider value={contextValue}>
      <ModalContainer isOpen={state.isOpen} onBackgroundClick={handleClose}>
        {state.content}
      </ModalContainer>
      <ModalContainerChild isOpen={state.isOpen}>
        {children}
      </ModalContainerChild>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
