import React, { FunctionComponent } from "react";
import {
  FormBoxContainer,
  FormBoxContent,
  FormBoxHeader,
  FormBoxHeaderIcon,
  FormBoxSectionHeader,
} from "./FormBox.componentsy";
import ContactIcon from "./contact_icon.svg";
import DocumentsIcon from "./documents_icon.svg";
import FloorPlansIcon from "./floor_plans_icon.svg";
import ListingIcon from "./listing_icon.svg";
import LocationIcon from "./location_icon.svg";
import PropertyDetailsIcon from "./property_details_icon.svg";
import PropertyPhotosIcon from "./property_photos_icon.svg";
import SpaceIcon from "./space_icon.svg";

type FormBoxSection =
  | "LOCATION"
  | "LISTING_DETAILS"
  | "SPACE_DETAILS"
  | "PROPERTY_DETAILS"
  | "PROPERTY_PHOTOS"
  | "FLOOR_PLANS"
  | "DOCUMENTS"
  | "CONTACT";

const getSectionTitle = (section: FormBoxSection) => {
  switch (section) {
    case "LOCATION":
      return "Location";
    case "LISTING_DETAILS":
      return "Listing Details";
    case "SPACE_DETAILS":
      return "Space details";
    case "PROPERTY_DETAILS":
      return "Property details";
    case "PROPERTY_PHOTOS":
      return "Property photos";
    case "FLOOR_PLANS":
      return "Floor plans (opt.)";
    case "DOCUMENTS":
      return "Brochures & Other documents (opt.)";
    case "CONTACT":
      return "Contact";
  }
};

const getSectionIcon = (section: FormBoxSection) => {
  switch (section) {
    case "LOCATION":
      return LocationIcon;
    case "LISTING_DETAILS":
      return ListingIcon;
    case "SPACE_DETAILS":
      return SpaceIcon;
    case "PROPERTY_DETAILS":
      return PropertyDetailsIcon;
    case "PROPERTY_PHOTOS":
      return PropertyPhotosIcon;
    case "FLOOR_PLANS":
      return FloorPlansIcon;
    case "DOCUMENTS":
      return DocumentsIcon;
    case "CONTACT":
      return ContactIcon;
  }
};

type FormBoxProps = {
  section: FormBoxSection;
  description?: string;
};

const FormBox: FunctionComponent<FormBoxProps> = ({
  section,
  description,
  ...props
}) => {
  return (
    <FormBoxContainer>
      <FormBoxHeader>
        <FormBoxHeaderIcon src={getSectionIcon(section)} />
        {getSectionTitle(section)}
      </FormBoxHeader>
      <FormBoxContent hasDescription={!!description}>
        {description && (
          <FormBoxSectionHeader>{description}</FormBoxSectionHeader>
        )}

        {props.children}
      </FormBoxContent>
    </FormBoxContainer>
  );
};

export default FormBox;
