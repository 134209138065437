import React, { FunctionComponent } from "react";
import Button from "../../shared/components/Button";
import {
  BackIcon,
  BackToLoginLink,
  ButtonContainer,
  ButtonRegisterContainer,
  CommitLogo,
  Container,
  Description,
  FormContent,
  Header,
} from "../SetNewPassword/SetNewPassword.components";
import CommigLogoBlue from "../../shared/images/commit_logo_blue.svg";
import routesPaths from "../../Routing/routesPaths";
import ArrowLeft from "../arrow_left.svg";
import { useNavigate } from "react-router";

const LinkExpired: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <FormContent>
        <CommitLogo src={CommigLogoBlue} alt="Commit logo" />
        <Header>Link expired</Header>
        <Description>
          You waited too long! The link to reset your password is no longer
          valid. <br />
          <br />
          But don’t worry, you can start theprocess again.
        </Description>

        <ButtonRegisterContainer>
          <ButtonContainer>
            <Button
              size="block"
              onClick={() => navigate(routesPaths.RESET_PASSWORD)}
            >
              Reset Password
            </Button>
          </ButtonContainer>
          <BackToLoginLink to={routesPaths.LOGIN}>
            <BackIcon src={ArrowLeft} />
            Back to login
          </BackToLoginLink>
        </ButtonRegisterContainer>
      </FormContent>
    </Container>
  );
};

export default LinkExpired;
