import React, { FunctionComponent } from "react";
import {
  CopyrightSpan,
  NotFoundContainer,
  NotFoundDescription,
  NotFoundHeading,
  NotFoundWrapper,
} from "./NotFound.components";
import * as animationData from "./79925-mbt-city.json";
import Lottie from "react-lottie";
import { useIsMediaDown } from "../shared/media";
import PublicLayout from "../Layouts/PublicLayout";

const NotFound: FunctionComponent = () => {
  const isMobileDown = useIsMediaDown("mobile");
  const defaultOptions = {
    loop: true,
    autoplay: true,
    duration: 1,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <PublicLayout>
      <NotFoundContainer>
        <NotFoundWrapper>
          <Lottie
            options={defaultOptions}
            width={isMobileDown ? "100%" : 762}
          />
          <NotFoundHeading>Page not found.</NotFoundHeading>
          <NotFoundDescription>
            Sorry, we couldn't find this page. But don't worry, you can find
            plenty of other things on our <a href="/">homepage.</a>
          </NotFoundDescription>
        </NotFoundWrapper>
        <CopyrightSpan>
          Distributed under the Lottie Simple License
        </CopyrightSpan>
      </NotFoundContainer>
    </PublicLayout>
  );
};

export default NotFound;
