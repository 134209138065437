import styled from "styled-components";
import { colors } from "../../../../shared/colors";
import { down } from "../../../../shared/media";

export const ListingAggregatedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 40px;
  box-sizing: border-box;
  background-color: ${colors.primary.pure_white};
  border-radius: 12px;
  margin-top: 8px;

  /* Smaller margin as first-child to avoid extra space when there is only external property url. */
  :first-child {
    margin-top: 0px;
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: ${colors.grayscale._600};

    ${down("tablet")} {
      max-width: 555px; //To wrap text.
    }
  }

  ${down("tablet")} {
    width: 100%;
  }

  ${down("mobile")} {
    padding: 16px 24px;
  }
`;

export const ListingAggregatedButton = styled.a`
  display: flex;
  align-items: center;
  margin-top: 18px;
  text-decoration: none;
  cursor: pointer;
  color: ${colors.secondary.pure_blue};
  > span {
    margin-left: 10px;
  }
  :hover {
    text-decoration: underline;
  }
`;
