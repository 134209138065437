import { Form, FormikProps } from "formik";
import React, { FunctionComponent } from "react";
import Button from "../../shared/components/Button";
import Password from "../../shared/components/Form/Password";
import { SetNewPasswordFormValues } from "./form";
import {
  BackIcon,
  BackToLoginLink,
  ButtonContainer,
  ButtonRegisterContainer,
  CommitLogo,
  Container,
  Description,
  FormContent,
  Header,
  InputContainer,
} from "./SetNewPassword.components";
import CommigLogoBlue from "../../shared/images/commit_logo_blue.svg";
import routesPaths from "../../Routing/routesPaths";
import ArrowLeft from "../arrow_left.svg";

type Props = FormikProps<SetNewPasswordFormValues>;

const SetNewPasswordFormContent: FunctionComponent<Props> = ({
  isSubmitting,
  isValid,
  submitCount,
  values,
  ...props
}) => {
  return (
    <Container>
      <Form>
        <FormContent>
          <CommitLogo src={CommigLogoBlue} alt="Commit logo" />
          <Header>New password</Header>
          <Description>
            And the last step. Please, set up your new password.
          </Description>
          <InputContainer>
            <Password name="password" {...props} />
          </InputContainer>
          <ButtonRegisterContainer>
            <ButtonContainer>
              <Button type="submit" size="block" isLoading={isSubmitting}>
                Set up new password
              </Button>
            </ButtonContainer>
            <BackToLoginLink to={routesPaths.LOGIN}>
              <BackIcon src={ArrowLeft} />
              Back to login
            </BackToLoginLink>
          </ButtonRegisterContainer>
        </FormContent>
      </Form>
    </Container>
  );
};

export default SetNewPasswordFormContent;
