export const publicStaticPaths = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  RESET_PASSWORD: '/reset-password',
  ERROR404: '/404',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  COOKIES: '/cookies',
  FAQ: '/frequently-asked-questions',
};

export const privateStaticPaths = {
  SEO: '/seo',
  MY_PROPERTIES_ROOT: '/my-properties',
  MY_PROPERTIES: '/my-properties/:status',
  ADD_PROPERTY: '/add-property',
  LISTINGS_ROOT: '/listings',
  LISTINGS: '/listings/:status',
};

export const staticPaths = { ...publicStaticPaths, ...privateStaticPaths };

const searchResultPaths = {
  PROPERTIES: '/properties',
};

export const propertyDetailsRootPaths = {
  PROPERTY_DETAILS_ROOT: '/p',
  PROPERTY_PREVIEW_ROOT: '/p/preview',
  PROPERTY_ACCEPT_ROOT: '/p/accept',
};

export const propertyDetailsPaths = {
  PROPERTY_DETAILS: '/p/:slug',
  PROPERTY_PREVIEW: '/p/preview/:uid',
  PROPERTY_ACCEPT: '/p/accept/:uid',
};

export const DEFAULT_ESTATE_AGENT_ROUTE = '/my-properties/active';

export const seoLinkPath = '/s/:slug';
export const blogPath = '/blog';
// ALL IN ONE
export const routes = {
  ...staticPaths,
  ...searchResultPaths,
  ...propertyDetailsPaths,
};
