import React, { FunctionComponent } from "react";
import Loader from "../shared/components/Loader";
import { Container } from "./ScreenLoading.components";

const ScreenLoading: FunctionComponent = () => (
  <Container>
    <Loader />
    <span>Wait a moment</span>
  </Container>
);

export default ScreenLoading;
