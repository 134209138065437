import styled, { css } from "styled-components";
import { colors } from "../../colors";
import { down } from "../../media";

export type ButtonType =
  | "dark"
  | "light"
  | "primary"
  | "outline"
  | "red"
  | "outline_dark";

export type ButtonSize = "large" | "medium" | "normal" | "block";

type ButtonHeight = "xl" | "l" | "m";

export interface StyledButtonProps {
  buttonType?: ButtonType;
  height?: ButtonHeight;
  size?: ButtonSize;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const getTextColor = (type?: ButtonType) => {
  switch (type) {
    case "outline":
      return colors.secondary.pure_blue;
    case "dark":
    case "primary":
    case "red":
      return colors.primary.pure_white;
    case "light":
    case "outline_dark":
    default:
      return colors.primary.frosted_black;
  }
};

const getBackgroundColor = (type?: ButtonType) => {
  switch (type) {
    case "dark":
      return colors.primary.frosted_black;
    case "outline":
    case "outline_dark":
    case "light":
      return colors.primary.pure_white;
    case "red":
      return colors.semantic.red_dark;
    case "primary":
    default:
      return colors.secondary.pure_blue;
  }
};

const getHeight = (height?: ButtonHeight) => {
  switch (height) {
    case "xl":
      return 56;
    case "l":
      return 48;
    case "m":
    default:
      return 40;
  }
};

const getWidth = (width?: ButtonSize) => {
  switch (width) {
    case "large":
      return "200px";
    case "medium":
      return "168px";
    case "normal":
      return "140px";
    case "block":
    default:
      return "100%";
  }
};

const getFontSize = (height?: ButtonHeight) => {
  switch (height) {
    case "xl":
      return 18;
    case "l":
      return 16;
    case "m":
    default:
      return 14;
  }
};

const getOutline = (type?: ButtonType) => {
  switch (type) {
    case "red":
      return `0px 0px 0px 4px ${colors.semantic.red_light};`;
    case "light":
      return "0px 0px 0px 4px rgba(255, 255, 255, 0.16);";
    case "dark":
    case "primary":
    default:
      return "0px 0px 0px 4px rgba(59, 122, 247, 0.12);";
  }
};

const getFocusedBackground = (type?: ButtonType) => {
  switch (type) {
    case "dark":
      return colors.grayscale._800;
    case "light":
    case "outline":
    case "outline_dark":
      return colors.primary.pure_white;
    case "red":
      return colors.semantic.red;
    case "primary":
    default:
      return colors.secondary.pure_blue_light;
  }
};

const getBorder = (type?: ButtonType) => {
  switch (type) {
    case "outline":
      return `1px solid ${colors.secondary.pure_blue}`;
    case "outline_dark":
      return `1px solid ${colors.primary.frosted_black}`;
    default:
      return "none";
  }
};

export const StyledButton = styled.button.attrs<StyledButtonProps>((p) => ({
  disabled: p.disabled,
  type: p.type || "button",
}))<StyledButtonProps>`
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => getFontSize(p.height)}px;
  line-height: 24px;
  font-weight: 500;
  white-space: nowrap;
  outline: none;
  border-radius: 8px;
  border: none;
  height: ${(p) => getHeight(p.height)}px;
  width: ${(p) => getWidth(p.size)};
  color: ${(p) => getTextColor(p.buttonType)};
  background: ${(p) => getBackgroundColor(p.buttonType)};
  cursor: pointer;
  transition: transform 0.5s ease;

  ${down("mobile")} {
    height: ${(p) => (p.height === "m" ? 40 : 48)}px;
  }

  ${(p) =>
    p.isDisabled
      ? css`
          background: ${colors.grayscale._400};
          cursor: not-allowed;
        `
      : css`
          :hover {
            transform: scale(1.05);
          }
          :focus {
            background-color: ${getFocusedBackground(p.buttonType)};
            box-shadow: ${getOutline(p.buttonType)};
          }
        `}

  ${(p) => p.isLoading && "cursor: not-allowed;"}


  border: ${(p) => getBorder(p.buttonType)}
`;
