import { PropertyInsightType } from "../../../../../modules/PropertyPreview/PropertyType";
import { useIsMediaDown } from "../../../../../modules/shared/media";
import React, { FunctionComponent } from "react";
import Button from "../../../../shared/components/Button";
import {
  PropertyDetailsSectionHeading,
  PropertyDetailsSectionSeparator,
} from "../../PropertyDetails.components";
import { AttachmentsWrapper } from "../Attachments/Attachments.components";
import {
  AreaStatisticsDescription,
  CrimeRateChartWrapper,
  EvenMoreStatsHeading,
  EvenMoreStatsInnerWrapper,
  EvenMoreStatsWrapper,
  RowChartsWrapper,
} from "./AreaStatistics.components";
import AreaProsperity from "./Charts/AreaProsperity/AreaProsperity";
import CrimeRate from "./Charts/CrimeRate/CrimeRate";
import PriceTrend from "./Charts/PriceTrend/PriceTrend";
import urls from "../../../../../common/constants/urls";

type AreaSatisticsProps = {
  propertyInsight: PropertyInsightType;
  postcode: string;
};

const AreaStatistics: FunctionComponent<AreaSatisticsProps> = ({
  propertyInsight,
  postcode,
}) => {
  const isMobileDown = useIsMediaDown("mobile");
  return (
    <AttachmentsWrapper>
      <PropertyDetailsSectionHeading>
        Area Statistics
      </PropertyDetailsSectionHeading>
      <AreaStatisticsDescription>
        Find out more about the area in which the property is located.
      </AreaStatisticsDescription>
      <PropertyDetailsSectionSeparator />
      <CrimeRateChartWrapper>
        <CrimeRate {...propertyInsight} postcode={postcode} />
      </CrimeRateChartWrapper>
      {!isMobileDown && <PropertyDetailsSectionSeparator />}
      <RowChartsWrapper>
        <PriceTrend {...propertyInsight} />
        <AreaProsperity
          {...propertyInsight}
          areaPrsperityIndex={propertyInsight.areaProsperityIndex}
        />
      </RowChartsWrapper>
      {!isMobileDown && <PropertyDetailsSectionSeparator />}
      <EvenMoreStatsWrapper>
        <EvenMoreStatsInnerWrapper>
          <EvenMoreStatsHeading>Even more stats?</EvenMoreStatsHeading>
          <p>Demographic, crime, competitors statistics, all in one place!</p>
        </EvenMoreStatsInnerWrapper>
        <Button
          buttonType="light"
          size="medium"
          onClick={() =>
            window.open(
              `${urls.STATS_BASE_URL}/${postcode.replace(" ", "")}`,
              "_blank",
            )
          }
        >
          Check all statistics
        </Button>
      </EvenMoreStatsWrapper>
    </AttachmentsWrapper>
  );
};

export default AreaStatistics;
