import { GrowthIndexType } from "../../../../../../../generated/schema";
import React, { FunctionComponent } from "react";
import { ChartHeader } from "../Charts.components";
import {
  PriceTrendChartWrapper,
  PriceTrendContainer,
  PriceTrendHeading,
  PriceTrendWrapper,
} from "./PriceTrend.components";
import PriceTrendChart from "./PriceTrendChart";

type PriceTrendProps = {
  price1YearIndex: number;
  price1YearTrend: GrowthIndexType;
  price5YearIndex: number;
  price5YearTrend: GrowthIndexType;
  price10YearIndex: number;
  price10YearTrend: GrowthIndexType;
};
const PriceTrend: FunctionComponent<PriceTrendProps> = ({
  price1YearIndex,
  price1YearTrend,
  price5YearIndex,
  price5YearTrend,
  price10YearIndex,
  price10YearTrend,
}) => {
  return (
    <PriceTrendContainer>
      <ChartHeader>Property Price Trend</ChartHeader>
      <PriceTrendWrapper>
        <PriceTrendHeading>
          {/* Map INCREASE/DECREASE to increasing/decreasing */}
          {`${price1YearTrend
            .slice(0, price1YearTrend.length - 1)
            .toLowerCase()}ing`}
        </PriceTrendHeading>
        <PriceTrendChartWrapper>
          <PriceTrendChart
            type="YEAR"
            percentage={price1YearIndex}
            growthIndex={price1YearTrend}
          />
          <PriceTrendChart
            type="5_YEARS"
            percentage={price5YearIndex}
            growthIndex={price5YearTrend}
          />
          <PriceTrendChart
            type="10_YEARS"
            percentage={price10YearIndex}
            growthIndex={price10YearTrend}
          />
        </PriceTrendChartWrapper>
      </PriceTrendWrapper>
    </PriceTrendContainer>
  );
};

export default PriceTrend;
