import styled from "styled-components";
import { colors } from "../shared/colors";
import { down } from "../shared/media";
import {
  DefaultScaleTransform,
  H6Style,
  LeadSmallStyle,
} from "../shared/mixins";

export const SeoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.grayscale._200};
  justify-content: center;
  align-items: center;
`;

export const SeoInnerContainer = styled.div`
  max-width: 1088px;
  width: 100%;
  height: 100%;
  margin-top: 32px;
  padding-bottom: 54px;

  ${down("tablet")} {
    max-width: calc(100% - 112px);
  }
`;

export const SeoRecordsWrapper = styled.div`
  margin-top: 32px;
`;

export const SeoRecord = styled.div`
  display: flex;
  max-width: 860px;
  width: 100%;
  margin-bottom: 16px;
`;

export const SeoRecordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 72px 24px 40px;
  box-sizing: border-box;
  background-color: ${colors.primary.pure_white};
  border-radius: 16px;
  max-width: 812px;
  width: 100%;

  ${down("tablet")} {
    overflow: hidden;
    padding: 24px 32px;
    max-width: unset;
  }
`;

export const DeleteSeoRecordButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  margin-left: 8px;
  background-color: ${colors.primary.pure_white};
  ${DefaultScaleTransform()}
`;

export const SeoRecordHeading = styled.h6`
  margin: 0;
  ${H6Style()}

  >span {
    color: ${colors.grayscale._600};
    font-weight: 400;
  }
`;

export const SeoRecordUrl = styled.a`
  ${LeadSmallStyle()};
  color: ${colors.secondary.pure_blue};
  margin-top: 12px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const SeoRecordDescription = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${colors.grayscale._600};
  margin-top: 12px;
`;
