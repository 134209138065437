import styled, { css } from "styled-components";
import { colors } from "../../colors";
import { down } from "../../media";
import { HorizontalGap, Unselectable } from "../../mixins";

const PaginationPillStyles = () => css`
  display: flex;
  min-width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 100px;
  background: ${colors.primary.pure_white};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  ${Unselectable()};
`;

export const PaginationWrapper = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const PaginationArrow = styled.li<{ disabled?: boolean }>`
  padding: 10px 24px;
  background: ${colors.primary.pure_white};
  border-radius: 100px;
  cursor: pointer;
  white-space: nowrap;
  ${Unselectable()};
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
  transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease;
  :hover {
    background: ${colors.grayscale._600};
    color: ${colors.primary.pure_white};
    transform: scale(1.05);
  }
`;

export const PaginationPillsWrapper = styled.div`
  display: flex;
  margin: 0 48px;
  width: 290px;
  justify-content: center;
  ${HorizontalGap(16)};

  ${down("tabletSmall")} {
    margin: 0px;
  }
`;

export const PaginationMobileWrapper = styled.div``;

export const PaginationMobileInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  > :last-child {
    margin-left: 16px;
  }
`;

export const PaginationDots = styled.li`
  ${PaginationPillStyles()};
`;

export const Page = styled.li<{
  active?: boolean;
  additionalPadding?: boolean;
}>`
  ${PaginationPillStyles()};
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease;

  ${(p) =>
    p.additionalPadding &&
    css`
      padding: 0 8px;
    `}

  ${(p) =>
    p.active
      ? css`
          background: ${colors.primary.frosted_black};
          color: ${colors.primary.pure_white};
        `
      : css`
          :hover {
            background: ${colors.grayscale._600};
            color: ${colors.primary.pure_white};
            transform: scale(1.05);
          }
        `}
`;
