import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
} from "react";
import Button from "../../../../shared/components/Button";
import { useModal } from "../../../../shared/components/Modal";
import MessageAgentModal from "../../../../shared/components/Modals/MessageAgentModal/MessageAgentModal";
import {
  AgentDetailsButtonWrapper,
  AgentDetailsDescription,
  AgentDetailsName,
  AgentDetailsStickyPanel,
} from "./AgentDetails.components";

type AgentDetailsProps = {
  uid: string;
  contactName: string;
  contactBio: string;
  setMessageSent: Dispatch<SetStateAction<boolean>>;
};
const AgentDetails: FunctionComponent<AgentDetailsProps> = ({
  uid,
  contactName,
  contactBio,
  setMessageSent,
}) => {
  const { openModal } = useModal();
  const openMessageAgentModal = useCallback(() => {
    openModal(
      <MessageAgentModal
        agentName={contactName}
        uid={uid}
        setMessageSent={setMessageSent}
      />,
    );
  }, [openModal, uid, contactName, setMessageSent]);

  return (
    <AgentDetailsStickyPanel>
      <AgentDetailsName>{contactName}</AgentDetailsName>
      <AgentDetailsDescription>{contactBio}</AgentDetailsDescription>
      <AgentDetailsButtonWrapper>
        <Button size="block" height="l" onClick={openMessageAgentModal}>
          Message agent
        </Button>
      </AgentDetailsButtonWrapper>
    </AgentDetailsStickyPanel>
  );
};

export default AgentDetails;
