import styled, { css } from "styled-components";
import { colors } from "../../shared/colors";
import { down } from "../../shared/media";
import {
  HorizontalGap,
  LeadMediumStyle,
  LeadSmallStyle,
  VerticalGap,
} from "../../shared/mixins";

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  ${VerticalGap(20)}

  ${down("tablet")} {
    padding: 24px 40px 24px 56px;
  }

  ${down("mobile")} {
    padding: 24px;
  }
`;

export const FiltersDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.grayscale._300};
`;

export const CollapsableSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CollapsableSectionHeaderContainer = styled.div<{
  isExpandable: boolean;
}>`
  display: flex;
  width: 100%;
  align-items: center;
  height: 24px;

  ${(p) =>
    p.isExpandable &&
    css`
      cursor: pointer;
    `}
`;

export const CollapsableSectionHeader = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  flex: 1;
  color: ${colors.primary.frosted_black};

  ${down("tablet")} {
    font-size: 16px;
  }
`;

export const CollabsableSectionContent = styled.div<{ isExpanded: boolean }>`
  max-height: ${(p) => (p.isExpanded ? "100%" : "0")};
  overflow: ${(p) => (p.isExpanded ? "visible" : "hidden")};
  transition: all 300ms ease-in-out;
`;

export const CollapsableSectionExpandButtonContent = styled.div<{
  isExpanded: boolean;
}>`
  border-radius: 12px;
  height: 24px;
  width: 24px;
  background-color: ${colors.secondary.pure_blue_transparent};
  transition: background-color 250ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    transition: transform 250ms ease-in-out;
    ${(p) =>
    p.isExpanded
      ? css`
            transform: rotate(0deg);
          `
      : css`
            transform: rotate(180deg);
          `}
  }
`;

export const CollapsableSectionExpandButton = styled.button`
  -webkit-tap-highlight-color: transparent;
  width: 24px;
  height: 24px;
  padding: 0px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;

  :hover ${CollapsableSectionExpandButtonContent} {
    background-color: ${colors.secondary.pure_blue};
    > svg > path {
      fill: ${colors.primary.pure_white};
    }
  }
`;

export const LocationRangeContainer = styled.div`
  margin-top: 24px;
  display: grid;
  width: fit-content;
  grid-column-gap: 40px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  margin-left: 16px;

  ${down("tablet")} {
    grid-column-gap: 60px;
    grid-row-gap: 24px;
  }
`;

export const LocationRangeRadio = styled.button<{ checked: boolean }>`
  cursor: pointer;
  padding: 0px;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  
  :focus {
    ${props => !props.checked && css`
      > div {
        border: 2px solid ${colors.secondary.pure_blue_light};
      }
    `}
  }
`;

export const LocationRangeRadioText = styled.span`
  ${LeadMediumStyle()};
  color: ${colors.primary.frosted_black};
`;

export const FiltersInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  ${HorizontalGap(8)};

  ${down("tablet")} {
    margin-top: 16px;
  }
`;

export const FiltersInput = styled.div`
  flex: 1;
  max-width: 162px;
`;

export const FiltersCommitContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  ${VerticalGap(24)};
`;

export const FiltersSingleCommitFilter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FiltersSingleCommitFilterHeader = styled.span`
  ${LeadSmallStyle()};
  margin-bottom: 12px;
`;

export const FiltersSingleCommitRadioContainer = styled.div`
  display: flex;
  ${HorizontalGap(4)};
`;

export const FiltersSingleCommitRadio = styled.div`
  flex: 1;
  max-width: 106px;
`;

export const FiltersPropertyUseContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${VerticalGap(16)};
  margin-top: 16px;
`;

export const FiltersPropertyUseText = styled.span`
  ${LeadMediumStyle};
  -webkit-tap-highlight-color: transparent;
  margin-left: 8px;
`;

export const FitlersPropertyUseContainer = styled.div`
  display: none;
  ${down("tablet")} {
    display: flex;
    flex-direction: column;
    ${VerticalGap(20)}
  }
`;
