import * as Yup from "yup";
export interface ResetPasswordFormValues {
  email: string;
}

export const resetPasswordValidationSchema = Yup.object({
  email: Yup.string()
    .email("Email must be valid")
    .required("Email is required"),
});
