import styled from "styled-components";
import { colors } from "../../../colors";
import { down } from "../../../media";
import { H4Style } from "../../../mixins";

export const ModalHeaderWrapper = styled.div`
  border-bottom: 1px solid ${colors.grayscale._300};
  width: 100%;
  padding-bottom: 16px;

  ${down("mobile")} {
    border: none;
    padding-bottom: 0px;
  }
`;

export const ModalHeading = styled.h4`
  ${H4Style};
`;

export const ModalDescription = styled.p`
  margin: 0;
  margin-top: 4px;
  color: ${colors.grayscale._600};
  font-size: 14px;
  line-height: 16px;
  ${down("mobile")} {
    font-size: 16px;
    line-height: 24px;
  }
`;
