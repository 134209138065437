import React, { FunctionComponent, useCallback } from "react";
import { PROPERTY_USE_TYPE_OPTIONS } from "../../../common/helpers/enumNamesHelper";
import { PropertyUseType } from "../../../generated/schema";
import FakeCheckbox from "../../shared/components/Form/FakeCheckbox";
import CollapsableSection from "./CollapsableSection";
import {
  FiltersPropertyUseContainer,
  FiltersPropertyUseText,
} from "./Filters.components";

type PropertyUseProps = {
  useTypes: PropertyUseType[];
  onChange: (types: PropertyUseType[]) => void;
};

const PropertyUse: FunctionComponent<PropertyUseProps> = ({
  useTypes,
  onChange,
  ...props
}) => {
  const onItemClick = useCallback(
    (type: PropertyUseType) => {
      const values = useTypes.includes(type)
        ? useTypes.filter((t) => t !== type)
        : useTypes.concat(type);
      onChange(values);
    },
    [onChange, useTypes],
  );
  return (
    <CollapsableSection
      title={`Property Use Type${
        useTypes.length > 0 ? ` (${useTypes.length})` : ""
      }`}
      isExpandable
    >
      <FiltersPropertyUseContainer>
        {PROPERTY_USE_TYPE_OPTIONS.map((option) => (
          <FakeCheckbox
            checked={useTypes.includes(option.type)}
            onClick={() => onItemClick(option.type)}
            key={option.value}
          >
            <FiltersPropertyUseText onClick={() => onItemClick(option.type)}>
              {option.value}
            </FiltersPropertyUseText>
          </FakeCheckbox>
        ))}
      </FiltersPropertyUseContainer>
    </CollapsableSection>
  );
};

export default PropertyUse;
