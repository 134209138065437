import { FormikProps } from "formik";
import React, { FunctionComponent } from "react";
import FilesDropdown from "../../shared/components/FilesDropdown/FilesDropdown";
import { Descriptions } from "../AddPropertyFormHints";
import { AddPropertyFormValues } from "../form";
import FormBox from "./FormBox/FormBox";

const FloorPlansSection: FunctionComponent<
  FormikProps<AddPropertyFormValues>
> = (props) => {
  const { setFieldValue } = props;
  return (
    <FormBox section="FLOOR_PLANS" description={Descriptions.floorPlans}>
      <FilesDropdown
        maxFileSizeMB={15}
        maxFiles={2}
        filesChanged={(files) => {
          setFieldValue("floorPlans", files);
        }}
        type="pdf"
      />
    </FormBox>
  );
};

export default FloorPlansSection;
