import styled from "styled-components";
import { colors } from "../../../colors";
import { down } from "../../../media";
import {
  DefaultScaleTransform,
  H5Style,
  LeadSmallStyle,
  ScrollBarStyle,
} from "../../../mixins";
import { MODAL_TRANSITION_TIME_MS } from "../../Modal/ModalContainer.components";

export const MessageAgentModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  transition: transform ${MODAL_TRANSITION_TIME_MS}ms ease-in-out;
  background: ${colors.primary.pure_white};
  margin: auto;
  padding: 40px 56px !important;
  box-sizing: border-box;
  border-radius: 16px;
  max-width: 526px;
  width: 100%;
  ${ScrollBarStyle()}

  ${down("mobile")} {
    width: 100%;
    margin-top: 56px;
    border-radius: 0;
    height: calc(100% - 56px);
    padding: 20px 24px 16px;
    overflow-y: scroll;
    max-width: 100%;
  }
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 28px;
  right: 28px;

  :hover {
    cursor: pointer;

    > svg {
      ${DefaultScaleTransform()};
    }
  }
`;

export const MessageAgentModalHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.grayscale._300};
  margin-bottom: 24px;
`;

export const MessageAgentModalHeader = styled.h5`
  ${H5Style()};
`;

export const MessageAgentGoBackMobile = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  > span {
    ${LeadSmallStyle()};
    margin-left: 4px;
  }

  ${DefaultScaleTransform(1.01)}
`;
