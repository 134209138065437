import { useMedia } from "use-media";

const breakpoints = {
  mobile: 599,
  tablet: 1200,
  tabletSmall: 949,
} as const;

type BreakpointsNames = keyof typeof breakpoints;

export const down = (name: BreakpointsNames) =>
  `@media(max-width: ${breakpoints[name]}px)`;

export const useIsMediaDown = (
  name: BreakpointsNames,
  defaultState?: boolean,
) => useMedia({ maxWidth: breakpoints[name] }, defaultState);

// To get information about immediately. useIsMediaDown initially always set value to false.
export const isMediaDown = (name: BreakpointsNames) =>
  window.innerWidth < breakpoints[name];
