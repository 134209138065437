import styled from "styled-components";
import { colors } from "../../../colors";

const ErrorMessage = styled.div`
  display: flex;
  white-space: nowrap;
  justify-content: flex-end;
  margin-top: 5px;
  color: ${colors.semantic.red_dark};
  font-weight: normal;
  font-size: 11px;
  line-height: 10px;
  letter-spacing: 0.01em;
  height: 11px;
`;

export default ErrorMessage;
