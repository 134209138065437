import { useEffect } from "react";
import { useGetRouteMetadataLazyQuery } from "../../generated/schema";
import { useLocation } from "react-router-dom";

const useMetadata = () => {
  const location = useLocation();
  const [getRouteMetadata, { data }] = useGetRouteMetadataLazyQuery();

  useEffect(() => {
    getRouteMetadata({
      variables: {
        url: window.location.href,
      },
    });
  }, [location, getRouteMetadata]);

  useEffect(() => {
    if (data) {
      const { title, description } = data.getRouteMetadata;
      document.title = title;
      document
        .getElementById("pageDescription")
        ?.setAttribute("content", description);
    }
  }, [data]);
};

export default useMetadata;
