import React from "react";
import {
  ResolutionNotSupportedContainer,
  ResolutionNotSupportedDescription,
  ResolutionNotSupportedDot,
  ResolutionNotSupportedHeading,
  ResolutionNotSupportedUrl,
} from "./ResolutionNotSupported.components";
import { ReactComponent as MobileImage } from "./MobileImage.svg";
import routesPaths from "../../../Routing/routesPaths";

const ResolutionNotSupported = () => {
  return (
    <ResolutionNotSupportedContainer>
      <ResolutionNotSupportedDot>
        <MobileImage />
      </ResolutionNotSupportedDot>
      <ResolutionNotSupportedHeading>
        Managing listings requires a screen <span>at least 600px</span> wide.
      </ResolutionNotSupportedHeading>
      <ResolutionNotSupportedDescription>
        We're still working on that. We'll add mobile functionality soon, but
        for now, please use the web version to manage your listings.
      </ResolutionNotSupportedDescription>
      <ResolutionNotSupportedUrl href={routesPaths.HOME}>
        Go home
      </ResolutionNotSupportedUrl>
    </ResolutionNotSupportedContainer>
  );
};

export default ResolutionNotSupported;
