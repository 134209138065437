import styled from "styled-components";
import { colors } from "../../../shared/colors";
import {
  HorizontalGap,
  LeadSmallStyle,
  VerticalGap,
} from "../../../shared/mixins";

export const ListingDetailsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${VerticalGap(24)};
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${HorizontalGap(12)};
`;

export const SmallInputContainer = styled.div`
  width: 94px;
`;

export const MediumInputContainer = styled.div`
  width: 147px;
`;

export const LargeInputContainer = styled.div`
  width: 200px;
`;

export const InputsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${colors.grayscale._300};
`;

export const CheckSpan = styled.span`
  ${LeadSmallStyle};
  margin-left: 4px;
`;

export const CheckboxContainer = styled.div`
  margin-top: 8px;
`;
