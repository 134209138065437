import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { colors } from "../../shared/colors";
import { down } from "../../shared/media";
import { BodyMediumStyle, H4Style, TinyStyle } from "../../shared/mixins";

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;

  > form {
    display: flex;
    height: 100%;
    flex: 1;
  }
`;

export const Header = styled.h4`
  ${H4Style};
`;

export const Description = styled.span`
  ${BodyMediumStyle};
`;

export const BottomDescription = styled(Description)`
  margin-bottom: 16px;
  align-self: flex-start;
`;

export const ErrorSpan = styled.span`
  ${TinyStyle}
  align-self: flex-start;
  color: ${colors.semantic.red_dark};
  margin: 8px 0px;
  display: flex;
  min-height: 16px;
  align-items: flex-end;
`;

export const FormContent = styled.div`
  margin: 80px 108px;
  flex: 1;
  display: flex;
  flex-direction: column;

  ${down("tablet")} {
    margin: 40px 72px 48px 72px;
  }

  ${down("mobile")} {
    margin: 24px;
  }
`;

export const CommitLogo = styled.img`
  width: 65px;
  height: 24px;
  display: none;

  ${down("tablet")} {
    display: block;
    margin-bottom: 20px;
  }

  ${down("mobile")} {
    margin-bottom: 36px;
  }
`;

export const InputContainer = styled.div`
  height: 72px;
  margin-top: 24px;

  :nth-of-type(2) {
    margin-top: 0px;

    ${down("mobile")} {
      margin-top: 16px;
    }
  }
`;

export const ButtonRegisterContainer = styled.span`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${down("mobile")} {
    align-items: center;
  }
`;

export const ButtonContainer = styled.div`
  width: 168px;
  height: 40px;

  ${down("mobile")} {
    width: 100%;
    height: 48px;
  }
`;

const BackIconAnimation = keyframes`
  0% {
    left: 0;
  }
  50% {
    left: -3px;
  }
  100% {
    left: 0;    
  }
`;

export const BackIcon = styled.img`
  position: absolute;
  left: 0px;
`;

export const BackToLoginLink = styled(Link)`
  text-decoration: none;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${colors.grayscale._600};
  margin-top: 24px;
  padding-left: 20px;

  :hover ${BackIcon} {
    animation: ease-in-out infinite;
    animation-name: ${BackIconAnimation};
    animation-duration: 1.5s;
  }

  ${down("mobile")} {
    margin-left: -8px; //To visually center text because of arrow.
  }
`;

export const EmailSentImage = styled.img`
  width: 205px;
  height: 104px;
  margin-bottom: 24px;

  ${down("tablet")} {
    margin-top: 16px;
  }

  ${down("mobile")} {
    margin-top: 0px;
  }
`;
