export const Hints = {
  propertyAddress: "Please enter the address of the property you wish to list.",
  flat: "Please enter the property street number.",
  postcode: "Please enter the postcode of the property.",
  locationType: "Let potential buyers know where the property is located.",
  tenancy:
    "Is the lease exclusive or are parts of the property rented to other businesses?",
  sqFootage:
    "What is the total square footage of the property, including all floors?",
  floors:
    "How many floors does the property you are listing have? Only include floors you are looking to sell or lease.",
  propertyUseType: "What is the current (or most recent) use of this property?",
  useClass: "What is the current use class of this property?",
  condition: "What is the current condition of this property?",
  existingBuildout: "What is the property currently built out as?",
  netIncome:
    "Let people, who might buy this property as an investment the net income (yearly) that the property has historically (or currently) brings in.",
  currentEpc: `What is the current EPC rating? If you have not got one, please put the status as "pending".`,
  potencialEpc: `What is the potential EPC rating of this property? If you applied for an EPC, but have not had an assessment (or it is a new build pending assessment), choose "pending". If you do not know the potential EPC choose "unknown".`,
  businessRate: (
    <>
      What are the current business rates for this property? If you do not have
      this number, check the last rate that was charged.{" "}
      <a
        href="https://www.gov.uk/find-business-rates"
        target="_blank"
        rel="noreferrer"
      >
        (https://www.gov.uk/find-business-rates)
      </a>
    </>
  ),
  amenities:
    "Letting viewers know the amenities your properties have can greatly increase the number of leads you get. Make sure to add as many as possible.",
  yearBuilt: "When was the property built?",
  yearLastRenovated: "When was the property last renovated?",
  parkingSpaces: "How many car parking spaces are included with the property?",
  parkingDetails:
    "Viewers of your listing will want clarity on where the parking spaces are and what form they take. Provide that information to viewers here.",
  propertyDescriptions:
    "This is your opportunity to promote your listing. Provide as much information as you can about the property.",
  email:
    "This is the email that leads from this listing will be sent to. It is not displayed publicly.",
  name: "Enter the name of the person or organisation that is advertising this property. This will be publicly displayed on the listing.",
  bio: "Enter a short bio about who is listing this property. This information will be publicly displayed on your listing.",
};

export const Descriptions = {
  contact:
    "These contact details will be displayed in the Contact section of your listing page. However, your email address will be hidden and used only for contact messages.",
  documents:
    "Brochures and other documents (such as marketing material) can help drive more leads and educate readers on your property.",
  floorPlans:
    "A floor plan can help potential leads understand how the property is laid out.",
  photos:
    "Photos are one of the most important parts of your property listing. Make sure to add as many as you can.",
  spaceDetails:
    "Let potential buyers know details about the space you are listing on our platform. The more information you provide, the more enquiries you will get.",
};
