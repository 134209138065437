import styled from "styled-components";
import { colors } from "../../shared/colors";
import { down } from "../../shared/media";
import SearchHeaderBackground from "./images/BackgroundBlack.svg";

export const SearchHeaderOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const SearchHeaderContainer = styled.div`
  position: relative;
  display: flex;
  max-width: 1360px;
  width: 100%;
  height: 632px;
  background: url(${SearchHeaderBackground});
  background-size: cover;
  border-radius: 24px;
  padding: 96px 0px 176px 0px;
  box-sizing: border-box;
  align-self: center;
  margin: 24px 40px 0px;
  z-index: 1;
  ${down("tablet")} {
    padding: 56px 56px 88px 40px;
    margin: 17px 16px 0px;
    height: 552px;
  }

  ${down("mobile")} {
    margin: 0;
    height: 100%;
    border-radius: 0;
    padding: 44px 16px 40px;
  }
`;

export const SeaerchHeaderImage = styled.div`
  position: absolute;
  top: 0px;
  right: 136px;
  width: 35%;
  height: 712px;
  z-index: 5;
  border-radius: 0px 0px 16px 16px;
  overflow: hidden;

  > img {
    object-fit: cover;
  }
  ${down("tablet")} {
    right: 40px;
    min-width: 300px;
    width: 40%;
    height: 600px;
  }

  ${down("mobile")} {
    top: 0px;
    right: -35%;
    height: 656px;
  }
`;

export const HigherIndexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;
  width: 100%;
  margin: auto;
  max-width: 1088px;

  ${down("tablet")} {
    margin: unset;
  }
`;

export const SearchHeaderHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 48px;
  color: ${colors.primary.pure_white};
  max-width: 570px;

  ${down("tablet")} {
    max-width: 50%;
    min-width: 360px;
    font-size: 32px;
    line-height: 40px;
  }

  ${down("mobile")} {
    font-size: 18px;
    line-height: 24px;
    max-width: 290px;

    > span {
      display: inline-flex;
      font-size: 32px;
      line-height: 36px;
      max-width: 300px;
    }
  }
`;

export const AbsoluteBackgroundArea = styled.div`
  position: absolute;
  right: 0px;
  height: 540px;
  width: 65%;
  background: ${colors.grayscale._200};
  bottom: -450px;
  z-index: 0;
`;
