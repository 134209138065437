import { useMemo } from "react";

export const usePaginationInput = (pageSize: number, currentPage: number) =>
  useMemo(
    () => ({
      count: pageSize,
      offset: (currentPage - 1) * pageSize,
    }),
    [currentPage, pageSize],
  );
