import React, { FunctionComponent } from "react";
import { ListingType } from "../../../generated/schema";
import RadioButton from "../../shared/components/RadioButotn/RadioButton";
import CollapsableSection from "./CollapsableSection";
import { FiltersInput, FiltersInputsContainer } from "./Filters.components";

type ListingTypeProps = {
  listingType: ListingType;
  onChange: (listingType: ListingType) => void;
};

const ListingTypeFilter: FunctionComponent<ListingTypeProps> = ({
  listingType,
  onChange,
  ...props
}) => {
  return (
    <CollapsableSection title="Listing type" isExpandable={false}>
      <FiltersInputsContainer>
        <FiltersInput>
          <RadioButton
            size="m"
            checked={listingType === ListingType.ForRent}
            color="normal"
            value="For rent"
            onCheck={() => onChange(ListingType.ForRent)}
          />
        </FiltersInput>
        <FiltersInput>
          <RadioButton
            size="m"
            checked={listingType === ListingType.ForSale}
            color="normal"
            value="For sale"
            onCheck={() => onChange(ListingType.ForSale)}
          />
        </FiltersInput>
      </FiltersInputsContainer>
    </CollapsableSection>
  );
};

export default ListingTypeFilter;
