import styled from "styled-components";
import { colors } from "../../../../../shared/colors";

export const ChartHeader = styled.h4`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${colors.grayscale._600};
  margin-bottom: 8px;
`;
