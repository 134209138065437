import React, { FunctionComponent, useState } from "react";
import {
  ExpandFaqButton,
  FaqAccordionWrapper,
  FaqNotExpandedRow,
  FaqTitle,
} from "./FAQ.components";
import { ReactComponent as Arrow } from "./Arrow.svg";
import AnimateHeight from "../shared/components/AnimateHeight";

type FAQAccordionType = {
  title: string;
};

const FAQAccordion: FunctionComponent<FAQAccordionType> = ({
  title,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <FaqAccordionWrapper>
      <FaqNotExpandedRow>
        <FaqTitle>{title} </FaqTitle>
        <ExpandFaqButton
          onClick={() => setIsExpanded(!isExpanded)}
          isExpanded={isExpanded}
        >
          <Arrow />
        </ExpandFaqButton>
      </FaqNotExpandedRow>
      <AnimateHeight height={!isExpanded ? 0 : "auto"}>
        {children}
      </AnimateHeight>
    </FaqAccordionWrapper>
  );
};

export default FAQAccordion;
