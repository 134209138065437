import React, { Dispatch, FunctionComponent, SetStateAction } from "react";
import useModal from "../../Modal/ModalContext/useModal";
import MessageAgentModalForm from "./Form/MessageAgentModalForm";
import {
  CloseButtonWrapper,
  MessageAgentGoBackMobile,
  MessageAgentModalContainer,
  MessageAgentModalHeader,
  MessageAgentModalHeaderWrapper,
} from "./MessageAgentModal.components";
import { ReactComponent as CloseIcon } from "./closeIcon.svg";
import { useIsMediaDown } from "../../../media";
import { ReactComponent as GoBackIcon } from "./goBackIcon.svg";
type MessageAgentModalProps = {
  agentName: string;
  uid: string;
  setMessageSent: Dispatch<SetStateAction<boolean>>;
};

const MessageAgentModal: FunctionComponent<MessageAgentModalProps> = ({
  agentName,
  uid,
  setMessageSent,
}) => {
  const { closeModal } = useModal();
  const isMobileDown = useIsMediaDown("mobile");

  return (
    <MessageAgentModalContainer>
      {!isMobileDown && (
        <CloseButtonWrapper onClick={closeModal}>
          <CloseIcon />
        </CloseButtonWrapper>
      )}
      <MessageAgentModalHeaderWrapper>
        {isMobileDown && (
          <MessageAgentGoBackMobile onClick={closeModal}>
            <GoBackIcon />
            <span>Back to property view</span>
          </MessageAgentGoBackMobile>
        )}
        <MessageAgentModalHeader>Contact {agentName}</MessageAgentModalHeader>
      </MessageAgentModalHeaderWrapper>
      <MessageAgentModalForm uid={uid} setMessageSent={setMessageSent} />
    </MessageAgentModalContainer>
  );
};

export default MessageAgentModal;
