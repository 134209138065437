import { getYearYield } from "../../../../../modules/AddProperty/components/ListingDetailsSection/ForSaleListing";
import React, { FunctionComponent } from "react";
import {
  getAmenitiesTypeValue,
  getEpcTypeValue,
  getExistingBuildoutTypeValue,
  useClassTypeValue,
} from "../../../../../common/helpers/enumNamesHelper";
import {
  AmenitiesType,
  EpcType,
  ExistingBuildoutType,
  TenancyType,
  UseClassType,
} from "../../../../../generated/schema";
import {
  PropertyDetailsSectionHeading,
  PropertyDetailsSectionSeparator,
  PropertyDetailsSectionWrapper,
} from "../../PropertyDetails.components";
import {
  PropertyAmenitiesHeading,
  PropertyAmenitiesWrapper,
  PropertyAmenity,
  PropertyDescription,
  PropertyDescriptionDetail,
  PropertyDescriptionDetails,
  PropertyDescriptionDetailTitle,
} from "./Property.components";
import { format } from "date-fns";
import formatPrice from "../../../../../common/helpers/formatPrice";

export type PropertyProps = {
  price?: number | null;
  propertyDescriptions: string;
  useClassType: UseClassType;
  existingBuildoutType: ExistingBuildoutType;
  potencialEpcType?: EpcType | null;
  parkingSpaces: number;
  parkingDetails?: string | null;
  floors: number;
  businessRate?: number | null;
  rentalTo?: string | null;
  isRentalNegotiable?: boolean | null;
  netIncome?: number | null;
  yearBuilt?: number | null;
  yearLastRenovated?: number | null;
  tenancyType: TenancyType;
  amenitiesType: AmenitiesType[];
};

const Property: FunctionComponent<PropertyProps> = ({
  price,
  propertyDescriptions,
  useClassType,
  existingBuildoutType,
  potencialEpcType,
  parkingSpaces,
  parkingDetails,
  floors,
  businessRate,
  rentalTo,
  isRentalNegotiable,
  netIncome,
  yearBuilt,
  yearLastRenovated,
  tenancyType,
  amenitiesType,
}) => {
  return (
    <PropertyDetailsSectionWrapper>
      <PropertyDetailsSectionHeading>
        Property Details
      </PropertyDetailsSectionHeading>
      <PropertyDescription>{propertyDescriptions}</PropertyDescription>
      <PropertyDetailsSectionSeparator />
      <PropertyDescriptionDetails>
        {(rentalTo || isRentalNegotiable) && (
          <PropertyDescriptionDetail>
            <PropertyDescriptionDetailTitle>
              Rent Length
            </PropertyDescriptionDetailTitle>
            {rentalTo && <span>{format(new Date(rentalTo), "MMMM YYYY")}</span>}
            {isRentalNegotiable && <span>Negotiable</span>}
          </PropertyDescriptionDetail>
        )}

        {netIncome && price && (
          <>
            <PropertyDescriptionDetail>
              <PropertyDescriptionDetailTitle>
                Net Income
              </PropertyDescriptionDetailTitle>
              <span>{formatPrice(netIncome)}</span>
            </PropertyDescriptionDetail>
            <PropertyDescriptionDetail>
              <PropertyDescriptionDetailTitle>
                Net Yield
              </PropertyDescriptionDetailTitle>
              <span>{getYearYield(price, netIncome)}</span>
            </PropertyDescriptionDetail>
          </>
        )}

        <PropertyDescriptionDetail>
          <PropertyDescriptionDetailTitle>
            Use Class
          </PropertyDescriptionDetailTitle>
          <span>{useClassTypeValue(useClassType)}</span>
        </PropertyDescriptionDetail>

        <PropertyDescriptionDetail>
          <PropertyDescriptionDetailTitle>
            Existing Buildout
          </PropertyDescriptionDetailTitle>
          <span>{getExistingBuildoutTypeValue(existingBuildoutType)}</span>
        </PropertyDescriptionDetail>

        {potencialEpcType && (
          <PropertyDescriptionDetail>
            <PropertyDescriptionDetailTitle>
              Potential EPC
            </PropertyDescriptionDetailTitle>
            <span>{getEpcTypeValue(potencialEpcType)}</span>
          </PropertyDescriptionDetail>
        )}

        <PropertyDescriptionDetail>
          <PropertyDescriptionDetailTitle>
            Parking Spaces
          </PropertyDescriptionDetailTitle>
          <span>
            {parkingSpaces} {parkingDetails && "-"} {parkingDetails}
          </span>
        </PropertyDescriptionDetail>

        <PropertyDescriptionDetail>
          <PropertyDescriptionDetailTitle>
            Floors
          </PropertyDescriptionDetailTitle>
          <span>{floors}</span>
        </PropertyDescriptionDetail>

        {businessRate && (
          <PropertyDescriptionDetail>
            <PropertyDescriptionDetailTitle>
              Business Rate
            </PropertyDescriptionDetailTitle>
            <span>{formatPrice(businessRate)}</span>
          </PropertyDescriptionDetail>
        )}

        {yearLastRenovated && (
          <PropertyDescriptionDetail>
            <PropertyDescriptionDetailTitle>
              Year Renovated
            </PropertyDescriptionDetailTitle>
            <span>{yearLastRenovated}</span>
          </PropertyDescriptionDetail>
        )}

        {yearBuilt && (
          <PropertyDescriptionDetail>
            <PropertyDescriptionDetailTitle>
              Year Build
            </PropertyDescriptionDetailTitle>
            <span>{yearBuilt}</span>
          </PropertyDescriptionDetail>
        )}

        <PropertyDescriptionDetail>
          <PropertyDescriptionDetailTitle>
            Tenancy
          </PropertyDescriptionDetailTitle>
          <span>{tenancyType.toLowerCase()}</span>
        </PropertyDescriptionDetail>
      </PropertyDescriptionDetails>

      {amenitiesType.length > 0 && (
        <>
          <PropertyDetailsSectionSeparator />
          <PropertyAmenitiesHeading>Amenities</PropertyAmenitiesHeading>
          <PropertyAmenitiesWrapper>
            {amenitiesType.map((a, i) => (
              <PropertyAmenity key={`${a}-${i}`}>
                {getAmenitiesTypeValue(a)}
              </PropertyAmenity>
            ))}
          </PropertyAmenitiesWrapper>
        </>
      )}
    </PropertyDetailsSectionWrapper>
  );
};

export default Property;
