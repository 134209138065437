import { FormikProps } from "formik";
import React, { FunctionComponent } from "react";
import FakeCheckbox from "../../../shared/components/Form/FakeCheckbox";
import { Input } from "../../../shared/components/Form/Input/Input";
import InputField from "../../../shared/components/Form/Input/InputField";
import { Hints } from "../../AddPropertyFormHints";
import { AddPropertyFormValues } from "../../form";
import HintHeader from "../HintHeader/HintHeader";
import {
  CheckboxContainer,
  CheckSpan,
  InputsContainer,
  InputsSectionContainer,
  MediumInputContainer,
  SmallInputContainer,
} from "./ListingDetailsSection.components";

export const getYearYield = (price?: number, netIncome?: number) => {
  if (price === undefined || !netIncome || netIncome <= 0) return "0%";
  const yearYield = (netIncome / price) * 100;
  return `${yearYield.toFixed(2).replace(/(\.0+|0+)$/, "")}%`;
};

const ForSaleListing: FunctionComponent<FormikProps<AddPropertyFormValues>> = (
  props,
) => {
  const { values, setFieldValue } = props;
  return (
    <InputsSectionContainer>
      <HintHeader>The sale price</HintHeader>
      <InputsContainer>
        <MediumInputContainer>
          <InputField
            name="price"
            inputSize="l"
            type="number"
            frontIcon="currency"
            label="Asking Price"
          />
        </MediumInputContainer>
        <MediumInputContainer>
          <InputField
            name="netIncome"
            type="number"
            inputSize="l"
            hint={Hints.netIncome}
            frontIcon="currency"
            label="Net Income (opt.)"
          />
        </MediumInputContainer>

        <SmallInputContainer>
          <Input
            name="net"
            inputSize="l"
            value={getYearYield(values.price, values.netIncome)}
            label="Net Yield (ROI)"
            isDisabled={true}
          />
        </SmallInputContainer>
      </InputsContainer>
      <CheckboxContainer>
        <FakeCheckbox
          checked={values.isPriceNegotiable ?? false}
          onClick={() =>
            setFieldValue("isPriceNegotiable", !values.isPriceNegotiable)
          }
        >
          <CheckSpan>Negotiable</CheckSpan>
        </FakeCheckbox>
      </CheckboxContainer>
    </InputsSectionContainer>
  );
};

export default ForSaleListing;
