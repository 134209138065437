import { Formik } from "formik";
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { useMessageAgentMutation } from "../../../../../../generated/schema";
import useModal from "../../../Modal/ModalContext/useModal";
import {
  MessageAgentModalFormValues,
  messageAgentModalInitialValues,
  messageAgentModalValidationSchema,
} from "./form";
import MessageAgentModalFormContent from "./MessageAgentModalFormContent";

type MessageAgentFormProps = {
  uid: string;
  setMessageSent: Dispatch<SetStateAction<boolean>>;
};

const MessageAgentModalForm: FunctionComponent<MessageAgentFormProps> = ({
  uid,
  setMessageSent,
}) => {
  const [error, setError] = useState("");

  const { closeModal } = useModal();
  const [messageAgent] = useMessageAgentMutation();

  const handleSubmit = useCallback(
    async (values: MessageAgentModalFormValues) => {
      try {
        const res = await messageAgent({
          variables: {
            input: {
              ...values,
              propertyUid: uid || "",
            },
          },
        });

        switch (res.data?.messageAgent?.__typename) {
          case "MessageAgentSuccessfulResponse":
            setError("");
            setMessageSent(true);
            closeModal();
            break;
          case "MessageAgentInvalidResponse":
            setError("There was a problem. Try again.");
            break;
        }
      } catch (e) {
        setError("There was a problem. Try again.");
      }
    },
    [closeModal, messageAgent, setMessageSent, uid],
  );

  return (
    <Formik
      initialValues={messageAgentModalInitialValues}
      validationSchema={messageAgentModalValidationSchema}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <MessageAgentModalFormContent {...formProps} error={error} />
      )}
    </Formik>
  );
};

export default MessageAgentModalForm;
