import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../colors";
import { down, useIsMediaDown } from "../../media";
import {
  BodySmallStyle,
  LeadMediumStyle,
  MainHeaderStyle,
  MaxLines,
} from "../../mixins";

export type PropertyCardVariant = "compact" | "wide";

export const PropertyCardOuterContainer = styled.div<{
  isLoading: boolean;
}>`
  display: flex;
  max-width: 1088px;
  width: 100%;

  ${down("mobile")} {
    justify-content: center;
  }

  ${(p) =>
    p.isLoading &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}
`;

export const PropertyCardContainer = styled(Link)<{
  variant: PropertyCardVariant;
}>`
  position: relative;
  display: flex;
  max-width: 812px;
  width: 100%;
  margin-bottom: 24px;
  background: ${colors.primary.pure_white};
  border-radius: 16px;
  height: 224px;
  text-decoration: none;
  color: ${colors.primary.frosted_black};

  ${(p) =>
    (p.variant === "wide" || useIsMediaDown("tablet")) &&
    css`
      height: 200px;
    `}
  ${down("mobile")} {
    margin-bottom: 16px;
    flex-direction: column;
    height: unset;
  }

  :hover {
    cursor: pointer;
    box-shadow: 0px 0px 0px 4px rgba(59, 122, 247, 0.12);
  }
`;

export const PropertyCardImageWrapper = styled.div<{
  variant: PropertyCardVariant;
}>`
  position: relative;
  max-width: 320px;
  width: 100%;
  height: 100%;
  ${(p) =>
    (p.variant === "wide" || useIsMediaDown("tablet")) &&
    css`
      max-width: 236px;
      height: 100%;
    `}

  ${down("mobile")} {
    max-width: unset;
    width: 100%;
    max-height: 204px;
  }
`;

export const PropertyCardImage = styled.img<{ variant: PropertyCardVariant }>`
  max-width: 320px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;

  ${(p) =>
    (p.variant === "wide" || useIsMediaDown("tablet")) &&
    css`
      max-width: 236px;
      height: 100%;
    `}

  ${down("mobile")} {
    max-width: unset;
    width: 100%;
    max-height: 204px;
  }
`;

export const PropertyCardDetailsWrapper = styled.div<{
  variant: PropertyCardVariant;
}>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 32px 32px 24px 32px;

  ${(p) =>
    (p.variant === "wide" || useIsMediaDown("tablet")) &&
    css`
      padding: 24px;
    `}

  ${down("mobile")} {
    padding: 16px;
  }
`;

export const PropertyCardDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const PropertyName = styled.h5<{ variant: PropertyCardVariant }>`
  ${MainHeaderStyle()};
  max-width: 260px;
  font-weight: 500;

  ${(p) =>
    (p.variant === "wide" || useIsMediaDown("tablet")) &&
    css`
      font-size: 16px;
      line-height: 24px;
      max-width: 220px;
    `}

  ${down("mobile")} {
    max-width: 200px;
  }

  ${MaxLines(3)};
`;

export const PropertyDescription = styled.span`
  ${BodySmallStyle()};
  color: ${colors.primary.frosted_black};
`;

export const PropertyVisualDetailsWrapper = styled.div<{
  variant: PropertyCardVariant;
}>`
  margin-top: 20px;

  ${(p) =>
    (p.variant === "wide" || useIsMediaDown("tablet")) &&
    css`
      margin-top: 12px;
    `}

  ${down("mobile")} {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

export const PropertyVisualDetail = styled.div<{
  variant: PropertyCardVariant;
}>`
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  > span {
    ${LeadMediumStyle()};
    ${MaxLines(1)};
    margin-left: 8px;
    flex: 1;
  }

  ${(p) =>
    (p.variant === "wide" || useIsMediaDown("tablet")) &&
    css`
      margin-bottom: 8px;
      > span {
        font-size: 14px;
        margin-left: 8px;
      }
    `}
`;

export const PropertyCreationDate = styled.span`
  margin-top: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${colors.grayscale._600};
`;
