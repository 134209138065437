import React, {
  FunctionComponent,
  forwardRef,
  Ref,
  ComponentPropsWithoutRef,
} from "react";
import ErrorMessage from "../shared/ErrorMessage";
import InputWrapper from "../shared/InputWrapper";
import {
  FrontIconWrapper,
  IconWrapper,
  InputComponentWrapper,
  StyledInput,
} from "./Input.components";
import { validateTypeTelKeyPress } from "./Input.helpers";
import Label from "./Label";
import { ReactComponent as CurrencyIcon } from "./currency_icon.svg";
import { ReactComponent as SearchIcon } from "./icon_search.svg";

export type InputProps = ComponentPropsWithoutRef<"input"> & {
  error?: string;
  type?: string;
  isDisabled?: boolean;
  isError?: boolean;
  showError?: boolean;
  label?: string;
  showLabel?: boolean;
  icon?: JSX.Element;
  hint?: JSX.Element | string;
  frontIcon?: "currency" | "search";
  onIconClick?: () => void;
  inputSize?: "m" | "l";
};

const getIcon = (type: "currency" | "search") => {
  switch (type) {
    case "currency":
      return <CurrencyIcon />;
    case "search":
      return <SearchIcon />;
  }
};

export const Input: FunctionComponent<
  InputProps & {
    forwardedRef?: Ref<HTMLInputElement>;
  }
> = ({
  error,
  isError,
  type = "text",
  forwardedRef,
  inputSize = "m",
  showLabel,
  isDisabled,
  onFocus,
  showError = true,
  icon,
  frontIcon,
  label,
  hint,
  onIconClick,
  ...inputProps
}) => (
  <InputComponentWrapper>
    {(label || showLabel) && (
      <Label forceShow={showLabel} hint={hint}>
        {label}
      </Label>
    )}

    <InputWrapper>
      {frontIcon && (
        <FrontIconWrapper isError={!!error || isError}>
          {getIcon(frontIcon)}
        </FrontIconWrapper>
      )}
      <StyledInput
        {...inputProps}
        inputSize={inputSize}
        ref={forwardedRef}
        type={type}
        disabled={isDisabled}
        hasFrontIcon={!!frontIcon}
        onFocus={onFocus}
        isError={!!error || isError}
        onPaste={(e) => {
          if (type === "tel") e.preventDefault();
        }}
        hasIcon={icon !== undefined}
        onKeyPress={(e) => {
          validateTypeTelKeyPress(e, type);
        }}
      />
      {icon && (
        <IconWrapper isError={!!error} onClick={onIconClick}>
          {icon}
        </IconWrapper>
      )}
    </InputWrapper>
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </InputComponentWrapper>
);

export default forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <Input {...props} forwardedRef={ref} />
));
