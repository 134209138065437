import { Formik } from "formik";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { AddPropertyLayoutContent } from "../Layouts/AddPropertyLayout/AddPropertyLayout.components";
import AddPropertyForm from "./AddPropertyForm";
import {
  AddPropertyFormValues,
  addPropertyValidationSchema,
  buildAddPropertyInput,
  buildEditPropertyInput,
  getAddPropertyInitialValues,
} from "./form";
import {
  GetEditPropertyQuery,
  GetSignedUploadUrlsResponse,
  PublicUrls,
  useAddPropertyMutation,
  useEditPropertyMutation,
  useGetEditPropertyLazyQuery,
  useGetSignedUploadUrlsLazyQuery,
} from "../../generated/schema";
import { useNavigate } from "react-router";
import routesPaths from "../Routing/routesPaths";
import useAppContext from "../App/AppContext/useAppContext";
import ScreenLoading from "../Routing/ScreenLoading";
import mapFilesToCategories, {
  ICategorizedFile,
} from "./FileUpload/mapFilesToCategories";
import { nanoid } from "nanoid";
import uploadFile from "./FileUpload/uploadFile";
import { useIsMediaDown } from "../shared/media";
import MainNavbar from "../shared/components/Navbar/MainNavbar";
import ResolutionNotSupported from "../shared/components/ResolutionNotSupported";
import useQueryParameters from "../../common/helpers/useQueryParameters";
import { EditPropertyResponse } from "./EditPropertyResponse";

const mapToProperty = (query?: GetEditPropertyQuery) => {
  if (query?.getEditProperty.__typename === "GetPropertySuccessfulResponse") {
    return query.getEditProperty.property;
  }
  return undefined;
};

const AddProperty: FunctionComponent = () => {
  //General variables:
  const isMobileDown = useIsMediaDown("mobile");
  const { currentUser } = useAppContext();
  const navigate = useNavigate();

  //Formik variables:
  const [error, setError] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [formValues, setFormValues] = useState<AddPropertyFormValues>();

  //Upload files variables:
  const [getSignedUploadUrlsQuery, { data, error: getUrlError }] =
    useGetSignedUploadUrlsLazyQuery();
  const [categorizedFiles, setCategorizedFiles] = useState<ICategorizedFile[]>(
    [],
  );

  //Edit property variables:
  const paramUid = useQueryParameters().get("uid");
  const [editPropertyLoading, setEditPropertyLoading] = useState(!!paramUid);
  const [editedProperty, setEditedProperty] = useState<EditPropertyResponse>();
  const [getPropertyQuery, getPropertyResult] = useGetEditPropertyLazyQuery();
  const [editPropertyQuery] = useEditPropertyMutation();

  // Replace - to _ to match slug-uid property route pattern
  const [uid, setUid] = useState(nanoid(11).replace(/-/g, "_"));
  const [addPropertyQuery] = useAddPropertyMutation();

  useEffect(() => {
    if (!editPropertyLoading) return;
    //Ensure to call get property query only once.
    if (!getPropertyResult.called) {
      getPropertyQuery({ variables: { uid: paramUid! } });
    } else if (!getPropertyResult.loading) {
      const property = mapToProperty(getPropertyResult.data);
      if (property) {
        setUid(property.uid);
        setEditedProperty(property);
        setEditPropertyLoading(false);
      } else {
        navigate(routesPaths.ERROR404, { replace: true });
      }
    }
  }, [
    paramUid,
    getPropertyResult,
    navigate,
    getPropertyQuery,
    editPropertyLoading,
  ]);

  const addProperty = useCallback(
    async (publicUrls: PublicUrls) => {
      const addPropertyResponse = await addPropertyQuery({
        variables: {
          input: buildAddPropertyInput(uid, formValues!, publicUrls),
        },
      });

      switch (addPropertyResponse.data?.addProperty.__typename) {
        case "AddPropertySuccessfulResponse":
          setSaveLoading(false);
          navigate(`${routesPaths.MY_PROPERTIES_ROOT}/pending`);
          break;
        case "AddPropertyApiErrorResponse":
          setError(
            "There was a problem with provided postcode. Please try again.",
          );
          setSaveLoading(false);
          break;
      }
    },
    [addPropertyQuery, uid, formValues, navigate],
  );

  const editProperty = useCallback(
    async (publicUrls: PublicUrls) => {
      await editPropertyQuery({
        variables: {
          input: buildEditPropertyInput(uid, formValues!, publicUrls),
        },
      });
      setSaveLoading(false);
      navigate(`${routesPaths.MY_PROPERTIES_ROOT}/pending`);
    },
    [editPropertyQuery, uid, navigate, formValues],
  );

  const handleFormSubmit = useCallback(
    async (response: GetSignedUploadUrlsResponse) => {
      try {
        await Promise.all(
          response.uploadUrls.map(async (data) => {
            const fileToUpload = categorizedFiles?.find(
              (f) => f.name === data.fileName,
            )?.file;
            if (fileToUpload) await uploadFile(data.uploadUrl, fileToUpload);
          }),
        );

        if (editedProperty) {
          await editProperty(response.publicUrls);
        } else {
          await addProperty(response.publicUrls);
        }
      } catch (error) {
        setError("There was a problem. Please try again.");
        setSaveLoading(false);
        console.log(error);
      }
    },
    [editedProperty, categorizedFiles, editProperty, addProperty],
  );

  useEffect(() => {
    if (getUrlError) setError("There was a problem. Please try again.");
    if (data) handleFormSubmit(data.getSignedUploadUrls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, getUrlError]);

  const onFormSubmit = useCallback(
    async (values: AddPropertyFormValues) => {
      try {
        setSaveLoading(true);
        const files = mapFilesToCategories(values);
        setCategorizedFiles(files);
        setFormValues(values);
        getSignedUploadUrlsQuery({
          variables: {
            input: {
              uid: uid,
              files: files.map((f) => {
                return {
                  category: f.category,
                  fileName: f.name,
                };
              }),
            },
          },
        });
      } catch (error) {
        setError("Unexpected error.");
        console.log(error);
      }
    },
    [getSignedUploadUrlsQuery, uid],
  );

  return !currentUser || editPropertyLoading ? (
    <ScreenLoading />
  ) : (
    <Formik
      validationSchema={addPropertyValidationSchema}
      initialValues={getAddPropertyInitialValues(currentUser, editedProperty)}
      onSubmit={onFormSubmit}
    >
      {(formProps) =>
        isMobileDown ? (
          <>
            <MainNavbar />
            <ResolutionNotSupported />
          </>
        ) : (
          <AddPropertyLayoutContent>
            <AddPropertyForm
              {...formProps}
              error={error}
              loading={saveLoading}
              onError={setError}
            />
          </AddPropertyLayoutContent>
        )
      }
    </Formik>
  );
};

export default AddProperty;
