import styled from "styled-components";
import { VerticalGap } from "../../../shared/mixins";

export const ContactSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${VerticalGap(24)};
`;

export const InputContainer = styled.div`
  max-width: 412px;
`;
