import React, { FunctionComponent } from "react";
import {
  ActionsModalButtonsContainer,
  ActionsModalContainer,
  ActionsModalDescription,
  ActionsModalDivider,
  ActionsModalHeader,
} from "./ActionsModal.components";

type ActionsModalProps = {
  title: string | JSX.Element;
  description: string | JSX.Element;
  leftButton: JSX.Element;
  rightButton: JSX.Element;
};

const ActionsModal: FunctionComponent<ActionsModalProps> = ({
  title,
  description,
  leftButton,
  rightButton,
}) => {
  return (
    <ActionsModalContainer>
      <ActionsModalHeader>{title}</ActionsModalHeader>
      <ActionsModalDescription>{description}</ActionsModalDescription>
      <ActionsModalDivider />
      <ActionsModalButtonsContainer>
        {leftButton}
        {rightButton}
      </ActionsModalButtonsContainer>
    </ActionsModalContainer>
  );
};

export default ActionsModal;
