import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getInitialPage } from "../../modules/Properties/propertyHelpers";

export const PAGE_SIZE = 10;

type PaginatedResponse = {
  data: any[];
  itemsCount: number;
};

export const usePaginatedResponse = (data?: PaginatedResponse) => {
  const isEmpty = !data || data.itemsCount === 0 || !data.data;
  const pagesCount = isEmpty ? 0 : Math.ceil(data.itemsCount / PAGE_SIZE);
  const totalCount: number = data?.itemsCount || 0;
  return { isEmpty, pagesCount, totalCount, pageSize: PAGE_SIZE };
};

export const useCurrentPage = (): [
  number,
  React.Dispatch<React.SetStateAction<number>>,
] => {
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(getInitialPage());
  useEffect(() => {
    const queryPage = Number(searchParams.get("page"));
    const page = queryPage ? Math.max(1, Number(queryPage)) : 1;
    if (currentPage !== page) {
      setCurrentPage(page);
    }
  }, [searchParams, currentPage]);

  return [currentPage, setCurrentPage];
};

export const getPropertiesCountText = (
  total: number,
  current: number,
  pages: number,
) =>
  `${total} ${
    total === 1 ? "Property" : "Properties"
  }. Page ${current}/${pages}`;
