import React, { FunctionComponent } from "react";
import { Navigate, useLocation, useParams } from "react-router";
import PropertyDetails from "../PropertyDetails/PropertyDetails";
import routesPaths from "../../Routing/routesPaths";
import ScreenLoading from "../../Routing/ScreenLoading";
import AcceptPropertyNavbar from "../../shared/components/Navbar/AcceptPropertyNavbar";
import {
  PropertyStatus,
  useGetPropertyPreviewByUidQuery,
} from "../../../generated/schema";
import MainNavbar from "../../shared/components/Navbar/MainNavbar";
import { propertyDetailsRootPaths } from "../../../../../backend/src/shared/routes/routes";
import { PropertyDetailsPageLoader } from "../PropertyDetails/PropertyDetails.components";

const AuthedPropertyPreview: FunctionComponent = () => {
  const { uid } = useParams<{ uid: string }>();

  const location = useLocation();
  const showAdminNavbar = location.pathname.includes(
    propertyDetailsRootPaths.PROPERTY_ACCEPT_ROOT,
  );

  const { data, loading, error } = useGetPropertyPreviewByUidQuery({
    variables: {
      uid: uid || "",
    },
  });

  if (loading)
    return (
      <PropertyDetailsPageLoader>
        <ScreenLoading />
      </PropertyDetailsPageLoader>
    );
  if (error)
    return <Navigate to={{ pathname: routesPaths.ERROR404 }} replace />;

  const renderPropertyPreview = () => {
    switch (data?.getPropertyPreviewByUid.__typename) {
      case "GetPropertySuccessfulResponse":
        const property = data?.getPropertyPreviewByUid.property;
        return (
          <>
            {showAdminNavbar && property.status === PropertyStatus.Pending ? (
              <AcceptPropertyNavbar
                name={property.contactName}
                email={property.contactEmail}
                id={property.id}
                status={property.status}
              />
            ) : (
              <MainNavbar />
            )}
            <PropertyDetails property={property} />
          </>
        );
      default:
        return <Navigate to={{ pathname: routesPaths.ERROR404 }} replace />;
    }
  };

  return renderPropertyPreview();
};

export default AuthedPropertyPreview;
