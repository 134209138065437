import {
  BodyMediumStyle,
  H6Style,
  LeadSmallStyle,
} from "../../../../../modules/shared/mixins";
import styled from "styled-components";
import { colors } from "../../../../shared/colors";
import { down } from "../../../../shared/media";

export const LocationWrapper = styled.div`
  display: flex;
  width: 100%;

  ${down("mobile")} {
    flex-direction: column;
  }
`;

export const LocationInnerWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const LocationDetails = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.grayscale._600};
`;

export const LocationMapsButton = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: fit-content;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${colors.secondary.pure_blue_dark};
  margin-top: 16px;
  cursor: pointer;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export const PriceBadge = styled.span<{
  smallFont?: boolean;
  isPriceNegotiable?: boolean;
}>`
  position: relative;
  display: flex;
  max-height: 40px;
  box-sizing: border-box;
  background: ${colors.semantic.green};
  backdrop-filter: blur(4px);
  border-radius: 100px;
  padding: 6px 16px 7px;
  font-weight: 400;
  font-size: ${(p) => (p.smallFont ? 16 : 20)}px;
  line-height: 28px;
  color: ${colors.primary.pure_white};
  white-space: nowrap;
  > span {
    align-self: flex-end;
    font-size: 12px;
    line-height: 18px;
  }

  ${down("mobile")} {
    flex-direction: column;
    width: fit-content;
    margin-top: 16px;
    margin-bottom: ${(p) => p.isPriceNegotiable && 24}px;
  }
`;

export const NegotiableBadge = styled.span`
  position: absolute;
  top: 44px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  line-height: 16px;
  color: ${colors.semantic.green_dark};
`;

export const LocationDetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 24px;
  max-width: 575px;

  ${down("mobile")} {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

export const LocationDetail = styled.div`
  display: flex;
  margin-right: 32px;
  ${down("mobile")} {
    margin-right: 40px;
    margin-bottom: 16px;
  }
  min-width: fit-content;
`;

export const LocationDetailInnerWrapper = styled.div`
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LocationDetailsCategory = styled.h5`
  display: flex;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${colors.primary.frosted_black};
  align-items: center;
  > svg {
    margin-left: 4px;
  }

  ${down("mobile")} {
    ${H6Style}
  }
`;

export const LocationDetailsData = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${colors.grayscale._600};
  white-space: nowrap;
  display: block;

  ${down("mobile")} {
    ${BodyMediumStyle}
    margin-top: 4px;
  }
`;

export const PropertyUseTypeWrapper = styled.div`
  margin-top: 16px;
  border-top: 1px solid ${colors.grayscale._300};
  padding-top: 24px;

  > h5 {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: ${colors.primary.frosted_black};
  }
`;

export const PropertyUseTypeInnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;

  > span {
    padding: 4px 8px;
    background: rgba(59, 122, 247, 0.12);
    border-radius: 20px;
    ${LeadSmallStyle()}
    margin-right: 4px;
    margin-bottom: 8px;
    color: ${colors.primary.frosted_black};
  }
`;
