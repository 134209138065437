import styled from "styled-components";
import { colors } from "../../colors";
import { down } from "../../media";
import { LeadSmallStyle } from "../../mixins";

export const ListingsContainer = styled.div`
  scroll-margin: 128px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 40px 0;
  background-color: ${colors.grayscale._200};

  ${down("tablet")} {
    padding: 16px 16px 0;
  }
`;

export const ListingsNoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ListingLoaderContainer = styled.div`
  padding: 256px 0px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const ListingsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  justify-content: center;
  max-width: 1088px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 135px;
  align-items: flex-start;
  position: relative;

  ${down("tablet")} {
    padding: 0 40px;
    align-items: center;
  }
  ${down("mobile")} {
    padding: 0 8px;
  }
`;

export const ListingsResultDetails = styled.span<{ smallerMargin?: boolean }>`
  ${LeadSmallStyle()};
  align-self: flex-start;
  margin-bottom: 16px;
  margin-top: ${(p) => (p.smallerMargin ? 24 : 48)}px;
`;

export const ListingResultsLoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListingsPaginationWrapper = styled.div`
  max-width: 812px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 104px;

  ${down("tablet")} {
    max-width: 608px;
    justify-content: center;
  }
`;

export const ListingsFooterContainer = styled.div`
  margin-top: auto;
  background-color: ${colors.grayscale._200};
`;
