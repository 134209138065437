import styled from "styled-components";
import { colors } from "../../../colors";
import { down } from "../../../media";
import { PropertyBadgeType } from "./PropertyBadge";

const getBadgeBackground = (type: PropertyBadgeType) => {
  switch (type) {
    case PropertyBadgeType.HIGH_STUDENT_POPULATION:
    case PropertyBadgeType.WEALTHY_AREA:
    case PropertyBadgeType.LOW_CRIME_RATE:
      return colors.semantic.green;
    case PropertyBadgeType.DECLINED:
      return colors.semantic.red;
  }
};

export const PropertyBadgeWrapper = styled.span<{ type: PropertyBadgeType }>`
  position: absolute;
  padding: 6px 16px 5px 16px;
  bottom: 12px;
  left: 12px;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  text-transform: uppercase;
  color: ${colors.primary.pure_white};
  background: ${(p) => getBadgeBackground(p.type)};
`;
