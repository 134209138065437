import { FormikProps } from "formik";
import React, { FunctionComponent } from "react";
import useAddPropertyContext from "../Layouts/AddPropertyLayout/AddPropertyContext/useAddPropertyContext";
import {
  AddPropertyContainer,
  AddPropertyContent,
  AddPropertyLoader,
  AddPropertyLoaderContainer,
  WarningBox,
  WarningIconImage,
} from "./AddProperty.components";
import ContactSection from "./components/ContactSection/ContactSection";
import DocumentsSection from "./components/DocumentsSection";
import FloorPlansSection from "./components/FloorPlansSection";
import ListingDetailsSection from "./components/ListingDetailsSection/ListingDetailsSection";
import LocationSection from "./components/LocationSection/LocationSection";
import PropertyDetailsSection from "./components/PropertyDetailsSection/PropertyDetailsSection";
import PropertyPhotosSection from "./components/PropertyPhotosSection";
import SpaceDetailsSection from "./components/SpaceDetailsSection/SpaceDetailsSection";
import { AddPropertyFormValues } from "./form";
import WarningIcon from "./warning_icon.svg";
import ErrorIcon from "./error_icon.svg";
import PageLoader from "../shared/components/PageLoader/PageLoader";

export enum AddPropertyMode {
  ADD = "add",
  EDIT = "edit",
}

export type AddPropertyFormContentProps = FormikProps<AddPropertyFormValues> & {
  isLoading?: boolean;
  error?: string;
};

const AddPropertyFormContent: FunctionComponent<
  AddPropertyFormContentProps
> = ({ ...props }) => {
  const { mode } = useAddPropertyContext();
  const { error, isLoading } = props;

  return (
    <AddPropertyContent>
      <AddPropertyContainer>
        {error && (
          <WarningBox color="red">
            <WarningIconImage src={ErrorIcon} />
            {error}
          </WarningBox>
        )}

        {mode === AddPropertyMode.EDIT && (
          <WarningBox color="orange">
            <WarningIconImage src={WarningIcon} />
            The edited listing goes to the pending and requires re-confirmation
            by the administrator.
          </WarningBox>
        )}
        <LocationSection {...props} />
        <ListingDetailsSection {...props} />
        <SpaceDetailsSection {...props} />
        <PropertyDetailsSection {...props} />
        <PropertyPhotosSection {...props} />
        <FloorPlansSection {...props} />
        <DocumentsSection {...props} />
        <ContactSection {...props} />
      </AddPropertyContainer>
      {isLoading ? (
        <AddPropertyLoaderContainer>
          <AddPropertyLoader>
            <PageLoader />
          </AddPropertyLoader>
        </AddPropertyLoaderContainer>
      ) : null}
    </AddPropertyContent>
  );
};

export default AddPropertyFormContent;
