export const PHONE_FORMAT = /^\+?[\s0-9]*$/;

export const validateTypeTelKeyPress = (
  e: React.KeyboardEvent<HTMLInputElement>,
  type: string,
) => {
  if (type === "tel") {
    const isPhoneFormat = PHONE_FORMAT.test(e.currentTarget.value);
    const isSpaceOrKeyNumber = /^[\s0-9]$/.test(e.key);
    const isPlus = e.key === "+";
    const containsPlus = e.currentTarget.value.includes("+");
    if (
      !isPhoneFormat ||
      (!isSpaceOrKeyNumber && !isPlus) ||
      (isPlus && containsPlus)
    ) {
      e.preventDefault();
    }
  }
};
