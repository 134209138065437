import styled from "styled-components";
import { colors } from "../../colors";
import { down } from "../../media";
import {
  BodySmallStyle,
  DefaultScaleTransform,
  LeadSmallStyle,
  SmallStyle,
} from "../../mixins";
import FooterBackground from "./BackgroundFooter.webp";
import FooterBackgroundTablet from "./BackgroundFooterTablet.webp";
import FooterBackgroundMobile from "./BackgroundFooterMobile.webp";

// SM Icons
import FacebookIcon from "./icons/FacebookIcon.svg";
import LinkedinIcon from "./icons/LinkedinIcon.svg";
import TwitterIcon from "./icons/TwitterIcon.svg";

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-self: flex-end;
  align-self: flex-end;
  z-index: 2;
`;

export const FooterOuterContainer = styled.div`
  display: flex;
  width: 100%;
  border-top-right-radius: 120px;
  background-image: url(${FooterBackground});
  background-size: cover;
  padding: 64px 56px 0px 56px;
  justify-content: center;

  ${down("tablet")} {
    padding: 48px 56px 0 56px;
    background-image: url(${FooterBackgroundTablet});
  }

  ${down("mobile")} {
    padding: 48px 24px 0 24px;
    background-image: url(${FooterBackgroundMobile});
  }
`;

export const FooterInnerContainer = styled.div`
  width: 100%;
  max-width: 1088px;
`;

export const FooterPopularSearchesWrapper = styled.div`
  ${down("tablet")} {
    margin-top: 32px;
  }
`;

export const FooterPopularSearchesOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
`;

export const FooterPupularSearchesHeader = styled.span`
  margin: 0px;
  padding: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.primary.pure_white};
`;

export const FooterPopularSearchWrapper = styled.a`
  padding: 8px;
  background: ${colors.grayscale._800};
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.grayscale._400};
  border-radius: 100px;
  margin-right: 3px;
  margin-bottom: 8px;
  text-decoration: none;

  ${DefaultScaleTransform()};
  :hover {
    transform: scale(1.03);
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;

  ${down("tablet")} {
    flex-direction: column;
    margin-top: 0px;
  }
`;

export const FooterDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 288px;
  > svg {
    height: 32px;
    width: 87px;
  }

  ${down("tablet")} {
    max-width: 440px;
  }
  ${down("mobile")} {
    max-width: 288px;
  }
`;

export const FooterDetails = styled.span`
  ${BodySmallStyle()};
  margin-top: 16px;
`;

export const FooterSocialMediaWrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

type SocialMediaType = "facebook" | "twitter" | "linkedin";

const getSocialMediaIcon = (type: SocialMediaType) => {
  switch (type) {
    case "facebook":
      return FacebookIcon;
    case "twitter":
      return TwitterIcon;
    case "linkedin":
      return LinkedinIcon;
  }
};
// prettier-ignore-start
export const FooterSocialMediaIcon = styled.a<{ type: SocialMediaType }>`
  // prettier-ignore-end
  width: 32px;
  height: 32px;
  background-image: url(${(p) => getSocialMediaIcon(p.type)});
  margin-right: 16px;
  ${DefaultScaleTransform()};
`;

export const FooterQuickLinksWrapper = styled.div`
  display: flex;
  margin-left: auto;

  ${down("tablet")} {
    margin-left: unset;
    margin-top: 32px;

    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  ${down("mobile")} {
    flex-direction: column-reverse;
  }
`;

export const QuickLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${down("tablet")} {
    margin-left: 104px;
  }

  ${down("mobile")} {
    margin-left: unset;
    margin-top: 32px;
  }
`;

export const QuickLinksInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  ${down("mobile")} {
    margin-top: 16px;
  }
`;
export const QuickLink = styled.a`
  text-decoration: none;
  width: fit-content;
  ${BodySmallStyle()};
  margin-bottom: 8px;
  ${DefaultScaleTransform()};
  ${down("mobile")} {
    max-width: 45px;
  }
`;

export const ReachUsWrapper = styled.div`
  margin-left: 90px;

  ${down("tablet")} {
    margin-left: 0px;
    min-width: 255px;
  }
`;

export const ReachUsPhoneWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  > svg {
    margin-right: 12px;
  }

  ${down("mobile")} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 18px;
    > svg {
      margin-bottom: 8px;
    }
  }
`;
export const ReachUsMessageWrapper = styled.div`
  display: flex;
  margin-top: 24px;

  ${down("mobile")} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 26px;
  }
`;

export const ReachUsMessageInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  > a {
    margin-bottom: 8px;
  }

  ${down("mobile")} {
    margin-left: unset;
    margin-top: 8px;
  }
`;

export const ReachUsSpan = styled.a`
  width: fit-content;
  ${LeadSmallStyle()}
  color: ${colors.grayscale._400};
  text-decoration: none;
  ${DefaultScaleTransform()};
`;

export const FooterBottomWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  border-top: 1px solid ${colors.grayscale._800};
  padding: 28px 0px;

  ${down("mobile")} {
    flex-direction: column-reverse;
  }
`;

export const CopyrightSpan = styled.span`
  ${SmallStyle()};
  color: ${colors.grayscale._500};

  ${down("tabletSmall")} {
    max-width: 200px;
  }
  ${down("mobile")} {
    margin-top: 28px;
  }
`;

export const PolicyLinksWrapper = styled.div`
  display: flex;
  margin-left: auto;

  ${down("mobile")} {
    margin-left: unset;
  }
`;

export const PolicyLink = styled.a`
  ${LeadSmallStyle()};
  color: ${colors.grayscale._500};
  text-decoration: none;
  ${DefaultScaleTransform()};
  ${down("tablet")} {
    align-self: center;
  }
  ${down("tabletSmall")} {
    text-align: center;
  }
`;

export const PolicySeparator = styled.span`
  ${LeadSmallStyle()};
  color: ${colors.grayscale._500};
  margin: 0px 8px;

  ${down("tablet")} {
    align-self: center;
  }
`;
