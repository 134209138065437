import React, { FunctionComponent } from "react";
import { PropertyStatus } from "../../../../generated/schema";
import { InfoBadgeContainer } from "./InfoBadge.components";
import { ReactComponent as PendingIcon } from "./icons/PendingIcon.svg";
import { ReactComponent as DangerIcon } from "./icons/DangerIcon.svg";

type InfoBadgeProps = {
  type: PropertyStatus;
};

const getBadgeContent = (type: PropertyStatus) =>
  type === "PENDING" ? (
    <span>
      We care about credibility, so all the listings have to be accepted by the
      admin. It shouldn't take long, but please be patient.
    </span>
  ) : (
    <span>
      Unfortunately, we had to decline your listings. Our Commit team will
      contact you to explain the issue. <br /> Do you have more questions?
      Contact{" "}
      <a
        href="mailto:support@commit.properties"
        target="_blank"
        rel="noreferrer"
      >
        support@commit.properties
      </a>
    </span>
  );

const getBadgeIcon = (type: PropertyStatus) =>
  type === "PENDING" ? <PendingIcon /> : <DangerIcon />;

const InfoBadge: FunctionComponent<InfoBadgeProps> = ({ type }) => {
  if (type === "ACTIVE") return null;
  return (
    <InfoBadgeContainer type={type}>
      {getBadgeIcon(type)}
      <span>{getBadgeContent(type)}</span>
    </InfoBadgeContainer>
  );
};

export default InfoBadge;
