import React from "react";
import { useIsMediaDown } from "../../media";
import {
  FooterPopularSearchesOptions,
  FooterPopularSearchesWrapper,
  FooterPopularSearchWrapper,
  FooterPupularSearchesHeader,
} from "./Footer.components";

const popularSearches = [
  {
    caption: "shop for rent in london",
    url: "/properties?listingType=FOR_RENT&locationRange=0&sortOrder=DATE_DESC&useType=SUPERMARKET&useType=SHOPPING_CENTRE_RETAIL&useType=SHOPPING_CENTRE_OTHER&useType=GENERAL_RETAIL&useType=DEPARTMENT_STORE&lat=51.5072178&lng=-0.1275862&address=London&page=1 ",
  },
  {
    caption: "shop for rent in whitefield",
    url: "/properties?listingType=FOR_RENT&locationRange=0&sortOrder=DATE_DESC&useType=SUPERMARKET&useType=SHOPPING_CENTRE_RETAIL&useType=SHOPPING_CENTRE_OTHER&useType=GENERAL_RETAIL&useType=DEPARTMENT_STORE&lat=53.552083&lng=-2.299201&address=Whitefield%2C+Manchester&page=1 ",
  },
  {
    caption: "shop for rent in ilford",
    url: "/properties?listingType=FOR_RENT&locationRange=0&sortOrder=DATE_DESC&useType=SUPERMARKET&useType=SHOPPING_CENTRE_RETAIL&useType=SHOPPING_CENTRE_OTHER&useType=GENERAL_RETAIL&useType=DEPARTMENT_STORE&lat=51.556619&lng=0.076251&address=Ilford&page=1 ",
  },
  {
    caption: "office to rent london",
    url: "/properties?listingType=FOR_RENT&locationRange=0&sortOrder=DATE_DESC&useType=PRIVATE_OFFICE&useType=OFFICE_SHARED&useType=OFFICE_CO_WORKING&useType=OFFICE_MANAGED&lat=51.5072178&lng=-0.1275862&address=London&page=1 ",
  },
  {
    caption: "office to rent fitzrovia",
    url: "/properties?listingType=FOR_RENT&locationRange=0&sortOrder=DATE_DESC&useType=PRIVATE_OFFICE&useType=OFFICE_SHARED&useType=OFFICE_CO_WORKING&useType=OFFICE_MANAGED&lat=51.5214993&lng=-0.1365872&address=Fitzrovia%2C+London&page=1 ",
  },
  {
    caption: "office to rent bolton",
    url: "/properties?listingType=FOR_RENT&locationRange=0&sortOrder=DATE_DESC&useType=PRIVATE_OFFICE&useType=OFFICE_SHARED&useType=OFFICE_CO_WORKING&useType=OFFICE_MANAGED&lat=53.57686469999999&lng=-2.4282192&address=Bolton&page=1 ",
  },
  {
    caption: "office to rent bristol",
    url: "/properties?listingType=FOR_RENT&locationRange=0&sortOrder=DATE_DESC&useType=PRIVATE_OFFICE&useType=OFFICE_SHARED&useType=OFFICE_CO_WORKING&useType=OFFICE_MANAGED&lat=51.454513&lng=-2.58791&address=Bristol&page=1 ",
  },
  {
    caption: "office to rent stratford",
    url: "/properties?listingType=FOR_RENT&locationRange=0&sortOrder=DATE_DESC&useType=PRIVATE_OFFICE&useType=OFFICE_SHARED&useType=OFFICE_CO_WORKING&useType=OFFICE_MANAGED&lat=51.5471806&lng=-0.0081304&address=Stratford%2C+London&page=1 ",
  },
  {
    caption: "office to rent hackney",
    url: "/properties?listingType=FOR_RENT&locationRange=0&sortOrder=DATE_DESC&useType=PRIVATE_OFFICE&useType=OFFICE_SHARED&useType=OFFICE_CO_WORKING&useType=OFFICE_MANAGED&lat=51.5436387&lng=-0.0553621&address=Hackney%2C+London&page=1 ",
  },
  {
    caption: "commercial property for sale london",
    url: "/properties?listingType=FOR_SALE&locationRange=0&sortOrder=DATE_DESC&lat=51.5072178&lng=-0.1275862&address=London&page=1 ",
  },
  {
    caption: "commercial property for sale liverpool",
    url: "/properties?listingType=FOR_SALE&locationRange=0&sortOrder=DATE_DESC&lat=53.4083714&lng=-2.9915726&address=Liverpool&page=1 ",
  },
  {
    caption: "commercial property for sale birmingham",
    url: "/properties?listingType=FOR_SALE&locationRange=0&sortOrder=DATE_DESC&lat=52.48624299999999&lng=-1.890401&address=Birmingham&page=1 ",
  },
  {
    caption: "commercial property for sale swansea",
    url: "/properties?listingType=FOR_SALE&locationRange=0&sortOrder=DATE_DESC&lat=51.62144&lng=-3.943645999999999&address=Swansea&page=1 ",
  },
  {
    caption: "commercial property for sale manchester",
    url: "/properties?listingType=FOR_SALE&locationRange=0&sortOrder=DATE_DESC&lat=53.4807593&lng=-2.2426305&address=Manchester&page=1 ",
  },
  {
    caption: "commercial property for sale burnley",
    url: "/properties?listingType=FOR_SALE&locationRange=0&sortOrder=DATE_DESC&lat=53.7892877&lng=-2.2405035&address=Burnley&page=1 ",
  },
];

const PopularSearches = () => {
  const isMobile = useIsMediaDown("mobile");

  const selectedSearches = isMobile
    ? popularSearches.slice(0, 9) //Display only 9 first searches on mobile devices.
    : popularSearches;
  return (
    <FooterPopularSearchesWrapper>
      <FooterPupularSearchesHeader>
        Popular searches
      </FooterPupularSearchesHeader>
      <FooterPopularSearchesOptions>
        {selectedSearches.map((searchPhrase) => (
          <FooterPopularSearchWrapper
            key={searchPhrase.caption}
            href={searchPhrase.url}
          >
            {searchPhrase.caption}
          </FooterPopularSearchWrapper>
        ))}
      </FooterPopularSearchesOptions>
    </FooterPopularSearchesWrapper>
  );
};

export default PopularSearches;
