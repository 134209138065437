import { useParams } from "react-router";
import { AccountType, PropertyStatus } from "../../generated/schema";
import useCurrentAccountType from "./useCurrentAccountType";

const useListingsStatus = (): PropertyStatus => {
  const { status } = useParams<{ status: PropertyStatus }>();
  const accountType = useCurrentAccountType();
  return status
    ? (status.toUpperCase() as PropertyStatus)
    : accountType === AccountType.Admin
    ? PropertyStatus.Pending
    : PropertyStatus.Active;
};

export default useListingsStatus;
