import styled from "styled-components";
import { colors } from "../../colors";
import { down } from "../../media";
import BackgroundDesktop from "./background-desktop.svg";
import BackgroundTablet from "./background-tablet.svg";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: row;
  min-height: 100%;
  width: 100%;
  margin: auto;
  flex: 1;
  background-color: ${colors.grayscale._200};

  ${down("mobile")} {
    flex-direction: column;
    background-color: ${colors.primary.pure_white};
  }
`;

export const ImageWrapper = styled.div`
  width: 368px;
  height: 480px;
  background-color: ${colors.secondary.pure_blue_dark};
  background-image: url(${BackgroundDesktop});
  border-radius: 24px 0px 0px 24px;

  ${down("tablet")} {
    position: absolute;
    border-radius: 24px;
    max-width: 692px;
    width: calc(100% - 112px);
    background-image: url(${BackgroundTablet});
    height: 448px;
  }

  ${down("mobile")} {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  width: 536px;
  height: 558px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: ${colors.primary.pure_white};
  box-shadow: -20px 0px 40px -20px rgba(20, 78, 135, 0.3);
  border-radius: 24px;

  ${down("tablet")} {
    z-index: 1;
    margin: auto;
    height: 530px;
    width: calc(100% - 224px);
    min-width: 420px;
    max-width: 464px;
  }

  ${down("mobile")} {
    flex: 1;
    width: 100%;
    margin: 0px;
    min-width: unset;
    max-width: 400px;
    border-radius: 0px;
    box-shadow: none;
    align-items: flex-start;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 48px 64px;
`;

export const DetailsLogo = styled.img`
  width: 65px;
  height: 24px;
  transition: transform 250ms ease-in-out;
  cursor: pointer;

  :hover {
    transform: scale(1.05);
  }

  ${down("tablet")} {
    display: none;
  }
`;

export const DetailsHeader = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  margin: 0px;
  padding: 0px;
  color: ${colors.primary.pure_white};
  margin-top: 48px;
  max-width: 220px;
  ${down("tablet")} {
    display: none;
  }
`;

export const DetailsDescription = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.primary.pure_white};
  margin-top: 8px;

  ${down("tablet")} {
    display: none;
  }
`;

export const DetailsList = styled.ul`
  margin: 0;
  padding-left: 20px;
  margin-top: 14px;
  ${down("tablet")} {
    display: none;
  }
`;

export const DetailsListItem = styled.li`
  color: ${colors.grayscale._300};
  font-size: 16px;
  line-height: 24px;
`;
