export const zIndexes = {
  MOBILE_MODAL_OVERLAY: 500,
  MOBILE_MODAL_CONTAINER: 501,
  NAVBAR_OVERLAY: 1000,
  NAVBAR_CONTAINER: 1001,
  FILTERS_MODA_OVERLAY: 1002,
  FILTERS_MODAL: 1003,
  MODAL_OVERLAY: 1500,
  MODAL_CONTAINER: 1501,
};
