import { Form } from "formik";
import styled from "styled-components";
import { colors } from "../../../../colors";
import { down } from "../../../../media";

export const MessageAgentFormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MessageAgentInputWrapper = styled.div`
  max-width: 280px;
  margin-bottom: 16px;
`;

export const MessageAgentCheckboxWrapper = styled.div`
  max-width: 340px;
  margin-top: 8px;

  ${down("mobile")} {
    max-width: 100%;
  }
`;

export const MessageAgentBottomSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${down("mobile")} {
    justify-content: flex-end;
  }
`;

export const MessageAgentButtonWrapper = styled.div`
  position: relative;
  padding-top: 48px;
`;

export const MessageAgentError = styled.span`
  position: absolute;
  color: ${colors.semantic.red};
  top: 16px;
  left: 0px;
  font-size: 14px;
`;

export const MessageAgentNote = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${colors.grayscale._600};
  margin-top: 16px;
  > a {
    color: ${colors.secondary.pure_blue};
  }
`;
