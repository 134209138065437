import { Formik } from "formik";
import React, { FunctionComponent, useCallback, useState } from "react";
import { useLoginMutation } from "../../../generated/schema";
import {
  LoginFormValues,
  loginInitialValues,
  loginValidationSchema,
} from "./form";
import LoginFormContent from "./LoginFormContent";
import {
  authTokenFromResponse,
  setAuthToken,
} from "../../../common/helpers/authToken";
import { useNavigate } from "react-router-dom";
import routesPaths from "../../Routing/routesPaths";
import AuthContainer from "../../shared/components/AuthContainer/AuthContainer";

const LoginForm: FunctionComponent = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [login] = useLoginMutation();

  const handleSubmit = useCallback(
    async (values: LoginFormValues) => {
      setError("");
      try {
        const { data } = await login({
          variables: {
            input: {
              email: values.email,
              password: values.password,
            },
          },
        });

        switch (data?.login.__typename) {
          case "TokenSuccessfulResponse":
            setAuthToken(authTokenFromResponse(data.login));
            navigate(
              data.login.accountType === "ADMIN"
                ? routesPaths.LISTINGS
                : routesPaths.MY_PROPERTIES,
            );
            return;
          case "TokenInvalidResponse":
            setError("Wrong email or password.");
            break;
          case "TokenUserInactiveResponse":
            setError("User account is not active.");
            break;
        }
      } catch (e) {
        setError("Unexected error. Try again.");
        console.error(e);
      }
    },
    [login, navigate],
  );

  return (
    <AuthContainer
      title={<>The next generation of commercial property platform</>}
      description={[
        "List your properties on our platform for free.",
        "Enhance your listings with our free intelligence tooling.",
        "Get leads delivered directly to your inbox.",
      ]}
    >
      <Formik
        initialValues={loginInitialValues}
        validationSchema={loginValidationSchema}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <LoginFormContent
            {...formProps}
            error={error}
            onChange={() => setError("")}
          />
        )}
      </Formik>
    </AuthContainer>
  );
};

export default LoginForm;
