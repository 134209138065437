import * as Yup from "yup";

export interface MessageAgentModalFormValues {
  name: string;
  email: string;
  message: string;
  marketingAgreement: boolean;
}

export const messageAgentModalInitialValues: MessageAgentModalFormValues = {
  name: "",
  email: "",
  message: "",
  marketingAgreement: false,
};

export const messageAgentModalValidationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Email must be valid")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
  marketingAgreement: Yup.boolean(),
});
