import styled, { css } from "styled-components";
import { colors } from "../../colors";

export const FilesInputContainer = styled.div<{ isDragActive: boolean }>`
  height: 120px;
  width: 100%;
  box-sizing: border-box;
  border: 1px dashed ${colors.grayscale._400};
  transition: border-color 250ms ease-in-out, background-color 250ms ease-in-out;
  cursor: pointer;
  border-radius: 8px;

  ${(p) =>
    p.isDragActive &&
    css`
      background-color: ${colors.grayscale._100};
      border: 1px dashed ${colors.secondary.pure_blue};
    `};

  :hover {
    background-color: ${colors.grayscale._100};
    border: 1px dashed ${colors.secondary.pure_blue};
  }

  > input {
    display: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const FilesInputContent = styled.div`
  cursor: pointer;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const UploadImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const DescriptionSpan = styled.span`
  text-align: center;
  margin-top: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: ${colors.primary.frosted_black};
`;

export const BlueSpan = styled.span`
  color: ${colors.secondary.pure_blue};
  text-decoration: underline;
  display: block;
`;

export const ExtensionsSpan = styled.span`
  margin-top: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${colors.grayscale._600};
`;
