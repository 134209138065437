import styled, { css } from "styled-components";
import { colors } from "../../../colors";

export const LabelContainer = styled.label<{ show: boolean }>`
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 10px;
  letter-spacing: 0.01em;
  color: ${colors.grayscale._600};
  margin-bottom: 6px;
  display: flex;
  ${(p) =>
    p.show &&
    css`
      height: 10px;
    `}
`;

export const HintImage = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 4px;
  transition: transform 250ms ease-in-out;
  cursor: pointer;

  :hover {
    transform: scale(1.25);
  }
`;
