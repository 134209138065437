import styled from "styled-components";
import { colors } from "../../colors";

export const HintContainer = styled.span`
  background-color: ${colors.grayscale._700};
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${colors.primary.pure_white};
  display: block;
  word-wrap: break-word;
  max-width: 256px;

  > a {
    color: white;
  }
`;
