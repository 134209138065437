import styled from "styled-components";
import { colors } from "../../shared/colors";
import { down } from "../../shared/media";
import { LeadSmallStyle } from "../../shared/mixins";

export const AboutUsOuterContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 120px;
  padding: 88px 0 152px 0;

  ${down("tabletSmall")} {
    padding: 80px 0 115px 0;
  }

  ${down("mobile")} {
    margin-top: 64px;
    padding: 0px 0 115px 0;
  }
`;

export const AboutUsBackground = styled.div`
  position: absolute;
  height: 100%;
  width: 75%;
  top: 0px;
  left: 0px;
  background: ${colors.grayscale._200};
  z-index: 0;

  ${down("tablet")} {
    width: 80%;
  }
  ${down("mobile")} {
    width: 100%;
    top: 33%;
    height: 80%;
  }
`;

export const AboutUsContainer = styled.div`
  position: relative;
  display: flex;
  max-width: 1088px;
  width: 100%;
  z-index: 1;
  margin: 0 112px;

  ${down("tablet")} {
    margin: 0 56px;
  }
  ${down("mobile")} {
    margin: 0 24px;
  }
`;

export const AboutUsImage = styled.img`
  position: absolute;
  min-width: 350px;
  width: 45%;
  max-height: 592px;

  top: -140px;
  right: 0px;
  z-index: 1;
  object-fit: contain;

  ${down("tablet")} {
    min-width: 400px;
    width: 48%;
  }

  ${down("tabletSmall")} {
    bottom: 0px;
    top: unset;
    max-width: 58%;
    width: 100%;
    height: unset;
    min-width: 300px;
  }

  ${down("mobile")} {
    position: initial;
    display: flex;
    align-self: center;
    margin-top: 16px;
    margin-left: -12px;
    max-width: unset;
    width: 80%;
  }
`;

export const AboutUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 100%;
  margin-right: 430px;

  ${down("tablet")} {
    margin-right: 400px;
  }

  ${down("tabletSmall")} {
    margin-right: unset;
  }
`;

export const AboutUsIndicator = styled.span`
  margin: 0;
  padding: 0;
  ${LeadSmallStyle()}
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(59, 122, 247, 0.12);
  margin-bottom: 16px;
  max-width: 220px;
  text-transform: uppercase;
  > span {
    color: ${colors.secondary.pure_blue};
  }

  ${down("mobile")} {
    padding-bottom: 12px;
    margin-bottom: 20px;
  }
`;

export const AboutUsHeader = styled.h2`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.01em;

  ${down("tablet")} {
    font-size: 32px;
    line-height: 44px;
  }

  ${down("mobile")} {
    line-height: 38px;
    max-width: 327px;
  }
`;

export const AboutUsDescription = styled.p`
  margin: 0;
  max-width: 535px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: ${colors.grayscale._600};
  margin-top: 4px;

  ${down("tablet")} {
    font-size: 16px;
    line-height: 24px;
    max-width: 480px;
  }

  ${down("tabletSmall")} {
    max-width: 374px;
  }

  ${down("mobile")} {
    max-width: 327px;
  }
`;

export const AboutUsDetailsWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  > :last-child {
    margin-left: 32px;
  }

  ${down("tabletSmall")} {
    flex-direction: column;
    margin-top: 43px;

    > :last-child {
      margin-left: 0px;
      margin-top: 35px;
    }
  }

  ${down("mobile")} {
    margin: 41px 24px 0;
  }
`;

export const AboutUsDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 278px;
  ${down("mobile")} {
    max-width: 90%;
  }
`;

export const AboutUsDetailHeading = styled.span`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.primary.frosted_black};
  margin-top: 8px;

  ${down("tablet")} {
    font-size: 16px;
    line-height: 16px;
  }
`;

export const AboutUsDetailDescription = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.grayscale._600};
  margin-top: 8px;
  > span {
    color: ${colors.secondary.pure_blue_dark};
  }

  ${down("tablet")} {
    max-width: 238px;
  }

  ${down("tabletSmall")} {
    max-width: 224px;
  }
  ${down("mobile")} {
    max-width: 100%;
  }
`;
