import React from "react";
import LoginForm from "./Form/LoginForm";

export type LoginNavigationState = {
  accountClaimed: boolean;
};

const Login = () => {
  return <LoginForm />;
};

export default Login;
