import styled from "styled-components";
import { H6Style, HorizontalGap } from "../../../shared/mixins";

export const HintHeaderContainer = styled.div`
  ${H6Style};
  display: flex;
  ${HorizontalGap(4)};
  margin-bottom: 16px;
`;

export const HintImage = styled.img`
  cursor: pointer;
  :hover {
    transform: scale(1.05);
  }
`;
