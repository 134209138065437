import React from "react";
import { useNavigate } from "react-router";
import routesPaths from "../../Routing/routesPaths";
import Button from "../../shared/components/Button";
import {
  ListingPropertiesContainer,
  ListingPropertiesDescription,
  ListingPropertiesDescriptionButtonWrapper,
  ListingPropertiesDescriptionHeading,
  ListingPropertiesDescriptionWrapper,
  ListingPropertiesImage,
  ListingPropertiesImageWrapper,
  ListingPropertiesIndicator,
  ListingPropertiesInnerContainer,
} from "./ListingProperies.components";
import PhotoLeft from "./images/PhotoLeft.webp";
import { useIsMediaDown } from "../../shared/media";

const ListingProperties = () => {
  const navigate = useNavigate();
  const isMobileDown = useIsMediaDown("mobile");

  return (
    <ListingPropertiesContainer>
      <ListingPropertiesInnerContainer>
        <ListingPropertiesImageWrapper>
          <ListingPropertiesIndicator>
            <span>01</span> Listing Properties
          </ListingPropertiesIndicator>
          <ListingPropertiesImage src={PhotoLeft} />
        </ListingPropertiesImageWrapper>
        <ListingPropertiesDescriptionWrapper>
          <ListingPropertiesDescriptionHeading>
            Market your properties {!isMobileDown && <br />}
            with Commit
          </ListingPropertiesDescriptionHeading>
          <ListingPropertiesDescription>
            List all your properties on Commit in
            <strong> under 10 minutes. </strong> {isMobileDown && <br />}
            Increase property enquiries and boosts conversion rates with our
            free intelligence and recommendation tooling. Enhance your deal flow
            by harnessing our highly engaged audience.
          </ListingPropertiesDescription>
          <ListingPropertiesDescriptionButtonWrapper>
            <Button
              size="large"
              height="l"
              buttonType="light"
              onClick={() => navigate(routesPaths.REGISTER)}
            >
              List your property
            </Button>
          </ListingPropertiesDescriptionButtonWrapper>
        </ListingPropertiesDescriptionWrapper>
      </ListingPropertiesInnerContainer>
    </ListingPropertiesContainer>
  );
};

export default ListingProperties;
