import styled from "styled-components";
import { PropertyStatus } from "../../../../generated/schema";
import { colors } from "../../colors";
import { ButtonSmallStyle } from "../../mixins";

export const InfoBadgeContainer = styled.div<{
  type: PropertyStatus;
}>`
  max-width: 1088px;
  width: 100%;
  background: ${colors.primary.pure_white};
  border-radius: 8px;
  padding: 16px 26px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-top: 24px;
  > span,
  * a {
    ${ButtonSmallStyle()};
    margin-left: 8px;
    color: ${(p) =>
      p.type === "PENDING"
        ? colors.semantic.green_dark
        : colors.semantic.red_dark};
  }

  * a {
    margin-left: 0px;
    :hover {
      text-decoration: none;
    }
  }
`;
