import React, { FunctionComponent, useCallback, useState } from "react";
import {
  CommitLogo,
  CommitLogoLink,
  Container,
  Content,
  MenuContainer,
  MenuDropdown,
  OptionsContainer,
  Overlay,
  RighMenuContainer,
} from "../Navbar.components";
import CommitLogoBlack from "../../../images/commit_logo_black.svg";
import routesPaths from "../../../../Routing/routesPaths";
import NavbarOption, { isPathSelected } from "./NavbarOption/NavbarOption";
import { useLocation, useNavigate } from "react-router";
import NavbarMenuOption from "./NavbarOption/NavbarMenuOption";
import { Transition } from "react-transition-group";
import { ReactComponent as AccountSettingsImage } from "../icons/icon_account_settings.svg";
import {
  MenuItem,
  MenuItemsDivider,
} from "./NavbarOption/NavbarOption.components";
import { deleteAuthToken } from "../../../../../common/helpers/authToken";
import { ReactComponent as LogoutImage } from "../icons/icon_logout.svg";
import { INavbarOption } from "../MainNavbar";
import MenuButton from "./MenuButton";
import { useModal } from "../../Modal";
import AccountDetailsModal from "../../Modals/AccountDetailsModal/AccountDetailsModal";

const AuthedNavbar: FunctionComponent<{ elements: INavbarOption[] }> = ({
  elements,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);
  const { openModal } = useModal();

  const openAccountDetailsModal = useCallback(() => {
    setMenuVisible(false);
    openModal(<AccountDetailsModal />);
  }, [openModal]);

  return (
    <>
      <Transition
        in={menuVisible}
        timeout={250}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {(status) => (
          <Overlay status={status} onClick={() => setMenuVisible(false)} />
        )}
      </Transition>
      <Container>
        <Content>
          <CommitLogoLink to={routesPaths.HOME}>
            <CommitLogo src={CommitLogoBlack} />
          </CommitLogoLink>

          <OptionsContainer>
            {elements
              .filter((e) => e.align !== "right")
              .map((e) => (
                <NavbarOption
                  key={e.name}
                  title={e.name}
                  path={e.path}
                  url={e.url}
                />
              ))}
          </OptionsContainer>

          <MenuContainer>
            <MenuButton
              visible={menuVisible}
              onClick={() => setMenuVisible(!menuVisible)}
            />
            <Transition
              in={menuVisible}
              timeout={200}
              mountOnEnter={true}
              unmountOnExit={true}
            >
              {(status) => (
                <MenuDropdown status={status}>
                  {elements.map((e) => (
                    <MenuItem
                      key={e.name}
                      selected={isPathSelected(
                        location.pathname,
                        e.path ?? e.url!,
                      )}
                      onClick={() => {
                        if (e.path) window.scrollTo(0, 0);
                        setMenuVisible(false);
                        e.url
                          ? window.open(e.url, e.target ?? "_blank")
                          : navigate(e.path!);
                      }}
                    >
                      {e.icon}
                      {e.name}
                    </MenuItem>
                  ))}
                  <MenuItem onClick={openAccountDetailsModal}>
                    <AccountSettingsImage />
                    Account settings
                  </MenuItem>
                  <MenuItemsDivider />

                  <MenuItem onClick={() => deleteAuthToken()}>
                    <LogoutImage />
                    Log out
                  </MenuItem>
                </MenuDropdown>
              )}
            </Transition>
          </MenuContainer>

          <RighMenuContainer>
            {elements
              .filter((e) => e.align === "right")
              .map((e) => (
                <NavbarOption
                  key={e.name}
                  title={e.name}
                  path={e.path}
                  url={e.url}
                />
              ))}
            <NavbarMenuOption title="Profile" />
          </RighMenuContainer>
        </Content>
      </Container>
    </>
  );
};

export default AuthedNavbar;
