import React, { FunctionComponent } from "react";
import { FormikProps, Form } from "formik";
import InputField from "../../../modules/shared/components/Form/Input/InputField";
import { ResetPasswordFormValues } from "../../../modules/ResetPassword/RequestResetPassword/form";
import {
  BackIcon,
  BackToLoginLink,
  ButtonContainer,
  ButtonRegisterContainer,
  CommitLogo,
  Container,
  Description,
  ErrorSpan,
  FormContent,
  Header,
  InputContainer,
} from "../SetNewPassword/SetNewPassword.components";
import CommigLogoBlue from "../../shared/images/commit_logo_blue.svg";
import routesPaths from "../../Routing/routesPaths";
import ArrowLeft from "../arrow_left.svg";
import Button from "../../shared/components/Button";

type Props = FormikProps<ResetPasswordFormValues> & {
  error?: string;
  onChange?: () => void;
};
const RequestResetPasswordFormContent: FunctionComponent<Props> = ({
  isSubmitting,
  error,
  onChange,
}) => (
  <Container>
    <Form onChange={() => onChange && onChange()}>
      <FormContent>
        <CommitLogo src={CommigLogoBlue} alt="Commit logo" />
        <Header>Password recovery</Header>

        <Description>
          Don’t worry happens to the best of us. Enter your email, and we'll
          send you a link with password recovery.
        </Description>

        <InputContainer>
          <InputField
            autoComplete="email"
            name="email"
            label="Email"
            type="email"
          />
        </InputContainer>

        <ButtonRegisterContainer>
          <ErrorSpan>{error}</ErrorSpan>
          <ButtonContainer>
            <Button type="submit" size="block" isLoading={isSubmitting}>
              Recover Password
            </Button>
          </ButtonContainer>
          <BackToLoginLink to={routesPaths.LOGIN}>
            <BackIcon src={ArrowLeft} />
            Back to login
          </BackToLoginLink>
        </ButtonRegisterContainer>
      </FormContent>
    </Form>
  </Container>
);

export default RequestResetPasswordFormContent;
