import {
  AreaHealthType,
  LevelType,
  ListingType,
  PropertyUseType,
} from "../../generated/schema";
import { IPropertiesFilters, SortOrder } from "./Filters/Filters";

const isEnumValue = <T>(values: T[], item: T | undefined) => {
  return item ? values.includes(item) : false;
};

const getNumber = (searchParams: URLSearchParams, name: string) => {
  const value = searchParams.get(name);
  if (value !== null && !isNaN(Number(value))) return Number(value);
  return undefined;
};

const getEnum = <T>(
  searchParams: URLSearchParams,
  name: string,
  values: T[],
) => {
  const enumValue = searchParams.get(name) as unknown as T;
  if (enumValue && isEnumValue(values, enumValue)) return enumValue;
  return undefined;
};

export type PropertyFiltersQuery = {
  locationRange: string;
  priceFrom?: string;
  priceTo?: string;
  sqFootageFrom?: string;
  sqFootageTo?: string;
  sortOrder: string;
  areaHealth?: string;
  crimeRate?: string;
  populationDensity?: string;
  studentPopulation?: string;
  shoplifting?: string;
  listingType: string;
  useType?: string[];
  lat?: string;
  lng?: string;
  address?: string;
};

export const getFiltersQuery = (filters: IPropertiesFilters) => {
  const query: PropertyFiltersQuery = {
    listingType: filters.listingType,
    locationRange: filters.locationRange.toString(),
    sortOrder: filters.sortOrder.toString(),
  };

  if (filters.priceFrom !== undefined) {
    query.priceFrom = filters.priceFrom.toString();
  }
  if (filters.priceTo !== undefined) {
    query.priceTo = filters.priceTo.toString();
  }
  if (filters.priceFrom !== undefined) {
    query.priceFrom = filters.priceFrom.toString();
  }
  if (filters.sqFootageFrom !== undefined) {
    query.sqFootageFrom = filters.sqFootageFrom.toString();
  }
  if (filters.sqFootageTo !== undefined) {
    query.sqFootageTo = filters.sqFootageTo.toString();
  }
  if (filters.areaHealth !== undefined) {
    query.areaHealth = filters.areaHealth.toString();
  }
  if (filters.crimeRate !== undefined) {
    query.crimeRate = filters.crimeRate.toString();
  }
  if (filters.populationDensity !== undefined) {
    query.populationDensity = filters.populationDensity.toString();
  }
  if (filters.studentPopulation !== undefined) {
    query.studentPopulation = filters.studentPopulation.toString();
  }
  if (filters.shoplifting !== undefined) {
    query.shoplifting = filters.shoplifting.toString();
  }
  if (filters.propertyUseTypes.length > 0) {
    query.useType = filters.propertyUseTypes;
  }
  if (filters.location?.lat !== undefined) {
    query.lat = filters.location.lat.toString();
  }
  if (filters.location?.lng !== undefined) {
    query.lng = filters.location.lng.toString();
  }
  if (filters.location?.fullAddress !== undefined) {
    query.address = filters.location.fullAddress;
  }

  return query;
};

export const getPropertyFiltersQuery = (searchParams: URLSearchParams) => {
  const filters: IPropertiesFilters = {
    listingType: ListingType.ForRent,
    locationRange: 0,
    propertyUseTypes: [],
    sortOrder: SortOrder.DATE_DESC,
  };

  filters.locationRange = getNumber(searchParams, "locationRange") ?? 0;
  filters.priceFrom = getNumber(searchParams, "priceFrom");
  filters.priceTo = getNumber(searchParams, "priceTo");
  filters.sqFootageFrom = getNumber(searchParams, "sqFootageFrom");
  filters.sqFootageTo = getNumber(searchParams, "sqFootageTo");

  filters.sortOrder =
    getEnum(searchParams, "sortOrder", Object.values(SortOrder)) ??
    SortOrder.DATE_DESC;

  const healthValues = Object.values(AreaHealthType);
  filters.areaHealth = getEnum(searchParams, "areaHealth", healthValues);

  const levelTypeValues = Object.values(LevelType);
  filters.crimeRate = getEnum(searchParams, "crimeRate", levelTypeValues);
  filters.populationDensity = getEnum(
    searchParams,
    "populationDensity",
    levelTypeValues,
  );
  filters.studentPopulation = getEnum(
    searchParams,
    "studentPopulation",
    levelTypeValues,
  );
  filters.shoplifting = getEnum(searchParams, "shoplifting", levelTypeValues);

  const listingTypeValues = Object.values(ListingType);

  filters.listingType =
    getEnum(searchParams, "listingType", listingTypeValues) ??
    ListingType.ForRent;

  const useTypeValues = Object.values(PropertyUseType);
  searchParams.getAll("useType").forEach((useType) => {
    const value = useType as PropertyUseType;
    if (isEnumValue(useTypeValues, value)) {
      filters.propertyUseTypes.push(value);
    }
  });

  const lat = getNumber(searchParams, "lat");
  const lng = getNumber(searchParams, "lng");
  const address = searchParams.get("address");
  if (lat && lng) {
    filters.location = {
      fullAddress: address ?? "",
      lat,
      lng,
    };
  }

  return filters;
};
