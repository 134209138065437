import React, { FunctionComponent } from "react";
import FormBox from "../FormBox/FormBox";
import { AddPropertyFormContentProps } from "../../AddPropertyFormContent";
import {
  InputsContainer,
  LargeInputContainer,
  MediumInputContainer,
  SmallInputContainer,
  SpaceDetailsSectionContainer,
} from "./SpaceDetailsSection.components";
import InputField from "../../../shared/components/Form/Input/InputField";
import MultiSelect from "../../../shared/components/Select/MultiSelect/MultiSelect";
import SingleSelect from "../../../shared/components/Select/SingleSelect/SingleSelect";
import {
  AMENITIES_TYPE_OPTIONS,
  CONDITION_TYPE_OPTIONS,
  EPC_TYPE_OPTIONS,
  EXISTING_BUILDOUT_TYPE_OPTIONS,
  getAmenitiesTypeValue,
  getPropertyUseTypeValue,
  PROPERTY_USE_TYPE_OPTIONS,
  USE_CLASS_TYPE_OPTIONS,
} from "../../../../common/helpers/enumNamesHelper";
import { AddPropertyDivider } from "../../../Layouts/AddPropertyLayout/AddPropertyLayout.components";
import { Descriptions, Hints } from "../../AddPropertyFormHints";

const SpaceDetailsSection: FunctionComponent<AddPropertyFormContentProps> = (
  props,
) => {
  const { values, errors, submitCount, setFieldValue } = props;
  const propertyUseTypeError = errors.propertyUseType as string;
  const amenitiesTypeError = errors.amenitiesType as string;

  return (
    <FormBox section="SPACE_DETAILS" description={Descriptions.spaceDetails}>
      <SpaceDetailsSectionContainer>
        <InputsContainer>
          <SmallInputContainer>
            <InputField
              label="Sq Footage"
              name="sqFootage"
              inputSize="l"
              hint={Hints.sqFootage}
              type="number"
            />
          </SmallInputContainer>
          <SmallInputContainer>
            <InputField
              label="Floors"
              hint={Hints.floors}
              name="floors"
              inputSize="l"
              type="number"
            />
          </SmallInputContainer>
        </InputsContainer>
        <AddPropertyDivider />
        <MultiSelect
          hint={Hints.propertyUseType}
          submitCount={submitCount}
          options={PROPERTY_USE_TYPE_OPTIONS.map((o) => o.value)}
          label="Property Use Type"
          name="propertyUseType"
          error={propertyUseTypeError}
          selections={values.propertyUseType.map((t) =>
            getPropertyUseTypeValue(t),
          )}
          optionClick={(value) => {
            const useTypeValues = values.propertyUseType;
            const option = PROPERTY_USE_TYPE_OPTIONS.find(
              (o) => o.value === value,
            )!;

            const newValues = useTypeValues.includes(option.type)
              ? useTypeValues.filter((o) => o !== option.type)
              : useTypeValues.concat(option.type);

            setFieldValue("propertyUseType", newValues);
          }}
        />
        <LargeInputContainer>
          <SingleSelect
            name="useClassType"
            hint={Hints.useClass}
            label="Use Class"
            submitCount={submitCount}
            error={errors.useClassType}
            options={USE_CLASS_TYPE_OPTIONS.map((o) => o.value)}
            selection={USE_CLASS_TYPE_OPTIONS.findIndex(
              (o) => o.type === values.useClassType,
            )}
            optionClick={(index) => {
              setFieldValue(
                "useClassType",
                index < 0 ? undefined : USE_CLASS_TYPE_OPTIONS[index].type,
              );
            }}
          />
        </LargeInputContainer>

        <InputsContainer>
          <MediumInputContainer>
            <SingleSelect
              name="conditionType"
              label="Condition"
              hint={Hints.condition}
              submitCount={submitCount}
              error={errors.conditionType}
              options={CONDITION_TYPE_OPTIONS.map((o) => o.value)}
              selection={CONDITION_TYPE_OPTIONS.findIndex(
                (o) => o.type === values.conditionType,
              )}
              optionClick={(index) => {
                setFieldValue(
                  "conditionType",
                  index < 0 ? undefined : CONDITION_TYPE_OPTIONS[index].type,
                );
              }}
            />
          </MediumInputContainer>
          <MediumInputContainer>
            <SingleSelect
              name="existingBuildout"
              hint={Hints.existingBuildout}
              submitCount={submitCount}
              label="Existing Buildout"
              error={errors.existingBuildout}
              options={EXISTING_BUILDOUT_TYPE_OPTIONS.map((o) => o.value)}
              selection={EXISTING_BUILDOUT_TYPE_OPTIONS.findIndex(
                (o) => o.type === values.existingBuildout,
              )}
              optionClick={(index) => {
                setFieldValue(
                  "existingBuildout",
                  index < 0
                    ? undefined
                    : EXISTING_BUILDOUT_TYPE_OPTIONS[index].type,
                );
              }}
            />
          </MediumInputContainer>
        </InputsContainer>

        <InputsContainer>
          <MediumInputContainer>
            <SingleSelect
              name="currentEpcType"
              submitCount={submitCount}
              hint={Hints.currentEpc}
              label="Current EPC"
              error={errors.currentEpcType}
              options={EPC_TYPE_OPTIONS.map((o) => o.value)}
              selection={EPC_TYPE_OPTIONS.findIndex(
                (o) => o.type === values.currentEpcType,
              )}
              optionClick={(index) => {
                setFieldValue(
                  "currentEpcType",
                  index < 0 ? undefined : EPC_TYPE_OPTIONS[index].type,
                );
              }}
            />
          </MediumInputContainer>
          <MediumInputContainer>
            <SingleSelect
              name="potentialEpc"
              submitCount={submitCount}
              hint={Hints.potencialEpc}
              label="Potential EPC (opt.)"
              error={errors.potencialEpcType}
              options={EPC_TYPE_OPTIONS.map((o) => o.value)}
              selection={EPC_TYPE_OPTIONS.findIndex(
                (o) => o.type === values.potencialEpcType,
              )}
              optionClick={(index) => {
                setFieldValue(
                  "potencialEpcType",
                  index < 0 ? undefined : EPC_TYPE_OPTIONS[index].type,
                );
              }}
            />
          </MediumInputContainer>
        </InputsContainer>

        <AddPropertyDivider />

        <MediumInputContainer>
          <InputField
            inputSize="l"
            hint={Hints.businessRate}
            name="businessRate"
            label="Business Rate - PA (opt.)"
            type="number"
            frontIcon="currency"
          />
        </MediumInputContainer>

        <MultiSelect
          submitCount={submitCount}
          hint={Hints.amenities}
          label="Amenities (opt.)"
          name="Amenities"
          error={amenitiesTypeError}
          options={AMENITIES_TYPE_OPTIONS.map((o) => o.value)}
          selections={values.amenitiesType.map((t) => getAmenitiesTypeValue(t))}
          optionClick={(value) => {
            const amenitiesValues = values.amenitiesType;
            const option = AMENITIES_TYPE_OPTIONS.find(
              (o) => o.value === value,
            )!;
            const newValues = amenitiesValues.includes(option.type)
              ? amenitiesValues.filter((o) => o !== option.type)
              : amenitiesValues.concat(option.type);
            setFieldValue("amenitiesType", newValues);
          }}
        />
      </SpaceDetailsSectionContainer>
    </FormBox>
  );
};

export default SpaceDetailsSection;
