import styled, { css } from "styled-components";
import { colors } from "../shared/colors";
import { PrivacyTermsParagraph } from "../shared/components/PrivacyTerms/PrivacyTerms.components";
import { down } from "../shared/media";
import { DefaultScaleTransform } from "../shared/mixins";

export const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  align-items: center;
  box-sizing: border-box;
  padding: 0 56px;
  margin-top: 40px;
  margin-bottom: 96px;

  ${down("mobile")} {
    padding: 0px;
  }
`;

export const FaqAccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 40px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 16px;
  max-width: 904px;
  width: 100%;

  margin-bottom: 8px;
  ${down("tabletSmall")} {
    max-width: unset;
  }

  ${down("mobile")} {
    padding: 24px;
  }
`;

export const FaqNotExpandedRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FaqTitle = styled.h4`
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.primary.frosted_black};
  max-width: 80%;

  ${down("mobile")} {
    font-size: 16px;
  }
`;

export const ExpandFaqButton = styled.button<{ isExpanded: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  background-color: ${(p) =>
    p.isExpanded
      ? colors.secondary.pure_blue
      : colors.secondary.pure_blue_transparent};
  ${DefaultScaleTransform()}

  >svg {
    margin-left: 1px;
    ${(p) =>
      p.isExpanded &&
      css`
        stroke: green;
        margin-left: 0px;
        margin-top: 2px;
        transform: rotate(90deg);
        #arrow-path {
          stroke: white;
        }
      `}
  }
`;

export const FaqParagraph = styled(PrivacyTermsParagraph)<{
  ignoreMaxWidth?: boolean;
}>`
  > span {
    font-weight: 500;
    color: ${colors.secondary.pure_blue_dark};
  }

  > a {
    color: ${colors.secondary.pure_blue_dark};
  }

  ${(p) =>
    p.ignoreMaxWidth &&
    css`
      max-width: unset; ;
    `}
`;

export const FaqIntelligenceFiltersWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  > img {
    margin-left: -20px;
    float: left;
    object-fit: contain;
    margin-right: 40px;
  }

  > div {
    padding-top: 48px;
  }

  ${down("tabletSmall")} {
    > div {
      padding-top: 32px;
    }
    > img {
      margin-right: 10px;
    }
  }

  ${down("mobile")} {
    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
      margin-left: 0px;
      margin-right: 0px;
    }
    > div {
      padding-top: 16px;
    }
  }
`;
