import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { debounce } from "ts-debounce";
import { getPublicPropertyDetailsRoute } from "../../common/helpers/navigationHelpers";
import { usePaginatedResponse } from "../../common/helpers/paginationHelpers";
import { usePaginationInput } from "../../common/helpers/usePropertyPaginationInput";
import { useSearchPropertiesLazyQuery } from "../../generated/schema";
import PageLoader from "../shared/components/PageLoader/PageLoader";
import Pagination from "../shared/components/Pagination";
import PropertyCard from "../shared/components/PropertyCard";
import { getBadgeType } from "../shared/components/PropertyCard/PropertyBadge/PropertyBadge";
import { IPropertiesFilters } from "./Filters/Filters";
import {
  PropertiesExtendedMagIcon,
  PropertiesExtendedSearch,
  PropertiesExtendedSearchContainer,
  PropertiesExtendedSearchContainerHeader,
  PropertiesExtendedSearchContainerMessage,
  PropertiesExtendedSearchImageContainer,
  PropertiesExtendedSearchLabel,
  PropertiesExtendedSearchLabelContainer,
  PropertiesExtendedSearchLine,
  PropertiesResultsContainer,
  PropertiesResultsLoading,
} from "./Properties.components";
import { buildApiFilters, buildApiSort } from "./propertyHelpers";
import { getFiltersQuery } from "./queryHelper";
import NoResultsMagIcon from "./no_results_mag_icon.svg";

type PropertiesListProps = {
  filters: IPropertiesFilters;
  page: number;
  changePage: (page: number) => void;
  paginationChanged: (count: number, total: number) => void;
};

const PropertiesList: FunctionComponent<PropertiesListProps> = ({
  filters,
  page,
  changePage,
  paginationChanged,
  ...props
}) => {
  const navigate = useNavigate();
  const [refeatchLoading, setRefeatchLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();

  const [searchPropertiesQuery, { data, loading, refetch, called }] =
    useSearchPropertiesLazyQuery();

  useEffect(() => {
    const queryFilters = getFiltersQuery(filters);
    const params = new URLSearchParams(window.location.search);
    const baseParams = { ...queryFilters, page: page.toString() };
    const baseKeys = Object.keys(baseParams);
    let missingParams = {};
    params.forEach((value, key) => {
      const isSearchParam = ["address", "lat", "lng"].includes(key);
      const isFiltersParam = Object.keys(filters).includes(key);
      if (!baseKeys.includes(key) && !isFiltersParam && !isSearchParam) {
        missingParams = { ...missingParams, [key]: value };
      }
    });
    setSearchParams({ ...baseParams, ...missingParams }, { replace: true });
  }, [page, filters, setSearchParams]);

  const { pageSize, pagesCount, totalCount } = usePaginatedResponse(
    data?.searchProperties,
  );

  useEffect(() => {
    paginationChanged(pagesCount, totalCount);
  }, [page, pagesCount, paginationChanged, totalCount]);

  const paginationInput = usePaginationInput(pageSize, page);

  const buildInput = useCallback(
    (count: number, offset: number, filters: IPropertiesFilters) => ({
      count: count,
      offset: offset,
      lat: filters.location?.lat,
      lng: filters.location?.lng,
      filters: buildApiFilters(filters),
      range: filters.locationRange,
      sortBy: buildApiSort(filters.sortOrder),
    }),
    [],
  );

  const searchProperties = useCallback(
    async (filters: IPropertiesFilters, count: number, offset: number) => {
      const input = buildInput(count, offset, filters);
      if (called) {
        setRefeatchLoading(true);
        await refetch({ input });
        setRefeatchLoading(false);
      } else {
        searchPropertiesQuery({ variables: { input } });
      }
    },
    [buildInput, called, refetch, searchPropertiesQuery],
  );

  const pageClearRef = useRef(false)
  const changePageDebounce = useMemo(
    () => debounce(() => {
      pageClearRef.current = true
      changePage(1)
    }, 1000),
    [filters],
  );

  const searchDebounce = useMemo(
    () => debounce(searchProperties, 1000),
    [searchProperties],
  );
  const search = useCallback(
    (noDelay?: boolean) => {
      if (noDelay) {
        searchProperties(
          filters,
          paginationInput.count,
          paginationInput.offset
        );
      } else {
        // Force 0 offset because of filters change.
        changePageDebounce()
        searchDebounce(filters, paginationInput.count, 0);
      }
    },
    [filters, paginationInput, searchDebounce, searchProperties],
  );

  useEffect(() => {
    if (!pageClearRef.current) {
      search(true);
    } else {
      pageClearRef.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // Used to disable double search on init.
  const initFiltersCall = useRef(false);
  useEffect(() => {
    if (!initFiltersCall.current) initFiltersCall.current = true;
    else {
      search(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <PropertiesResultsContainer>
      {data?.searchProperties.extended && (
        <PropertiesExtendedSearch>
          <PropertiesExtendedSearchContainer>
            <PropertiesExtendedSearchImageContainer>
              <PropertiesExtendedMagIcon src={NoResultsMagIcon} />
            </PropertiesExtendedSearchImageContainer>
            <PropertiesExtendedSearchContainerHeader>
              No results
            </PropertiesExtendedSearchContainerHeader>
            <PropertiesExtendedSearchContainerMessage>
              We couldn't find listings that match your filters. But don't
              worry, we've expanded them a bit and found similar offers. Hope
              you like them!
            </PropertiesExtendedSearchContainerMessage>
          </PropertiesExtendedSearchContainer>
          <PropertiesExtendedSearchLabelContainer>
            <PropertiesExtendedSearchLine />

            <PropertiesExtendedSearchLabel>
              Explore similar listings
            </PropertiesExtendedSearchLabel>

            <PropertiesExtendedSearchLine />
          </PropertiesExtendedSearchLabelContainer>
        </PropertiesExtendedSearch>
      )}

      {data?.searchProperties.data?.map((data) => (
        <PropertyCard
          url={getPublicPropertyDetailsRoute(data.slug, data.uid)}
          onActionClick={() =>
            navigate(getPublicPropertyDetailsRoute(data.slug, data.uid))
          }
          loading={loading || refeatchLoading}
          variant="wide"
          key={data.uid}
          name={data.address}
          img={data.propertyPhotos[0]}
          price={data.price}
          badgeType={getBadgeType(data.propertyInsight)}
          {...data}
        />
      ))}

      <Pagination
        currentPage={page}
        pageSize={pageSize}
        onPagechange={changePage}
        totalCount={totalCount}
      />

      {(refeatchLoading || loading) && (
        <PropertiesResultsLoading>
          <PageLoader />
        </PropertiesResultsLoading>
      )}
    </PropertiesResultsContainer>
  );
};

export default PropertiesList;
