import React, { FunctionComponent } from "react";
import { HintImage, LabelContainer } from "./Label.components";
import HintIcon from "./icon_hint.svg";
import Hint from "../../Hint/Hint";

type LabelProps = {
  hint?: JSX.Element | string;
  forceShow?: boolean;
};

const Label: FunctionComponent<LabelProps> = ({
  children,
  forceShow = false,
  hint,
  ...props
}) => {
  return (
    <LabelContainer show={forceShow}>
      {children}
      {hint && <Hint hint={hint} children={<HintImage src={HintIcon} />} />}
    </LabelContainer>
  );
};

export default Label;
