import React, { FunctionComponent } from "react";
import routesPaths from "../../../Routing/routesPaths";
import useAppContext from "../../../App/AppContext/useAppContext";
import { ReactComponent as LoginImage } from "./icons/icon_login.svg";
import { ReactComponent as HomeImage } from "./icons/icon_home.svg";
import { ReactComponent as ContactImage } from "./icons/icon_contact.svg";
import { ReactComponent as BlogImage } from "./icons/icon_blog.svg";
import { ReactComponent as ListingImage } from "./icons/icon_listing.svg";
import { ReactComponent as SeoImage } from "./icons/icon_seo.svg";

import AuthedNavbar from "./components/AuthedNavbar";
import PublicNavbar from "./components/PublicNavbar";
import { useAccountType } from "../../../../common/helpers/useAccountType";
import { AccountType } from "../../../../generated/schema";
import { blogPath } from "../../../../../../backend/src/shared/routes/routes";

export interface INavbarOption {
  name: string;
  path?: string;
  url?: string;
  icon?: JSX.Element;
  selectable?: boolean;
  target?: string;
  align: "left" | "right";
}

const generalOptions: INavbarOption[] = [
  { name: "Home", path: routesPaths.HOME, icon: <HomeImage />, align: "left" },
  {
    name: "Contact",
    url: "#footer",
    target: "_self",
    icon: <ContactImage />,
    align: "left",
  },
  {
    name: "Blog",
    url: blogPath,
    icon: <BlogImage />,
    align: "left",
  },
];

const adminOptions: INavbarOption[] = [
  ...generalOptions,
  {
    name: "Listings",
    path: routesPaths.LISTINGS_ROOT,
    icon: <ListingImage />,
    align: "right",
  },
  {
    name: "SEO",
    path: routesPaths.SEO,
    icon: <SeoImage />,
    align: "right",
  },
];

const authedOptions: INavbarOption[] = [
  ...generalOptions,
  {
    name: "My Listings",
    path: routesPaths.MY_PROPERTIES_ROOT,
    icon: <ListingImage />,
    align: "right",
  },
];

const publicOptions: INavbarOption[] = [
  ...generalOptions,
  {
    name: "Login",
    path: routesPaths.LOGIN,
    icon: <LoginImage />,
    align: "left",
  },
];

const MainNavbar: FunctionComponent = () => {
  const isAdmin = useAccountType([AccountType.Admin]);
  const hasToken = !!useAppContext().authToken;

  if (!hasToken) return <PublicNavbar elements={publicOptions} />;
  return <AuthedNavbar elements={isAdmin ? adminOptions : authedOptions} />;
};

export default MainNavbar;
