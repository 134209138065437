import styled, { keyframes } from "styled-components";
import { colors } from "../../colors";

const spin = keyframes`
 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } 
`;

export const PageLoaderWrapper = styled.div<{ size: number }>`
  box-sizing: border-box;
  border: 6px solid rgba(42, 122, 234, 0.28);
  border-top: 6px solid ${colors.secondary.pure_blue};
  border-radius: 50%;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  animation: ${spin} 1.1s linear infinite;
`;
