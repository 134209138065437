import { AccountType, PropertyStatus } from "../../generated/schema";
import routesPaths from "../../modules/Routing/routesPaths";

export const getPropertyPreviewDetailsRoute = (uid: string) =>
  routesPaths.PROPERTY_PREVIEW.replace(":uid", uid);

export const getPublicPropertyDetailsRoute = (slug: string, uid: string) =>
  routesPaths.PROPERTY_DETAILS.replace(":slug", `${slug}-${uid}`);

export const getPendingPropertyDetailsRoute = (
  uid: string,
  accountType?: string,
) =>
  accountType === AccountType.Admin
    ? `${routesPaths.PROPERTY_ACCEPT.replace(":uid", uid)}`
    : getPropertyPreviewDetailsRoute(uid);

export const getPropertyRoute = (
  status: PropertyStatus,
  slug: string,
  uid: string,
  accountType?: string,
) =>
  status === PropertyStatus.Active
    ? getPublicPropertyDetailsRoute(slug, uid)
    : status === PropertyStatus.Pending
    ? getPendingPropertyDetailsRoute(uid, accountType)
    : getPropertyPreviewDetailsRoute(uid);
