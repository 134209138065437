import React from "react";
import PrivacyTerms from "../shared/components/PrivacyTerms/PrivacyTerms";
import {
  FaqContainer,
  FaqIntelligenceFiltersWrapper,
  FaqParagraph,
} from "./FAQ.components";
import FAQAccordion from "./FAQAccordion";
import FiltersImage from "./FilterLeft.png";

const FAQ = () => {
  return (
    <PrivacyTerms heading="Frequently Asked Questions" isFaqView>
      <FaqContainer>
        <FAQAccordion title="How does the Commit search engine work?">
          <FaqParagraph>
            Commit is a new approach to commercial property selection allowing
            agencies and landlords to list their properties for free and
            enhancing their listings at no cost with the site selection
            intelligence previously only available to the largest businesses in
            the world.
          </FaqParagraph>
          <FaqParagraph>
            <span>Agents: </span>Our proprietary tooling scouts out new listings
            on your website, analyses them and lists them on our platform. We
            provide traffic directly back to your website giving you free
            pre-qualified leads.
          </FaqParagraph>
          <FaqParagraph>
            <span>Businesses: </span>Select the properties you want to view
            based on hard data such as footfall, demographics, crime rates and
            much more. Take the guessing out of which property to select and
            grow your business with Commits free intelligence engine.
          </FaqParagraph>
        </FAQAccordion>

        <FAQAccordion title="What is the mission of Commit?">
          <FaqParagraph>
            Commit is democratising the commercial site selection process giving
            everyone access to the decision making tools only previously
            accessible to the largest companies in the world.
          </FaqParagraph>
          <FaqParagraph>
            <span>Understand the market: </span>We aggregate listings from over
            5000 sources providing estate agents and landlords with a reliable
            and consistent source of traffic and our users with a complete
            overview of the available commercial properties on the market.
          </FaqParagraph>
          <FaqParagraph>
            <span>Understand the property: </span>Filter and explore properties
            using conventional location, use class and price or use our site
            selection intelligence tooling to find properties that match your
            exact business requirements. Discover properties based on crime
            rates, student populations, population density, health trends and
            shoplifting numbers, with many more being added regularly.
          </FaqParagraph>
          <FaqParagraph>
            <span>Understand the opportunity: </span>Get a deep, data driven,
            understanding of every property on our platform with our free
            location intelligence. Whether you’re looking for a new yoga studio
            in Camden or an office block in Telford we have the data you need to
            make an informed decision.
          </FaqParagraph>
        </FAQAccordion>

        <FAQAccordion title="How do you search for commercial properties on Commit?">
          <FaqParagraph>
            Searching for commercial property on Commit is a simple process.
            Select the area you want to search, select your use class (optional)
            and click ‘search’. Filter down the list of properties using
            conventional filters or use the commit intelligence filters to find
            properties based on crime rates, student populations, population
            density, health trends or shoplifting rates.iously accessible to the
            largest companies in the world.
          </FaqParagraph>
        </FAQAccordion>

        <FAQAccordion title="How to use Commit intelligence filters?">
          <FaqParagraph>
            The commit intelligence filters are a simple and easy way to filter
            and discover properties based on hard data. After you search for
            properties in your intended area, the commit intelligent filters
            will appear on the left hand side (desktop) or under the “filters”
            tab (mobile).
          </FaqParagraph>
          <FaqParagraph>
            Simply select the filters you want to apply and the list of
            properties you are show will be filtered down to match the criteria.
          </FaqParagraph>

          <FaqIntelligenceFiltersWrapper>
            <img src={FiltersImage} alt="Commit filters." />
            <div>
              <FaqParagraph ignoreMaxWidth>
                <span>Use Case 1: </span>You are opening up an off-licence and
                are worried about shoplifting rates. Simply select the ‘low’
                filter under the shoplifting rate and the only properties you
                will see are ones in low shoplifting rate areas.
              </FaqParagraph>
              <FaqParagraph ignoreMaxWidth>
                <span>Use Case 2: </span>You are expanding your chain of vegan
                restaurants. You cater primarily to a health conscious client
                base and so are only looking for restaurant locations in high
                population areas where the people are healthy. Commit makes this
                easy. Select ‘Healthy’ under Population Health and ‘medium’ or
                ‘high’ under population density. You will be served up a list of
                appropriate properties.
              </FaqParagraph>
            </div>
          </FaqIntelligenceFiltersWrapper>
        </FAQAccordion>

        <FAQAccordion title="Where does Commit get its site selection intelligence from?">
          <FaqParagraph>
            Commit gathers from both open source & paid datasets to create and
            optimise the intelligence engine that powers our site selection
            tooling.
          </FaqParagraph>
        </FAQAccordion>

        <FAQAccordion title="How to enquire about a property I found on Commit?">
          <FaqParagraph>
            If you have found a property you like you can either visit the site
            of the agent that has listed it to get the contact details or if the
            listing was submitted directly to Commit, use the form on the
            listing to contact the agent. Your details will be passed to the
            agent and they will contact you to arrange a viewing.
          </FaqParagraph>
        </FAQAccordion>

        <FAQAccordion title="How do I list a property on Commit?">
          <FaqParagraph>
            Listing a property on Commit is <span>free, fast and simple.</span>{" "}
            Click ‘list your property’ in the toolbar at the top of your screen.
            Register a new account if you do not have one. Submit the details of
            your property and your listing will be live on our website within 48
            hours.
          </FaqParagraph>
          <FaqParagraph>
            We will send leads directly to the email you register with so make
            sure you use the appropriate one.
          </FaqParagraph>
          <FaqParagraph>
            If you have more than 3 properties and you want to speed up the
            listing process, you can contact our dedicated integration team who
            will automate the process for you. You can contact them by emailing{" "}
            <a href="mailto:nathaniel@commit.properties">
              nathaniel@commit.properties.
            </a>
          </FaqParagraph>
        </FAQAccordion>
      </FaqContainer>
    </PrivacyTerms>
  );
};

export default FAQ;
