import React, { FunctionComponent, useCallback, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { Transition } from "react-transition-group";
import NavbarOption from "./NavbarOption";
import {
  MenuContainer,
  MenuItem,
  MenuItemImage,
  MenuItemsDivider,
  MenuOptionContainer,
  MenuOptionContent,
} from "./NavbarOption.components";
import AccountSettingsImage from "../../icons/icon_account_settings.svg";
import LogoutImage from "../../icons/icon_logout.svg";
import { deleteAuthToken } from "../../../../../../common/helpers/authToken";
import AccountDetailsModal from "../../../Modals/AccountDetailsModal/AccountDetailsModal";
import { useModal } from "../../../Modal";

const NavbarMenuOption: FunctionComponent<{
  title: string;
}> = ({ title }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const { openModal } = useModal();

  const openAccountDetailsModal = useCallback(() => {
    setIsMenuOpened(false);
    openModal(<AccountDetailsModal />);
  }, [openModal]);

  return (
    <ClickAwayListener onClickAway={() => setIsMenuOpened(false)}>
      <MenuOptionContainer>
        <MenuOptionContent onClick={() => setIsMenuOpened(!isMenuOpened)}>
          <NavbarOption title={title} path="#" selected={isMenuOpened} />
        </MenuOptionContent>
        <Transition
          in={isMenuOpened}
          timeout={200}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          {(status) => (
            <MenuContainer status={status}>
              <MenuItem onClick={openAccountDetailsModal}>
                <MenuItemImage src={AccountSettingsImage} />
                Account settings
              </MenuItem>
              <MenuItemsDivider />
              <MenuItem onClick={deleteAuthToken}>
                <MenuItemImage src={LogoutImage} />
                Log out
              </MenuItem>
            </MenuContainer>
          )}
        </Transition>
      </MenuOptionContainer>
    </ClickAwayListener>
  );
};

export default NavbarMenuOption;
