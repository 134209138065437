import React from "react";
import {
  AboutUsBackground,
  AboutUsContainer,
  AboutUsDescription,
  AboutUsDetailDescription,
  AboutUsDetailHeading,
  AboutUsDetailsWrapper,
  AboutUsDetailWrapper,
  AboutUsHeader,
  AboutUsImage,
  AboutUsIndicator,
  AboutUsOuterContainer,
  AboutUsWrapper,
} from "./AboutUs.components";
import { ReactComponent as PresentationIcon } from "./images/Presentation.svg";
import { ReactComponent as DocumentIcon } from "./images/Document.svg";
import AboutGraphics from "./images/AboutGraphics.webp";
import { useIsMediaDown } from "../../shared/media";

const AboutUs = () => {
  const isMobileDown = useIsMediaDown("mobile");

  return (
    <AboutUsOuterContainer id="about-us">
      <AboutUsBackground />
      <AboutUsContainer>
        {!isMobileDown && (
          <AboutUsImage
            src={AboutGraphics}
            alt="Picture with demographic placeholder."
          />
        )}
        <AboutUsWrapper>
          <AboutUsIndicator>
            <span>02</span> About commit
          </AboutUsIndicator>
          <AboutUsHeader>
            The future of <br />
            commercial site selection
          </AboutUsHeader>
          <AboutUsDescription>
            Free location and demographic intelligence for commercial property
            selection. Discover properties the way the professionals do, with
            free premium intelligence and demographic tooling.
          </AboutUsDescription>
          {isMobileDown && (
            <AboutUsImage
              src={AboutGraphics}
              alt="Picture with demographic placeholder."
            />
          )}
          <AboutUsDetailsWrapper>
            <AboutUsDetailWrapper>
              <PresentationIcon />
              <AboutUsDetailHeading>
                Free Location Intelligence
              </AboutUsDetailHeading>
              <AboutUsDetailDescription>
                Explore commercial real estate based on
                <span> never-before-used </span>
                intelligence parameters and take your property research to the
                next level.
              </AboutUsDetailDescription>
            </AboutUsDetailWrapper>

            <AboutUsDetailWrapper>
              <DocumentIcon />
              <AboutUsDetailHeading>Free Property Listing</AboutUsDetailHeading>
              <AboutUsDetailDescription>
                Enhance your property listings. Grow your customer base and get
                <span> high-conversion-rate </span>
                leads delivered directly to your inbox.
              </AboutUsDetailDescription>
            </AboutUsDetailWrapper>
          </AboutUsDetailsWrapper>
        </AboutUsWrapper>
      </AboutUsContainer>
    </AboutUsOuterContainer>
  );
};

export default AboutUs;
