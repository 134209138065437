import { createGlobalStyle, css } from "styled-components";
import { isMobileSafari } from "react-device-detect";

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    width: 100%;
    height: 100%;

    ${
      isMobileSafari &&
      css`
        height: 100vh;
      `
    }
  }
  
  body {
    display: block;
		margin: 0px;
    padding: 0px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export default GlobalStyle;
