import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Input } from "../Form/Input/Input";
import { ISearchResult } from "./ISearchResult";
import {
  SearchInputContainer,
  SearchInputTextContainer,
} from "./SearchInput.components";
import "./SearchInput.css";
import { ReactComponent as IconX } from "./x_icon.svg";
import { usePlacesWidget } from "react-google-autocomplete";

type SearchInputProps = {
  onChange: (location?: ISearchResult) => void;
  label?: string;
  error?: string;
  isError?: boolean;
  inputSize?: "l" | "m";
  name?: string;
  hint?: string;
  submitCount?: number;
  value?: string;
};

const SearchInput: FunctionComponent<SearchInputProps> = ({
  value,
  label,
  onChange,
  isError,
  error,
  inputSize,
  name,
  hint,
  submitCount = 0,
  ...props
}) => {
  const funcRef = useRef<(location?: ISearchResult) => void>();

  useEffect(() => {
    funcRef.current = onChange;
  }, [onChange]);

  const { ref: inputRef } = usePlacesWidget<HTMLInputElement>({
    onPlaceSelected: (place) => {
      const location = place.geometry?.location;
      if (!place.formatted_address || !location) {
        onChange(undefined);
      } else {
        const postcode = place.address_components?.find((c) =>
          c.types.includes("postal_code"),
        )?.long_name;

        funcRef.current &&
          funcRef.current({
            fullAddress: place.formatted_address ?? "",
            lat: location.lat(),
            lng: location.lng(),
            postcode: postcode,
          });
      }
    },
    language: "en",
    options: {
      strictBounds: false,
      types: ["geocode"],
      fields: ["ALL"],
      componentRestrictions: { country: ["gb"] },
    },
  });

  const [wasTouched, setWasTouched] = useState(false);
  const showError = (wasTouched || submitCount > 0) && (!!error || isError);

  return (
    <SearchInputContainer>
      <SearchInputTextContainer>
        <Input
          name={name}
          inputSize={inputSize}
          onBlur={() => setTimeout(() => setWasTouched(true), 100)} //Set timeout to avoid blinking error on selection.
          error={showError ? error : undefined}
          isError={isError}
          label={label}
          hint={hint}
          forwardedRef={inputRef}
          frontIcon="search"
          icon={value ? <IconX /> : undefined}
          defaultValue={value}
          onIconClick={() => {
            if (inputRef.current && value) {
              inputRef.current!.value = "";
              funcRef.current && funcRef.current(undefined);
            }
          }}
        />
      </SearchInputTextContainer>
    </SearchInputContainer>
  );
};

export default SearchInput;
