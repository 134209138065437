import { Route } from "react-router";
import AuthedRoute from "../Routing/AuthedRoute";
import PublicRoute from "../Routing/PublicRoute";
import routes from "../Routing/routes";
import SignInRoute from "../Routing/SignInRoute";

const AppRoutes = routes.map((routeConfig, index) => {
  const Element = routeConfig.element;

  switch (routeConfig.type) {
    case "public":
      return (
        <Route
          key={index}
          path={routeConfig.path}
          element={
            <PublicRoute>
              {/* @ts-ignore */}
              <Element />
            </PublicRoute>
          }
        />
      );
    case "authed":
      return (
        <Route
          key={index}
          path={routeConfig.path}
          element={
            <AuthedRoute
              accountType={routeConfig.accountType}
              layoutType={routeConfig.layoutType}
            >
              {/* @ts-ignore */}
              <Element />
            </AuthedRoute>
          }
        />
      );
    case "sign_in":
      return (
        <Route
          key={index}
          path={routeConfig.path}
          element={
            <SignInRoute>
              {/* @ts-ignore */}
              <Element />
            </SignInRoute>
          }
        />
      );
  }
  return null;
});

export default AppRoutes;
