import React from "react";
import PrivacyTerms from "../shared/components/PrivacyTerms/PrivacyTerms";
import {
  PrivacyTermsSectionWrapper,
  PrivacyTermsHeading,
  PrivacyTermsParagraph,
} from "../shared/components/PrivacyTerms/PrivacyTerms.components";
import { TermsAndConditionsContactWrapper } from "./TermsAndConditions.components";

const TermsAndConditions = () => {
  return (
    <PrivacyTerms heading="Terms & Conditions">
      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>
          Your use of this web site constitutes your agreement to be bound by
          these terms and conditions of use.
        </PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          commit.properties, www.commit.properties, commitproperty.co.uk
          www.commitproperty.co.uk and any subdomains or apps affiliated with
          them from to time to time (the “Service”) are owned and operated by
          Commit Limited ("Commit", “we”, “us” or “our").
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          Commit is not itself an estate agent and it does not operate an estate
          agency. Commit operates the Service to enable users to publicise or
          consider properties that may be available for purchase or rental.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          These terms of use apply to all use of the Service and form a legal
          agreement between you and Commit.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          By using the Service you are confirming that you understand and accept
          (and are able to understand and accept) these terms of use and that
          you agree to be bound by them. If you are under the age of 18 or don’t
          understand these terms of use, please ask a parent or guardian to
          explain their meaning to you. You must not use the Service if you are
          under 13.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          Commit may make alterations to these terms of use from time to time.
          If you are not happy with any alteration, you must stop using the
          Service. If you keep using any part of the Service, this will indicate
          your acceptance of these terms of use as altered. We may notify you of
          key changes but you should review these terms of use from time to time
          to ensure you are aware of any changes. Where appropriate we may
          notify you of changes by email or by putting a notice on the Service.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Registration on use</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          In order to register on the Service you must be over 18 and ensure
          that all details you provide are true, accurate and up-to-date.
          Certain parts of the Service may be accessed by any user, whereas
          other parts are only available to registered users.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          You may be required to make additional registrations in order to see
          information about some of the properties which are shown on the
          Service as available for purchase or rental. These registrations and
          services may be provided by us, or the owners of the properties for
          sale or rental, or their representatives.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          Before registering with any third party services, please consider any
          terms or policies which may be applicable to them. Sections further
          down in this policy contain provisions relating to our liability for
          ‘External Sites'.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          By using the Service you are confirming that you understand and accept
          (and are able to understand and accept) these terms of use and that
          you agree to be bound by them. If you are under the age of 18 or don’t
          understand these terms of use, please ask a parent or guardian to
          explain their meaning to you. You must not use the Service if you are
          under 13.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          The account name and password are personal to you and must not be
          disclosed to any person without our prior written consent.
        </PrivacyTermsParagraph>
        <br />
        <PrivacyTermsParagraph>
          You agree, accept and understand that:
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          you must ensure that all information held about you by Commit is true
          accurate and kept up to date. You can amend your registration details
          at any time through the Service;
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          you are and shall remain responsible for maintaining the
          confidentiality of your account name and password;
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          you are solely liable for any use of the Service using your account
          name, and password whatsoever.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>
          Rights of use and intellectual property rights
        </PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          The Service is made available solely for your use only. These terms of
          use do not grant you any rights of ownership in the Service. Our
          service terms permit commercial use of the Service in some
          circumstances.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          Unless otherwise specified, all copyright, design rights, database
          rights, patent rights, trade mark and trade dress rights and other
          intellectual property rights in the Service belong to and vest in
          Commit, or are licensed to Commit. This includes images, text, video,
          designs, database rights and the graphical arrangement of the Service.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          All third party trade names and trade marks are the property of their
          respective owners and Commit makes no warranty or representation in
          relation to them.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Restrictions and obligations</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          You agree to comply with these terms of use and all rules applicable
          to the use of the Service.
          <br /> You will not:
          <ul>
            <li>
              hack, modify, reverse engineer or create derivative works of the
              Service or any part of any of it;
            </li>
            <li>gain unauthorised access to any part of any of the Service;</li>
            <li>
              remove, modify or obscure any copyright, trade mark or other
              proprietary notices on any of part of the Service;
            </li>
            <li>
              create software which replicates or mimics the data or
              functionality in any part of the Service
            </li>
            <li>
              use your access to the Service for the sending of direct
              marketing;
            </li>
            <li>
              make any part of any of the Service available to a third party who
              does not agree to these terms of use;
            </li>
            <li>
              copy or exploit any part of any of the Service or the content it
              contains;
            </li>
            <li>
              use the Service or any part of any of them unfairly or for any
              illegal or immoral purpose; or
            </li>
            <li>attempt to do any of the acts listed above.</li>
          </ul>
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Property Information</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          Any information relating to real estate (both commercial and
          residential) as displayed on the Service is provided to us by third
          party estate agents, landlords or developers (“Property Information”).
          We provide Property Information for your information only. We do not
          verify Property Information, or assess its quality or accuracy.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          You are responsible for making your own enquiries into any property
          shown on the Service. Before committing to any purchase or rental, you
          should consider instructing a surveyor and obtaining legal advice.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          We provide no assurance as to the quality or accuracy of the Property
          Information available on the Service.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Communications</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          The Service may enable the display of third party content (“User
          Content”).
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          The views expressed in any User Content are the views of the
          individual authors and not those of Commit unless we specify
          otherwise.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          We intend that all User Content will be appropriate and lawful.
          However, by using the Service you acknowledge that we have no
          responsibility to review any User Content and that all User Content is
          made available on the basis that we are not required to exercise any
          control or judgement over it.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          Although we are not obliged to do so, we may remove or reject any User
          Content.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          You agree that we may process and store any content you submit to the
          Service ("Your Content”).
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          You may display Your Content to other Users of the Service, and other
          Customers of the Service may display User Content to you.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          You agree to the distribution of Your Content by us both internally
          and externally. Therefore you should ensure that Your Content does not
          contain information which you intend to keep confidential or private.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          By making available, posting or transmitting Your Content to any of
          the Service, you are granting us a non-exclusive, transferrable,
          sublicensable, royalty-free, irrevocable, perpetual worldwide licence
          to copy, modify, display, distribute, create derivative works from, or
          otherwise use and exploit Your Content, which such licence includes
          the right for us to syndicate Your Content to our affiliated or
          partner sites.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          You agree that you are entitled to make available, post or transmit
          Your Content to the Service.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          You will not make available, post or transmit to the Service any
          statement, material or other content, nor use the Service in any way,
          that:
          <ul>
            <li>
              is unlawful or may give rise to civil or criminal liability;
            </li>
            <li>
              infringes any copyright or other intellectual property right of
              any third party;
            </li>
            <li>
              infringes any third party's rights of privacy or rights of
              publicity;
            </li>
            <li>includes any computer virus or other malicious software;</li>
            <li>
              is abusive, pornographic, defamatory, discriminatory or obscene;
            </li>
            <li>harasses any other person;</li>
            <li>
              interferes with another user's use and enjoyment of the Service;
            </li>
            <li>
              impersonates any moderator, administrator or any staff or any
              other person connected with Commit;
            </li>
            <li>contains the confidential information of any other person;</li>
            <li>solicits passwords or personal information;</li>
            <li>
              contains video, photographs, or images of any other person without
              his or her permission (or in the case of a minor, the minor's
              legal guardian);
            </li>
            <li>exploits any other person;</li>
            <li>we consider inappropriate; or</li>
            <li>
              encourages or provokes any other person to do any of the acts
              listed above.
            </li>
          </ul>
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          If you discover any material which you believe contravenes these
          Terms, please inform us.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          The Service provides a means by which you can communicate with us. We
          will communicate with you at the email address you have provided or
          through other means of communication provided by the Service. Notices
          that are applicable to all our customers shall be made available on
          the Service publicly. You will be deemed to have received a notice at
          the time the email is sent or the time the notice is posted on the
          Service. We will be deemed to have received a notice when we issue
          confirmation to you.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          All emails (or other messages) we send are intended for the addressee
          only.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Liability</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          Commit provides and maintains the Service on an “as is” basis and is
          liable only to provide its services with reasonable skill and care.
          External Sites have not been verified or reviewed by Commit and all
          use and access of External Sites is made at your own risk. “External
          Sites” means third party websites and online services to which any of
          the Service may provide links, or for which you may be required to
          register in order to see information about particular properties.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          COMMIT GIVES NO WARRANTY IN RESPECT OF THE INFORMATION CONTAINED ON
          THE SERVICE AND EXCLUDES ALL LIABILITY FOR ANY INCORRECT OR INACCURATE
          INFORMATION OR MATERIAL THE SERVICE CONTAINS. <br />
          Commit gives no other warranty in connection with the Service and to
          the maximum extent permitted by law, Commit excludes liability for:
          <ul>
            <li>
              any loss or damage of any kind howsoever arising, including any
              direct, indirect, special, punitive or consequential loss whether
              or not that loss arises out of something of which Commit has been
              made aware
            </li>
            <li>
              any interruptions to or delays in updating any of the Service
            </li>
            <li>
              the infringement by any other person of any copyright or other
              intellectual property rights of any third party through any use of
              the Service
            </li>
            <li>
              the availability, quality, content or nature of External Sites
            </li>
            <li>any transaction taking place on External Sites</li>
            <li>
              any transaction with third party retailers taking place on the
              Service
            </li>
            <li>
              any amount or kind of loss or damage due to viruses or other
              malicious software that may infect a user's computer equipment,
              software, data or other property caused by any other person
              accessing, using or downloading from any part of the Service; and
            </li>
            <li>
              all representations, warranties, conditions and other terms and
              conditions which but for this notice would have effect.
            </li>
          </ul>
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          Commit does not warrant that the operation of any of the Service will
          be uninterrupted or error free.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          Commit will not be liable in any amount for failure to perform any
          obligation under these terms of use if that failure is caused by the
          occurrence of an event beyond its reasonable control.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          Except as provided above there are no other warranties, conditions or
          other terms and conditions, express or implied, statutory or
          otherwise, and all of those terms and conditions are hereby excluded
          to the maximum extent permitted by law.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          You agree not to use the Service in any way which is:
          <ul>
            <li>unlawful;</li>
            <li>may give rise to civil or criminal liability for Commit; or</li>
            <li>which might call Commit into disrepute.</li>
          </ul>
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Termination</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          Without limiting any other rights it may have, Commit may cancel or
          suspend access to any part of the Service if you breach any of these
          terms of use.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>General</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          These terms of use are subject to your statutory and common law
          consumer rights and will not limit any rights you might have that
          cannot be excluded under applicable law. These terms of use will not
          exclude or limit Commit’s liability for death or personal injury
          resulting from its negligence nor any fraudulent acts, representations
          or misstatements.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          These terms of use, together with our privacy policy, constitute the
          entire agreement between you and Commit relating to your use of the
          Service, to the exclusion of any other terms of use.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          Failure to enforce any term does not constitute a waiver of that term.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          If any part of these terms of use is found to be unenforceable, it
          will be amended to the minimum extent necessary to make it enforceable
          and the remainder of the provisions will remain in full force and
          effect.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          No representation or warranty is made as to whether the Service
          complies with the laws of any other country other than the United
          Kingdom.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          These terms of use are subject to the laws of England and the parties
          submit to the exclusive jurisdiction of the English courts.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          Commit will be entitled to assign and otherwise transfer the agreement
          covered by these terms of use by giving you reasonable notice, which
          may include notice given via the Service.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          All questions, comments or enquiries should be directed to Commit.
          Commit will try to respond to within 48 hours.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Contact</PrivacyTermsHeading>
        <TermsAndConditionsContactWrapper>
          <h5>
            Company Name: <span>COMMIT LIMITED</span>
          </h5>
          <h5>
            Company number: <span>13831313</span>
          </h5>
          <h5>
            Registered Address:
            <span> 33 Curling Vale, Guildford, England, GU2 7PJ </span>
          </h5>
          <h5>
            Email:{" "}
            <a href="mailto:contact@commit.properties">
              contact@commit.properties
            </a>
          </h5>
        </TermsAndConditionsContactWrapper>
      </PrivacyTermsSectionWrapper>
    </PrivacyTerms>
  );
};

export default TermsAndConditions;
