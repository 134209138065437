import {
  AddPropertyInput,
  AmenitiesType,
  BillingType,
  ConditionType,
  EpcType,
  ExistingBuildoutType,
  ListingType,
  LocationType,
  PropertyUseType,
  PublicUrls,
  TenancyType,
  UseClassType,
} from "../../generated/schema";
import * as Yup from "yup";
import { startOfMonth, setMonth, setYear } from "date-fns";
import { CurrentUser } from "../../common/helpers/currentUser";
import {
  IFileSelection,
  urlToFileSelection,
} from "../shared/components/FilesInput/IFileSelection";
import { EditPropertyResponse } from "./EditPropertyResponse";
import { getMonth, getYear } from "date-fns";

export interface AddPropertyFormValues {
  address?: string;
  lat: number;
  lng: number;
  flat?: string;
  postcode: string;
  locationType?: LocationType;
  listingType: ListingType;
  billingType?: BillingType;
  tenancyType: TenancyType;
  price?: number;
  netIncome?: number;
  month?: number;
  year?: number;
  isPriceNegotiable?: boolean;
  isRentalNegotiable?: boolean;
  photos: IFileSelection[];
  floorPlans: IFileSelection[];
  documents: IFileSelection[];
  propertyUseType: PropertyUseType[];
  amenitiesType: AmenitiesType[];
  useClassType?: UseClassType;
  conditionType?: ConditionType;
  existingBuildout?: ExistingBuildoutType;
  currentEpcType?: EpcType;
  potencialEpcType?: EpcType;
  sqFootage?: number;
  floors?: number;
  businessRate?: number;
  yearBuilt?: number;
  yearLastRenovated?: number;
  parkingSpaces?: number;
  parkingDetails?: string;
  propertyDescriptions?: string;
  email?: string;
  name?: string;
  bio?: string;
}

const addPropertyInitialValues: AddPropertyFormValues = {
  address: undefined,
  lat: 0,
  lng: 0,
  postcode: "",
  flat: undefined,
  locationType: undefined,
  listingType: ListingType.ForSale,
  billingType: undefined,
  tenancyType: TenancyType.Single,
  price: undefined,
  netIncome: undefined,
  month: undefined,
  year: undefined,
  isPriceNegotiable: undefined,
  isRentalNegotiable: undefined,
  photos: [],
  floorPlans: [],
  documents: [],
  propertyUseType: [],
  amenitiesType: [],
  useClassType: undefined,
  conditionType: undefined,
  existingBuildout: undefined,
  currentEpcType: undefined,
  potencialEpcType: undefined,
  sqFootage: undefined,
  floors: undefined,
  businessRate: undefined,
  yearBuilt: undefined,
  yearLastRenovated: undefined,
  parkingSpaces: undefined,
  parkingDetails: undefined,
  propertyDescriptions: undefined,
  email: undefined,
  name: undefined,
  bio: undefined,
};

export const buildInitialData = (
  property: EditPropertyResponse,
): AddPropertyFormValues => {
  return {
    address: property.address,
    lat: property.location[0],
    lng: property.location[1],
    flat: property.flat ?? undefined,
    postcode: property.postcode,
    locationType: property.locationType,
    listingType: property.listingType,
    billingType: property.billingType ?? undefined,
    tenancyType: property.tenancyType,
    price: property.price ?? undefined,
    netIncome: property.netIncome ?? undefined,
    month: property.rentalTo ? getMonth(property.rentalTo) : undefined,
    year: property.rentalTo ? getYear(property.rentalTo) : undefined,
    isPriceNegotiable: property.isPriceNegotiable ?? undefined,
    isRentalNegotiable: property.isRentalNegotiable ?? undefined,
    photos: property.propertyPhotos.map((url) => urlToFileSelection(url)),
    floorPlans:
      property.floorPlans?.map((url) => urlToFileSelection(url)) ?? [],
    documents:
      property.brochuresDocuments?.map((url) => urlToFileSelection(url)) ?? [],
    propertyUseType: property.propertyUseType,
    amenitiesType: property.amenitiesType,
    useClassType: property.useClassType,
    conditionType: property.conditionType,
    existingBuildout: property.existingBuildoutType,
    currentEpcType: property.currentEpcType,
    potencialEpcType: property.potencialEpcType ?? undefined,
    sqFootage: property.sqFootage,
    floors: property.floors,
    businessRate: property.businessRate ?? undefined,
    yearBuilt: property.yearBuilt ?? undefined,
    yearLastRenovated: property.yearLastRenovated ?? undefined,
    parkingSpaces: property.parkingSpaces,
    parkingDetails: property.parkingDetails ?? undefined,
    propertyDescriptions: property.propertyDescriptions,
    email: property.contactEmail,
    name: property.contactName,
    bio: property.contactBio,
  };
};

export const getAddPropertyInitialValues = (
  user?: CurrentUser | null,
  property?: EditPropertyResponse,
) => {
  if (property) {
    return buildInitialData(property);
  }
  return {
    ...addPropertyInitialValues,
    email: user?.email,
    name: user?.name,
    bio: user?.bio,
  };
};

export const addPropertyValidationSchema = Yup.object({
  address: Yup.string().min(1).required("Address is required"),
  lat: Yup.number().required("Required"),
  lng: Yup.number().required("Required"),
  postcode: Yup.string()
    .min(5, "Invalid")
    .max(8, "Invalid")
    .required("Required"),
  flat: Yup.string().optional(),
  locationType: Yup.mixed()
    .oneOf(Object.values(LocationType))
    .required("Location type is required"),
  listingType: Yup.mixed()
    .oneOf(Object.values(ListingType))
    .required("Listing type is required"), //This error is never displayed.
  tenancyType: Yup.mixed()
    .oneOf(Object.values(TenancyType))
    .required("Tenancy is required"), //This error is never displayed.
  price: Yup.number()
    .when("billingType", {
      is: (billingType: BillingType | undefined) =>
        ![undefined, BillingType.OnRequest].includes(billingType),
      then: Yup.number()
        .required("Required")
        .positive("Required")
        .test("maxDigitsAfterDecimal", "Required", (value: number) =>
          /^\d+(\.\d{1,2})?$/.test(String(value)),
        ),
    })
    .when("listingType", {
      is: ListingType.ForSale,
      then: Yup.number()
        .required("Required")
        .positive("Required")
        .test("maxDigitsAfterDecimal", "Required", (value: number) =>
          /^\d+(\.\d{1,2})?$/.test(String(value)),
        ),
    }),
  netIncome: Yup.number().optional().min(0, "Minimum £0"),
  month: Yup.number()
    .when("listingType", {
      is: ListingType.ForRent,
      then: Yup.number().when("isRentalNegotiable", {
        is: false,
        then: Yup.number()
          .min(0, "Required")
          .max(11, "Required")
          .required("Required"),
      }),
    })
    .when("year", {
      is: (year: number) => year === new Date().getFullYear(),
      then: Yup.number()
        .min(new Date().getMonth() + 1, "Select future date")
        .required("Required"),
    })
    .optional(),
  year: Yup.number().when("listingType", {
    is: ListingType.ForRent,
    then: Yup.number().when("isRentalNegotiable", {
      is: false,
      then: Yup.number()
        .min(new Date().getFullYear(), "Select future date")
        .required("Required"),
    }),
  }),
  isRentalNegotiable: Yup.bool().when("listingType", {
    is: ListingType.ForRent,
    then: Yup.bool().required("Required"),
  }),
  isPriceNegotiable: Yup.bool().when("listingType", {
    is: ListingType.ForSale,
    then: Yup.bool().optional(),
  }),
  photos: Yup.array().min(1, "Select at least 1 image").required("Required"),
  floorPlans: Yup.array().optional(),
  documents: Yup.array().optional(),
  propertyUseType: Yup.array()
    .min(1, "Select at least 1 item.")
    .required("Property use type is required"),
  amenitiesType: Yup.array().optional(),
  useClassType: Yup.mixed()
    .oneOf(Object.values(UseClassType))
    .required("Use Class is required"),
  conditionType: Yup.mixed()
    .oneOf(Object.values(ConditionType))
    .required("Required"),
  existingBuildout: Yup.mixed()
    .oneOf(Object.values(ExistingBuildoutType))
    .required("Required"),
  currentEpcType: Yup.mixed()
    .oneOf(Object.values(EpcType))
    .required("Required"),
  potencialEpcType: Yup.mixed().oneOf(Object.values(EpcType)),
  sqFootage: Yup.number().positive("Required").required("Required"),
  floors: Yup.number()
    .min(0, "Required")
    .integer("Invalid")
    .required("Required"),
  billingType: Yup.mixed().when("listingType", {
    is: ListingType.ForRent,
    then: Yup.mixed().oneOf(Object.values(BillingType)).required("Required"),
  }),
  businessRate: Yup.number().positive("Required").optional(),
  yearBuilt: Yup.number().positive("Required").optional(),
  yearLastRenovated: Yup.number().positive("Required").optional(),
  parkingSpaces: Yup.number().min(0, "Required").required("Required"),
  parkingDetails: Yup.string().optional().max(100, "Max 100 characters"),
  propertyDescriptions: Yup.string()
    .max(2000, "Max 2000 characters")
    .required("Property descriptions are required"),
  email: Yup.string()
    .max(255, "Max 255 characters")
    .email("Email must be valid")
    .required("Email is required"),
  name: Yup.string()
    .max(255, "Max 255 characters")
    .required("Name is required"),
  bio: Yup.string().max(250, "Max 250 characters").required("Bio is required"),
});

const dateFromMonthAndYear = (month?: number, year?: number) =>
  month === undefined || year === undefined
    ? undefined
    : setMonth(setYear(startOfMonth(new Date()), year), month);

const buildBasePropertyInput = (
  uid: string,
  values: AddPropertyFormValues,
) => ({
  //Always required fields:
  uid: uid,
  address: values.address!,
  location: [values.lat, values.lng],
  postcode: values.postcode.toUpperCase(),
  locationType: values.locationType!,
  listingType: values.listingType,
  tenancyType: values.tenancyType,
  price: Number(values.price!),
  propertyUseType: values.propertyUseType,
  amenitiesType: values.amenitiesType,
  useClassType: values.useClassType!,
  contactEmail: values.email!,
  contactName: values.name!,
  contactBio: values.bio!,
  conditionType: values.conditionType!,
  existingBuildoutType: values.existingBuildout!,
  currentEpcType: values.currentEpcType!,
  sqFootage: Number(values.sqFootage!),
  floors: Number(values.floors!),
  parkingSpaces: Number(values.parkingSpaces!),
  propertyDescriptions: values.propertyDescriptions!,

  //Optional fields:
  potencialEpcType: values.potencialEpcType,
  billingType: values.billingType,
  flat: values.flat,
  businessRate: values.businessRate ? Number(values.businessRate) : undefined,
  yearBuilt: values.yearBuilt ? Number(values.yearBuilt) : undefined,
  yearLastRenovated: values.yearLastRenovated
    ? Number(values.yearLastRenovated)
    : undefined,
  parkingDetails: values.parkingDetails,

  //For sale required fields:
  netIncome: values.netIncome ? Number(values.netIncome) : undefined,
  isPriceNegotiable: values.isPriceNegotiable,

  //Fo rent required fields:
  isRentalNegotiable: values.isRentalNegotiable,
  rentalTo: dateFromMonthAndYear(values.month, values.year)?.toDateString(),
});

export const buildAddPropertyInput = (
  uid: string,
  values: AddPropertyFormValues,
  publicUrls: PublicUrls,
): AddPropertyInput => ({
  ...buildBasePropertyInput(uid, values),
  //Files url fields.
  propertyPhotos: publicUrls.propertyImages || [],
  floorPlans: publicUrls.floorPlans || [],
  brochuresDocuments: publicUrls.documents || [],
});

export const buildEditPropertyInput = (
  uid: string,
  values: AddPropertyFormValues,
  publicUrls: PublicUrls,
): AddPropertyInput => {
  const propertyPhotos = values.photos
    .filter((p) => p.url)
    .map((p) => p.url!)
    .concat(publicUrls.propertyImages || []);
  const floorPlans = values.floorPlans
    .filter((p) => p.url)
    .map((p) => p.url!)
    .concat(publicUrls.floorPlans || []);
  const documents = values.documents
    .filter((p) => p.url)
    .map((p) => p.url!)
    .concat(publicUrls.documents || []);

  return {
    ...buildBasePropertyInput(uid, values),

    //Files url fields.
    propertyPhotos: propertyPhotos,
    floorPlans: floorPlans,
    brochuresDocuments: documents,
  };
};
