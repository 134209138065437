import { createContext } from "react";
import { AuthToken } from "../../../common/helpers/authToken";
import { CurrentUser } from "../../../common/helpers/currentUser";

export interface AppContextType {
  authToken: AuthToken | null;
  currentUser: CurrentUser | null | undefined;
  refetch: () => void;
}

const AppContext: React.Context<AppContextType> = createContext<AppContextType>(
  {} as AppContextType,
);

export default AppContext;
