import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router";
import routesPaths from "../../../../../Routing/routesPaths";
import {
  ActiveIndicator,
  ContainerA,
  ContainerLink,
  Content,
  OptionSpan,
  OptionSpanBold,
} from "./NavbarOption.components";

type NavbarOptionProps = {
  title: string;
  path?: string;
  url?: string;
  selected?: boolean;
};

export const isPathSelected = (location: string, path: string) => {
  return path === routesPaths.HOME
    ? location === routesPaths.HOME
    : location.includes(path);
};

const NavbarLinkContent: FunctionComponent<NavbarOptionProps> = ({
  title,
  path = "#",
  url,
  selected,
}) => {
  const location = useLocation();
  const [itemSelected, setItemSelected] = useState(false);

  useEffect(() => {
    setItemSelected(
      selected ? selected : isPathSelected(location.pathname, path),
    );
  }, [location.pathname, path, selected]);

  useEffect(() => {
    const onScroll = () => {
      const isScrolledBottom =
        document.documentElement.scrollHeight ===
        window.pageYOffset + window.innerHeight;

      if (url === "#footer") {
        setItemSelected(isScrolledBottom);
      } else if (
        location.pathname === routesPaths.HOME &&
        path === routesPaths.HOME
      ) {
        setItemSelected(!isScrolledBottom);
      } else {
        setItemSelected(
          selected
            ? selected
            : path === routesPaths.HOME
            ? location.pathname === routesPaths.HOME
            : location.pathname.includes(path),
        );
      }
    };
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, [location.pathname, path, selected, url]);

  return (
    <>
      <Content>
        <OptionSpanBold active={itemSelected}>{title}</OptionSpanBold>
        <OptionSpan active={itemSelected}>{title}</OptionSpan>
      </Content>
      <ActiveIndicator selected={itemSelected} />
    </>
  );
};

const NavbarOption: FunctionComponent<NavbarOptionProps> = (props) => {
  return props.path ? (
    <ContainerLink
      to={props.path}
      replace
      onClick={() => {
        window.scrollTo(0, 0);
      }}
    >
      <NavbarLinkContent {...props} />
    </ContainerLink>
  ) : (
    <ContainerA href={props.url}>
      <NavbarLinkContent {...props} />
    </ContainerA>
  );
};

export default NavbarOption;
