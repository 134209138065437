import React, { FunctionComponent, useEffect } from "react";
import { FormikProps, Form } from "formik";
import InputField from "../../shared/components/Form/Input/InputField";
import CommigLogoBlue from "../../shared/images/commit_logo_blue.svg";
import { RegisterFormValues } from "./form";
import {
  Description,
  Header,
  FormContainer,
  InputContainer,
  CheckboxContainer,
  LoginSpan,
  ButtonContainer,
  ButtonLoginContainer,
  FormContent,
  LoginLink,
  CommitLogo,
  ErrorSpan,
  CheckboxLabel,
  CheckboxLink,
} from "../Register.components";
import routesPaths from "../../Routing/routesPaths";
import Password from "../../shared/components/Form/Password";
import FakeCheckbox from "../../shared/components/Form/FakeCheckbox";
import Button from "../../shared/components/Button";

type Props = FormikProps<RegisterFormValues> & {
  error?: string;
  onChange?: () => void;
};

const RegisterFormContent: FunctionComponent<Props> = ({
  isValid,
  error,
  values,
  errors,
  isSubmitting,
  submitCount,
  setFieldValue,
  onChange,
  setFieldError,
  ...props
}) => {
  useEffect(() => {
    // Set red border for inputs when there is a submit error
    if (error) {
      setFieldError("email", " ");
      setFieldError("password", " ");
    }
  }, [error, setFieldError]);

  return (
    <FormContainer>
      <Form onChange={() => onChange && onChange()}>
        <FormContent>
          <CommitLogo src={CommigLogoBlue} alt="Commit logo" />
          <Header>Register to list properties</Header>
          <Description>
            Keep in mind that your email will be used to contact you about
            properties.
          </Description>

          <InputContainer>
            <InputField
              name="email"
              label="Email"
              autoComplete="email"
              type="email"
            />
          </InputContainer>

          <InputContainer>
            <Password
              name="password"
              placeholder="at least 8 characters"
              autoComplete="new-password"
            />
          </InputContainer>

          <CheckboxContainer>
            <FakeCheckbox
              error={!values.termsAndPolicyAccepted && submitCount > 0}
              checked={values.termsAndPolicyAccepted}
              onClick={() => {
                setFieldValue(
                  "termsAndPolicyAccepted",
                  !values.termsAndPolicyAccepted,
                );
              }}
            >
              <CheckboxLabel>
                By register I agree to the{" "}
                <CheckboxLink to={routesPaths.TERMS_AND_CONDITIONS}>
                  terms &amp; conditions
                </CheckboxLink>{" "}
                and{" "}
                <CheckboxLink to={routesPaths.PRIVACY_POLICY}>
                  privacy policy
                </CheckboxLink>
              </CheckboxLabel>
            </FakeCheckbox>
          </CheckboxContainer>

          <ButtonLoginContainer>
            <ErrorSpan>{error}</ErrorSpan>
            <ButtonContainer>
              <Button type="submit" size="block" isLoading={isSubmitting}>
                Create an account
              </Button>
            </ButtonContainer>

            <LoginSpan>
              Already have an account?{" "}
              <LoginLink to={routesPaths.LOGIN}>Login</LoginLink>
            </LoginSpan>
          </ButtonLoginContainer>
        </FormContent>
      </Form>
    </FormContainer>
  );
};

export default RegisterFormContent;
