import React, { FunctionComponent, useEffect, useState } from "react";
import useQueryParameters from "../../../../common/helpers/useQueryParameters";
import { useShowAccoutnDetailsModal } from "../../../../common/helpers/useShowAccountDetailsModal";
import { AddPropertyMode } from "../../../AddProperty/AddPropertyFormContent";
import AddPropertyContext from "./AddPropertyContext";

export const AddPropertyProvider: FunctionComponent = ({ children }) => {
  const [address, setAddress] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [mode, setMode] = useState(AddPropertyMode.ADD);
  const [, setPropertyUid] = useState("");
  const queryAction = useQueryParameters().get("action");
  const uid = useQueryParameters().get("uid");

  useShowAccoutnDetailsModal();

  useEffect(() => {
    const propertyModeValues = Object.values(AddPropertyMode);
    // Allow to set mode only for values present in AddPropertyMode.
    if (propertyModeValues.includes(queryAction as AddPropertyMode)) {
      setMode(queryAction as AddPropertyMode);
    }
    setPropertyUid(uid ?? "");
  }, [queryAction, uid]);

  return (
    <AddPropertyContext.Provider
      value={{
        mode,
        address,
        userName,
        userEmail,
        setAddress,
        setUserName,
        setUserEmail,
      }}
    >
      {children}
    </AddPropertyContext.Provider>
  );
};
