import React, { FunctionComponent, useCallback, useState } from "react";
import routesPaths from "../../../Routing/routesPaths";
import {
  AddPropertyButtonsContainer,
  AddPropertyButtonWrapper,
  AddressSeparator,
  BackIconImage,
  BackLink,
  BackLinkSpan,
  Container,
  Content,
  UserDetails,
  UserDetailsMail,
  UserDetailsName,
} from "./Navbar.components";
import BackIcon from "./icons/icon_back.svg";
import Button from "../Button";
import { useNavigate } from "react-router";
import {
  PropertyStatus,
  useSetPropertyStatusMutation,
} from "../../../../generated/schema";

type AcceptPropertyNavbarProps = {
  email: string;
  name: string;
  id: number;
  status: PropertyStatus;
};

const AcceptPropertyNavbar: FunctionComponent<AcceptPropertyNavbarProps> = ({
  email,
  name,
  id,
  status,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [acceptButtonClicked, setAcceptButtonClicked] = useState(false);
  const [setStatus] = useSetPropertyStatusMutation();

  const setPropertyStatus = useCallback(
    async (id: number, status: PropertyStatus) => {
      setLoading(true);
      try {
        await setStatus({
          variables: {
            id,
            status,
          },
        });
        setLoading(false);
        navigate(`${routesPaths.LISTINGS_ROOT}/pending`);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    },
    [setStatus, navigate],
  );

  return (
    <Container>
      <Content>
        <BackLink onClick={() => navigate(routesPaths.LISTINGS_ROOT)}>
          <BackIconImage src={BackIcon} />
          <BackLinkSpan>Back to listings</BackLinkSpan>
        </BackLink>

        <AddressSeparator />

        <UserDetails>
          <UserDetailsName>{name}</UserDetailsName>
          <UserDetailsMail>{email}</UserDetailsMail>
        </UserDetails>

        {status === PropertyStatus.Pending && (
          <AddPropertyButtonsContainer>
            <AddPropertyButtonWrapper isWrap={true}>
              <Button
                buttonType="outline"
                size="block"
                isDisabled={acceptButtonClicked && loading}
                isLoading={!acceptButtonClicked && loading}
                onClick={() => setPropertyStatus(id, PropertyStatus.Declined)}
              >
                Decline
              </Button>
            </AddPropertyButtonWrapper>
            <AddPropertyButtonWrapper isWrap={true}>
              <Button
                size="block"
                isDisabled={!acceptButtonClicked && loading}
                isLoading={acceptButtonClicked && loading}
                onClick={() => {
                  setAcceptButtonClicked(true);
                  setPropertyStatus(id, PropertyStatus.Active);
                }}
              >
                Accept
              </Button>
            </AddPropertyButtonWrapper>
          </AddPropertyButtonsContainer>
        )}
      </Content>
    </Container>
  );
};

export default AcceptPropertyNavbar;
