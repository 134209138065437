import {
  AmenitiesType,
  AreaHealthType,
  BillingType,
  ConditionType,
  EpcType,
  ExistingBuildoutType,
  LevelType,
  LocationType,
  PropertyUseType,
  UseClassType,
} from "../../generated/schema";

export const getValue = (name: LocationType) => {
  switch (name) {
    case LocationType.HighStreet:
      return "High Street";
    case LocationType.CommercialStreet:
      return "Commercial Street";
    case LocationType.Urbar:
      return "Urban";
    case LocationType.Rural:
      return "Rural";
    case LocationType.Residential:
      return "Residential";
    case LocationType.FoodCourt:
      return "Food Court";
    case LocationType.DepartmentStore:
      return "Department Store";
    case LocationType.Offices:
      return "Offices";
    case LocationType.Industrial:
      return "Industrial";
  }
};

export const getPropertyUseTypeValue = (name: PropertyUseType) => {
  switch (name) {
    case PropertyUseType.CallCentre:
      return "Call Centre";
    case PropertyUseType.FinancialServices:
      return "Financial Services";
    case PropertyUseType.GovernmentFacility:
      return "Government Facility";
    case PropertyUseType.LawFirm:
      return "Law Firm";
    case PropertyUseType.ProfessionalServices:
      return "Professional Services";
    case PropertyUseType.ResearchAndDevelopment:
      return "Research & Development";
    case PropertyUseType.Bar:
      return "Bar";
    case PropertyUseType.Beauty:
      return "Beauty";
    case PropertyUseType.Bank:
      return "Bank";
    case PropertyUseType.TakeAwayRestaurant:
      return "Take-Away Restaurant";
    case PropertyUseType.GhostKitchen:
      return "Ghost Kitchen";
    case PropertyUseType.Cafe:
      return "Cafe";
    case PropertyUseType.Casino:
      return "Casino";
    case PropertyUseType.Cinema:
      return "Cinema";
    case PropertyUseType.DepartmentStore:
      return "Department Store";
    case PropertyUseType.EventVenue:
      return "Event Venue";
    case PropertyUseType.Gym:
      return "Gym";
    case PropertyUseType.Hotel:
      return "Hotel";
    case PropertyUseType.Industrial:
      return "Industrial";
    case PropertyUseType.PrivateOffice:
      return "Private Office";
    case PropertyUseType.OfficeShared:
      return "Office (Shared)";
    case PropertyUseType.OfficeCoWorking:
      return "Office (Co-working)";
    case PropertyUseType.OfficeManaged:
      return "Office (Managed)";
    case PropertyUseType.Pub:
      return "Pub";
    case PropertyUseType.Restaurant:
      return "Restaurant";
    case PropertyUseType.GeneralRetail:
      return "General Retail";
    case PropertyUseType.ShoppingCentreRetail:
      return "Shopping Centre (Retail)";
    case PropertyUseType.ShoppingCentreRestaurant:
      return "Shopping Centre (Restaurant)";
    case PropertyUseType.ShoppingCentreOther:
      return "Shopping Centre (Other)";
    case PropertyUseType.StorageWarehouse:
      return "Storage/Warehouse";
    case PropertyUseType.Supermarket:
      return "Supermarket";
    case PropertyUseType.Religious:
      return "Religious";
    case PropertyUseType.Other:
      return "Other";
  }
};

export const getAmenitiesTypeValue = (name: AmenitiesType) => {
  switch (name) {
    case AmenitiesType.Laboratory:
      return "Laboratory";
    case AmenitiesType.RaisedFloor:
      return "Raised Floor";
    case AmenitiesType.TradingFloor:
      return "Trading Floor";
    case AmenitiesType.Kitchen:
      return "Kitchen";
    case AmenitiesType.AccentLighting:
      return "Accent Lighting";
    case AmenitiesType.RollerShutters:
      return "Roller Shutters";
    case AmenitiesType.AirConditioning:
      return "Air Conditioning";
    case AmenitiesType.Atrium:
      return "Atrium";
    case AmenitiesType.Balcony:
      return "Balcony";
    case AmenitiesType.Basement:
      return "Basement";
    case AmenitiesType.BicycleStorage:
      return "Bicycle Storage";
    case AmenitiesType.CentralHeating:
      return "Central Heating";
    case AmenitiesType.WcFacilities:
      return "WC Facilities";
    case AmenitiesType.DdaCompliant:
      return "DDA Compliant ";
    case AmenitiesType.FullyCarpeted:
      return "Fully Carpeted";
    case AmenitiesType.HighCeilings:
      return "High Ceilings";
    case AmenitiesType.LiftAccess:
      return "Lift Access";
    case AmenitiesType.AccessibilityAccess:
      return "Accessibility Access";
    case AmenitiesType.NaturalLight:
      return "Natural Light";
    case AmenitiesType.FoodService:
      return "Food Service";
    case AmenitiesType.OpenPlan:
      return "Open-Plan";
    case AmenitiesType.PartitionedOffices:
      return "Partitioned Offices";
    case AmenitiesType.Reception:
      return "Reception";
    case AmenitiesType.RecessedLighting:
      return "Recessed Lighting";
    case AmenitiesType.SecureStorage:
      return "Secure Storage";
    case AmenitiesType.ShowerFacilities:
      return "Shower Facilities";
    case AmenitiesType.SuspendedCeilings:
      return "Suspended Ceilings";
    case AmenitiesType.WiFi:
      return "Wi-Fi";
    case AmenitiesType.WoodenFloors:
      return "Wooden Floors";
    case AmenitiesType.Cctv:
      return "CCTV";
    case AmenitiesType.ConferenceRooms:
      return "Conference Rooms";
    case AmenitiesType.EmergencyLighting:
      return "Emergency Lighting";
    case AmenitiesType.ExposedCeiling:
      return "Exposed Ceiling ";
    case AmenitiesType.CornerSpace:
      return "Corner Space";
    case AmenitiesType.PrintCopyRoom:
      return "Print/Copy Room";
    case AmenitiesType.PrivateRestrooms:
      return "Private Restrooms";
    case AmenitiesType.LoungeArea:
      return "Lounge Area";
  }
};

export const useClassTypeValue = (name: UseClassType) => {
  switch (name) {
    case UseClassType.A1:
      return "A1 (Shops)";
    case UseClassType.A2:
      return "A2 (Financial & Professional Services)";
    case UseClassType.A3:
      return "A3 (Restaurants and Cafes)";
    case UseClassType.A4:
      return "A4 (Drinks Establishments)";
    case UseClassType.A5:
      return "A5 (Hot Food Takeaway)";
    case UseClassType.B1:
      return "B1 (Business)";
    case UseClassType.B2:
      return "B2 (General Industrial)";
    case UseClassType.B8:
      return "B8 (Storage and Distribution)";
    case UseClassType.C1:
      return "C1 (Hotels)";
    case UseClassType.C2:
      return "C2 (Residential Institutions)";
    case UseClassType.C2A:
      return "C2A (Secure Residential Institutions)";
    case UseClassType.C3:
      return "C3 (Dwellinghouses)";
    case UseClassType.Class_1:
      return "Class 1 (Shops)";
    case UseClassType.Class_10:
      return "Class 10 (Non Residential Institution)";
    case UseClassType.Class_11:
      return "Class 11 (Assembly and Leisure)";
    case UseClassType.Class_2:
      return "Class 2 (Financial and Professional)";
    case UseClassType.Class_3:
      return "Class 3 (Food and Drink)";
    case UseClassType.Class_4:
      return "Class 4 (Business)";
    case UseClassType.Class_5:
      return "Class 5 (General Industrial)";
    case UseClassType.Class_6:
      return "Class 6 (Storage and Distribution)";
    case UseClassType.Class_7:
      return "Class 7 (Hotels and Hostels)";
    case UseClassType.Class_8:
      return "Class 8 (Residential Institutions)";
    case UseClassType.Class_9:
      return "Class 9 (Houses)";
    case UseClassType.D1:
      return "D1 (Non-Residential Institutions)";
    case UseClassType.D2:
      return "D2 (Assembly and Leisure)";
    case UseClassType.E:
      return "E (Commercial; Business and Service)";
    case UseClassType.F_1:
      return "F.1 (Learning and Non-Residential Institutions)";
    case UseClassType.F_2:
      return "F.2 (Local Community)";
    case UseClassType.NotSpecified:
      return "Not Specified";
    case UseClassType.SuiGeneris:
      return "Sui Generis";
  }
};

export const getConditionTypeValue = (name: ConditionType) => {
  switch (name) {
    case ConditionType.Demolished:
      return "Demolished";
    case ConditionType.PartialDemolished:
      return "Partial Demolished";
    case ConditionType.NeedsRenovation:
      return "Needs Renovation";
    case ConditionType.Average:
      return "Average";
    case ConditionType.Good:
      return "Good";
    case ConditionType.Excellent:
      return "Excellent";
    case ConditionType.Unknown:
      return "Unknown";
  }
};

export const getExistingBuildoutTypeValue = (name: ExistingBuildoutType) => {
  switch (name) {
    case ExistingBuildoutType.FullBuildOut:
      return "Full Build Out";
    case ExistingBuildoutType.PartialBuildOut:
      return "Partial Build Out";
    case ExistingBuildoutType.ShellSpace:
      return "Shell Space";
    case ExistingBuildoutType.SpecSuite:
      return "Spec Suite";
    case ExistingBuildoutType.NotKnown:
      return "Not known";
  }
};

export const getEpcTypeValue = (name: EpcType) => {
  switch (name) {
    case EpcType.A:
      return "A";
    case EpcType.B:
      return "B";
    case EpcType.C:
      return "C";
    case EpcType.D:
      return "D";
    case EpcType.E:
      return "E";
    case EpcType.F:
      return "F";
    case EpcType.G:
      return "G";
    case EpcType.Pending:
      return "Pending";
    case EpcType.Unknown:
      return "Unknown";
  }
};

export const getLevelTypeValue = (name: LevelType) => {
  switch (name) {
    case LevelType.Low:
      return "Low";
    case LevelType.Medium:
      return "Medium";
    case LevelType.High:
      return "High";
  }
};

export const getAreaHealthValue = (name: AreaHealthType) => {
  switch (name) {
    case AreaHealthType.BelowAverage:
      return "Below avg";
    case AreaHealthType.Average:
      return "Average";
    case AreaHealthType.Healthy:
      return "Healthy";
    case AreaHealthType.Excellent:
      return "Excellent";
  }
};

export const getBillingTypeValue = (name: BillingType) => {
  switch (name) {
    case BillingType.OnRequest:
      return "Available on request";
    case BillingType.Monthly:
      return "Billed monthly";
    case BillingType.Yearly:
      return "Billed yearly";
  }
};

export const LOCATION_TYPE_OPTIONS = Object.values(LocationType).map((t) => ({
  type: t,
  value: getValue(t),
}));

export const PROPERTY_USE_TYPE_OPTIONS = Object.values(PropertyUseType).map(
  (t) => ({
    type: t,
    value: getPropertyUseTypeValue(t),
  }),
);

export const AMENITIES_TYPE_OPTIONS = Object.values(AmenitiesType)
  .map((t) => ({
    type: t,
    value: getAmenitiesTypeValue(t),
  }))
  .sort((o1, o2) => o1.value.localeCompare(o2.value));

export const USE_CLASS_TYPE_OPTIONS = Object.values(UseClassType).map((t) => ({
  type: t,
  value: useClassTypeValue(t),
}));

export const CONDITION_TYPE_OPTIONS = Object.values(ConditionType).map((t) => ({
  type: t,
  value: getConditionTypeValue(t),
}));

export const EXISTING_BUILDOUT_TYPE_OPTIONS = Object.values(
  ExistingBuildoutType,
).map((t) => ({
  type: t,
  value: getExistingBuildoutTypeValue(t),
}));

export const EPC_TYPE_OPTIONS = Object.values(EpcType).map((t) => ({
  type: t,
  value: getEpcTypeValue(t),
}));

export const BILLING_TYPE_OPTIONS = [
  BillingType.OnRequest,
  BillingType.Monthly,
  BillingType.Yearly,
].map((t) => ({
  type: t,
  value: getBillingTypeValue(t),
}));
