import { createContext } from "react";
import { AddPropertyMode } from "../../../AddProperty/AddPropertyFormContent";

export interface AddPropertyContextType {
  mode: AddPropertyMode;
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  userName: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  userEmail: string;
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
}

const AddPropertyContext: React.Context<AddPropertyContextType> =
  createContext<AddPropertyContextType>({} as AddPropertyContextType);

export default AddPropertyContext;
