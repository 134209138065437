import styled, { css } from "styled-components";
import { colors } from "../../shared/colors";
import { down } from "../../shared/media";
import {
  ButtonSmallStyle,
  LeadSmallStyle,
  VerticalGap,
} from "../../shared/mixins";

export const SortByContainer = styled.div<{ isOpened: boolean }>`
  width: 128px;
  height: 32px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.primary.pure_white};
  border-radius: 8px;
  margin-left: 8px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  transition: box-shadow 250ms ease-in-out;

  :hover {
    ${(p) =>
      !p.isOpened &&
      css`
        box-shadow: 0 0 0 1px ${colors.secondary.pure_blue};
      `}
  }

  ${(p) =>
    p.isOpened &&
    css`
      box-shadow: 0 0 0 1px ${colors.secondary.pure_blue};
    `}

  ${down("tablet")} {
    width: 40px;
    height: 40px;
    box-shadow: 0 0 0 1px ${colors.grayscale._400};

    ${(p) =>
      p.isOpened &&
      css`
        box-shadow: 0 0 0 1px ${colors.secondary.pure_blue};
      `}
  }
`;

export const SortByDropdown = styled.div`
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 2;
  width: 200px;
  box-shadow: 0px 0px 4px 2px rgba(59, 122, 247, 0.1);
  border-radius: 8px;
  padding: 8px;
  background-color: ${colors.primary.pure_white};
  ${VerticalGap(4)}

  ${down("tablet")} {
    top: 48px;
  }
`;

export const SortByDropdownButton = styled.button<{ selected: boolean }>`
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 40px;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 8px;
  text-align: left;
  padding-left: 12px;
  ${LeadSmallStyle};

  :hover {
    background-color: ${colors.secondary.pure_blue_transparent};
  }

  ${(p) =>
    p.selected &&
    css`
      ${ButtonSmallStyle};
      color: ${colors.grayscale._800};
      background-color: ${colors.secondary.pure_blue_transparent};
    `}
`;

export const SortByMobileIcon = styled.img<{ isOpened: boolean }>`
  display: none;

  ${down("tablet")} {
    display: block;
  }
`;

export const SortByText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.primary.frosted_black};
  margin-left: 12px;
  flex: 1;

  ${down("tablet")} {
    display: none;
  }
`;

export const SortByIcon = styled.img<{ isOpened: boolean }>`
  width: 13px;
  height: 12px;
  margin-right: 8px;
  margin-left: 13px;
  transition: transform 250ms ease-in-out;
  transform: rotate(${(p) => (p.isOpened ? 180 : 0)}deg);

  ${down("tablet")} {
    display: none;
  }
`;
