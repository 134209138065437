import styled from "styled-components";
import { colors } from "../shared/colors";
import { ButtonSmallStyle, VerticalGap } from "../shared/mixins";

type WarningBoxColor = "red" | "orange";

const getColor = (boxColor: WarningBoxColor) => {
  switch (boxColor) {
    case "red":
      return colors.semantic.red_dark;
    case "orange":
      return colors.semantic.orange_dark;
  }
};

export const AddPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${VerticalGap(16)};
  max-width: 720px;
  padding: 0px 56px;
  margin: 40px auto;
`;

export const WarningBox = styled.div<{ color: WarningBoxColor }>`
  ${ButtonSmallStyle};
  height: 56px;
  border-radius: 8px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  background-color: ${colors.primary.pure_white};
  color: ${(p) => getColor(p.color)};
`;

export const WarningIconImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const AddPropertyContent = styled.div`
  background-color: ${colors.grayscale._200};
`;

export const AddPropertyLoaderContainer = styled.div`
  position: fixed;
  bottom: -40px;
  top: -40px;
  left: 0;
  right: 0;
  background-color: ${colors.primary.frosted_black_transparent_25};
  z-index: 20;
  bottom: 0;
`;

export const AddPropertyLoader = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
