import React, { FunctionComponent } from "react";
import AnimatedHeight, { AnimateHeightProps } from "react-animate-height";

// Separate component with ts-ignore because of some issues with types in react-animate-height.
const AnimateHeight: FunctionComponent<AnimateHeightProps> = (props) => {
  return (
    /*@ts-ignore */
    <AnimatedHeight {...props} />
  );
};

export default AnimateHeight;
