import React, { FunctionComponent } from "react";
import { useLocation } from "react-router";
import { propertyDetailsRootPaths } from "../../../../../backend/src/shared/routes/routes";
import { useShowAccoutnDetailsModal } from "../../../common/helpers/useShowAccountDetailsModal";
import MainNavbar from "../../shared/components/Navbar/MainNavbar";
import ResolutionNotSupported from "../../shared/components/ResolutionNotSupported";
import { useIsMediaDown } from "../../shared/media";
import { AuthedLayoutContent } from "./AuthedLayout.components";

const AuthedLayout: FunctionComponent = ({ children }) => {
  const location = useLocation();
  const isMobileDown = useIsMediaDown("mobile");

  useShowAccoutnDetailsModal();

  const hideMainNavbar = location.pathname.includes(
    propertyDetailsRootPaths.PROPERTY_ACCEPT_ROOT,
  );

  if (isMobileDown)
    return (
      <AuthedLayoutContent>
        <MainNavbar />
        <ResolutionNotSupported />
      </AuthedLayoutContent>
    );

  return (
    <AuthedLayoutContent>
      {!hideMainNavbar && <MainNavbar />}
      {children}
    </AuthedLayoutContent>
  );
};

export default AuthedLayout;
