import { FormikProps } from "formik";
import React, { FunctionComponent } from "react";
import {
  getPropertyUseTypeValue,
  PROPERTY_USE_TYPE_OPTIONS,
} from "../../../../common/helpers/enumNamesHelper";
import { ListingType } from "../../../../generated/schema";
import Button from "../../../shared/components/Button";
import Label from "../../../shared/components/Form/Input/Label";
import RadioButton from "../../../shared/components/RadioButotn/RadioButton";
import SearchInput from "../../../shared/components/SearchInput/SearchInput";
import MultiSelect from "../../../shared/components/Select/MultiSelect/MultiSelect";
import { useIsMediaDown } from "../../../shared/media";
import { SearchHeaderFormValues } from "./form";
import {
  SearchHeaderButtonWrapper,
  SearchHeaderFormContentContainer,
  SearchHeaderInputsInnerWrapper,
  SearchHeaderInputsWrapper,
  SearchHeaderLocationInputWrapper,
  SearchHeaderPropertyUseWrapper,
  SearchHeaderRadioWrapper,
} from "./SearchHeaderForm.components";

type Props = FormikProps<SearchHeaderFormValues>;

const SearchHeaderFormContent: FunctionComponent<Props> = ({
  values,
  submitCount,
  errors,
  setFieldValue,
}) => {
  const isMobileDown = useIsMediaDown("mobile");
  return (
    <SearchHeaderFormContentContainer>
      <SearchHeaderRadioWrapper>
        <RadioButton
          color={isMobileDown ? "normal" : "dark"}
          checked={values.searchType === ListingType.ForRent}
          value="For rent"
          onCheck={() => setFieldValue("searchType", ListingType.ForRent)}
        />
        <RadioButton
          color={isMobileDown ? "normal" : "dark"}
          checked={values.searchType === ListingType.ForSale}
          value="For sale"
          onCheck={() => setFieldValue("searchType", ListingType.ForSale)}
        />
      </SearchHeaderRadioWrapper>
      <SearchHeaderInputsWrapper>
        <SearchHeaderInputsInnerWrapper>
          <SearchHeaderLocationInputWrapper>
            <Label>Location</Label>
            <SearchInput
              inputSize="l"
              isError={
                Object.keys(errors).includes("address") && submitCount > 0
              }
              submitCount={submitCount}
              onChange={(location) => {
                if (location) {
                  const { lat, lng, fullAddress } = location;
                  setFieldValue("lat", lat);
                  setFieldValue("lng", lng);
                  setFieldValue("address", fullAddress);
                }
              }}
            ></SearchInput>
          </SearchHeaderLocationInputWrapper>
          <SearchHeaderPropertyUseWrapper>
            <MultiSelect
              options={PROPERTY_USE_TYPE_OPTIONS.map((o) => o.value)}
              label="Property Use Type"
              name="propertyUseType"
              selections={values.propertyUseType.map((t) =>
                getPropertyUseTypeValue(t),
              )}
              truncated
              optionClick={(value) => {
                const useTypeValues = values.propertyUseType;
                const option = PROPERTY_USE_TYPE_OPTIONS.find(
                  (o) => o.value === value,
                )!;

                const newValues = useTypeValues.includes(option.type)
                  ? useTypeValues.filter((o) => o !== option.type)
                  : useTypeValues.concat(option.type);

                setFieldValue("propertyUseType", newValues);
              }}
            />
          </SearchHeaderPropertyUseWrapper>
        </SearchHeaderInputsInnerWrapper>
        <SearchHeaderButtonWrapper>
          <Button buttonType="dark" height="l" type="submit">
            Search
          </Button>
        </SearchHeaderButtonWrapper>
      </SearchHeaderInputsWrapper>
    </SearchHeaderFormContentContainer>
  );
};

export default SearchHeaderFormContent;
