import React, { FunctionComponent, useCallback } from "react";
import SearchInput from "../../../shared/components/SearchInput/SearchInput";
import SingleSelect from "../../../shared/components/Select/SingleSelect/SingleSelect";
import FormBox from "../FormBox/FormBox";
import {
  LargeInputContainer,
  LocationAddressContainer,
  LocationSectionContainer,
  PostcodeInputContainer,
  SmallInputContainer,
  SmallInputsContainer,
} from "./LocationSection.components";
import { LOCATION_TYPE_OPTIONS } from "../../../../common/helpers/enumNamesHelper";
import { AddPropertyFormContentProps } from "../../AddPropertyFormContent";
import InputField from "../../../shared/components/Form/Input/InputField";
import { ISearchResult } from "../../../shared/components/SearchInput/ISearchResult";
import { Hints } from "../../AddPropertyFormHints";

const LocationSection: FunctionComponent<AddPropertyFormContentProps> = (
  props,
) => {
  const { errors, submitCount, values, setFieldValue } = props;
  const onLoactionChange = useCallback(
    (location?: ISearchResult) => {
      setFieldValue("address", location?.fullAddress);
      setFieldValue("lat", location?.lat);
      setFieldValue("lng", location?.lng);
      if (values.postcode === "" && location?.postcode) {
        setFieldValue("postcode", location?.postcode);
      }
    },
    [setFieldValue, values.postcode],
  );

  return (
    <FormBox section="LOCATION">
      <LocationSectionContainer>
        <LocationAddressContainer>
          <LargeInputContainer>
            <SearchInput
              value={values.address}
              submitCount={submitCount}
              name="locationSearch"
              error={errors.address}
              inputSize="l"
              onChange={onLoactionChange}
              hint={Hints.propertyAddress}
              label="Property Address"
            />
          </LargeInputContainer>
          <SmallInputsContainer>
            <SmallInputContainer>
              <InputField
                label="Flat no. (opt.)"
                name="flat"
                inputSize="l"
                hint={Hints.flat}
              />
            </SmallInputContainer>
            <SmallInputContainer>
              <PostcodeInputContainer>
                <InputField
                  label="Postcode"
                  name="postcode"
                  inputSize="l"
                  hint={Hints.postcode}
                />
              </PostcodeInputContainer>
            </SmallInputContainer>
          </SmallInputsContainer>
        </LocationAddressContainer>
        <LargeInputContainer>
          <SingleSelect
            hint={Hints.locationType}
            submitCount={submitCount}
            error={errors.locationType}
            optionClick={(index) => {
              setFieldValue(
                "locationType",
                index < 0 ? undefined : LOCATION_TYPE_OPTIONS[index].type,
              );
            }}
            label="Location Type"
            options={LOCATION_TYPE_OPTIONS.map((t) => t.value)}
            name="locationType"
            selection={LOCATION_TYPE_OPTIONS.findIndex(
              (o) => o.type === values.locationType,
            )}
          />
        </LargeInputContainer>
      </LocationSectionContainer>
    </FormBox>
  );
};

export default LocationSection;
