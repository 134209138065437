import React, { FunctionComponent } from "react";
import MenuIcon from "../icons/menu_icon.svg";
import CloseIcon from "../icons/close_icon.svg";
import { MenuButtonContainer, MenuImage } from "../Navbar.components";

const MenuButton: FunctionComponent<{
  visible: boolean;
  onClick: () => void;
}> = ({ visible, onClick }) => {
  return (
    <MenuButtonContainer opened={visible} onClick={onClick}>
      <MenuImage src={visible ? CloseIcon : MenuIcon} alt="Open/Close button" />
      {visible ? <span>Close</span> : <span>Menu</span>}
    </MenuButtonContainer>
  );
};

export default MenuButton;
