import React, {
  ComponentPropsWithoutRef,
  FunctionComponent,
  useState,
} from "react";
import ClickAwayListener from "react-click-away-listener";
import FakeCheckbox from "../../Form/FakeCheckbox";
import {
  Container,
  ListContainer,
  ListOption,
  MultiSelectionContainer,
  OpenCloseContainer,
  OpenCloseImage,
  OptionSpan,
  SelectionContainer,
  SelectionDeleteButton,
  SelectionDeleteImage,
  SelectionOption,
} from "./MultiSelect.components";
import IconDelete from "../icon_delete.svg";
import Label from "../../Form/Input/Label";
import ErrorMessage from "../../Form/shared/ErrorMessage";
import { useDropdownKeyboardListener } from "../useDropdownKeyboardListener";

export type MultiSelectProps = ComponentPropsWithoutRef<"select"> & {
  showError?: boolean;
  error?: string;
  label: string;
  options: string[];
  hint?: string;
  selections: string[];
  isDisabled?: boolean;
  truncated?: boolean;
  submitCount?: number;
  name: string;
  inputSize?: "l" | "m";
  optionClick: (option: string, selected: boolean) => void;
};

const getTruncatedSelections = (selections: string[], isDisabled: boolean) => {
  if (selections.length === 0) return null;
  return (
    <>
      <SelectionOption disabled={isDisabled} deleteVisible={false} isTruncated>
        {selections[0]}
      </SelectionOption>
      {selections.length > 1 && (
        <SelectionOption
          disabled={isDisabled}
          deleteVisible={false}
          isTruncated
        >
          +{selections.length - 1} more
        </SelectionOption>
      )}
    </>
  );
};

const MultiSelect: FunctionComponent<MultiSelectProps> = ({
  options,
  selections,
  hint,
  optionClick,
  label,
  error,
  submitCount = 0,
  inputSize = "l",
  isDisabled = false,
  truncated = false,
  name,
}) => {
  const [listVisible, setListVisible] = useState(false);
  const [uniqueClassName] = useState(`multiselect-${name}`);
  const [wasTouched, setWasTouched] = useState(false);

  useDropdownKeyboardListener(isDisabled, uniqueClassName, setListVisible);
  const showError = (wasTouched || submitCount > 0) && !!error;

  return (
    <ClickAwayListener onClickAway={() => setListVisible(false)}>
      <MultiSelectionContainer>
        <Label hint={hint}>{label}</Label>
        <Container
          inputSize={inputSize}
          empty={selections.length === 0}
          disabled={isDisabled}
          truncated={truncated}
        >
          <OpenCloseContainer
            disabled={isDisabled}
            onClick={() => !isDisabled && setListVisible(!listVisible)}
          >
            <OpenCloseImage
              opened={listVisible}
              disabled={isDisabled}
              onClick={() => !isDisabled && setListVisible(!listVisible)}
            />
          </OpenCloseContainer>
          <SelectionContainer
            inputSize={inputSize}
            truncated={truncated}
            error={showError}
            className={uniqueClassName}
            disabled={isDisabled}
            onClick={() => !isDisabled && setListVisible(!listVisible)}
            tabIndex={0}
          >
            {!truncated
              ? selections.map((selection) => (
                  <SelectionOption
                    isTruncated={truncated}
                    key={selection}
                    disabled={isDisabled}
                    deleteVisible={true}
                  >
                    {selection}
                    <SelectionDeleteButton
                      truncated={truncated}
                      disabled={isDisabled}
                      onClick={(e) => {
                        if (!isDisabled) {
                          optionClick(selection, false);
                        }
                        e.stopPropagation();
                      }}
                    >
                      <SelectionDeleteImage src={IconDelete} />
                    </SelectionDeleteButton>
                  </SelectionOption>
                ))
              : getTruncatedSelections(selections, isDisabled)}
          </SelectionContainer>
          {listVisible && (
            <ListContainer>
              {options.map((option, index) => {
                const selected = selections.includes(option);
                return (
                  <ListOption
                    autoFocus={listVisible ? index === 0 : false}
                    disabled={isDisabled}
                    type="button"
                    key={option}
                    className={`${uniqueClassName}-button`}
                    selected={selected}
                    onClick={() => {
                      !isDisabled && optionClick(option, !selected);
                      setWasTouched(true);
                    }}
                  >
                    <FakeCheckbox
                      selectable={false}
                      checked={selected}
                      onClick={() => {
                        !isDisabled && optionClick(option, !selected);
                        setWasTouched(true);
                      }}
                    >
                      <OptionSpan selected={selected}>{option}</OptionSpan>
                    </FakeCheckbox>
                  </ListOption>
                );
              })}
            </ListContainer>
          )}
        </Container>
        {showError && <ErrorMessage>{error}</ErrorMessage>}
      </MultiSelectionContainer>
    </ClickAwayListener>
  );
};

export default MultiSelect;
