import { Field, FieldProps } from "formik";
import React, { ComponentProps, FunctionComponent } from "react";
import { Textarea } from "./Textarea";

export type TextareaFieldComponentProps = Omit<
  ComponentProps<typeof Textarea>,
  "error"
>;
export type TextareaFieldProps = TextareaFieldComponentProps & {
  label: string;
  maxLength?: number;
  hint?: JSX.Element | string;
};

const TextareaField: FunctionComponent<TextareaFieldProps> = ({
  ...fieldProps
}) => {
  return (
    <Field {...fieldProps}>
      {({ field, meta }: FieldProps) => (
        <>
          <Textarea
            {...fieldProps}
            {...field}
            error={meta.touched && meta.error ? meta.error : undefined}
          />
        </>
      )}
    </Field>
  );
};

export default TextareaField;
