import { FormikProps } from "formik";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { CheckboxLabel } from "../../../../../Register/Register.components";
import routesPaths from "../../../../../Routing/routesPaths";
import { useIsMediaDown } from "../../../../media";
import Button from "../../../Button";
import FakeCheckbox from "../../../Form/FakeCheckbox";
import InputField from "../../../Form/Input/InputField";
import TextareaField from "../../../Form/Textarea/TextareaField";
import useModal from "../../../Modal/ModalContext/useModal";
import { MessageAgentModalFormValues } from "./form";
import {
  MessageAgentBottomSectionWrapper,
  MessageAgentButtonWrapper,
  MessageAgentCheckboxWrapper,
  MessageAgentError,
  MessageAgentFormWrapper,
  MessageAgentInputWrapper,
  MessageAgentNote,
} from "./MessageAgentForm.components";

type Props = FormikProps<MessageAgentModalFormValues> & {
  error?: string;
};

const MessageAgentModalFormContent: FunctionComponent<Props> = ({
  setFieldValue,
  submitForm,
  values,
  isSubmitting,
  error,
}) => {
  const isMobileDown = useIsMediaDown("mobile");
  const { closeModal } = useModal();

  return (
    <MessageAgentFormWrapper>
      <MessageAgentInputWrapper>
        <InputField
          label="Name"
          autoComplete="name"
          name="name"
          type="text"
          maxLength={255}
        />
      </MessageAgentInputWrapper>
      <MessageAgentInputWrapper>
        <InputField
          label="Email"
          autoComplete="email"
          name="email"
          type="email"
          maxLength={255}
        />
      </MessageAgentInputWrapper>
      <TextareaField
        name="message"
        label="Message"
        maxLength={500}
        rows={3}
        value={values.message}
      />
      <MessageAgentCheckboxWrapper>
        <FakeCheckbox
          checked={values.marketingAgreement}
          onClick={() => {
            setFieldValue("marketingAgreement", !values.marketingAgreement);
          }}
        >
          <CheckboxLabel>
            I agree to receive information and commercial offers from Commit
          </CheckboxLabel>
        </FakeCheckbox>
      </MessageAgentCheckboxWrapper>
      <MessageAgentBottomSectionWrapper>
        <MessageAgentButtonWrapper>
          {error && <MessageAgentError>{error}</MessageAgentError>}
          <Button
            isLoading={isSubmitting}
            type="submit"
            size={isMobileDown ? "block" : "medium"}
            onClick={submitForm}
          >
            Send Message
          </Button>
        </MessageAgentButtonWrapper>
        <MessageAgentNote>
          By submitting this form, you accept our{" "}
          <Link to={routesPaths.TERMS_AND_CONDITIONS} onClick={closeModal}>
            terms & conditions
          </Link>{" "}
          and{" "}
          <Link to={routesPaths.PRIVACY_POLICY} onClick={closeModal}>
            privacy policy
          </Link>
          . The submitted details will be shared with the estate agent.
        </MessageAgentNote>
      </MessageAgentBottomSectionWrapper>
    </MessageAgentFormWrapper>
  );
};

export default MessageAgentModalFormContent;
