import React, { FunctionComponent, useCallback } from "react";
import {
  getAreaHealthValue,
  getLevelTypeValue,
} from "../../../common/helpers/enumNamesHelper";
import { AreaHealthType, LevelType } from "../../../generated/schema";
import RadioButton from "../../shared/components/RadioButotn/RadioButton";
import CollapsableSection from "./CollapsableSection";
import {
  FiltersCommitContainer,
  FiltersSingleCommitFilter,
  FiltersSingleCommitFilterHeader,
  FiltersSingleCommitRadio,
  FiltersSingleCommitRadioContainer,
} from "./Filters.components";

const LevelTypeOptions = [
  { value: LevelType.Low, name: getLevelTypeValue(LevelType.Low) },
  { value: LevelType.Medium, name: getLevelTypeValue(LevelType.Medium) },
  { value: LevelType.High, name: getLevelTypeValue(LevelType.High) },
];

const AreaHealthOptions = [
  {
    value: AreaHealthType.Healthy,
    name: getAreaHealthValue(AreaHealthType.Healthy),
  },
  {
    value: AreaHealthType.Average,
    name: getAreaHealthValue(AreaHealthType.Average),
  },
  {
    value: AreaHealthType.BelowAverage,
    name: getAreaHealthValue(AreaHealthType.BelowAverage),
  },
];

export type CommitFilters = {
  crimeRate?: LevelType;
  populationDensity?: LevelType;
  studentPopulation?: LevelType;
  areaHealth?: AreaHealthType;
  shoplifting?: LevelType;
};

type CommitIntelligentFiltersProps = {
  commitIntelligentFilters: CommitFilters;
  onChange: (filters: CommitFilters) => void;
};

const SingleFilter = (
  name: string,
  options: string[],
  selection: number,
  onChange: (index?: number) => void,
) => (
  <FiltersSingleCommitFilter>
    <FiltersSingleCommitFilterHeader>{name}</FiltersSingleCommitFilterHeader>
    <FiltersSingleCommitRadioContainer>
      {options.map((option, index) => (
        <FiltersSingleCommitRadio key={option}>
          <RadioButton
            size="s"
            checked={index === selection}
            color="normal"
            value={option}
            onCheck={() =>
              index === selection ? onChange(undefined) : onChange(index)
            }
          />
        </FiltersSingleCommitRadio>
      ))}
    </FiltersSingleCommitRadioContainer>
  </FiltersSingleCommitFilter>
);

const CommitIntelligentFilters: FunctionComponent<
  CommitIntelligentFiltersProps
> = ({ commitIntelligentFilters, onChange }) => {
  const changeFilters = useCallback(
    (change: CommitFilters) => {
      onChange({ ...commitIntelligentFilters, ...change });
    },
    [commitIntelligentFilters, onChange],
  );

  return (
    <CollapsableSection title="Commit Intelligent Filters" isExpandable={false}>
      <FiltersCommitContainer>
        {SingleFilter(
          "Crime Rate",
          LevelTypeOptions.map((o) => o.name),
          LevelTypeOptions.findIndex(
            (o) => o.value === commitIntelligentFilters.crimeRate,
          ),
          (index) => {
            const option =
              index !== undefined ? LevelTypeOptions[index] : undefined;
            changeFilters({ crimeRate: option?.value });
          },
        )}

        {SingleFilter(
          "Population Density",
          LevelTypeOptions.map((o) => o.name),
          LevelTypeOptions.findIndex(
            (o) => o.value === commitIntelligentFilters.populationDensity,
          ),
          (index) => {
            const option =
              index !== undefined ? LevelTypeOptions[index] : undefined;
            changeFilters({ populationDensity: option?.value });
          },
        )}

        {SingleFilter(
          "Student Population",
          LevelTypeOptions.map((o) => o.name),
          LevelTypeOptions.findIndex(
            (o) => o.value === commitIntelligentFilters.studentPopulation,
          ),
          (index) => {
            const option =
              index !== undefined ? LevelTypeOptions[index] : undefined;
            changeFilters({ studentPopulation: option?.value });
          },
        )}

        {SingleFilter(
          "Population Health",
          AreaHealthOptions.map((o) => o.name),
          AreaHealthOptions.findIndex(
            (o) => o.value === commitIntelligentFilters.areaHealth,
          ),
          (index) => {
            // TODO: Move option variable to changeFilters callback.
            // Move if all filters will be this same type (area health is now different).
            const option =
              index !== undefined ? AreaHealthOptions[index] : undefined;
            changeFilters({ areaHealth: option?.value });
          },
        )}

        {SingleFilter(
          "Shoplifting Rate",
          LevelTypeOptions.map((o) => o.name),
          LevelTypeOptions.findIndex(
            (o) => o.value === commitIntelligentFilters.shoplifting,
          ),
          (index) => {
            const option =
              index !== undefined ? LevelTypeOptions[index] : undefined;
            changeFilters({ shoplifting: option?.value });
          },
        )}
      </FiltersCommitContainer>
    </CollapsableSection>
  );
};

export default CommitIntelligentFilters;
