import client from "..";
import {
  AuthToken,
  authTokenFromResponse,
  deleteAuthToken,
} from "../../../common/helpers/authToken";
import {
  RefreshTokenDocument,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
} from "../../../generated/schema";

const refreshToken = async (): Promise<AuthToken | null> => {
  let result: AuthToken | null = null;

  try {
    const mutationPromise = client.mutate<
      RefreshTokenMutation,
      RefreshTokenMutationVariables
    >({
      mutation: RefreshTokenDocument,
      context: {
        skipAutoTokenRefresh: true,
      },
    });
    const { data } = await mutationPromise;

    switch (data?.refreshToken.__typename) {
      case "TokenSuccessfulResponse":
        result = authTokenFromResponse(data.refreshToken);
        break;
      case "TokenInvalidResponse":
      case "TokenUserInactiveResponse":
        deleteAuthToken();
        break;
    }
  } catch (e) {
    console.error(e);
    console.error("Unable to refresh token", e);
  }
  return result;
};

export default refreshToken;
