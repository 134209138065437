import styled, { css } from "styled-components";
import { colors } from "../../colors";
import {
  ButtonSmallStyle,
  H6Style,
  LeadSmallStyle,
  SmallStyle,
} from "../../mixins";

type RadioColor = "normal" | "dark";

export const Radio = styled.div<{ checked: boolean; disabled: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  margin-right: 8px;
  box-sizing: border-box;
  transition: border 100ms ease-in-out;
  border: ${(p) => (p.checked ? 4 : 2)}px solid
    ${(p) => (p.checked ? colors.secondary.pure_blue : colors.grayscale._400)};

  ${(p) =>
    p.disabled &&
    p.checked &&
    css`
      outline: 3px solid ${colors.grayscale._200};
      outline-offset: -5px;
      border: none;
      background-color: ${colors.grayscale._400};
    `}

  ${(p) =>
    p.disabled &&
    !p.checked &&
    css`
      border: 2px solid ${colors.grayscale._400};
      background-color: ${colors.grayscale._200};
    `}
`;

export type RadioButtonSize = "s" | "m" | "l";

const getRadioHeight = (size: RadioButtonSize) => {
  switch (size) {
    case "s":
      return 32;
    case "m":
      return 40;
    case "l":
      return 48;
  }
};

export const Container = styled.div<{
  checked: boolean;
  disabled: boolean;
  color: RadioColor;
  size: RadioButtonSize;
}>`
  height: ${(p) => getRadioHeight(p.size)}px;
  display: flex;
  justify-content: center;
  background-color: ${colors.primary.pure_white};
  align-items: center;
  border: 1px solid
    ${(p) => (p.checked ? colors.secondary.pure_blue : colors.grayscale._400)};
  border-radius: 8px;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  transition: transform 250ms ease-in-out;
  box-sizing: border-box;
  white-space: nowrap;
  ${(p) =>
    p.color === "dark" &&
    css`
      background-color: ${p.checked
        ? colors.primary.pure_white
        : colors.primary.pure_white_transparent};
      border: none;
    `}

  ${(p) =>
    !p.disabled &&
    css`
      :hover {
        transform: scale(1.05);
      }
    `}
`;

export const NameSpan = styled.span<{
  checked: boolean;
  color: RadioColor;
  size: RadioButtonSize;
}>`
  ${(p) =>
    p.color === "dark"
      ? H6Style
      : p.checked
      ? ButtonSmallStyle
      : LeadSmallStyle};

  ${(p) => p.size === "s" && SmallStyle}

  ${(p) =>
    p.color === "normal" &&
    css`
      color: ${p.checked
        ? colors.primary.frosted_black
        : colors.grayscale._600};
    `}

  ${(p) =>
    p.color === "dark" &&
    css`
      color: ${p.checked
        ? colors.primary.frosted_black
        : colors.primary.pure_white};
    `}
`;
