import React, { FunctionComponent } from "react";
import routesPaths from "../Routing/routesPaths";
import AuthContainer from "../shared/components/AuthContainer/AuthContainer";
import Button from "../shared/components/Button";
import {
  ButtonContainer,
  ButtonLoginContainer,
  CommitLogo,
  Description,
  FormContainer,
  FormContent,
  Header,
  LoginLink,
  LoginSpan,
} from "./Register.components";
import CommigLogoBlue from "../shared/images/commit_logo_blue.svg";
import { useNavigate } from "react-router";

const TokenExpired: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <AuthContainer
      title={
        <>
          Enhance your
          <br />
          deal flow
        </>
      }
      description="Once you have registered, you will be assigned an account manager who will give you access to helpful tooling to post properties via feed/API/CSV upload."
    >
      <FormContainer>
        <FormContent>
          <CommitLogo src={CommigLogoBlue} alt="Commit logo" />
          <Header>Link expired</Header>
          <Description>
            You waited too long! The link to activateyour account is no longer
            valid. But don't worry, you can start theprocess again.
          </Description>

          <ButtonLoginContainer>
            <ButtonContainer>
              <Button
                type="button"
                size="block"
                onClick={() => navigate(routesPaths.REGISTER)}
              >
                Create an account
              </Button>
            </ButtonContainer>
            <LoginSpan>
              Already have an account?{" "}
              <LoginLink to={routesPaths.LOGIN}>Login</LoginLink>
            </LoginSpan>
          </ButtonLoginContainer>
        </FormContent>
      </FormContainer>
    </AuthContainer>
  );
};

export default TokenExpired;
