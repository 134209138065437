import { Link } from "react-router-dom";
import { TransitionStatus } from "react-transition-group";
import styled, { css } from "styled-components";
import { colors } from "../../../../colors";
import { down } from "../../../../media";
import {
  ButtonSmallStyle,
  LeadMediumStyle,
  LeadSmallStyle,
} from "../../../../mixins";

const Container = () => css`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  justify-content: center;
`;

export const ContainerLink = styled(Link)`
  ${Container};
`;

export const ContainerA = styled.a`
  ${Container};
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 250ms ease-in-out;

  :hover {
    transform: scale(1.05);
  }
`;

export const OptionSpan = styled.span<{ active: boolean }>`
  ${(props) => (props.active ? ButtonSmallStyle : LeadSmallStyle)};
  font-weight: ${(p) => (p.active ? 600 : 400)};
  color: ${(p) =>
    p.active ? colors.primary.frosted_black : colors.grayscale._600};
  padding: 0px 16px;
  min-width: fit-content;
  position: absolute;
`;

export const OptionSpanBold = styled.span<{ active: boolean }>`
  ${ButtonSmallStyle};
  font-weight: 600;
  padding: 0px 16px;
  visibility: hidden;
  min-width: fit-content;
`;

export const ActiveIndicator = styled.div<{ selected: boolean }>`
  position: absolute;
  width: 64px;
  height: 2px;
  background-color: ${colors.secondary.pure_blue};
  bottom: 0px;
  transition: transform 250ms ease-in-out;
  transform: scaleX(${(p) => (p.selected ? 1 : 0)});
`;

export const MenuContainer = styled.div<{ status: TransitionStatus }>`
  width: 200px;
  right: 0px;
  position: absolute;
  transition: opacity 250ms ease-in-out;
  opacity: ${(p) => (["entering", "entered"].includes(p.status) ? 1 : 0)};
  margin: 4px 12px;
  background-color: white;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 4px 4px -2px rgba(59, 122, 247, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MenuItemImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

export const MenuItemsDivider = styled.div`
  width: calc(100% - 24px);
  height: 1px;
  background-color: ${colors.secondary.pure_blue_transparent};

  ${down("tablet")} {
    margin-top: 16px;
    width: calc(100% - 80px);
  }
`;

export const MenuItem = styled.button<{ selected?: boolean }>`
  ${LeadSmallStyle};
  ${down("tablet")} {
    ${LeadMediumStyle};
  }
  display: flex;
  width: 176px;
  height: 40px;
  margin: 8px;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  color: ${(p) =>
    p.selected ? colors.primary.frosted_black : colors.grayscale._600};
  font-weight: ${(p) => (p.selected ? 600 : 400)};

  :hover {
    background-color: ${colors.secondary.pure_blue_transparent};
    font-weight: 600;
    ${down("tablet")} {
      font-weight: 400;
    }
  }

  > svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;

    > path {
      stroke: ${(p) =>
        p.selected ? colors.secondary.pure_blue : colors.grayscale._500};
    }
  }
`;

export const MenuOptionContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const MenuOptionContent = styled.div`
  height: 100%;
`;
