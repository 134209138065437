import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
  /** Geo point custom scalar type */
  Point: any;
};

export enum AccountType {
  Admin = "ADMIN",
  EstateAgent = "ESTATE_AGENT",
}

export type AddPropertyApiErrorResponse = {
  __typename?: "AddPropertyApiErrorResponse";
  message: Scalars["String"];
};

export type AddPropertyInput = {
  address: Scalars["String"];
  amenitiesType: Array<AmenitiesType>;
  billingType?: InputMaybe<BillingType>;
  brochuresDocuments: Array<Scalars["String"]>;
  businessRate?: InputMaybe<Scalars["Float"]>;
  conditionType: ConditionType;
  contactBio?: InputMaybe<Scalars["String"]>;
  contactEmail?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  currentEpcType: EpcType;
  existingBuildoutType: ExistingBuildoutType;
  flat?: InputMaybe<Scalars["String"]>;
  floorPlans: Array<Scalars["String"]>;
  floors: Scalars["Float"];
  isPriceNegotiable?: InputMaybe<Scalars["Boolean"]>;
  isRentalNegotiable?: InputMaybe<Scalars["Boolean"]>;
  listingType: ListingType;
  location: Scalars["Point"];
  locationType: LocationType;
  netIncome?: InputMaybe<Scalars["Float"]>;
  parkingDetails?: InputMaybe<Scalars["String"]>;
  parkingSpaces: Scalars["Float"];
  postcode: Scalars["String"];
  potencialEpcType?: InputMaybe<EpcType>;
  price?: InputMaybe<Scalars["Float"]>;
  propertyDescriptions: Scalars["String"];
  propertyPhotos: Array<Scalars["String"]>;
  propertyUseType: Array<PropertyUseType>;
  rentalTo?: InputMaybe<Scalars["DateTime"]>;
  sqFootage: Scalars["Float"];
  tenancyType: TenancyType;
  uid: Scalars["String"];
  useClassType: UseClassType;
  yearBuilt?: InputMaybe<Scalars["Float"]>;
  yearLastRenovated?: InputMaybe<Scalars["Float"]>;
};

export type AddPropertyResponse =
  | AddPropertyApiErrorResponse
  | AddPropertySuccessfulResponse;

export type AddPropertySuccessfulResponse = {
  __typename?: "AddPropertySuccessfulResponse";
  message: Scalars["String"];
};

export type AddSeoLinkInput = {
  description: Scalars["String"];
  link: Scalars["String"];
  title: Scalars["String"];
  urlSlug: Scalars["String"];
};

export type AddSeoLinkInvalidResponse = {
  __typename?: "AddSeoLinkInvalidResponse";
  message: Scalars["String"];
};

export type AddSeoLinkResponse =
  | AddSeoLinkInvalidResponse
  | AddSeoLinkSuccessfulResponse;

export type AddSeoLinkSuccessfulResponse = {
  __typename?: "AddSeoLinkSuccessfulResponse";
  message: Scalars["String"];
};

export enum AmenitiesType {
  AccentLighting = "ACCENT_LIGHTING",
  AccessibilityAccess = "ACCESSIBILITY_ACCESS",
  AirConditioning = "AIR_CONDITIONING",
  Atrium = "ATRIUM",
  Balcony = "BALCONY",
  Basement = "BASEMENT",
  BicycleStorage = "BICYCLE_STORAGE",
  Cctv = "CCTV",
  CentralHeating = "CENTRAL_HEATING",
  ConferenceRooms = "CONFERENCE_ROOMS",
  CornerSpace = "CORNER_SPACE",
  DdaCompliant = "DDA_COMPLIANT",
  EmergencyLighting = "EMERGENCY_LIGHTING",
  ExposedCeiling = "EXPOSED_CEILING",
  FoodService = "FOOD_SERVICE",
  FullyCarpeted = "FULLY_CARPETED",
  HighCeilings = "HIGH_CEILINGS",
  Kitchen = "KITCHEN",
  Laboratory = "LABORATORY",
  LiftAccess = "LIFT_ACCESS",
  LoungeArea = "LOUNGE_AREA",
  NaturalLight = "NATURAL_LIGHT",
  OpenPlan = "OPEN_PLAN",
  PartitionedOffices = "PARTITIONED_OFFICES",
  PrintCopyRoom = "PRINT_COPY_ROOM",
  PrivateRestrooms = "PRIVATE_RESTROOMS",
  RaisedFloor = "RAISED_FLOOR",
  Reception = "RECEPTION",
  RecessedLighting = "RECESSED_LIGHTING",
  RollerShutters = "ROLLER_SHUTTERS",
  SecureStorage = "SECURE_STORAGE",
  ShowerFacilities = "SHOWER_FACILITIES",
  SuspendedCeilings = "SUSPENDED_CEILINGS",
  TradingFloor = "TRADING_FLOOR",
  WcFacilities = "WC_FACILITIES",
  WiFi = "WI_FI",
  WoodenFloors = "WOODEN_FLOORS",
}

export enum AreaHealthType {
  Average = "AVERAGE",
  BelowAverage = "BELOW_AVERAGE",
  Excellent = "EXCELLENT",
  Healthy = "HEALTHY",
}

export enum AreaProsperityType {
  Average = "AVERAGE",
  BelowAverage = "BELOW_AVERAGE",
  Wealthy = "WEALTHY",
}

export enum BillingType {
  Monthly = "MONTHLY",
  OnRequest = "ON_REQUEST",
  Yearly = "YEARLY",
}

export type CategorizedFile = {
  category: UploadCategory;
  fileName: Scalars["String"];
};

export type ClaimAccountResponse =
  | ClaimAccountSuccessfulResponse
  | ClaimAccountTokenExpiredResponse
  | ClaimAccountTokenNotFoundResponse;

export type ClaimAccountSuccessfulResponse = {
  __typename?: "ClaimAccountSuccessfulResponse";
  message: Scalars["String"];
};

export type ClaimAccountTokenExpiredResponse = {
  __typename?: "ClaimAccountTokenExpiredResponse";
  message: Scalars["String"];
};

export type ClaimAccountTokenNotFoundResponse = {
  __typename?: "ClaimAccountTokenNotFoundResponse";
  message: Scalars["String"];
};

export enum ConditionType {
  Average = "AVERAGE",
  Demolished = "DEMOLISHED",
  Excellent = "EXCELLENT",
  Good = "GOOD",
  NeedsRenovation = "NEEDS_RENOVATION",
  PartialDemolished = "PARTIAL_DEMOLISHED",
  Unknown = "UNKNOWN",
}

export type EditPropertyInvalidResponse = {
  __typename?: "EditPropertyInvalidResponse";
  message: Scalars["String"];
};

export type EditPropertyMissingPermissionResponse = {
  __typename?: "EditPropertyMissingPermissionResponse";
  message: Scalars["String"];
};

export type EditPropertyStatusResponse =
  | EditPropertyInvalidResponse
  | EditPropertyMissingPermissionResponse
  | EditPropertySuccessfulResponse;

export type EditPropertySuccessfulResponse = {
  __typename?: "EditPropertySuccessfulResponse";
  message: Scalars["String"];
};

export enum EpcType {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  Pending = "PENDING",
  Unknown = "UNKNOWN",
}

export enum ExistingBuildoutType {
  FullBuildOut = "FULL_BUILD_OUT",
  NotKnown = "NOT_KNOWN",
  PartialBuildOut = "PARTIAL_BUILD_OUT",
  ShellSpace = "SHELL_SPACE",
  SpecSuite = "SPEC_SUITE",
}

export enum ExternalPropertyProviderType {
  NotAssigned = "NOT_ASSIGNED",
}

export enum FilterType {
  AreaHealth = "AREA_HEALTH",
  CrimeRate = "CRIME_RATE",
  ListingType = "LISTING_TYPE",
  PopulationDensity = "POPULATION_DENSITY",
  Price = "PRICE",
  PropertyUseType = "PROPERTY_USE_TYPE",
  Shoplifting = "SHOPLIFTING",
  SqFootage = "SQ_FOOTAGE",
  StudentPopulation = "STUDENT_POPULATION",
}

export type GeoPoint = {
  coordinates: Array<Scalars["Float"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type GetPropertyInvalidPermissionsResponse = {
  __typename?: "GetPropertyInvalidPermissionsResponse";
  message: Scalars["String"];
};

export type GetPropertyInvalidPropertyStatusResponse = {
  __typename?: "GetPropertyInvalidPropertyStatusResponse";
  message: Scalars["String"];
};

export type GetPropertyNotFoundResponse = {
  __typename?: "GetPropertyNotFoundResponse";
  message: Scalars["String"];
};

export type GetPropertyResponse =
  | GetPropertyInvalidPermissionsResponse
  | GetPropertyInvalidPropertyStatusResponse
  | GetPropertyNotFoundResponse
  | GetPropertySuccessfulResponse;

export type GetPropertySuccessfulResponse = {
  __typename?: "GetPropertySuccessfulResponse";
  property: Property;
};

export type GetSignedUploadUrlsInput = {
  files: Array<CategorizedFile>;
  uid: Scalars["String"];
};

export type GetSignedUploadUrlsResponse = {
  __typename?: "GetSignedUploadUrlsResponse";
  publicUrls: PublicUrls;
  uploadUrls: Array<SignedUploadUrl>;
};

export type GetUserPropertiesInput = {
  count?: InputMaybe<Scalars["Float"]>;
  offset?: InputMaybe<Scalars["Float"]>;
  status: Scalars["String"];
};

export enum GrowthIndexType {
  Decrease = "DECREASE",
  Increase = "INCREASE",
}

export enum LevelType {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
}

export enum ListingType {
  ForRent = "FOR_RENT",
  ForSale = "FOR_SALE",
}

export enum LocationType {
  CommercialStreet = "COMMERCIAL_STREET",
  DepartmentStore = "DEPARTMENT_STORE",
  FoodCourt = "FOOD_COURT",
  HighStreet = "HIGH_STREET",
  Industrial = "INDUSTRIAL",
  Offices = "OFFICES",
  Residential = "RESIDENTIAL",
  Rural = "RURAL",
  Urbar = "URBAR",
}

export type LoginInput = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MessageAgentInput = {
  email: Scalars["String"];
  marketingAgreement: Scalars["Boolean"];
  message: Scalars["String"];
  name: Scalars["String"];
  propertyUid: Scalars["String"];
};

export type MessageAgentInvalidResponse = {
  __typename?: "MessageAgentInvalidResponse";
  message: Scalars["String"];
};

export type MessageAgentResponse =
  | MessageAgentInvalidResponse
  | MessageAgentSuccessfulResponse;

export type MessageAgentSuccessfulResponse = {
  __typename?: "MessageAgentSuccessfulResponse";
  message: Scalars["String"];
};

export type MetadataResponse = {
  __typename?: "MetadataResponse";
  description: Scalars["String"];
  image: Scalars["String"];
  title: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  addProperty: AddPropertyResponse;
  addSeoLink: AddSeoLinkResponse;
  changePassword: NewPasswordResponse;
  claimAccount: ClaimAccountResponse;
  deleteProperty: SetPropertyStatusResponse;
  editProperty: EditPropertyStatusResponse;
  login: TokenResponse;
  messageAgent?: Maybe<MessageAgentResponse>;
  refreshToken: TokenResponse;
  register: RegisterResponse;
  removeSeoLink: RemoveSeoLinkResponse;
  requestPasswordReset: ResetPasswordRequestResponse;
  setPropertyStatus: SetPropertyStatusResponse;
  updateUser: UpdateUserResponse;
};

export type MutationAddPropertyArgs = {
  input: AddPropertyInput;
};

export type MutationAddSeoLinkArgs = {
  input: AddSeoLinkInput;
};

export type MutationChangePasswordArgs = {
  input: NewPasswordInput;
};

export type MutationClaimAccountArgs = {
  token: Scalars["String"];
};

export type MutationDeletePropertyArgs = {
  uid: Scalars["String"];
};

export type MutationEditPropertyArgs = {
  input: AddPropertyInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationMessageAgentArgs = {
  input: MessageAgentInput;
};

export type MutationRegisterArgs = {
  input: RegisterInput;
};

export type MutationRemoveSeoLinkArgs = {
  id: Scalars["Float"];
};

export type MutationRequestPasswordResetArgs = {
  input: ResetPasswordRequestInput;
};

export type MutationSetPropertyStatusArgs = {
  id: Scalars["Float"];
  status: Scalars["String"];
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type NewPasswordInput = {
  newPassword: Scalars["String"];
  passwordResetToken: Scalars["String"];
};

export type NewPasswordInvalidResponse = {
  __typename?: "NewPasswordInvalidResponse";
  message: Scalars["String"];
};

export type NewPasswordResponse =
  | NewPasswordInvalidResponse
  | NewPasswordSuccessfulResponse
  | NewPasswordTokenExpiredResponse;

export type NewPasswordSuccessfulResponse = {
  __typename?: "NewPasswordSuccessfulResponse";
  message: Scalars["String"];
};

export type NewPasswordTokenExpiredResponse = {
  __typename?: "NewPasswordTokenExpiredResponse";
  message: Scalars["String"];
};

export type PaginatedPropertyResponse = {
  __typename?: "PaginatedPropertyResponse";
  data: Array<Property>;
  extended: Scalars["Boolean"];
  itemsCount: Scalars["Int"];
};

export type Property = {
  __typename?: "Property";
  address: Scalars["String"];
  amenitiesType: Array<AmenitiesType>;
  billingType?: Maybe<BillingType>;
  brochuresDocuments?: Maybe<Array<Scalars["String"]>>;
  businessRate?: Maybe<Scalars["Float"]>;
  conditionType: ConditionType;
  contactBio: Scalars["String"];
  contactEmail: Scalars["String"];
  contactName: Scalars["String"];
  createdAt: Scalars["DateTime"];
  currentEpcType: EpcType;
  existingBuildoutType: ExistingBuildoutType;
  externalPropertyUrl?: Maybe<Scalars["String"]>;
  externalProvider?: Maybe<ExternalPropertyProviderType>;
  flat?: Maybe<Scalars["String"]>;
  floorPlans?: Maybe<Array<Scalars["String"]>>;
  floors: Scalars["Float"];
  id: Scalars["Int"];
  isExternal?: Maybe<Scalars["Boolean"]>;
  isPriceNegotiable?: Maybe<Scalars["Boolean"]>;
  isRentalNegotiable?: Maybe<Scalars["Boolean"]>;
  listingType: ListingType;
  location: Scalars["Point"];
  locationType: LocationType;
  netIncome?: Maybe<Scalars["Float"]>;
  parkingDetails?: Maybe<Scalars["String"]>;
  parkingSpaces: Scalars["Float"];
  postcode: Scalars["String"];
  potencialEpcType?: Maybe<EpcType>;
  price?: Maybe<Scalars["Float"]>;
  propertyDescriptions: Scalars["String"];
  propertyInsight: PropertyInsight;
  propertyInsightId: PropertyInsight;
  propertyPhotos: Array<Scalars["String"]>;
  propertyUseType: Array<PropertyUseType>;
  rentalTo?: Maybe<Scalars["DateTime"]>;
  slug: Scalars["String"];
  sqFootage: Scalars["Float"];
  status: PropertyStatus;
  tenancyType: TenancyType;
  uid: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  useClassType: UseClassType;
  user?: Maybe<User>;
  userId?: Maybe<User>;
  yearBuilt?: Maybe<Scalars["Float"]>;
  yearLastRenovated?: Maybe<Scalars["Float"]>;
};

export type PropertyFilter = {
  eq?: InputMaybe<Scalars["String"]>;
  ge?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  le?: InputMaybe<Scalars["Float"]>;
  lt?: InputMaybe<Scalars["Float"]>;
  ne?: InputMaybe<Scalars["String"]>;
  type: FilterType;
};

export type PropertyInsight = {
  __typename?: "PropertyInsight";
  areaHealth: AreaHealthType;
  areaProsperity: AreaProsperityType;
  areaProsperityIndex: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  crimeIndex: Scalars["Float"];
  crimeRateLevel: LevelType;
  crimeTrend: GrowthIndexType;
  id: Scalars["Int"];
  popDensity: LevelType;
  postcode: Scalars["String"];
  price1YearIndex: Scalars["Float"];
  price1YearTrend: GrowthIndexType;
  price5YearIndex: Scalars["Float"];
  price5YearTrend: GrowthIndexType;
  price10YearIndex: Scalars["Float"];
  price10YearTrend: GrowthIndexType;
  shopliftingLevel: LevelType;
  studentPopLevel: LevelType;
  updatedAt: Scalars["DateTime"];
};

export enum PropertySortKeys {
  CreatedAt = "CREATED_AT",
  Price = "PRICE",
  UpdateAt = "UPDATE_AT",
}

export type PropertySortType = {
  direction?: InputMaybe<SortDirection>;
  key?: InputMaybe<PropertySortKeys>;
};

export enum PropertyStatus {
  Active = "ACTIVE",
  Declined = "DECLINED",
  Deleted = "DELETED",
  Pending = "PENDING",
}

export enum PropertyUseType {
  Bank = "BANK",
  Bar = "BAR",
  Beauty = "BEAUTY",
  Cafe = "CAFE",
  CallCentre = "CALL_CENTRE",
  Casino = "CASINO",
  Cinema = "CINEMA",
  DepartmentStore = "DEPARTMENT_STORE",
  EventVenue = "EVENT_VENUE",
  FinancialServices = "FINANCIAL_SERVICES",
  GeneralRetail = "GENERAL_RETAIL",
  GhostKitchen = "GHOST_KITCHEN",
  GovernmentFacility = "GOVERNMENT_FACILITY",
  Gym = "GYM",
  Hotel = "HOTEL",
  Industrial = "INDUSTRIAL",
  LawFirm = "LAW_FIRM",
  OfficeCoWorking = "OFFICE_CO_WORKING",
  OfficeManaged = "OFFICE_MANAGED",
  OfficeShared = "OFFICE_SHARED",
  Other = "OTHER",
  PrivateOffice = "PRIVATE_OFFICE",
  ProfessionalServices = "PROFESSIONAL_SERVICES",
  Pub = "PUB",
  Religious = "RELIGIOUS",
  ResearchAndDevelopment = "RESEARCH_AND_DEVELOPMENT",
  Restaurant = "RESTAURANT",
  ShoppingCentreOther = "SHOPPING_CENTRE_OTHER",
  ShoppingCentreRestaurant = "SHOPPING_CENTRE_RESTAURANT",
  ShoppingCentreRetail = "SHOPPING_CENTRE_RETAIL",
  StorageWarehouse = "STORAGE_WAREHOUSE",
  Supermarket = "SUPERMARKET",
  TakeAwayRestaurant = "TAKE_AWAY_RESTAURANT",
}

export type PublicUrls = {
  __typename?: "PublicUrls";
  documents?: Maybe<Array<Scalars["String"]>>;
  floorPlans?: Maybe<Array<Scalars["String"]>>;
  propertyImages?: Maybe<Array<Scalars["String"]>>;
};

export type Query = {
  __typename?: "Query";
  currentUser?: Maybe<User>;
  getAllUsersProperties: PaginatedPropertyResponse;
  getEditProperty: GetPropertyResponse;
  getPropertyByUid: Property;
  getPropertyPreviewByUid: GetPropertyResponse;
  getRouteMetadata: MetadataResponse;
  getSeoLinks: Array<SeoLinks>;
  getSignedUploadUrls: GetSignedUploadUrlsResponse;
  getUserProperties: PaginatedPropertyResponse;
  resendActivationLink: ResendLinkResponse;
  searchProperties: PaginatedPropertyResponse;
  validateResetPasswordToken: ValidateResetTokenResponse;
};

export type QueryGetAllUsersPropertiesArgs = {
  input: GetUserPropertiesInput;
};

export type QueryGetEditPropertyArgs = {
  uid: Scalars["String"];
};

export type QueryGetPropertyByUidArgs = {
  uid: Scalars["String"];
};

export type QueryGetPropertyPreviewByUidArgs = {
  uid: Scalars["String"];
};

export type QueryGetRouteMetadataArgs = {
  url: Scalars["String"];
};

export type QueryGetSignedUploadUrlsArgs = {
  input: GetSignedUploadUrlsInput;
};

export type QueryGetUserPropertiesArgs = {
  input: GetUserPropertiesInput;
};

export type QueryResendActivationLinkArgs = {
  email: Scalars["String"];
};

export type QuerySearchPropertiesArgs = {
  input: SearchPropertyInput;
};

export type QueryValidateResetPasswordTokenArgs = {
  token: Scalars["String"];
};

export type RegisterInput = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type RegisterInvalidResponse = {
  __typename?: "RegisterInvalidResponse";
  message: Scalars["String"];
};

export type RegisterResponse =
  | RegisterInvalidResponse
  | RegisterSuccessfulResponse
  | RegisterTokenExpiredResponse;

export type RegisterSuccessfulResponse = {
  __typename?: "RegisterSuccessfulResponse";
  message: Scalars["String"];
};

export type RegisterTokenExpiredResponse = {
  __typename?: "RegisterTokenExpiredResponse";
  message: Scalars["String"];
};

export type RemoveSeoLinkInvalidResponse = {
  __typename?: "RemoveSeoLinkInvalidResponse";
  message: Scalars["String"];
};

export type RemoveSeoLinkResponse =
  | RemoveSeoLinkInvalidResponse
  | RemoveSeoLinkSuccessfulResponse;

export type RemoveSeoLinkSuccessfulResponse = {
  __typename?: "RemoveSeoLinkSuccessfulResponse";
  message: Scalars["String"];
};

export type ResendLinkInvalidResponse = {
  __typename?: "ResendLinkInvalidResponse";
  message: Scalars["String"];
};

export type ResendLinkResponse =
  | ResendLinkInvalidResponse
  | ResendLinkSuccesfullResponse;

export type ResendLinkSuccesfullResponse = {
  __typename?: "ResendLinkSuccesfullResponse";
  message: Scalars["String"];
};

export type ResetPasswordRequestInput = {
  email: Scalars["String"];
};

export type ResetPasswordRequestInvalidResponse = {
  __typename?: "ResetPasswordRequestInvalidResponse";
  message: Scalars["String"];
};

export type ResetPasswordRequestResponse =
  | ResetPasswordRequestInvalidResponse
  | ResetPasswordRequestSuccessfulResponse;

export type ResetPasswordRequestSuccessfulResponse = {
  __typename?: "ResetPasswordRequestSuccessfulResponse";
  message: Scalars["String"];
};

export type SearchPropertyInput = {
  count?: InputMaybe<Scalars["Float"]>;
  filters?: InputMaybe<Array<PropertyFilter>>;
  lat?: InputMaybe<Scalars["Float"]>;
  lng?: InputMaybe<Scalars["Float"]>;
  offset?: InputMaybe<Scalars["Float"]>;
  range?: InputMaybe<Scalars["Float"]>;
  sortBy?: InputMaybe<PropertySortType>;
};

export type SeoLinks = {
  __typename?: "SeoLinks";
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  id: Scalars["Int"];
  link: Scalars["String"];
  title: Scalars["String"];
  urlSlug: Scalars["String"];
};

export type SetPropertyStatusInvalidResponse = {
  __typename?: "SetPropertyStatusInvalidResponse";
  message: Scalars["String"];
};

export type SetPropertyStatusMissingPermissionResponse = {
  __typename?: "SetPropertyStatusMissingPermissionResponse";
  message: Scalars["String"];
};

export type SetPropertyStatusResponse =
  | SetPropertyStatusInvalidResponse
  | SetPropertyStatusMissingPermissionResponse
  | SetPropertyStatusSuccessfulResponse;

export type SetPropertyStatusSuccessfulResponse = {
  __typename?: "SetPropertyStatusSuccessfulResponse";
  message: Scalars["String"];
};

export type SignedUploadUrl = {
  __typename?: "SignedUploadUrl";
  fileName: Scalars["String"];
  uploadUrl: Scalars["String"];
};

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export enum TenancyType {
  Multiple = "MULTIPLE",
  Single = "SINGLE",
}

export type TokenInvalidResponse = {
  __typename?: "TokenInvalidResponse";
  message: Scalars["String"];
};

export type TokenResponse =
  | TokenInvalidResponse
  | TokenSuccessfulResponse
  | TokenUserInactiveResponse;

export type TokenSuccessfulResponse = {
  __typename?: "TokenSuccessfulResponse";
  accountType: Scalars["String"];
  expiresAt: Scalars["DateTime"];
  issuedAt: Scalars["DateTime"];
  token: Scalars["String"];
};

export type TokenUserInactiveResponse = {
  __typename?: "TokenUserInactiveResponse";
  message: Scalars["String"];
};

export type UpdateUserInput = {
  bio: Scalars["String"];
  companyName?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  phone: Scalars["String"];
};

export type UpdateUserInvalidResponse = {
  __typename?: "UpdateUserInvalidResponse";
  message: Scalars["String"];
};

export type UpdateUserResponse =
  | UpdateUserInvalidResponse
  | UpdateUserSuccessfulResponse;

export type UpdateUserSuccessfulResponse = {
  __typename?: "UpdateUserSuccessfulResponse";
  message: Scalars["String"];
};

export enum UploadCategory {
  Documents = "DOCUMENTS",
  FloorPlans = "FLOOR_PLANS",
  PropertyImages = "PROPERTY_IMAGES",
}

export enum UseClassType {
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  A4 = "A4",
  A5 = "A5",
  B1 = "B1",
  B2 = "B2",
  B8 = "B8",
  C1 = "C1",
  C2 = "C2",
  C2A = "C2A",
  C3 = "C3",
  Class_1 = "CLASS_1",
  Class_2 = "CLASS_2",
  Class_3 = "CLASS_3",
  Class_4 = "CLASS_4",
  Class_5 = "CLASS_5",
  Class_6 = "CLASS_6",
  Class_7 = "CLASS_7",
  Class_8 = "CLASS_8",
  Class_9 = "CLASS_9",
  Class_10 = "CLASS_10",
  Class_11 = "CLASS_11",
  D1 = "D1",
  D2 = "D2",
  E = "E",
  F_1 = "F_1",
  F_2 = "F_2",
  NotSpecified = "NOT_SPECIFIED",
  SuiGeneris = "SUI_GENERIS",
}

export type User = {
  __typename?: "User";
  accountType: AccountType;
  bio?: Maybe<Scalars["String"]>;
  companyName?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

export type ValidateResetTokenResponse =
  | ValidateResetTokenSuccessfulResponse
  | ValidateResetTokenTokenExpiredResponse
  | ValidateResetTokenTokenNotFoundResponse;

export type ValidateResetTokenSuccessfulResponse = {
  __typename?: "ValidateResetTokenSuccessfulResponse";
  message: Scalars["String"];
};

export type ValidateResetTokenTokenExpiredResponse = {
  __typename?: "ValidateResetTokenTokenExpiredResponse";
  message: Scalars["String"];
};

export type ValidateResetTokenTokenNotFoundResponse = {
  __typename?: "ValidateResetTokenTokenNotFoundResponse";
  message: Scalars["String"];
};

export type GetRouteMetadataQueryVariables = Exact<{
  url: Scalars["String"];
}>;

export type GetRouteMetadataQuery = {
  __typename?: "Query";
  getRouteMetadata: {
    __typename?: "MetadataResponse";
    title: string;
    description: string;
    image: string;
  };
};

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshTokenMutation = {
  __typename?: "Mutation";
  refreshToken:
    | { __typename: "TokenInvalidResponse"; message: string }
    | {
        __typename: "TokenSuccessfulResponse";
        token: string;
        issuedAt: string;
        expiresAt: string;
        accountType: string;
      }
    | { __typename: "TokenUserInactiveResponse"; message: string };
};

export type AddPropertyMutationVariables = Exact<{
  input: AddPropertyInput;
}>;

export type AddPropertyMutation = {
  __typename?: "Mutation";
  addProperty:
    | { __typename: "AddPropertyApiErrorResponse"; message: string }
    | { __typename: "AddPropertySuccessfulResponse"; message: string };
};

export type EditPropertyMutationVariables = Exact<{
  input: AddPropertyInput;
}>;

export type EditPropertyMutation = {
  __typename?: "Mutation";
  editProperty:
    | { __typename: "EditPropertyInvalidResponse" }
    | { __typename: "EditPropertyMissingPermissionResponse" }
    | { __typename: "EditPropertySuccessfulResponse" };
};

export type GetEditPropertyQueryVariables = Exact<{
  uid: Scalars["String"];
}>;

export type GetEditPropertyQuery = {
  __typename?: "Query";
  getEditProperty:
    | { __typename: "GetPropertyInvalidPermissionsResponse" }
    | { __typename: "GetPropertyInvalidPropertyStatusResponse" }
    | { __typename: "GetPropertyNotFoundResponse" }
    | {
        __typename: "GetPropertySuccessfulResponse";
        property: {
          __typename?: "Property";
          uid: string;
          address: string;
          location: any;
          flat?: string | null;
          postcode: string;
          listingType: ListingType;
          price?: number | null;
          netIncome?: number | null;
          billingType?: BillingType | null;
          tenancyType: TenancyType;
          rentalTo?: string | null;
          isRentalNegotiable?: boolean | null;
          isPriceNegotiable?: boolean | null;
          sqFootage: number;
          floors: number;
          propertyUseType: Array<PropertyUseType>;
          useClassType: UseClassType;
          conditionType: ConditionType;
          existingBuildoutType: ExistingBuildoutType;
          currentEpcType: EpcType;
          potencialEpcType?: EpcType | null;
          businessRate?: number | null;
          amenitiesType: Array<AmenitiesType>;
          yearBuilt?: number | null;
          yearLastRenovated?: number | null;
          parkingSpaces: number;
          parkingDetails?: string | null;
          propertyDescriptions: string;
          propertyPhotos: Array<string>;
          floorPlans?: Array<string> | null;
          brochuresDocuments?: Array<string> | null;
          contactEmail: string;
          contactName: string;
          contactBio: string;
          locationType: LocationType;
        };
      };
};

export type GetSignedUploadUrlsQueryVariables = Exact<{
  input: GetSignedUploadUrlsInput;
}>;

export type GetSignedUploadUrlsQuery = {
  __typename?: "Query";
  getSignedUploadUrls: {
    __typename?: "GetSignedUploadUrlsResponse";
    uploadUrls: Array<{
      __typename?: "SignedUploadUrl";
      fileName: string;
      uploadUrl: string;
    }>;
    publicUrls: {
      __typename?: "PublicUrls";
      propertyImages?: Array<string> | null;
      documents?: Array<string> | null;
      floorPlans?: Array<string> | null;
    };
  };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: number;
    email: string;
    name?: string | null;
    phone?: string | null;
    companyName?: string | null;
    bio?: string | null;
  } | null;
};

export type GetAllUsersPropertiesQueryVariables = Exact<{
  input: GetUserPropertiesInput;
}>;

export type GetAllUsersPropertiesQuery = {
  __typename?: "Query";
  getAllUsersProperties: {
    __typename?: "PaginatedPropertyResponse";
    itemsCount: number;
    data: Array<{
      __typename?: "Property";
      uid: string;
      id: number;
      slug: string;
      address: string;
      price?: number | null;
      updatedAt: string;
      useClassType: UseClassType;
      propertyUseType: Array<PropertyUseType>;
      propertyPhotos: Array<string>;
      sqFootage: number;
      status: PropertyStatus;
      isPriceNegotiable?: boolean | null;
      billingType?: BillingType | null;
      listingType: ListingType;
      contactName: string;
      isExternal?: boolean | null;
      propertyInsight: {
        __typename?: "PropertyInsight";
        crimeRateLevel: LevelType;
        studentPopLevel: LevelType;
        areaProsperity: AreaProsperityType;
      };
      user?: { __typename?: "User"; name?: string | null } | null;
    }>;
  };
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login:
    | { __typename: "TokenInvalidResponse"; message: string }
    | {
        __typename: "TokenSuccessfulResponse";
        token: string;
        issuedAt: string;
        expiresAt: string;
        accountType: string;
      }
    | { __typename: "TokenUserInactiveResponse"; message: string };
};

export type GetUserPropertiesQueryVariables = Exact<{
  input: GetUserPropertiesInput;
}>;

export type GetUserPropertiesQuery = {
  __typename?: "Query";
  getUserProperties: {
    __typename?: "PaginatedPropertyResponse";
    itemsCount: number;
    data: Array<{
      __typename?: "Property";
      uid: string;
      slug: string;
      address: string;
      price?: number | null;
      updatedAt: string;
      useClassType: UseClassType;
      propertyUseType: Array<PropertyUseType>;
      propertyPhotos: Array<string>;
      sqFootage: number;
      isPriceNegotiable?: boolean | null;
      status: PropertyStatus;
      billingType?: BillingType | null;
      listingType: ListingType;
      propertyInsight: {
        __typename?: "PropertyInsight";
        crimeRateLevel: LevelType;
        studentPopLevel: LevelType;
        areaProsperity: AreaProsperityType;
      };
    }>;
  };
};

export type DeletePropertyMutationVariables = Exact<{
  uid: Scalars["String"];
}>;

export type DeletePropertyMutation = {
  __typename?: "Mutation";
  deleteProperty:
    | { __typename: "SetPropertyStatusInvalidResponse" }
    | { __typename: "SetPropertyStatusMissingPermissionResponse" }
    | { __typename: "SetPropertyStatusSuccessfulResponse" };
};

export type SearchPropertiesQueryVariables = Exact<{
  input: SearchPropertyInput;
}>;

export type SearchPropertiesQuery = {
  __typename?: "Query";
  searchProperties: {
    __typename?: "PaginatedPropertyResponse";
    itemsCount: number;
    extended: boolean;
    data: Array<{
      __typename?: "Property";
      uid: string;
      slug: string;
      updatedAt: string;
      address: string;
      price?: number | null;
      isPriceNegotiable?: boolean | null;
      billingType?: BillingType | null;
      sqFootage: number;
      propertyUseType: Array<PropertyUseType>;
      propertyPhotos: Array<string>;
      listingType: ListingType;
      propertyInsight: {
        __typename?: "PropertyInsight";
        crimeRateLevel: LevelType;
        studentPopLevel: LevelType;
        areaProsperity: AreaProsperityType;
      };
    }>;
  };
};

export type GetPropertyPreviewByUidQueryVariables = Exact<{
  uid: Scalars["String"];
}>;

export type GetPropertyPreviewByUidQuery = {
  __typename?: "Query";
  getPropertyPreviewByUid:
    | { __typename?: "GetPropertyInvalidPermissionsResponse" }
    | { __typename?: "GetPropertyInvalidPropertyStatusResponse" }
    | { __typename?: "GetPropertyNotFoundResponse" }
    | {
        __typename?: "GetPropertySuccessfulResponse";
        property: {
          __typename?: "Property";
          id: number;
          uid: string;
          createdAt: string;
          status: PropertyStatus;
          location: any;
          address: string;
          flat?: string | null;
          postcode: string;
          locationType: LocationType;
          listingType: ListingType;
          price?: number | null;
          netIncome?: number | null;
          billingType?: BillingType | null;
          tenancyType: TenancyType;
          rentalTo?: string | null;
          isRentalNegotiable?: boolean | null;
          isPriceNegotiable?: boolean | null;
          sqFootage: number;
          floors: number;
          propertyUseType: Array<PropertyUseType>;
          useClassType: UseClassType;
          conditionType: ConditionType;
          existingBuildoutType: ExistingBuildoutType;
          currentEpcType: EpcType;
          potencialEpcType?: EpcType | null;
          businessRate?: number | null;
          amenitiesType: Array<AmenitiesType>;
          yearBuilt?: number | null;
          yearLastRenovated?: number | null;
          parkingSpaces: number;
          parkingDetails?: string | null;
          propertyDescriptions: string;
          propertyPhotos: Array<string>;
          floorPlans?: Array<string> | null;
          brochuresDocuments?: Array<string> | null;
          contactEmail: string;
          contactName: string;
          contactBio: string;
          user?: {
            __typename?: "User";
            name?: string | null;
            phone?: string | null;
            companyName?: string | null;
            bio?: string | null;
          } | null;
          propertyInsight: {
            __typename?: "PropertyInsight";
            crimeRateLevel: LevelType;
            crimeTrend: GrowthIndexType;
            crimeIndex: number;
            studentPopLevel: LevelType;
            areaHealth: AreaHealthType;
            shopliftingLevel: LevelType;
            popDensity: LevelType;
            areaProsperity: AreaProsperityType;
            areaProsperityIndex: number;
            price1YearTrend: GrowthIndexType;
            price1YearIndex: number;
            price5YearTrend: GrowthIndexType;
            price5YearIndex: number;
            price10YearTrend: GrowthIndexType;
            price10YearIndex: number;
          };
        };
      };
};

export type GetPropertyByUidQueryVariables = Exact<{
  uid: Scalars["String"];
}>;

export type GetPropertyByUidQuery = {
  __typename?: "Query";
  getPropertyByUid: {
    __typename?: "Property";
    id: number;
    uid: string;
    createdAt: string;
    status: PropertyStatus;
    location: any;
    address: string;
    flat?: string | null;
    postcode: string;
    locationType: LocationType;
    listingType: ListingType;
    price?: number | null;
    netIncome?: number | null;
    billingType?: BillingType | null;
    tenancyType: TenancyType;
    rentalTo?: string | null;
    isRentalNegotiable?: boolean | null;
    isPriceNegotiable?: boolean | null;
    sqFootage: number;
    floors: number;
    propertyUseType: Array<PropertyUseType>;
    useClassType: UseClassType;
    conditionType: ConditionType;
    existingBuildoutType: ExistingBuildoutType;
    currentEpcType: EpcType;
    potencialEpcType?: EpcType | null;
    businessRate?: number | null;
    amenitiesType: Array<AmenitiesType>;
    yearBuilt?: number | null;
    yearLastRenovated?: number | null;
    parkingSpaces: number;
    parkingDetails?: string | null;
    propertyDescriptions: string;
    propertyPhotos: Array<string>;
    floorPlans?: Array<string> | null;
    brochuresDocuments?: Array<string> | null;
    contactEmail: string;
    contactName: string;
    contactBio: string;
    isExternal?: boolean | null;
    externalPropertyUrl?: string | null;
    user?: {
      __typename?: "User";
      name?: string | null;
      phone?: string | null;
      companyName?: string | null;
      bio?: string | null;
    } | null;
    propertyInsight: {
      __typename?: "PropertyInsight";
      crimeRateLevel: LevelType;
      crimeTrend: GrowthIndexType;
      crimeIndex: number;
      studentPopLevel: LevelType;
      areaHealth: AreaHealthType;
      shopliftingLevel: LevelType;
      popDensity: LevelType;
      areaProsperity: AreaProsperityType;
      areaProsperityIndex: number;
      price1YearTrend: GrowthIndexType;
      price1YearIndex: number;
      price5YearTrend: GrowthIndexType;
      price5YearIndex: number;
      price10YearTrend: GrowthIndexType;
      price10YearIndex: number;
    };
  };
};

export type ClaimAccountMutationVariables = Exact<{
  token: Scalars["String"];
}>;

export type ClaimAccountMutation = {
  __typename?: "Mutation";
  claimAccount:
    | { __typename: "ClaimAccountSuccessfulResponse"; message: string }
    | { __typename: "ClaimAccountTokenExpiredResponse"; message: string }
    | { __typename: "ClaimAccountTokenNotFoundResponse"; message: string };
};

export type RegisterMutationVariables = Exact<{
  input: RegisterInput;
}>;

export type RegisterMutation = {
  __typename?: "Mutation";
  register:
    | { __typename: "RegisterInvalidResponse"; message: string }
    | { __typename: "RegisterSuccessfulResponse"; message: string }
    | { __typename: "RegisterTokenExpiredResponse"; message: string };
};

export type ResendActivationLinkQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type ResendActivationLinkQuery = {
  __typename?: "Query";
  resendActivationLink:
    | { __typename: "ResendLinkInvalidResponse" }
    | { __typename: "ResendLinkSuccesfullResponse" };
};

export type RequestPasswordResetMutationVariables = Exact<{
  input: ResetPasswordRequestInput;
}>;

export type RequestPasswordResetMutation = {
  __typename?: "Mutation";
  requestPasswordReset:
    | { __typename: "ResetPasswordRequestInvalidResponse"; message: string }
    | { __typename: "ResetPasswordRequestSuccessfulResponse"; message: string };
};

export type ChangePasswordMutationVariables = Exact<{
  input: NewPasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: "Mutation";
  changePassword:
    | { __typename: "NewPasswordInvalidResponse"; message: string }
    | { __typename: "NewPasswordSuccessfulResponse"; message: string }
    | { __typename: "NewPasswordTokenExpiredResponse"; message: string };
};

export type ValidateTokenQueryVariables = Exact<{
  token: Scalars["String"];
}>;

export type ValidateTokenQuery = {
  __typename?: "Query";
  validateResetPasswordToken:
    | { __typename: "ValidateResetTokenSuccessfulResponse"; message: string }
    | { __typename: "ValidateResetTokenTokenExpiredResponse"; message: string }
    | {
        __typename: "ValidateResetTokenTokenNotFoundResponse";
        message: string;
      };
};

export type AddSeoLinkMutationVariables = Exact<{
  input: AddSeoLinkInput;
}>;

export type AddSeoLinkMutation = {
  __typename?: "Mutation";
  addSeoLink:
    | { __typename: "AddSeoLinkInvalidResponse"; message: string }
    | { __typename: "AddSeoLinkSuccessfulResponse"; message: string };
};

export type GetSeoLinksQueryVariables = Exact<{ [key: string]: never }>;

export type GetSeoLinksQuery = {
  __typename?: "Query";
  getSeoLinks: Array<{
    __typename?: "SeoLinks";
    id: number;
    createdAt: string;
    title: string;
    urlSlug: string;
    link: string;
    description: string;
  }>;
};

export type RemoveSeoLinkMutationVariables = Exact<{
  id: Scalars["Float"];
}>;

export type RemoveSeoLinkMutation = {
  __typename?: "Mutation";
  removeSeoLink:
    | { __typename: "RemoveSeoLinkInvalidResponse"; message: string }
    | { __typename: "RemoveSeoLinkSuccessfulResponse"; message: string };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser:
    | { __typename: "UpdateUserInvalidResponse"; message: string }
    | { __typename: "UpdateUserSuccessfulResponse"; message: string };
};

export type MessageAgentMutationVariables = Exact<{
  input: MessageAgentInput;
}>;

export type MessageAgentMutation = {
  __typename?: "Mutation";
  messageAgent?:
    | { __typename: "MessageAgentInvalidResponse"; message: string }
    | { __typename: "MessageAgentSuccessfulResponse"; message: string }
    | null;
};

export type SetPropertyStatusMutationVariables = Exact<{
  id: Scalars["Float"];
  status: Scalars["String"];
}>;

export type SetPropertyStatusMutation = {
  __typename?: "Mutation";
  setPropertyStatus:
    | { __typename: "SetPropertyStatusInvalidResponse" }
    | { __typename: "SetPropertyStatusMissingPermissionResponse" }
    | { __typename: "SetPropertyStatusSuccessfulResponse" };
};

export const GetRouteMetadataDocument = gql`
  query GetRouteMetadata($url: String!) {
    getRouteMetadata(url: $url) {
      title
      description
      image
    }
  }
`;

/**
 * __useGetRouteMetadataQuery__
 *
 * To run a query within a React component, call `useGetRouteMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteMetadataQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetRouteMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRouteMetadataQuery,
    GetRouteMetadataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRouteMetadataQuery, GetRouteMetadataQueryVariables>(
    GetRouteMetadataDocument,
    options,
  );
}
export function useGetRouteMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRouteMetadataQuery,
    GetRouteMetadataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRouteMetadataQuery,
    GetRouteMetadataQueryVariables
  >(GetRouteMetadataDocument, options);
}
export type GetRouteMetadataQueryHookResult = ReturnType<
  typeof useGetRouteMetadataQuery
>;
export type GetRouteMetadataLazyQueryHookResult = ReturnType<
  typeof useGetRouteMetadataLazyQuery
>;
export type GetRouteMetadataQueryResult = Apollo.QueryResult<
  GetRouteMetadataQuery,
  GetRouteMetadataQueryVariables
>;
export const RefreshTokenDocument = gql`
  mutation RefreshToken {
    refreshToken {
      __typename
      ... on TokenSuccessfulResponse {
        token
        issuedAt
        expiresAt
        accountType
      }
      ... on TokenUserInactiveResponse {
        message
      }
      ... on TokenInvalidResponse {
        message
      }
    }
  }
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >(RefreshTokenDocument, options);
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>;
export type RefreshTokenMutationResult =
  Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const AddPropertyDocument = gql`
  mutation addProperty($input: AddPropertyInput!) {
    addProperty(input: $input) {
      __typename
      ... on AddPropertySuccessfulResponse {
        message
      }
      ... on AddPropertyApiErrorResponse {
        message
      }
    }
  }
`;
export type AddPropertyMutationFn = Apollo.MutationFunction<
  AddPropertyMutation,
  AddPropertyMutationVariables
>;

/**
 * __useAddPropertyMutation__
 *
 * To run a mutation, you first call `useAddPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPropertyMutation, { data, loading, error }] = useAddPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPropertyMutation,
    AddPropertyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPropertyMutation, AddPropertyMutationVariables>(
    AddPropertyDocument,
    options,
  );
}
export type AddPropertyMutationHookResult = ReturnType<
  typeof useAddPropertyMutation
>;
export type AddPropertyMutationResult =
  Apollo.MutationResult<AddPropertyMutation>;
export type AddPropertyMutationOptions = Apollo.BaseMutationOptions<
  AddPropertyMutation,
  AddPropertyMutationVariables
>;
export const EditPropertyDocument = gql`
  mutation editProperty($input: AddPropertyInput!) {
    editProperty(input: $input) {
      __typename
    }
  }
`;
export type EditPropertyMutationFn = Apollo.MutationFunction<
  EditPropertyMutation,
  EditPropertyMutationVariables
>;

/**
 * __useEditPropertyMutation__
 *
 * To run a mutation, you first call `useEditPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPropertyMutation, { data, loading, error }] = useEditPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditPropertyMutation,
    EditPropertyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditPropertyMutation,
    EditPropertyMutationVariables
  >(EditPropertyDocument, options);
}
export type EditPropertyMutationHookResult = ReturnType<
  typeof useEditPropertyMutation
>;
export type EditPropertyMutationResult =
  Apollo.MutationResult<EditPropertyMutation>;
export type EditPropertyMutationOptions = Apollo.BaseMutationOptions<
  EditPropertyMutation,
  EditPropertyMutationVariables
>;
export const GetEditPropertyDocument = gql`
  query getEditProperty($uid: String!) {
    getEditProperty(uid: $uid) {
      __typename
      ... on GetPropertySuccessfulResponse {
        property {
          uid
          address
          location
          flat
          postcode
          location
          listingType
          price
          netIncome
          billingType
          tenancyType
          rentalTo
          isRentalNegotiable
          isPriceNegotiable
          sqFootage
          floors
          propertyUseType
          useClassType
          conditionType
          existingBuildoutType
          currentEpcType
          potencialEpcType
          businessRate
          amenitiesType
          yearBuilt
          yearLastRenovated
          parkingSpaces
          parkingDetails
          propertyDescriptions
          propertyPhotos
          floorPlans
          brochuresDocuments
          contactEmail
          contactName
          contactBio
          locationType
        }
      }
    }
  }
`;

/**
 * __useGetEditPropertyQuery__
 *
 * To run a query within a React component, call `useGetEditPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditPropertyQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetEditPropertyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEditPropertyQuery,
    GetEditPropertyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEditPropertyQuery, GetEditPropertyQueryVariables>(
    GetEditPropertyDocument,
    options,
  );
}
export function useGetEditPropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEditPropertyQuery,
    GetEditPropertyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEditPropertyQuery,
    GetEditPropertyQueryVariables
  >(GetEditPropertyDocument, options);
}
export type GetEditPropertyQueryHookResult = ReturnType<
  typeof useGetEditPropertyQuery
>;
export type GetEditPropertyLazyQueryHookResult = ReturnType<
  typeof useGetEditPropertyLazyQuery
>;
export type GetEditPropertyQueryResult = Apollo.QueryResult<
  GetEditPropertyQuery,
  GetEditPropertyQueryVariables
>;
export const GetSignedUploadUrlsDocument = gql`
  query GetSignedUploadUrls($input: GetSignedUploadUrlsInput!) {
    getSignedUploadUrls(input: $input) {
      uploadUrls {
        fileName
        uploadUrl
      }
      publicUrls {
        propertyImages
        documents
        floorPlans
      }
    }
  }
`;

/**
 * __useGetSignedUploadUrlsQuery__
 *
 * To run a query within a React component, call `useGetSignedUploadUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUploadUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedUploadUrlsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSignedUploadUrlsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSignedUploadUrlsQuery,
    GetSignedUploadUrlsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSignedUploadUrlsQuery,
    GetSignedUploadUrlsQueryVariables
  >(GetSignedUploadUrlsDocument, options);
}
export function useGetSignedUploadUrlsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSignedUploadUrlsQuery,
    GetSignedUploadUrlsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSignedUploadUrlsQuery,
    GetSignedUploadUrlsQueryVariables
  >(GetSignedUploadUrlsDocument, options);
}
export type GetSignedUploadUrlsQueryHookResult = ReturnType<
  typeof useGetSignedUploadUrlsQuery
>;
export type GetSignedUploadUrlsLazyQueryHookResult = ReturnType<
  typeof useGetSignedUploadUrlsLazyQuery
>;
export type GetSignedUploadUrlsQueryResult = Apollo.QueryResult<
  GetSignedUploadUrlsQuery,
  GetSignedUploadUrlsQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    currentUser {
      id
      email
      name
      phone
      companyName
      bio
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetAllUsersPropertiesDocument = gql`
  query GetAllUsersProperties($input: GetUserPropertiesInput!) {
    getAllUsersProperties(input: $input) {
      itemsCount
      data {
        uid
        id
        slug
        address
        price
        updatedAt
        useClassType
        propertyUseType
        propertyPhotos
        sqFootage
        status
        isPriceNegotiable
        billingType
        listingType
        contactName
        isExternal
        propertyInsight {
          crimeRateLevel
          studentPopLevel
          areaProsperity
        }
        user {
          name
        }
      }
    }
  }
`;

/**
 * __useGetAllUsersPropertiesQuery__
 *
 * To run a query within a React component, call `useGetAllUsersPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersPropertiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllUsersPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllUsersPropertiesQuery,
    GetAllUsersPropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllUsersPropertiesQuery,
    GetAllUsersPropertiesQueryVariables
  >(GetAllUsersPropertiesDocument, options);
}
export function useGetAllUsersPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllUsersPropertiesQuery,
    GetAllUsersPropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllUsersPropertiesQuery,
    GetAllUsersPropertiesQueryVariables
  >(GetAllUsersPropertiesDocument, options);
}
export type GetAllUsersPropertiesQueryHookResult = ReturnType<
  typeof useGetAllUsersPropertiesQuery
>;
export type GetAllUsersPropertiesLazyQueryHookResult = ReturnType<
  typeof useGetAllUsersPropertiesLazyQuery
>;
export type GetAllUsersPropertiesQueryResult = Apollo.QueryResult<
  GetAllUsersPropertiesQuery,
  GetAllUsersPropertiesQueryVariables
>;
export const LoginDocument = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      __typename
      ... on TokenSuccessfulResponse {
        token
        issuedAt
        expiresAt
        accountType
      }
      ... on TokenInvalidResponse {
        message
      }
      ... on TokenUserInactiveResponse {
        message
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const GetUserPropertiesDocument = gql`
  query GetUserProperties($input: GetUserPropertiesInput!) {
    getUserProperties(input: $input) {
      itemsCount
      data {
        uid
        slug
        address
        price
        updatedAt
        useClassType
        propertyUseType
        propertyPhotos
        sqFootage
        isPriceNegotiable
        status
        billingType
        listingType
        propertyInsight {
          crimeRateLevel
          studentPopLevel
          areaProsperity
        }
      }
    }
  }
`;

/**
 * __useGetUserPropertiesQuery__
 *
 * To run a query within a React component, call `useGetUserPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPropertiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserPropertiesQuery,
    GetUserPropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserPropertiesQuery,
    GetUserPropertiesQueryVariables
  >(GetUserPropertiesDocument, options);
}
export function useGetUserPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPropertiesQuery,
    GetUserPropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserPropertiesQuery,
    GetUserPropertiesQueryVariables
  >(GetUserPropertiesDocument, options);
}
export type GetUserPropertiesQueryHookResult = ReturnType<
  typeof useGetUserPropertiesQuery
>;
export type GetUserPropertiesLazyQueryHookResult = ReturnType<
  typeof useGetUserPropertiesLazyQuery
>;
export type GetUserPropertiesQueryResult = Apollo.QueryResult<
  GetUserPropertiesQuery,
  GetUserPropertiesQueryVariables
>;
export const DeletePropertyDocument = gql`
  mutation DeleteProperty($uid: String!) {
    deleteProperty(uid: $uid) {
      __typename
    }
  }
`;
export type DeletePropertyMutationFn = Apollo.MutationFunction<
  DeletePropertyMutation,
  DeletePropertyMutationVariables
>;

/**
 * __useDeletePropertyMutation__
 *
 * To run a mutation, you first call `useDeletePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyMutation, { data, loading, error }] = useDeletePropertyMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeletePropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePropertyMutation,
    DeletePropertyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePropertyMutation,
    DeletePropertyMutationVariables
  >(DeletePropertyDocument, options);
}
export type DeletePropertyMutationHookResult = ReturnType<
  typeof useDeletePropertyMutation
>;
export type DeletePropertyMutationResult =
  Apollo.MutationResult<DeletePropertyMutation>;
export type DeletePropertyMutationOptions = Apollo.BaseMutationOptions<
  DeletePropertyMutation,
  DeletePropertyMutationVariables
>;
export const SearchPropertiesDocument = gql`
  query searchProperties($input: SearchPropertyInput!) {
    searchProperties(input: $input) {
      data {
        uid
        slug
        updatedAt
        address
        price
        isPriceNegotiable
        billingType
        sqFootage
        propertyUseType
        propertyPhotos
        listingType
        propertyInsight {
          crimeRateLevel
          studentPopLevel
          areaProsperity
        }
      }
      itemsCount
      extended
    }
  }
`;

/**
 * __useSearchPropertiesQuery__
 *
 * To run a query within a React component, call `useSearchPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPropertiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchPropertiesQuery,
    SearchPropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPropertiesQuery, SearchPropertiesQueryVariables>(
    SearchPropertiesDocument,
    options,
  );
}
export function useSearchPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPropertiesQuery,
    SearchPropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchPropertiesQuery,
    SearchPropertiesQueryVariables
  >(SearchPropertiesDocument, options);
}
export type SearchPropertiesQueryHookResult = ReturnType<
  typeof useSearchPropertiesQuery
>;
export type SearchPropertiesLazyQueryHookResult = ReturnType<
  typeof useSearchPropertiesLazyQuery
>;
export type SearchPropertiesQueryResult = Apollo.QueryResult<
  SearchPropertiesQuery,
  SearchPropertiesQueryVariables
>;
export const GetPropertyPreviewByUidDocument = gql`
  query GetPropertyPreviewByUid($uid: String!) {
    getPropertyPreviewByUid(uid: $uid) {
      ... on GetPropertySuccessfulResponse {
        property {
          id
          uid
          user {
            name
            phone
            companyName
            bio
          }
          propertyInsight {
            crimeRateLevel
            crimeTrend
            crimeIndex
            studentPopLevel
            areaHealth
            shopliftingLevel
            popDensity
            areaProsperity
            areaProsperityIndex
            price1YearTrend
            price1YearIndex
            price5YearTrend
            price5YearIndex
            price10YearTrend
            price10YearIndex
          }
          createdAt
          status
          location
          address
          flat
          postcode
          locationType
          listingType
          price
          netIncome
          billingType
          tenancyType
          rentalTo
          isRentalNegotiable
          isPriceNegotiable
          sqFootage
          floors
          floors
          propertyUseType
          useClassType
          conditionType
          existingBuildoutType
          currentEpcType
          potencialEpcType
          businessRate
          amenitiesType
          yearBuilt
          yearLastRenovated
          parkingSpaces
          parkingDetails
          propertyDescriptions
          propertyPhotos
          floorPlans
          brochuresDocuments
          contactEmail
          contactName
          contactBio
        }
      }
    }
  }
`;

/**
 * __useGetPropertyPreviewByUidQuery__
 *
 * To run a query within a React component, call `useGetPropertyPreviewByUidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyPreviewByUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyPreviewByUidQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPropertyPreviewByUidQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyPreviewByUidQuery,
    GetPropertyPreviewByUidQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPropertyPreviewByUidQuery,
    GetPropertyPreviewByUidQueryVariables
  >(GetPropertyPreviewByUidDocument, options);
}
export function useGetPropertyPreviewByUidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyPreviewByUidQuery,
    GetPropertyPreviewByUidQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPropertyPreviewByUidQuery,
    GetPropertyPreviewByUidQueryVariables
  >(GetPropertyPreviewByUidDocument, options);
}
export type GetPropertyPreviewByUidQueryHookResult = ReturnType<
  typeof useGetPropertyPreviewByUidQuery
>;
export type GetPropertyPreviewByUidLazyQueryHookResult = ReturnType<
  typeof useGetPropertyPreviewByUidLazyQuery
>;
export type GetPropertyPreviewByUidQueryResult = Apollo.QueryResult<
  GetPropertyPreviewByUidQuery,
  GetPropertyPreviewByUidQueryVariables
>;
export const GetPropertyByUidDocument = gql`
  query GetPropertyByUid($uid: String!) {
    getPropertyByUid(uid: $uid) {
      id
      uid
      user {
        name
        phone
        companyName
        bio
      }
      propertyInsight {
        crimeRateLevel
        crimeTrend
        crimeIndex
        studentPopLevel
        areaHealth
        shopliftingLevel
        popDensity
        areaProsperity
        areaProsperityIndex
        price1YearTrend
        price1YearIndex
        price5YearTrend
        price5YearIndex
        price10YearTrend
        price10YearIndex
      }
      createdAt
      status
      location
      address
      flat
      postcode
      locationType
      listingType
      price
      netIncome
      billingType
      tenancyType
      rentalTo
      isRentalNegotiable
      isPriceNegotiable
      sqFootage
      floors
      floors
      propertyUseType
      useClassType
      conditionType
      existingBuildoutType
      currentEpcType
      potencialEpcType
      businessRate
      amenitiesType
      yearBuilt
      yearLastRenovated
      parkingSpaces
      parkingDetails
      propertyDescriptions
      propertyPhotos
      floorPlans
      brochuresDocuments
      contactEmail
      contactName
      contactBio
      isExternal
      externalPropertyUrl
    }
  }
`;

/**
 * __useGetPropertyByUidQuery__
 *
 * To run a query within a React component, call `useGetPropertyByUidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyByUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyByUidQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPropertyByUidQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyByUidQuery,
    GetPropertyByUidQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyByUidQuery, GetPropertyByUidQueryVariables>(
    GetPropertyByUidDocument,
    options,
  );
}
export function useGetPropertyByUidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyByUidQuery,
    GetPropertyByUidQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPropertyByUidQuery,
    GetPropertyByUidQueryVariables
  >(GetPropertyByUidDocument, options);
}
export type GetPropertyByUidQueryHookResult = ReturnType<
  typeof useGetPropertyByUidQuery
>;
export type GetPropertyByUidLazyQueryHookResult = ReturnType<
  typeof useGetPropertyByUidLazyQuery
>;
export type GetPropertyByUidQueryResult = Apollo.QueryResult<
  GetPropertyByUidQuery,
  GetPropertyByUidQueryVariables
>;
export const ClaimAccountDocument = gql`
  mutation claimAccount($token: String!) {
    claimAccount(token: $token) {
      __typename
      ... on ClaimAccountSuccessfulResponse {
        message
      }
      ... on ClaimAccountTokenExpiredResponse {
        message
      }
      ... on ClaimAccountTokenNotFoundResponse {
        message
      }
    }
  }
`;
export type ClaimAccountMutationFn = Apollo.MutationFunction<
  ClaimAccountMutation,
  ClaimAccountMutationVariables
>;

/**
 * __useClaimAccountMutation__
 *
 * To run a mutation, you first call `useClaimAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimAccountMutation, { data, loading, error }] = useClaimAccountMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useClaimAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimAccountMutation,
    ClaimAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClaimAccountMutation,
    ClaimAccountMutationVariables
  >(ClaimAccountDocument, options);
}
export type ClaimAccountMutationHookResult = ReturnType<
  typeof useClaimAccountMutation
>;
export type ClaimAccountMutationResult =
  Apollo.MutationResult<ClaimAccountMutation>;
export type ClaimAccountMutationOptions = Apollo.BaseMutationOptions<
  ClaimAccountMutation,
  ClaimAccountMutationVariables
>;
export const RegisterDocument = gql`
  mutation register($input: RegisterInput!) {
    register(input: $input) {
      __typename
      ... on RegisterSuccessfulResponse {
        message
      }
      ... on RegisterInvalidResponse {
        message
      }
      ... on RegisterTokenExpiredResponse {
        message
      }
    }
  }
`;
export type RegisterMutationFn = Apollo.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterMutation,
    RegisterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(
    RegisterDocument,
    options,
  );
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>;
export const ResendActivationLinkDocument = gql`
  query resendActivationLink($email: String!) {
    resendActivationLink(email: $email) {
      __typename
    }
  }
`;

/**
 * __useResendActivationLinkQuery__
 *
 * To run a query within a React component, call `useResendActivationLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useResendActivationLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResendActivationLinkQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendActivationLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResendActivationLinkQuery,
    ResendActivationLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResendActivationLinkQuery,
    ResendActivationLinkQueryVariables
  >(ResendActivationLinkDocument, options);
}
export function useResendActivationLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResendActivationLinkQuery,
    ResendActivationLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResendActivationLinkQuery,
    ResendActivationLinkQueryVariables
  >(ResendActivationLinkDocument, options);
}
export type ResendActivationLinkQueryHookResult = ReturnType<
  typeof useResendActivationLinkQuery
>;
export type ResendActivationLinkLazyQueryHookResult = ReturnType<
  typeof useResendActivationLinkLazyQuery
>;
export type ResendActivationLinkQueryResult = Apollo.QueryResult<
  ResendActivationLinkQuery,
  ResendActivationLinkQueryVariables
>;
export const RequestPasswordResetDocument = gql`
  mutation requestPasswordReset($input: ResetPasswordRequestInput!) {
    requestPasswordReset(input: $input) {
      __typename
      ... on ResetPasswordRequestSuccessfulResponse {
        message
      }
      ... on ResetPasswordRequestInvalidResponse {
        message
      }
    }
  }
`;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >(RequestPasswordResetDocument, options);
}
export type RequestPasswordResetMutationHookResult = ReturnType<
  typeof useRequestPasswordResetMutation
>;
export type RequestPasswordResetMutationResult =
  Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: NewPasswordInput!) {
    changePassword(input: $input) {
      __typename
      ... on NewPasswordSuccessfulResponse {
        message
      }
      ... on NewPasswordInvalidResponse {
        message
      }
      ... on NewPasswordTokenExpiredResponse {
        message
      }
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const ValidateTokenDocument = gql`
  query ValidateToken($token: String!) {
    validateResetPasswordToken(token: $token) {
      __typename
      ... on ValidateResetTokenSuccessfulResponse {
        message
      }
      ... on ValidateResetTokenTokenExpiredResponse {
        message
      }
      ... on ValidateResetTokenTokenNotFoundResponse {
        message
      }
    }
  }
`;

/**
 * __useValidateTokenQuery__
 *
 * To run a query within a React component, call `useValidateTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateTokenQuery,
    ValidateTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidateTokenQuery, ValidateTokenQueryVariables>(
    ValidateTokenDocument,
    options,
  );
}
export function useValidateTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateTokenQuery,
    ValidateTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidateTokenQuery, ValidateTokenQueryVariables>(
    ValidateTokenDocument,
    options,
  );
}
export type ValidateTokenQueryHookResult = ReturnType<
  typeof useValidateTokenQuery
>;
export type ValidateTokenLazyQueryHookResult = ReturnType<
  typeof useValidateTokenLazyQuery
>;
export type ValidateTokenQueryResult = Apollo.QueryResult<
  ValidateTokenQuery,
  ValidateTokenQueryVariables
>;
export const AddSeoLinkDocument = gql`
  mutation addSeoLink($input: AddSeoLinkInput!) {
    addSeoLink(input: $input) {
      __typename
      ... on AddSeoLinkSuccessfulResponse {
        message
      }
      ... on AddSeoLinkInvalidResponse {
        message
      }
    }
  }
`;
export type AddSeoLinkMutationFn = Apollo.MutationFunction<
  AddSeoLinkMutation,
  AddSeoLinkMutationVariables
>;

/**
 * __useAddSeoLinkMutation__
 *
 * To run a mutation, you first call `useAddSeoLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSeoLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSeoLinkMutation, { data, loading, error }] = useAddSeoLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSeoLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSeoLinkMutation,
    AddSeoLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSeoLinkMutation, AddSeoLinkMutationVariables>(
    AddSeoLinkDocument,
    options,
  );
}
export type AddSeoLinkMutationHookResult = ReturnType<
  typeof useAddSeoLinkMutation
>;
export type AddSeoLinkMutationResult =
  Apollo.MutationResult<AddSeoLinkMutation>;
export type AddSeoLinkMutationOptions = Apollo.BaseMutationOptions<
  AddSeoLinkMutation,
  AddSeoLinkMutationVariables
>;
export const GetSeoLinksDocument = gql`
  query GetSeoLinks {
    getSeoLinks {
      id
      createdAt
      title
      urlSlug
      link
      description
    }
  }
`;

/**
 * __useGetSeoLinksQuery__
 *
 * To run a query within a React component, call `useGetSeoLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeoLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeoLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSeoLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSeoLinksQuery,
    GetSeoLinksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSeoLinksQuery, GetSeoLinksQueryVariables>(
    GetSeoLinksDocument,
    options,
  );
}
export function useGetSeoLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSeoLinksQuery,
    GetSeoLinksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSeoLinksQuery, GetSeoLinksQueryVariables>(
    GetSeoLinksDocument,
    options,
  );
}
export type GetSeoLinksQueryHookResult = ReturnType<typeof useGetSeoLinksQuery>;
export type GetSeoLinksLazyQueryHookResult = ReturnType<
  typeof useGetSeoLinksLazyQuery
>;
export type GetSeoLinksQueryResult = Apollo.QueryResult<
  GetSeoLinksQuery,
  GetSeoLinksQueryVariables
>;
export const RemoveSeoLinkDocument = gql`
  mutation RemoveSeoLink($id: Float!) {
    removeSeoLink(id: $id) {
      __typename
      ... on RemoveSeoLinkSuccessfulResponse {
        message
      }
      ... on RemoveSeoLinkInvalidResponse {
        message
      }
    }
  }
`;
export type RemoveSeoLinkMutationFn = Apollo.MutationFunction<
  RemoveSeoLinkMutation,
  RemoveSeoLinkMutationVariables
>;

/**
 * __useRemoveSeoLinkMutation__
 *
 * To run a mutation, you first call `useRemoveSeoLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSeoLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSeoLinkMutation, { data, loading, error }] = useRemoveSeoLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSeoLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSeoLinkMutation,
    RemoveSeoLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveSeoLinkMutation,
    RemoveSeoLinkMutationVariables
  >(RemoveSeoLinkDocument, options);
}
export type RemoveSeoLinkMutationHookResult = ReturnType<
  typeof useRemoveSeoLinkMutation
>;
export type RemoveSeoLinkMutationResult =
  Apollo.MutationResult<RemoveSeoLinkMutation>;
export type RemoveSeoLinkMutationOptions = Apollo.BaseMutationOptions<
  RemoveSeoLinkMutation,
  RemoveSeoLinkMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      __typename
      __typename
      ... on UpdateUserSuccessfulResponse {
        message
      }
      ... on UpdateUserInvalidResponse {
        message
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const MessageAgentDocument = gql`
  mutation MessageAgent($input: MessageAgentInput!) {
    messageAgent(input: $input) {
      __typename
      ... on MessageAgentSuccessfulResponse {
        message
      }
      ... on MessageAgentInvalidResponse {
        message
      }
    }
  }
`;
export type MessageAgentMutationFn = Apollo.MutationFunction<
  MessageAgentMutation,
  MessageAgentMutationVariables
>;

/**
 * __useMessageAgentMutation__
 *
 * To run a mutation, you first call `useMessageAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageAgentMutation, { data, loading, error }] = useMessageAgentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageAgentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MessageAgentMutation,
    MessageAgentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MessageAgentMutation,
    MessageAgentMutationVariables
  >(MessageAgentDocument, options);
}
export type MessageAgentMutationHookResult = ReturnType<
  typeof useMessageAgentMutation
>;
export type MessageAgentMutationResult =
  Apollo.MutationResult<MessageAgentMutation>;
export type MessageAgentMutationOptions = Apollo.BaseMutationOptions<
  MessageAgentMutation,
  MessageAgentMutationVariables
>;
export const SetPropertyStatusDocument = gql`
  mutation SetPropertyStatus($id: Float!, $status: String!) {
    setPropertyStatus(id: $id, status: $status) {
      __typename
    }
  }
`;
export type SetPropertyStatusMutationFn = Apollo.MutationFunction<
  SetPropertyStatusMutation,
  SetPropertyStatusMutationVariables
>;

/**
 * __useSetPropertyStatusMutation__
 *
 * To run a mutation, you first call `useSetPropertyStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPropertyStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPropertyStatusMutation, { data, loading, error }] = useSetPropertyStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetPropertyStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPropertyStatusMutation,
    SetPropertyStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetPropertyStatusMutation,
    SetPropertyStatusMutationVariables
  >(SetPropertyStatusDocument, options);
}
export type SetPropertyStatusMutationHookResult = ReturnType<
  typeof useSetPropertyStatusMutation
>;
export type SetPropertyStatusMutationResult =
  Apollo.MutationResult<SetPropertyStatusMutation>;
export type SetPropertyStatusMutationOptions = Apollo.BaseMutationOptions<
  SetPropertyStatusMutation,
  SetPropertyStatusMutationVariables
>;
