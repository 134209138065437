import Tippy from "@tippyjs/react";
import React, { FunctionComponent, ReactElement, useState } from "react";
import { HintContainer } from "./Hint.components";

type HintProps = {
  hint: string | JSX.Element;
  children: ReactElement;
  placement?: "right" | "top";
};

const Hint: FunctionComponent<HintProps> = ({
  hint,
  children,
  placement = "top",
}) => {
  const [hintVisible, setHintVisible] = useState(false);
  return (
    <Tippy
      interactive={true}
      allowHTML
      visible={hintVisible}
      appendTo={() => document.body}
      placement={placement}
      content={<HintContainer>{hint}</HintContainer>}
      children={
        <div
          tabIndex={0}
          onMouseEnter={() => setHintVisible(true)}
          onMouseLeave={() => setHintVisible(false)}
          onFocus={() => setHintVisible(true)}
          onBlur={() => setHintVisible(false)}
        >
          {children}
        </div>
      }
    />
  );
};

export default Hint;
