import { AreaProsperityType } from "../../../../../../../generated/schema";
import React, { FunctionComponent, useEffect, useRef } from "react";
import { ChartHeader } from "../Charts.components";
import {
  AreaProsperityChartWrapper,
  AreaProsperityContainer,
  FakeChartBackground,
  FakeChartWrapper,
  FluidChart,
} from "./AreaProsperity.components";

// @ts-ignore
import { FluidMeter } from "./js-fluid-meter.js";

type AreaProsperityProps = {
  areaProsperity: AreaProsperityType;
  areaPrsperityIndex: number;
};
const AreaProsperity: FunctionComponent<AreaProsperityProps> = ({
  areaProsperity,
  areaPrsperityIndex,
}) => {
  const chartRef = useRef();

  useEffect(() => {
    if (!chartRef.current) {
      chartRef.current = new FluidMeter();
      if (chartRef.current) {
        // @ts-ignore
        chartRef.current.init({
          targetContainer: document.getElementById("fluid-meter"),
          fillPercentage: areaPrsperityIndex,
          options: {
            fontSize: "32px",
            fontFamily: "Geomanist",
            fontFillStyle: "white",
            drawShadow: false,
            drawText: false,
            drawPercentageSign: false,
            drawBubbles: false,
            size: 180,
            borderWidth: 7,
            backgroundColor: "#A1c4f7",
            foregroundColor: "#5598f6",
            foregroundFluidLayer: {
              fillStyle: "#2A7AEA",
              angularSpeed: 120,
              maxAmplitude: 15,
              frequency: 25,
              horizontalSpeed: -20,
            },
            middleFluidLayer: {
              fillStyle: "#5598F6",
              angularSpeed: 100,
              maxAmplitude: 20,
              frequency: 35,
              horizontalSpeed: -10,
            },
            backgroundFluidLayer: {
              fillStyle: "#2a7aea80",
              angularSpeed: 100,
              maxAmplitude: 25,
              frequency: 45,
              horizontalSpeed: 10,
            },
          },
        });
      }
    }
  }, [areaPrsperityIndex]);

  const mapAreaProsperity = () => {
    switch (areaProsperity) {
      case AreaProsperityType.BelowAverage:
        return "Below Average";
      case AreaProsperityType.Average:
        return "Average";
      case AreaProsperityType.Wealthy:
        return "Wealthy";
    }
  };

  return (
    <AreaProsperityContainer>
      <ChartHeader>Area prosperity</ChartHeader>
      <AreaProsperityChartWrapper>
        <FakeChartWrapper>
          <FakeChartBackground></FakeChartBackground>
          <FluidChart id="fluid-meter" style={{ zIndex: 10 }} />
          <h5>
            {areaPrsperityIndex}
            <span>%</span>
          </h5>
          <span>{mapAreaProsperity()}</span>
        </FakeChartWrapper>
      </AreaProsperityChartWrapper>
    </AreaProsperityContainer>
  );
};

export default AreaProsperity;
