import React, { FunctionComponent } from "react";
import { MessageSentContainer } from "./MessageSent.components";
import { ReactComponent as ContactIcon } from "./contactIcon.svg";

type MessageAgentProps = {
  contactName: string;
};

const MessageSent: FunctionComponent<MessageAgentProps> = ({ contactName }) => {
  return (
    <MessageSentContainer>
      <ContactIcon />
      <span>
        Message sent successfully. {contactName} should contact you soon.
      </span>
    </MessageSentContainer>
  );
};

export default MessageSent;
