import styled from "styled-components";
import { colors } from "../../../../shared/colors";
import { down } from "../../../../shared/media";

export const AgentDetailsStickyPanel = styled.div`
  border-radius: 16px;
  background-color: ${colors.primary.pure_white};
  padding: 40px;
  box-sizing: border-box;
  margin-bottom: 8px;

  :last-child {
    margin-bottom: 0px;
  }

  ${down("tablet")} {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: unset;
    bottom: 0px;
    height: 64px;
    border-radius: 0px;
    margin: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    padding: 8px 56px;
    align-items: center;
    box-shadow: 0px 10px 40px rgba(37, 122, 208, 0.3);
  }

  ${down("mobile")} {
    padding: 8px 24px;
  }
`;

export const AgentDetailsName = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: ${colors.primary.frosted_black};
  max-width: 225px;

  ${down("tablet")} {
    max-width: unset;
    font-size: 16px;
    line-height: 16px;
  }

  ${down("mobile")} {
    display: none;
  }
`;

export const AgentDetailsDescription = styled.p`
  margin: 0;
  margin-top: 8px;
  white-space: pre-wrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${colors.grayscale._600};
  ${down("tablet")} {
    display: none;
  }
`;

export const AgentDetailsButtonWrapper = styled.div`
  margin-top: 24px;
  width: 200px;
  ${down("tablet")} {
    margin-top: unset;
  }

  ${down("mobile")} {
    width: 100%;
  }
`;
