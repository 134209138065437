import React, { FunctionComponent } from "react";
import {
  PropertyDetailsLocationSectionHeading,
  PropertyDetailsSectionWrapper,
} from "../../PropertyDetails.components";
import {
  LocationDetail,
  LocationDetailInnerWrapper,
  LocationDetails,
  LocationDetailsCategory,
  LocationDetailsData,
  LocationDetailsWrapper,
  LocationInnerWrapper,
  LocationMapsButton,
  LocationWrapper,
  NegotiableBadge,
  PriceBadge,
  PropertyUseTypeInnerWrapper,
  PropertyUseTypeWrapper,
} from "./Location.components";

import { ReactComponent as SquareFtIcon } from "./icons/SquareFtIcon.svg";
import { ReactComponent as EpcIcon } from "./icons/EpcIcon.svg";
import { ReactComponent as ConditionIcon } from "./icons/ConditionIcon.svg";
import {
  BillingType,
  ConditionType,
  EpcType,
  PropertyUseType,
} from "../../../../../generated/schema";
import {
  getEpcTypeValue,
  getConditionTypeValue,
  getPropertyUseTypeValue,
} from "../../../../../common/helpers/enumNamesHelper";
import formatPrice from "../../../../../common/helpers/formatPrice";
import { formatBilling } from "../../../../../common/helpers/formatBilling";

type LocationProps = {
  address: string;
  postcode: string;
  location: [number, number];
  price?: number | null;
  sqFootage: number;
  currentEpcType: EpcType;
  conditionType: ConditionType;
  propertyUseType: PropertyUseType[];
  isPriceNegotiable?: boolean | null;
  isRentalNegotiable?: boolean | null;
  billingType?: BillingType | null;
};

const Location: FunctionComponent<LocationProps> = ({
  address,
  postcode,
  location,
  price,
  sqFootage,
  currentEpcType,
  conditionType,
  propertyUseType,
  billingType,
  isPriceNegotiable,
  isRentalNegotiable,
}) => {
  return (
    <PropertyDetailsSectionWrapper>
      <LocationWrapper>
        <LocationInnerWrapper>
          <PropertyDetailsLocationSectionHeading>
            {address}
          </PropertyDetailsLocationSectionHeading>
          <LocationDetails>{postcode}</LocationDetails>
          <LocationMapsButton
            target="_blank"
            href={`https://www.google.com/maps/search/?api=1&query=${location[0]}%2C${location[1]}`}
          >
            SEE ON THE MAPS
          </LocationMapsButton>
        </LocationInnerWrapper>
        {price ? (
          <PriceBadge isPriceNegotiable={isPriceNegotiable || false}>
            {formatPrice(price)}
            <span>{formatBilling(billingType)}</span>
            {isPriceNegotiable && (
              <NegotiableBadge>*negotiable</NegotiableBadge>
            )}
          </PriceBadge>
        ) : (
          <PriceBadge smallFont>price on request</PriceBadge>
        )}
      </LocationWrapper>
      <LocationDetailsWrapper>
        <LocationDetail>
          <SquareFtIcon />
          <LocationDetailInnerWrapper>
            <LocationDetailsCategory>Sq Footage</LocationDetailsCategory>
            <LocationDetailsData>{sqFootage}</LocationDetailsData>
          </LocationDetailInnerWrapper>
        </LocationDetail>

        <LocationDetail>
          <EpcIcon />
          <LocationDetailInnerWrapper>
            <LocationDetailsCategory>EPC</LocationDetailsCategory>
            <LocationDetailsData>
              {getEpcTypeValue(currentEpcType)}
            </LocationDetailsData>
          </LocationDetailInnerWrapper>
        </LocationDetail>

        <LocationDetail>
          <ConditionIcon />
          <LocationDetailInnerWrapper>
            <LocationDetailsCategory>Condition</LocationDetailsCategory>
            <LocationDetailsData>
              {getConditionTypeValue(conditionType)}
            </LocationDetailsData>
          </LocationDetailInnerWrapper>
        </LocationDetail>
      </LocationDetailsWrapper>

      <PropertyUseTypeWrapper>
        <h5>Property Use Type</h5>
        <PropertyUseTypeInnerWrapper>
          {propertyUseType.map((useType) => (
            <span key={useType}>{getPropertyUseTypeValue(useType)}</span>
          ))}
        </PropertyUseTypeInnerWrapper>
      </PropertyUseTypeWrapper>
    </PropertyDetailsSectionWrapper>
  );
};

export default Location;
