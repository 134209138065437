import React, { FunctionComponent, useState } from "react";
import { Transition } from "react-transition-group";
import { useIsMediaDown } from "../../media";
import Button from "../Button";
import {
  CookiesBoxButtonWrapper,
  CookiesBoxWrapper,
  CookiesDescription,
  CookiesHeader,
  CookiesImage,
  CookiesImageContainer,
} from "./CookiesBox.components";
import CookiesIcon from "./CookiesIcon.svg";
import useCookiesAccepted, { COOKIES_ACCEPTED_KEY } from "./useCookiesAccepted";

const CookiesBox: FunctionComponent = () => {
  const [showBox, setShowBox] = useState(true);
  const cookiesAccepted = useCookiesAccepted();
  const isTabletDown = useIsMediaDown("tablet");

  const description =
    "We use cookies to ensure that we give you the best experience on our website.";

  const acceptCookiePolicy = () => {
    setShowBox(false);
    document.cookie = `${COOKIES_ACCEPTED_KEY}=true; domain=commit.properties; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
  };

  return (
    <Transition
      timeout={500}
      mountOnEnter={true}
      unmountOnExit={true}
      appear={true}
      in={!cookiesAccepted && showBox}
    >
      {(status) => (
        <CookiesBoxWrapper status={status}>
          <CookiesImageContainer>
            <CookiesImage src={CookiesIcon} alt="Cookie box icon" />
          </CookiesImageContainer>

          <CookiesHeader>We use cookies</CookiesHeader>

          <CookiesDescription>
            {description}
            <a href="/cookies"> Read cookies policies.</a>
          </CookiesDescription>
          <CookiesBoxButtonWrapper>
            <Button
              size="block"
              onClick={acceptCookiePolicy}
              buttonType={isTabletDown ? "light" : "primary"}
            >
              {isTabletDown ? "Accept" : "Allow"}
            </Button>
          </CookiesBoxButtonWrapper>
        </CookiesBoxWrapper>
      )}
    </Transition>
  );
};

export default CookiesBox;
