import styled, { keyframes } from "styled-components";
import { colors } from "../shared/colors";
import { down } from "../shared/media";
import {
  BodyMediumStyle,
  BodySmallStyle,
  H4Style,
  H5Style,
  H6Style,
  HorizontalGap,
  LeadSmallStyle,
} from "../shared/mixins";
import NoResultsImage from "./no_results_image.svg";

export const PropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${colors.grayscale._200};
`;

export const PropertiesTopBar = styled.div`
  background-color: ${colors.primary.pure_white};
  border-radius: 0px 0px 40px 40px;
  width: 100%;

  ${down("tablet")} {
    border-radius: 0px 0px 32px 32px;
  }
  ${down("mobile")} {
    border-radius: 0px 0px 16px 16px;
  }
`;

export const PropertiesTopBarContainer = styled.div`
  margin: 32px auto;
  max-width: 1088px;
  display: flex;
  flex-direction: column;

  ${down("tablet")} {
    margin: 24px 56px;
  }

  ${down("mobile")} {
    margin: 24px;
  }
`;

export const PropertiesTopBarHeader = styled.span`
  ${H4Style};

  ${down("tablet")} {
    ${H6Style}
  }
`;

export const PropertiesTopBarInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  ${HorizontalGap(8)};
  align-items: flex-end;

  ${down("tablet")} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const PropertiesTopBarSearch = styled.div`
  flex: 1;
  width: 100%;
`;

export const PropertiesTopBarPropertyType = styled.div`
  flex: 1;

  ${down("tablet")} {
    display: none;
  }
`;

export const PropertiesTopBarFilters = styled.div`
  display: none;

  ${down("tablet")} {
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
  }
`;

export const PropertiesTopBarFilterButton = styled.div`
  width: 112px;
`;

export const PropertiesTopBarFilterButtonImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

export const PropertiesTopBarSortButtonContainer = styled.div`
  flex: 1;
  justify-content: flex-end;
  display: flex;
`;

export const PropertiesSearchInfo = styled.div`
  display: flex;
  align-items: flex-end;
  max-width: 1088px;
  width: 100%;
  margin-top: 32px;
  flex: 1;

  ${down("tablet")} {
    max-width: unset;
    margin-left: 56px;
    margin-right: 56px;
    width: calc(100% - 112px);
  }

  ${down("mobile")} {
    margin-top: 20px;
    margin-left: 24px;
    margin-right: 24px;
    width: calc(100% - 48px);
  }
`;

export const PropertiesSearchResultsCount = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PropertiesSearchResultsCountHeader = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.primary.frosted_black};
  margin-bottom: 2px;

  ${down("tablet")} {
    display: none;
  }
`;

export const PropertiesSearchResultsCountSpan = styled.span`
  ${LeadSmallStyle()};
  color: ${colors.grayscale._600};
  height: 16px;
`;

export const PropertiesSearchSort = styled.div`
  display: flex;
  align-items: center;
  ${down("tablet")} {
    display: none;
  }
`;

export const PropertiesSearchButton = styled.div`
  width: 120px;

  ${down("tablet")} {
    display: none;
  }
`;

export const PropertiesContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 64px;
  max-width: 1088px;

  ${down("tablet")} {
    max-width: unset;
  }
`;

export const PropertiesFilterContainer = styled.div`
  width: 336px;
  background-color: ${colors.primary.pure_white};
  margin-right: 16px;
  border-radius: 16px;
  height: fit-content;

  ${down("tablet")} {
    display: none;
  }
`;

export const PropertiesFilterHeaderContainer = styled.div`
  display: flex;
  margin: 24px 24px 0px 24px;
  align-items: center;
`;

export const PropertiesFilterHeader = styled.span`
  flex: 1;
  ${H5Style}
`;

export const PropertiesFiltersCount = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${colors.secondary.pure_blue};
  cursor: pointer;

  :hover {
    text-decoration: none;
  }
`;

export const PropertiesResultsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;

  /* To always center pagination. */
  max-width: 812px;

  ${down("tablet")} {
    margin: 0px 56px;
  }

  ${down("mobile")} {
    margin: 0px 24px;
  }
`;

export const PropertiesResultsLoading = styled.div`
  display: flex;
  align-items: center;
  min-height: 320px;
  height: 100%;
  justify-content: center;
`;

export const PropertiesFooterContainer = styled.div`
  background-color: ${colors.grayscale._200};
`;

export const PropertiesExtendedSearchLabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
  justify-content: center;
`;

export const PropertiesExtendedSearchLabel = styled.span`
  margin: 0px 24px;
  ${BodyMediumStyle}
`;

export const PropertiesExtendedSearchLine = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${colors.grayscale._400};
`;

export const PropertiesExtendedSearch = styled.div`
  width: 100%;
  height: fit-content;
`;

export const PropertiesExtendedSearchContainer = styled.div`
  padding: 24px;
  background-color: ${colors.primary.pure_white};
  border-radius: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
`;

export const PropertiesExtendedSearchImageContainer = styled.div`
  background-image: url(${NoResultsImage});
  width: 88px;
  height: 88px;
  position: relative;
`;

const MagAnimatinon = keyframes`
  0%, 100% {
    transform: translateX(-50%) rotate(64deg);
  }
  50% {
    transform: translateX(50%) rotate(0deg);
  }
`;

export const PropertiesExtendedMagIcon = styled.img`
  height: 74px;
  position: absolute;
  bottom: -16px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  animation: ${MagAnimatinon} 3.5s ease-in-out infinite;
`;

export const PropertiesExtendedSearchContainerHeader = styled.h5`
  margin: 0px;
  padding: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${colors.primary.frosted_black};
  margin-top: 24px;
`;

export const PropertiesExtendedSearchContainerMessage = styled.span`
  ${BodySmallStyle}
  color: ${colors.grayscale._600};
  text-align: center;
  max-width: 530px;
  display: block;
`;
