import React, { FunctionComponent } from "react";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import App from "../modules/App/App";
import client from "./apollo";

const Root: FunctionComponent = () => (
  <ApolloHooksProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloHooksProvider>
);

export default Root;
