import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router";
import useQueryParameters from "../../common/helpers/useQueryParameters";
import { useClaimAccountMutation } from "../../generated/schema";
import routesPaths from "../Routing/routesPaths";
import ScreenLoading from "../Routing/ScreenLoading";
import AuthContainer from "../shared/components/AuthContainer/AuthContainer";
import AccountActivation from "./AccountActivation";
import RegisterForm from "./Form/RegisterForm";
import TokenExpired from "./TokenExpired";

const Register: FunctionComponent = () => {
  const token = useQueryParameters().get("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState("");
  const [tokenExpired, setTokenExpired] = useState(false);

  const [claimAccountMutation] = useClaimAccountMutation();

  const claimAccount = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await claimAccountMutation({
        variables: {
          token: token || "",
        },
      });

      setLoading(false);
      switch (data?.claimAccount.__typename) {
        case "ClaimAccountSuccessfulResponse":
          navigate(`${routesPaths.LOGIN}?accountActivated`);
          break;
        case "ClaimAccountTokenExpiredResponse":
          setTokenExpired(true);
          break;
        case "ClaimAccountTokenNotFoundResponse":
          navigate(routesPaths.LOGIN);
          break;
      }
    } catch (e) {
      setLoading(false);
      navigate(routesPaths.LOGIN);
    }
  }, [claimAccountMutation, navigate, token]);

  useEffect(() => {
    setLoading(true);
    if (token) claimAccount();
  }, [token, claimAccount]);

  return (
    <AuthContainer
      // prettier-ignore
      title={<>Enhance your<br />deal flow</>}
      description="Once you have completed your registration, you will be assigned a personal account manager. This manager will help you best use our platform to drive leads."
    >
      {!token ? (
        <>
          {!registered ? (
            <RegisterForm setRegistered={setRegistered} />
          ) : (
            <AccountActivation email={registered} />
          )}
        </>
      ) : loading ? (
        <ScreenLoading />
      ) : (
        <>{tokenExpired ? <TokenExpired /> : null}</>
      )}
    </AuthContainer>
  );
};

export default Register;
