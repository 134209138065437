import React, { FunctionComponent, ComponentPropsWithoutRef } from "react";
import ButtonLoader from "../ButtonLoader";
import { StyledButton, StyledButtonProps } from "./Button.components";

export type ButtonProps = ComponentPropsWithoutRef<"button"> &
  StyledButtonProps;

const Button: FunctionComponent<ButtonProps> = ({
  children,
  size = "normal",
  buttonType = "primary",
  height,
  isLoading,
  disabled,
  ...props
}) => {
  return (
    <StyledButton
      size={size}
      buttonType={buttonType}
      height={height}
      isLoading={isLoading}
      disabled={disabled || isLoading}
      isDisabled={disabled}
      {...props}
    >
      {!isLoading ? children : <ButtonLoader type={buttonType} />}
    </StyledButton>
  );
};

export default Button;
