import * as Yup from "yup";

export interface RegisterFormValues {
  email: string;
  password: string;
  termsAndPolicyAccepted: boolean;
}
export const registerInitialValues = {
  email: "",
  password: "",
  termsAndPolicyAccepted: false,
};
export const registerValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be valid")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Passwords must contain at least 8 characters")
    .required("Passwords must contain at least 8 characters"),
  termsAndPolicyAccepted: Yup.boolean().isTrue().required(),
});
