import { Form } from "formik";
import styled, { css } from "styled-components";
import { colors } from "../../../colors";
import { down } from "../../../media";
import { TinyStyle, VerticalGap } from "../../../mixins";

export const AccountDetailsModalFormContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  width: 100%;

  ${down("tablet")} {
    flex-direction: column;
    ${VerticalGap(16)};
  }
`;
export const AccountDetailsInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 272px;
  ${VerticalGap(16)};

  ${down("tablet")} {
    max-width: 280px;
  }

  ${down("mobile")} {
    min-width: 100px;
    max-width: 90%;
  }
`;

export const AccountDetailsTextareaWrapper = styled.div`
  width: 100%;
  max-height: 160px;
  margin-left: 24px;

  ${down("tablet")} {
    margin-left: 0px;
    max-height: 112px;
  }
`;

export const AccountDetailsButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  ${VerticalGap(8)};

  ${down("tablet")} {
    margin-top: 56px;
  }

  ${down("mobile")} {
    margin-top: auto;
    width: 100%;
  }
`;

export const AccountDetailsError = styled.span`
  ${TinyStyle};
  color: ${colors.semantic.red_dark};
  min-height: 10px;
`;

export const AccountDetailsButtonsWrapper = styled.div<{
  singleButton?: boolean;
}>`
  display: flex;

  ${down("mobile")} {
    ${(p) =>
      !p.singleButton &&
      css`
        > :last-child {
          margin-left: 16px;
        }
      `}
  }
`;

export const AccountDetailsButtonWrapper = styled.div`
  width: 128px;
  margin-right: 16px;
  ${down("mobile")} {
    width: 100%;
    margin-right: 0px;
  }
`;

export const AccountDetailsForm = styled(Form)`
  ${down("mobile")} {
    height: 100%;
    display: flex;
    flex-direction: column;
    ${VerticalGap(16)};
  }
`;
