import React, { FunctionComponent, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import {
  NewPasswordInput,
  useChangePasswordMutation,
} from "../../../generated/schema";
import {
  initialValues,
  SetNewPasswordFormValues,
  setNewPasswordValidationSchema,
} from "./form";
import { useNavigate } from "react-router";
import routesPaths from "../../Routing/routesPaths";
import SetNewPasswordFormContent from "./SetNewPasswordFormContent";

export interface SetNewPasswordFormProps {
  token: string;
  onLinkExpired: () => void;
}

const SetNewPasswordForm: FunctionComponent<SetNewPasswordFormProps> = ({
  token,
  onLinkExpired,
}) => {
  const navigate = useNavigate();
  const [changePassword] = useChangePasswordMutation();

  const handleSubmit = useCallback(
    async (
      values: SetNewPasswordFormValues,
      { setSubmitting }: FormikHelpers<SetNewPasswordFormValues>,
    ) => {
      try {
        const { data } = await changePassword({
          variables: {
            input: {
              newPassword: values.password,
              passwordResetToken: token,
            } as NewPasswordInput,
          },
        });

        switch (data?.changePassword.__typename) {
          case "NewPasswordSuccessfulResponse":
            navigate(`${routesPaths.LOGIN}?passwordChanged`);
            return;
          case "NewPasswordTokenExpiredResponse":
            onLinkExpired();
            return;
          case "NewPasswordInvalidResponse":
            navigate(routesPaths.LOGIN);
            return;
        }
      } catch (e) {
        console.error(e);
      }
      setSubmitting(false);
    },
    [changePassword, navigate, onLinkExpired, token],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={setNewPasswordValidationSchema}
      onSubmit={handleSubmit}
    >
      {(formProps) => <SetNewPasswordFormContent {...formProps} />}
    </Formik>
  );
};

export default SetNewPasswordForm;
