import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import TopBar from "../shared/components/TopBar/TopBar";
import PropertyCard from "../shared/components/PropertyCard";
import {
  ListingLoaderContainer,
  ListingResultsLoadingContainer,
  ListingsContainer,
  ListingsFooterContainer,
  ListingsInnerContainer,
  ListingsNoResultsContainer,
  ListingsPaginationWrapper,
  ListingsResultDetails,
} from "../shared/components/Listings/Listings.components";
import useListingsStatus from "../../common/helpers/useListingsStatus";
import { PropertyAction } from "../shared/components/PropertyCard/PropertyActions/PropertyActions";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  Property,
  PropertyStatus,
  useDeletePropertyMutation,
  useGetAllUsersPropertiesLazyQuery,
  useSetPropertyStatusMutation,
} from "../../generated/schema";
import { scrollToElement } from "../../common/helpers/scrollHelpers";
import PageLoader from "../shared/components/PageLoader/PageLoader";
import NothingHere from "../shared/components/NothingHere";
import Pagination from "../shared/components/Pagination";
import {
  getPropertiesCountText,
  useCurrentPage,
  usePaginatedResponse,
} from "../../common/helpers/paginationHelpers";
import useAvailablePropertyActions from "../../common/helpers/useAvailablePropertyActions";
import Footer from "../shared/components/Footer/Footer";
import { usePaginationInput } from "../../common/helpers/usePropertyPaginationInput";
import {
  getPendingPropertyDetailsRoute,
  getPropertyRoute,
  getPublicPropertyDetailsRoute,
} from "../../common/helpers/navigationHelpers";
import { getBadgeType } from "../shared/components/PropertyCard/PropertyBadge/PropertyBadge";
import { useModal } from "../shared/components/Modal";
import {
  getPropertyDeclineModal,
  getPropertyDeleteModal,
} from "../shared/components/Modals/modalsHelpers";
import useAppContext from "../App/AppContext/useAppContext";

const Listings: FunctionComponent = () => {
  //General variables
  const { openModal, closeModal } = useModal();
  const [currentPage, setCurrentPage] = useCurrentPage();
  const availableActions = useAvailablePropertyActions();
  const [setPropertyStatusMutation] = useSetPropertyStatusMutation();
  const status = useListingsStatus();
  const navigate = useNavigate();

  const [refetchLoading, setRefetchLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [query, { data, loading, refetch, called }] =
    useGetAllUsersPropertiesLazyQuery();
  const { authToken } = useAppContext();

  //Pagination variables:
  const { isEmpty, pageSize, pagesCount, totalCount } = usePaginatedResponse(
    data?.getAllUsersProperties,
  );
  const paginationInput = usePaginationInput(pageSize, currentPage);

  const getProperties = useCallback(async () => {
    if (called) {
      setRefetchLoading(true);
      await refetch({
        input: { ...paginationInput, status },
      });
      setRefetchLoading(false);
    } else {
      query({ variables: { input: { ...paginationInput, status } } });
    }
  }, [called, paginationInput, query, refetch, status]);

  const onPageChange = useCallback(
    (page: number) => {
      scrollToElement("listingsContainer");
      setSearchParams({ page: page.toString() });
      setCurrentPage(page);
    },
    [setCurrentPage, setSearchParams],
  );

  // Check if user is out of paging and redirect to last page.
  useEffect(() => {
    const count = data?.getAllUsersProperties.itemsCount ?? 0;
    const pages = Math.ceil(count / pageSize);
    const outOfPaging = currentPage > pages;
    if (!loading && outOfPaging && count > 0) {
      onPageChange(pages);
    }
  }, [currentPage, data, loading, onPageChange, pageSize]);

  useEffect(() => {
    getProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, status]);

  const [deletePropertyMutation] = useDeletePropertyMutation();

  const setPropertyStatus = useCallback(
    async (id: number, status: PropertyStatus) => {
      try {
        setRefetchLoading(true);
        await setPropertyStatusMutation({
          variables: { id, status },
        });
        closeModal();
        await getProperties();
        setRefetchLoading(false);
      } catch (e) {
        console.log(e);
        setRefetchLoading(false);
      }
    },
    [closeModal, getProperties, setPropertyStatusMutation],
  );

  const deleteProperty = useCallback(
    async (uid: string) => {
      setRefetchLoading(true);
      await deletePropertyMutation({ variables: { uid } });
      closeModal();
      await getProperties();
      setRefetchLoading(false);
    },
    [closeModal, deletePropertyMutation, getProperties],
  );

  const handleDeleteProperty = useCallback(
    (address: string, uid: string) => {
      const modal = getPropertyDeleteModal(
        address,
        () => deleteProperty(uid),
        () => closeModal(),
      );
      openModal(modal);
    },
    [closeModal, deleteProperty, openModal],
  );

  const handleDeclineProperty = useCallback(
    (address: string, id: number) => {
      const modal = getPropertyDeclineModal(
        address,
        () => setPropertyStatus(id, PropertyStatus.Declined),
        () => closeModal(),
      );
      openModal(modal);
    },
    [closeModal, openModal, setPropertyStatus],
  );

  const handleAcceptProperty = useCallback(
    (id: number) => {
      setPropertyStatus(id, PropertyStatus.Active);
    },
    [setPropertyStatus],
  );

  const handleViewProperty = useCallback(
    (status: PropertyStatus, uid: string, slug: string) => {
      const path =
        status === PropertyStatus.Active
          ? getPublicPropertyDetailsRoute(slug, uid)
          : status === PropertyStatus.Pending
          ? getPendingPropertyDetailsRoute(uid)
          : getPropertyPreviewDetailsRoute(uid);

      navigate(path!);
    },
    [navigate],
  );

  const onPropertyActionClick = useCallback(
    async (
      action: PropertyAction,
      property: Pick<Property, "id" | "uid" | "slug" | "status" | "address">,
    ) => {
      const { id, uid, slug, status, address } = property;

      if (action === PropertyAction.DELETE) {
        handleDeleteProperty(address, uid);
      } else if (action === PropertyAction.DECLINE) {
        handleDeclineProperty(address, id);
      } else if (action === PropertyAction.ACCEPT) {
        handleAcceptProperty(id);
      } else {
        handleViewProperty(status, uid, slug);
      }
    },
    [
      handleDeleteProperty,
      handleDeclineProperty,
      handleAcceptProperty,
      handleViewProperty,
    ],
  );

  return (
    <>
      <ListingsContainer id="listingsContainer">
        <TopBar heading="Properties List" status={status} />
        <ListingsInnerContainer>
          {refetchLoading && (
            <ListingResultsLoadingContainer>
              <PageLoader />
            </ListingResultsLoadingContainer>
          )}
          {loading ? (
            <ListingLoaderContainer>
              <PageLoader />
            </ListingLoaderContainer>
          ) : isEmpty && !refetchLoading && !loading ? (
            <ListingsNoResultsContainer>
              <NothingHere />
            </ListingsNoResultsContainer>
          ) : (
            <>
              <ListingsResultDetails smallerMargin={true}>
                {getPropertiesCountText(totalCount, currentPage, pagesCount)}
              </ListingsResultDetails>
              {data?.getAllUsersProperties.data.map((property) => (
                <PropertyCard
                  {...property}
                  url={getPropertyRoute(
                    status,
                    property.slug,
                    property.uid,
                    authToken?.accountType,
                  )}
                  loading={loading || refetchLoading}
                  name={property?.contactName}
                  description={property.address}
                  key={property.id.toString()}
                  onActionClick={(action) =>
                    onPropertyActionClick(action, property)
                  }
                  // Field is required so the first one should always be present
                  img={property.propertyPhotos[0]}
                  availableActions={availableActions}
                  badgeType={getBadgeType(property.propertyInsight)}
                  isExternal={property.isExternal}
                />
              ))}
              <ListingsPaginationWrapper>
                <Pagination
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={pageSize}
                  onPagechange={onPageChange}
                />
              </ListingsPaginationWrapper>
            </>
          )}
        </ListingsInnerContainer>
      </ListingsContainer>
      <ListingsFooterContainer>
        <Footer />
      </ListingsFooterContainer>
    </>
  );
};

export default Listings;
function getPropertyPreviewDetailsRoute(uid: string) {
  throw new Error("Function not implemented.");
}
