import styled from "styled-components";
import { colors } from "../../shared/colors";
import { AppLayoutStyle } from "../../shared/mixins";

export const AuthedLayoutContent = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.grayscale._200};
  ${AppLayoutStyle()}
`;
