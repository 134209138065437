import styled, { keyframes } from "styled-components";
import { colors } from "../../shared/colors";
import { down } from "../../shared/media";
import { H5Style, ScrollBarStyle } from "../../shared/mixins";

const SlideInAnimation = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0%); }`;

const SlideOutAnimation = keyframes`
  0% { transform: translateX(0%);}
  100% { transform: translateX(-100%); }
`;

export const FiltersModalContainer = styled.div<{ visible: boolean }>`
  display: flex;
  position: fixed;
  z-index: 3000;
  top: 0px;
  bottom: 0px;
  right: 100px;
  left: 56px;
  flex-direction: column;
  max-width: 612px;
  left: 0px;

  ${down("tablet")} {
    animation: ${(p) => (p.visible ? SlideInAnimation : SlideOutAnimation)}
      500ms ease-in-out forwards;
  }

  ${down("mobile")} {
    animation: none;
    background-color: ${colors.primary.frosted_black_transparent};
    padding-top: 24px;
    left: 0px;
    right: 0px;
  }
`;

export const FiltersModalHeaderContainer = styled.div`
  height: 56px;
  background-color: ${colors.primary.pure_white};
  position: relative;
  padding: 0px 16px 0px 24px;
  align-items: center;
  border-bottom: 1px solid ${colors.grayscale._300};
  ${down("mobile")} {
    border-radius: 24px 24px 0px 0px;
  }
`;

export const FiltersModalHeaderClose = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  left: 16px;
  top: 4px;
  bottom: 0;
  transform: translateY(50%);
  position: absolute;
`;

export const FiltersModalHeader = styled.span`
  ${H5Style()};
  line-height: 28px;
  transform: translateY(50%);
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
`;
export const FiltersModalClear = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 4px;
  right: 16px;
  text-decoration-line: underline;
  color: ${colors.secondary.pure_blue};
  cursor: pointer;
  padding: 16px;

  :hover {
    text-decoration: none;
  }
`;

export const FiltersModalContent = styled.div`
  ${ScrollBarStyle()}
  background-color: ${colors.primary.pure_white};
  overflow-y: auto;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 64px;
  top: 57px;

  ${down("mobile")} {
    top: 81px;
  }
`;

export const FiltersModalFooter = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 64px;
  background-color: ${colors.primary.pure_white};
  box-shadow: 0px 0px 50px -22px rgba(37, 122, 208, 0.5);

  ${down("mobile")} {
    left: 0px;
    right: 0px;
  }
`;

export const FiltersModalFooterButton = styled.div`
  max-width: 320px;
  display: flex;
  height: 100%;
  align-items: center;
  margin: auto;

  ${down("mobile")} {
    margin: 0px 24px;
    max-width: unset;
  }
`;
