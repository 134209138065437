import styled from "styled-components";
import { colors } from "../../../colors";
import {
  ButtonSmallStyle,
  LeadSmallStyle,
  ScrollBarStyle,
} from "../../../mixins";
import IconOpenClose from "../icon_open_close.svg";

export const OpenCloseImage = styled.div<{
  disabled: boolean;
  opened: boolean;
}>`
  z-index: 1;
  position: absolute;
  top: 16px;
  right: 12px;
  width: 13px;
  height: 13px;
  background-image: url(${IconOpenClose});
  transform: rotate(${(p) => (p.opened ? 0 : 180)}deg);
  transition: transform 250ms ease-in-out;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  pointer-events: none;
`;

export const SingleSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div<{ empty: boolean; disabled: boolean }>`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  display: ${(p) => (p.empty ? "block" : "inline-block")};
  margin-top: 0px;
`;

export const SelectionContainer = styled.div<{
  disabled: boolean;
  error: boolean;
}>`
  height: 100%;
  min-height: 48px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 14px;
  position: relative;
  border-radius: 8px;
  background-color: ${(p) =>
    p.disabled ? colors.grayscale._100 : colors.primary.pure_white};
  border: 1px solid
    ${(p) => (p.error ? colors.semantic.red_dark : colors.grayscale._400)};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  padding-right: 16px;

  :hover,
  :active,
  :focus {
    border-color: ${(p) =>
      p.error ? colors.semantic.red_dark : colors.secondary.pure_blue};

    > img {
      transform: rotate(0deg);
    }
  }

  :active,
  :focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px rgba(59, 122, 247, 0.1);
  }
`;

export const SelectionOption = styled.span<{ disabled: boolean }>`
  ${LeadSmallStyle};
  color: ${(p) =>
    p.disabled ? colors.grayscale._600 : colors.primary.frosted_black};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "default")};
  margin: auto 0px;
`;

export const ListContainer = styled.div`
  z-index: 2;
  display: block;
  position: absolute;
  background-color: ${colors.primary.pure_white};
  margin-top: 8px;
  left: 0;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  max-height: 248px;
  overflow-y: scroll;
  border-radius: 8px;

  @supports (-moz-appearance: none) {
    /* Fixefox scrollbar should be moved to 8px from list. */
    padding-right: 8px;
  }

  ${ScrollBarStyle};
`;

export const ListOption = styled.button<{
  selected: boolean;
  disabled: boolean;
}>`
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  text-align: left;
  height: 40px;
  background-color: ${(p) =>
    p.selected
      ? colors.secondary.pure_blue_transparent
      : colors.primary.pure_white};
  border: none;
  border-radius: 8px;
  background-color: ${(p) =>
    p.selected
      ? colors.secondary.pure_blue_transparent
      : colors.primary.pure_white};
  width: calc(100% - 8px);
  margin: 4px 0px 4px 8px;
  color: ${(p) => (p.selected ? colors.grayscale._800 : colors.grayscale._700)};

  :first-of-type {
    margin: 8px 0px 4px 8px;
  }

  :last-of-type {
    margin: 4px 0px 8px 8px;
  }

  :hover {
    background-color: ${colors.secondary.pure_blue_transparent};
  }

  :focus,
  :hover {
    outline: none;
    background-color: ${colors.secondary.pure_blue_transparent};
  }
`;

export const OptionSpan = styled.span<{ selected: boolean }>`
  ${(p) => (p.selected ? ButtonSmallStyle : LeadSmallStyle)};
  color: ${colors.grayscale._800};
  margin-left: 8px;
`;
