import styled from "styled-components";
import { colors } from "../../../../shared/colors";
import { down } from "../../../../shared/media";

export const AttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 24px 40px;
  background: ${colors.primary.pure_white};
  margin-bottom: 8px;
  max-width: 720px;
  box-sizing: border-box;
  ${down("tablet")} {
    width: 100%;
    max-width: unset;
  }

  ${down("mobile")} {
    padding: 32px 24px;
  }
`;

export const FileListWrapper = styled.div`
  margin-top: 16px;
`;
