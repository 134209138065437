import styled, { css } from "styled-components";
import { colors } from "../../../colors";
import {
  ButtonSmallStyle,
  LeadSmallStyle,
  ScrollBarStyle,
} from "../../../mixins";
import IconOpenClose from "../icon_open_close.svg";

export const OpenCloseContainer = styled.div<{ disabled: boolean }>`
  background-color: white;
  z-index: 1;
  position: absolute;
  width: 26px;
  right: 2px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  height: 50%;
  top: 50%;
  transform: translateY(-50%);
`;

export const OpenCloseImage = styled.div<{
  opened: boolean;
  disabled: boolean;
}>`
  width: 13px;
  height: 13px;
  background-image: url(${IconOpenClose});
  transform: rotate(${(p) => (p.opened ? 0 : 180)}deg);
  transition: transform 250ms ease-in-out;
  pointer-events: none;
`;

export const MultiSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div<{
  empty: boolean;
  disabled: boolean;
  truncated: boolean;
  inputSize: "m" | "l";
}>`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  display: ${(p) => (p.empty ? "block" : "inline-block")};
  margin-top: 0px;
  ${(p) =>
    p.truncated &&
    css`
      max-height: ${p.inputSize === "m" ? 40 : 48}px;
    `}
`;

export const SelectionContainer = styled.div<{
  disabled: boolean;
  error: boolean;
  truncated: boolean;
  inputSize: "m" | "l";
}>`
  height: 100%;
  min-height: ${(p) => (p.inputSize === "m" ? 40 : 48)}px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 14px;
  ${(p) =>
    p.inputSize === "m" &&
    css`
      padding: 4px 14px;
    `}
  position: relative;
  border-radius: 8px;
  background-color: ${(p) =>
    p.disabled ? colors.grayscale._100 : colors.primary.pure_white};
  border: 1px solid
    ${(p) => (p.error ? colors.semantic.red_dark : colors.grayscale._400)};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};

  :hover,
  :active,
  :focus {
    border-color: ${(p) =>
      p.error ? colors.semantic.red_dark : colors.secondary.pure_blue};

    > img {
      transform: rotate(0deg);
    }
  }

  :active,
  :focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px rgba(59, 122, 247, 0.1);
  }

  ${(p) =>
    p.truncated &&
    css`
      display: inline-flex;
      flex-wrap: nowrap;
      overflow: hidden;
    `}
`;

export const SelectionDeleteImage = styled.img`
  margin-left: 0px;
`;

export const SelectionOption = styled.span<{
  disabled: boolean;
  deleteVisible: boolean;
  isTruncated?: boolean;
}>`
  ${LeadSmallStyle};
  color: ${(p) =>
    p.disabled ? colors.grayscale._600 : colors.primary.frosted_black};
  display: flex;
  align-items: center;

  height: 22px;
  max-height: 22px;
  margin: 2px 2px;

  ${(p) =>
    p.isTruncated &&
    css`
      margin: auto 2px;
    `}

  box-sizing: border-box;
  border-radius: 20px;
  background-color: ${(p) =>
    p.disabled
      ? colors.grayscale._200
      : colors.secondary.pure_blue_transparent};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "default")};
  min-width: fit-content;

  ${(p) =>
    p.deleteVisible
      ? css`
          padding: 0px 4px 0px 12px;
        `
      : css`
          padding: 0px 12px;
        `}
`;

export const ListContainer = styled.div`
  z-index: 2;
  display: block;
  position: absolute;
  border-radius: 8px;
  background-color: ${colors.primary.pure_white};
  margin-top: 8px;
  left: 0;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  max-height: 248px;
  overflow-y: scroll;

  ${ScrollBarStyle};
`;

export const ListOption = styled.button<{
  selected: boolean;
  disabled: boolean;
}>`
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  min-height: 40px;
  text-align: start;
  background-color: ${(p) =>
    p.selected
      ? colors.secondary.pure_blue_transparent
      : colors.primary.pure_white};
  border: none;
  border-radius: 8px;
  background-color: ${(p) =>
    p.selected
      ? colors.secondary.pure_blue_transparent
      : colors.primary.pure_white};
  width: calc(100% - 8px);
  margin: 4px 0px 4px 8px;
  color: ${(p) => (p.selected ? colors.grayscale._800 : colors.grayscale._700)};

  :first-of-type {
    margin: 8px 0px 4px 8px;
  }

  :last-of-type {
    margin: 4px 0px 8px 8px;
  }

  :hover,
  :focus {
    background-color: ${colors.secondary.pure_blue_transparent};
  }
`;

export const OptionSpan = styled.span<{ selected: boolean }>`
  ${(p) => (p.selected ? ButtonSmallStyle : LeadSmallStyle)};
  color: ${colors.grayscale._800};
  margin-left: 8px;
`;

export const SelectionDeleteButton = styled.button<{
  disabled: boolean;
  truncated: boolean;
}>`
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  padding: 2px 4px;
  transition: transform 200ms ease-in-out;

  :focus {
    outline: none;
    transform: scale(1.2);
  }
`;
