import React, {
  ComponentPropsWithoutRef,
  FunctionComponent,
  useState,
} from "react";
import ClickAwayListener from "react-click-away-listener";
import {
  Container,
  ListContainer,
  ListOption,
  OpenCloseImage,
  OptionSpan,
  SelectionContainer,
  SelectionOption,
  SingleSelectionContainer,
} from "./SingleSelect.components";
import Label from "../../Form/Input/Label";
import ErrorMessage from "../../Form/shared/ErrorMessage";
import { useDropdownKeyboardListener } from "../useDropdownKeyboardListener";

export type SingleSelectProps = ComponentPropsWithoutRef<"select"> & {
  error?: string;
  label: string;
  options: string[];
  name: string;
  hint?: string;
  selection?: number;
  isDisabled?: boolean;
  optionClick: (option: number) => void;
  submitCount?: number;
};

const SingleSelect: FunctionComponent<SingleSelectProps> = ({
  options,
  selection,
  optionClick,
  hint,
  label,
  error,
  name,
  isDisabled = false,
  submitCount = 0,
}) => {
  const [listVisible, setListVisible] = useState(false);
  const [uniqueClassName] = useState(`singleselect-${name}`);

  useDropdownKeyboardListener(isDisabled, uniqueClassName, setListVisible);
  const showError = submitCount > 0 && !!error;

  return (
    <ClickAwayListener onClickAway={() => setListVisible(false)}>
      <SingleSelectionContainer>
        <Label hint={hint}>{label}</Label>
        <Container
          empty={selection === undefined || selection < 0}
          disabled={isDisabled}
        >
          <OpenCloseImage
            opened={listVisible}
            disabled={isDisabled}
            onClick={() => !isDisabled && setListVisible(!listVisible)}
          />
          <SelectionContainer
            className={uniqueClassName}
            error={!isDisabled && showError}
            disabled={isDisabled}
            onClick={() => !isDisabled && setListVisible(!listVisible)}
            tabIndex={0}
          >
            <SelectionOption disabled={isDisabled}>
              {selection !== undefined && selection >= 0 && options[selection]}
            </SelectionOption>
          </SelectionContainer>
          {listVisible && (
            <ListContainer>
              {options.map((option, index) => {
                const selected = selection === index;
                return (
                  <ListOption
                    disabled={isDisabled}
                    autoFocus={
                      // Focus first element if there is no currently selected item.
                      listVisible
                        ? selection && selection >= 0
                          ? selected
                          : index === 0
                        : false
                    }
                    type="button"
                    className={`${uniqueClassName}-button`}
                    key={option}
                    selected={selected}
                    onClick={() => {
                      if (!isDisabled) {
                        optionClick(index === selection ? -1 : index);
                        setListVisible(false);
                      }
                    }}
                  >
                    <OptionSpan selected={selected}>{option}</OptionSpan>
                  </ListOption>
                );
              })}
            </ListContainer>
          )}
        </Container>
        {!isDisabled && showError && <ErrorMessage>{error}</ErrorMessage>}
      </SingleSelectionContainer>
    </ClickAwayListener>
  );
};

export default SingleSelect;
