import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { differenceInMinutes } from "date-fns";
import {
  deleteAuthToken,
  getAuthToken,
  setAuthToken,
} from "../../../common/helpers/authToken";
import refreshToken from "./refreshToken";

const authTokenLink = setContext(async (_, context) => {
  const {
    headers,
    skipAuthentication = false,
    skipAutoTokenRefresh = false,
  } = context;

  if (skipAuthentication) {
    return context;
  }

  let authToken = getAuthToken();

  if (authToken !== null && !skipAutoTokenRefresh) {
    const validDuration = differenceInMinutes(
      authToken.expiresAt,
      authToken.issuedAt,
    );
    const diff = differenceInMinutes(authToken.expiresAt, new Date());
    if (diff > 0 && diff < validDuration / 2) {
      // token is still valid, but about to expire soon so new one is requested
      authToken = await refreshToken();
      setAuthToken(authToken);
    }
  }

  return {
    ...context,
    headers: {
      ...headers,
      ...(authToken && { authorization: `Bearer ${authToken.token}` }),
    },
  };
});

interface Exception {
  response: string;
  message: string;
  status: number;
}

const unauthorizedErrorLink = onError(({ graphQLErrors }) => {
  if (!graphQLErrors) {
    return;
  }

  for (const error of graphQLErrors) {
    const errException: Exception | null = error.extensions?.exception;

    if (errException?.response === "InvalidToken") {
      deleteAuthToken();
    }
  }
});

const authFlowLink = authTokenLink.concat(unauthorizedErrorLink);

export default authFlowLink;
