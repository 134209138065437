import styled from "styled-components";
import { colors } from "../../shared/colors";
import { AppLayoutStyle } from "../../shared/mixins";

export const AddPropertyLayoutContent = styled.div`
  ${AppLayoutStyle()};

  > form {
    justify-content: center;
    flex: 1;
    display: flex;
    background: ${colors.grayscale._200};
  }
`;

export const AddPropertyDivider = styled.div`
  height: 1px;
  background-color: ${colors.grayscale._300};
`;
