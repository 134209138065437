import { getFileName } from "../../../../../common/helpers/fileNameHelpers";
import React, { FunctionComponent } from "react";
import FilesList from "../../../../shared/components/FilesList/FilesList";
import { PropertyDetailsSectionHeading } from "../../PropertyDetails.components";
import { AttachmentsWrapper, FileListWrapper } from "./Attachments.components";

type AttachmentsProps = {
  heading: string;
  files: string[] | undefined | null;
};

const Attachments: FunctionComponent<AttachmentsProps> = ({
  heading,
  files,
}) => {
  if (!files || files.length === 0) return null;
  return (
    <AttachmentsWrapper>
      <PropertyDetailsSectionHeading>{heading}</PropertyDetailsSectionHeading>
      <FileListWrapper>
        <FilesList
          names={files.map((file, i) => {
            // Filename is last url part after /
            const fileName = getFileName(file);
            return { id: `${file}${i}`, name: fileName!, url: file };
          })}
        />
      </FileListWrapper>
    </AttachmentsWrapper>
  );
};

export default Attachments;
