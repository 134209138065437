import { down } from "../../../../../../../modules/shared/media";
import styled from "styled-components";
import { colors } from "../../../../../../shared/colors";
import { BodySmallStyle } from "../../../../../../shared/mixins";

export const CrimeRateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const CrimeRateChartContainer = styled.div`
  display: flex;
  padding: 24px 28px 32px 30px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 16px;
  width: 100%;
  border: 1px solid ${colors.grayscale._300};

  ${down("mobile")} {
    flex-direction: column;
  }
`;

export const CrimeRateChartWrapper = styled.div`
  display: flex;
  position: relative;
  max-width: 180px;
  max-height: 180px;

  > canvas {
    max-width: 180px;
    max-height: 180px;
    z-index: 1;
    pointer-events: none;
  }
`;

export const CrimeRateBlurLayer = styled.div`
  display: flex;
  width: 178px;
  height: 178px;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 15px 30px rgba(52, 149, 239, 0.25);
`;

export const CrimeRateWhiteDot = styled.span`
  position: absolute;
  top: 3.5px;
  left: 51%;
  transform: translateX(-50%);
  width: 12px;
  height: 12px;
  background: ${colors.primary.pure_white};
  z-index: 2;
  border-radius: 50%;
`;

export const CrimeRateChartCaptionWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.primary.pure_white};
  max-width: 100px;
  max-height: 100px;
  height: 100%;
  width: 100%;
  z-index: 2;
  border-radius: 50%;
`;

export const CrimeRateChartCaption = styled.span`
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: ${colors.grayscale._500};
  }
`;

export const CrimeRateChartStatus = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.grayscale._600};
  margin-top: 4px;
  text-transform: capitalize;
`;

export const CrimeRateDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;

  ${down("mobile")} {
    margin-top: 24px;
    margin-left: 0px;
  }
`;

export const CrimeRateHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  > h5 {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: ${colors.primary.frosted_black};
    text-transform: capitalize;
  }
  > svg {
    margin-left: 4px;
    transform: rotate(180deg);
    width: 9.5px;
    height: 12px;
  }
`;

export const CrimeRateDescription = styled.p`
  ${BodySmallStyle()};
  color: ${colors.grayscale._600};
  margin: 0;
  margin-top: 4px;
  margin-bottom: 16px;
`;

export const FindOutMoreButton = styled.a`
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${colors.secondary.pure_blue_dark};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;
