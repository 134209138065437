import React, { FunctionComponent, useCallback, useState } from "react";
import Button from "../../shared/components/Button";
import {
  BackIcon,
  BackToLoginLink,
  BottomDescription,
  ButtonContainer,
  ButtonRegisterContainer,
  CommitLogo,
  Container,
  Description,
  EmailSentImage,
  FormContent,
  Header,
} from "../SetNewPassword/SetNewPassword.components";
import CommigLogoBlue from "../../shared/images/commit_logo_blue.svg";
import routesPaths from "../../Routing/routesPaths";
import ArrowLeft from "../arrow_left.svg";
import EmailSent from "../email_sent.svg";
import { useRequestPasswordResetMutation } from "../../../generated/schema";

type ConfirmationProps = {
  email: string;
};

const Confirmation: FunctionComponent<ConfirmationProps> = ({ email }) => {
  const [loading, setLoading] = useState(false);
  const [requestResetPassword] = useRequestPasswordResetMutation();

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      await requestResetPassword({
        variables: {
          input: {
            email: email,
          },
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [email, requestResetPassword]);

  return (
    <Container>
      <FormContent>
        <CommitLogo src={CommigLogoBlue} alt="Commit logo" />
        <EmailSentImage src={EmailSent} />
        <Header>Check your mailbox</Header>
        <Description>
          We sent you a message. If your email address exists in our database,
          you should have a message in your mailbox.{" "}
        </Description>

        <ButtonRegisterContainer>
          <BottomDescription>Didn't get a message?</BottomDescription>
          <ButtonContainer>
            <Button size="block" onClick={handleSubmit} isLoading={loading}>
              Re-send email
            </Button>
          </ButtonContainer>
          <BackToLoginLink to={routesPaths.LOGIN}>
            <BackIcon src={ArrowLeft} />
            Back to login
          </BackToLoginLink>
        </ButtonRegisterContainer>
      </FormContent>
    </Container>
  );
};

export default Confirmation;
