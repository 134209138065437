import { AuthToken } from "../helpers/authToken";
import { appEventTypes } from "./AppEventTarget";

interface TokenChangeEventDetail {
  authToken: AuthToken | null;
}

class TokenChangeEvent extends CustomEvent<TokenChangeEventDetail> {
  constructor(authToken: AuthToken | null) {
    super(appEventTypes.TOKEN_CHANGE, {
      detail: {
        authToken,
      },
    });
  }
}

export default TokenChangeEvent;
