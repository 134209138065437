import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../colors";
import { down } from "../../media";
import { H2Style, H4Style } from "../../mixins";
import TopBackground from "./TopBackground.svg";

export const TopBarContainer = styled.div`
  display: flex;
  align-self: center;
  justify-self: center;
  justify-content: center;
  width: 100%;
  max-width: 1360px;
  height: 192px;
  border-radius: 24px;
  background-image: url(${TopBackground});
  margin: 0 40px;
  position: relative;

  ${down("tablet")} {
    justify-content: unset;
  }
`;

export const TopBarHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1088px;
  padding: 56px 0px 96px;
  justify-content: space-between;
  align-items: center;

  ${down("tablet")} {
    max-width: unset;
    padding: 32px 40px 80px;
  }
`;

export const TopBarHeader = styled.h2`
  ${H2Style()};
  ${down("tablet")} {
    ${H4Style()};
    color: ${colors.primary.pure_white};
  }
`;

export const TopBarButtonsWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  max-width: 1088px;
  width: 100%;

  ${down("tablet")} {
    left: 40px;
    max-width: 300px;
  }
`;

export const TopBarButton = styled(Link)<{ active?: string }>`
  position: relative;
  display: flex;
  padding: 24px 16px;
  margin-right: 24px;
  border-radius: 8px 8px 0px 0px;
  text-decoration: none;
  text-transform: capitalize;
  transition: background 250ms ease, font-weight 250ms ease;
  color: rgba(0, 0, 0, 0);

  :after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: #ffffff;
    left: 0px;
    bottom: -1px;
    transition: transform 250ms ease;
    transform: scaleX(0);
  }

  ${(p) =>
    p.active === "true" &&
    css`
      > span {
        font-weight: 600;
      }
      background: rgba(255, 255, 255, 0.1);
      :after {
        transform: scaleX(1);
      }
    `}

  :hover {
    > span {
      font-weight: 600;
    }
    background: rgba(255, 255, 255, 0.1);
    :after {
      transform: scaleX(1);
    }
  }
`;

export const FakeSpan = styled.span`
  position: absolute;
  color: ${colors.grayscale._400};
`;
