import React, { FunctionComponent, useState, useCallback } from "react";
import useQueryParameters from "../../common/helpers/useQueryParameters";
import AuthContainer from "../shared/components/AuthContainer/AuthContainer";
import Confirmation from "./Confirmation/Confirmation";
import LinkExpired from "./LinkExpired/LinkExpired";
import RequestResetPasswordForm from "./RequestResetPassword/RequestResetPasswordForm";
import SetNewPassword from "./SetNewPassword/SetNewPassword";

const ResetPassword: FunctionComponent = () => {
  const token = useQueryParameters().get("token");
  const [email, setEmail] = useState("");
  const [currentStep, setCurrentStep] = useState<
    "REQUEST" | "CONFIRMATION" | "SET_NEW_PASSWORD" | "EXPIRED"
  >(token ? "SET_NEW_PASSWORD" : "REQUEST");

  const resetRequestHandler = useCallback((email: string) => {
    setEmail(email);
    setCurrentStep("CONFIRMATION");
  }, []);

  const linkExpiredHandler = useCallback(() => {
    setCurrentStep("EXPIRED");
  }, []);

  return (
    <AuthContainer
      title={<>The next generation of commercial property platform</>}
      description={[
        "List your properties on our platform for free.",
        "Enhance your listings with our free intelligence tooling.",
        "Get leads delivered directly to your inbox.",
      ]}
    >
      {currentStep === "REQUEST" && (
        <RequestResetPasswordForm onResetRequested={resetRequestHandler} />
      )}
      {currentStep === "CONFIRMATION" && <Confirmation email={email} />}
      {currentStep === "SET_NEW_PASSWORD" && (
        <SetNewPassword token={token!} onLinkExpired={linkExpiredHandler} />
      )}
      {currentStep === "EXPIRED" && <LinkExpired />}
    </AuthContainer>
  );
};

export default ResetPassword;
