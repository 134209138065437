export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AddPropertyResponse: [
      "AddPropertyApiErrorResponse",
      "AddPropertySuccessfulResponse",
    ],
    AddSeoLinkResponse: [
      "AddSeoLinkInvalidResponse",
      "AddSeoLinkSuccessfulResponse",
    ],
    ClaimAccountResponse: [
      "ClaimAccountSuccessfulResponse",
      "ClaimAccountTokenExpiredResponse",
      "ClaimAccountTokenNotFoundResponse",
    ],
    EditPropertyStatusResponse: [
      "EditPropertyInvalidResponse",
      "EditPropertyMissingPermissionResponse",
      "EditPropertySuccessfulResponse",
    ],
    GetPropertyResponse: [
      "GetPropertyInvalidPermissionsResponse",
      "GetPropertyInvalidPropertyStatusResponse",
      "GetPropertyNotFoundResponse",
      "GetPropertySuccessfulResponse",
    ],
    MessageAgentResponse: [
      "MessageAgentInvalidResponse",
      "MessageAgentSuccessfulResponse",
    ],
    NewPasswordResponse: [
      "NewPasswordInvalidResponse",
      "NewPasswordSuccessfulResponse",
      "NewPasswordTokenExpiredResponse",
    ],
    RegisterResponse: [
      "RegisterInvalidResponse",
      "RegisterSuccessfulResponse",
      "RegisterTokenExpiredResponse",
    ],
    RemoveSeoLinkResponse: [
      "RemoveSeoLinkInvalidResponse",
      "RemoveSeoLinkSuccessfulResponse",
    ],
    ResendLinkResponse: [
      "ResendLinkInvalidResponse",
      "ResendLinkSuccesfullResponse",
    ],
    ResetPasswordRequestResponse: [
      "ResetPasswordRequestInvalidResponse",
      "ResetPasswordRequestSuccessfulResponse",
    ],
    SetPropertyStatusResponse: [
      "SetPropertyStatusInvalidResponse",
      "SetPropertyStatusMissingPermissionResponse",
      "SetPropertyStatusSuccessfulResponse",
    ],
    TokenResponse: [
      "TokenInvalidResponse",
      "TokenSuccessfulResponse",
      "TokenUserInactiveResponse",
    ],
    UpdateUserResponse: [
      "UpdateUserInvalidResponse",
      "UpdateUserSuccessfulResponse",
    ],
    ValidateResetTokenResponse: [
      "ValidateResetTokenSuccessfulResponse",
      "ValidateResetTokenTokenExpiredResponse",
      "ValidateResetTokenTokenNotFoundResponse",
    ],
  },
};
export default result;
