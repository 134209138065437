import { TransitionStatus } from "react-transition-group";
import styled from "styled-components";
import { colors } from "../../../../../../shared/colors";
import { down } from "../../../../../../shared/media";
import { LeadSmallStyle, SmallStyle } from "../../../../../../shared/mixins";
import { ChartType } from "./PriceTrendChart";

export const PriceTrendContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PriceTrendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 24px 31px 2px;
  border: 1px solid ${colors.grayscale._300};
  box-sizing: border-box;
`;

export const PriceTrendHeading = styled.h5`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: ${colors.primary.frosted_black};
  margin-bottom: 24px;
  text-transform: capitalize;
`;

export const PriceTrendChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 181px;
  margin-bottom: 16px;

  @media (max-width: 683px) {
    width: 100%;
  }
`;

export const PriceTrendChartHeading = styled.div`
  display: flex;
  justify-content: space-between;

  // Percentage span
  > span {
    ${LeadSmallStyle()};
    color: ${colors.grayscale._600};
  }
`;

export const PriceTrendChartHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  // Chart label
  > span {
    ${SmallStyle()}
    color: ${colors.grayscale._600};

    ${down("mobile")} {
      ${LeadSmallStyle()};
      color: ${colors.grayscale._600};
    }
  }
  // Increasing/Decreasing icon
  > svg {
    margin-left: 4px;
  }
`;

export const PriceTrendChartBar = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  background: #e1e9f4;
  border-radius: 100px;
  margin-top: 4px;
`;

const getPercentageColor = (type: ChartType) => {
  switch (type) {
    case "YEAR":
      return colors.secondary.blue_extra_light;
    case "5_YEARS":
      return colors.secondary.pure_blue_light;
    case "10_YEARS":
      return colors.secondary.blue_extra_dark;
  }
};

export const PriceTrendChartPercentageBar = styled.span<{
  type: ChartType;
  status: TransitionStatus;
  percentage: number;
}>`
  position: absolute;
  background: ${(p) => getPercentageColor(p.type)};
  height: 6px;
  border-radius: 100px;
  width: 0;
  transition: width 1.5s ease;

  width: ${(p) => p.status === "entered" && `${p.percentage}%`};
`;
