import * as Yup from "yup";

export interface SeoFormValues {
  title: string;
  urlSlug: string;
  link: string;
  description: string;
}

export const seoFormInitialValues: SeoFormValues = {
  title: "",
  urlSlug: "",
  link: "",
  description: "",
};

export const seoFormValidationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  urlSlug: Yup.string().required("Url slug is required"),
  link: Yup.string().required("Link is required"),
  description: Yup.string().required("Description is required"),
});
