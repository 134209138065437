import React, { FunctionComponent } from "react";
import {
  ModalHeading,
  ModalHeaderWrapper,
  ModalDescription,
} from "./ModalHeader.components";

type ModalHeaderProps = {
  heading: string;
  description: string;
};

const ModalHeader: FunctionComponent<ModalHeaderProps> = ({
  heading,
  description,
}) => {
  return (
    <ModalHeaderWrapper>
      <ModalHeading>{heading}</ModalHeading>
      <ModalDescription>{description}</ModalDescription>
    </ModalHeaderWrapper>
  );
};

export default ModalHeader;
