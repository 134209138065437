import { down } from "../../../../../modules/shared/media";
import styled from "styled-components";
import { colors } from "../../../../shared/colors";
import {
  BodyMediumStyle,
  BodySmallStyle,
  H5Style,
  LeadSmallStyle,
} from "../../../../shared/mixins";

export const AreaStatisticsDescription = styled.p`
  margin: 0;
  ${LeadSmallStyle()};
  color: ${colors.grayscale._600};
  margin-bottom: 24px;

  ${down("mobile")} {
    ${BodyMediumStyle()}
    color: ${colors.grayscale._600};
  }
`;

export const AreaStatisticsChartHeader = styled.h4`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${colors.grayscale._600};
  margin-top: 24px;
`;

export const CrimeRateChartWrapper = styled.div`
  display: flex;
  max-width: 491px;
  width: 100%;
  margin: 24px 0;
`;

export const RowChartsWrapper = styled.div`
  display: flex;
  max-width: 491px;
  width: 100%;
  margin: 24px 0px;

  @media (max-width: 683px) {
    flex-direction: column;
  }

  ${down("mobile")} {
    margin: 0;
    flex-direction: column;
  }
`;

export const EvenMoreStatsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 24px;
  background: linear-gradient(269.21deg, #5598f6 0%, #2a7aea 99.85%);
  border-radius: 12px;
  margin-top: 24px;
  :last-child {
    align-items: center;
  }

  > button {
    color: ${colors.secondary.pure_blue_dark};
  }

  ${down("mobile")} {
    flex-direction: column;
    :last-child {
      align-items: flex-start;
    }

    > button {
      margin-top: 16px;
      height: 40px !important;
    }
  }
`;

export const EvenMoreStatsInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  > p {
    margin: 0;
    ${BodySmallStyle()};
    font-weight: 300;
    color: ${colors.grayscale._300};
    max-width: 370px;

    ${down("mobile")} {
      ${BodyMediumStyle()}
      color: ${colors.grayscale._300};
    }
  }
`;

export const EvenMoreStatsHeading = styled.h5`
  margin: 0;
  ${H5Style()};
  font-weight: 500;
  color: ${colors.primary.pure_white};
`;
