import { Link } from "react-router-dom";
import { TransitionStatus } from "react-transition-group";
import styled, { css } from "styled-components";
import { colors } from "../../colors";
import { zIndexes } from "../../constants";
import { down } from "../../media";
import { H6Style, HorizontalGap, LeadSmallStyle } from "../../mixins";

export const Overlay = styled.div<{ status: TransitionStatus }>`
  width: 100%;
  height: 100%;
  position: fixed;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.primary.frosted_black};
  transition: opacity 0ms ease-in-out;
  opacity: 0;
  z-index: ${zIndexes.NAVBAR_OVERLAY};
  ${(p) =>
    p.status === "entering" &&
    css`
      opacity: 0.4;
    `}

  ${(p) =>
    p.status === "entered" &&
    css`
      opacity: 0.4;
    `}

  ${down("tablet")} {
    display: block;
  }
`;

export const Container = styled.div`
  display: flex;
  top: 0px;
  width: 100%;
  height: 64px;
  background-color: rgba(255, 255, 255, 0.96);
  backdrop-filter: blur(8px);
  box-shadow: 0px 0px 50px -22px rgba(37, 122, 208, 0.5);
  justify-content: center;
  position: fixed;
  z-index: ${zIndexes.NAVBAR_CONTAINER};

  ${down("mobile")} {
    height: 56px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1088px;
  display: flex;
  align-items: center;

  ${down("tablet")} {
    margin: 0px 52px;
  }

  ${down("mobile")} {
    margin: 0px 16px;
  }
`;

export const CommitLogoLink = styled(Link)`
  display: flex;
  justify-content: center;
  transition: transform 250ms ease-in-out;

  :hover {
    transform: scale(1.05);
  }
`;

export const CommitLogo = styled.img`
  height: 34px;

  ${down("mobile")} {
    height: 30px;
  }
`;

export const OptionsContainer = styled.div`
  height: 100%;
  margin-left: 88px;
  display: flex;

  > * {
    margin-right: 8px;
  }

  ${down("tablet")} {
    display: none;
  }
`;

export const RighMenuContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  ${down("tablet")} {
    display: none;
  }
`;

export const ListPropertyButtonContainer = styled.div`
  width: 168px;

  ${down("tablet")} {
    width: 168px;
    margin: 32px 0px;
    justify-self: center;
  }
`;

export const MenuContainer = styled.div`
  height: 100%;
  display: none;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  position: relative;

  ${down("tablet")} {
    display: flex;
  }
`;

export const MenuImage = styled.img`
  margin-right: 3px;
`;

export const MenuButtonContainer = styled.button<{ opened: boolean }>`
  -webkit-tap-highlight-color: transparent;
  ${H6Style};
  color: ${colors.grayscale._800};
  height: 28px;
  padding: 0px 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 250ms ease-in-out;

  ${(p) =>
    p.opened
      ? css`
          background-color: ${colors.secondary.pure_blue_transparent};
        `
      : css`
          background-color: ${colors.primary.pure_white_transparent};
        `};

  :hover {
    background-color: ${colors.secondary.pure_blue_transparent};
  }
`;

export const MenuDropdown = styled.div<{ status: TransitionStatus }>`
  width: 257px;
  border-radius: 8px;
  background-color: ${colors.primary.pure_white};
  padding: 28px 0px;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: opacity 250ms ease-in-out;
  opacity: ${(p) => (["entering", "entered"].includes(p.status) ? 1 : 0)};

  ${down("tablet")} {
    top: 72px;
  }

  ${down("mobile")} {
    top: 64px;
  }
`;

export const BackLink = styled.span`
  ${LeadSmallStyle};
  cursor: pointer;
  color: ${colors.primary.frosted_black};
  display: flex;
  text-decoration: none;
  align-items: center;
  transition: transform 200ms ease-in-out;

  :hover {
    transform: scale(1.05);

    ${down("tablet")} {
      /* On tablet only arrow is visible. */
      transform: scale(1.25);
    }
  }
`;

export const BackLinkSpan = styled.span`
  ${down("tablet")} {
    display: none;
  }
`;

export const BackIconImage = styled.img`
  margin-right: 4px;
  width: 13px;
  height: 13px;
`;

export const AddressSeparator = styled.div`
  margin: 0px 28px;
  height: 24px;
  width: 1px;
  background-color: ${colors.secondary.pure_blue};

  ${down("tablet")} {
    margin: 0px 14px;
  }
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
`;

export const UserDetailsName = styled.span`
  font-weight: 500;
  color: ${colors.primary.frosted_black};
`;

export const UserDetailsMail = styled.span`
  font-weight: 400;
  color: ${colors.grayscale._600};
`;

export const AddressSpan = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: ${colors.primary.frosted_black};
  max-width: 256px;
`;

export const AddPropertyButtonsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  ${HorizontalGap(16)};
`;

export const AddPropertyButtonWrapper = styled.div<{ isWrap?: boolean }>`
  display: flex;
  min-width: 140px;

  ${(p) =>
    p.isWrap &&
    css`
      ${down("tablet")} {
        width: 96px;
      }
    `}
`;
