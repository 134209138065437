import React, { FunctionComponent } from "react";
import {
  AreaProsperityType,
  LevelType,
  PropertyInsight,
} from "../../../../../generated/schema";
import { PropertyBadgeWrapper } from "./PropertyBadge.components";

export enum PropertyBadgeType {
  LOW_CRIME_RATE,
  HIGH_STUDENT_POPULATION,
  WEALTHY_AREA,
  DECLINED,
}

type PropertyBadgeProps = {
  type: PropertyBadgeType;
};

export type BadgeType = Pick<
  PropertyInsight,
  "areaProsperity" | "crimeRateLevel" | "studentPopLevel"
>;

export const getBadgeType = (value: BadgeType) => {
  if (value.crimeRateLevel === LevelType.Low)
    return PropertyBadgeType.LOW_CRIME_RATE;
  if (value.studentPopLevel === LevelType.High)
    return PropertyBadgeType.HIGH_STUDENT_POPULATION;
  if (value.areaProsperity === AreaProsperityType.Wealthy)
    return PropertyBadgeType.WEALTHY_AREA;

  return undefined;
};

const getPropertyBadgeText = (type: PropertyBadgeType) => {
  switch (type) {
    case PropertyBadgeType.LOW_CRIME_RATE:
      return "Low crime rate";
    case PropertyBadgeType.DECLINED:
      return "Declined";
    case PropertyBadgeType.HIGH_STUDENT_POPULATION:
      return "High student population";
    case PropertyBadgeType.WEALTHY_AREA:
      return "Wealthy area";
  }
};

const PropertyBadge: FunctionComponent<PropertyBadgeProps> = ({ type }) => {
  return (
    <PropertyBadgeWrapper type={type}>
      {getPropertyBadgeText(type)}
    </PropertyBadgeWrapper>
  );
};

export default PropertyBadge;
