import React, { FunctionComponent } from "react";
import {
  Container,
  ContentWrapper,
  DetailsContainer,
  DetailsDescription,
  DetailsHeader,
  DetailsList,
  DetailsListItem,
  DetailsLogo,
  ImageWrapper,
} from "./AuthContainer.components";
import CommitLogo from "../../images/commit_logo.svg";
import { useNavigate } from "react-router";
import routesPaths from "../../../Routing/routesPaths";

type AuthContainerProps = {
  title?: JSX.Element;
  description?: string | string[];
};

const AuthContainer: FunctionComponent<AuthContainerProps> = ({
  children,
  title,
  description,
}) => {
  const navigate = useNavigate();
  return (
    <Container>
      <ImageWrapper>
        <DetailsContainer>
          <DetailsLogo
            src={CommitLogo}
            onClick={() => navigate(routesPaths.HOME)}
            alt="Commit logo"
          />
          <DetailsHeader>{title}</DetailsHeader>
          {Array.isArray(description) ? (
            <DetailsList>
              {description?.map((el, index) => (
                <DetailsListItem key={el}>{el}</DetailsListItem>
              ))}
            </DetailsList>
          ) : (
            <DetailsDescription>{description}</DetailsDescription>
          )}
        </DetailsContainer>
      </ImageWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </Container>
  );
};

export default AuthContainer;
