const formatPrice = (price: number) => {
  const gbpFormat = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  return gbpFormat.format(price);
};

export default formatPrice;
