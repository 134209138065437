import React, { FunctionComponent } from "react";
import { FakeBox, FakeCheckboxWrapper } from "./FakeCheckbox.components";
import { ReactComponent as Check } from "./check.svg";

type FakeCheckboxProps = {
  checked: boolean;
  onClick: () => void;
  error?: boolean;
  selectable?: boolean;
};

const FakeCheckbox: FunctionComponent<FakeCheckboxProps> = ({
  checked,
  onClick,
  children,
  selectable = true,
  error,
}) => {
  return (
    <FakeCheckboxWrapper>
      <FakeBox
        error={error}
        checked={checked}
        onClick={onClick}
        tabIndex={selectable ? 0 : -1}
        onKeyPress={(e) => {
          if (e.code === "Space" || e.code === "Enter") {
            onClick();
            e.preventDefault();
          }
        }}
      >
        <Check />
      </FakeBox>
      {children}
    </FakeCheckboxWrapper>
  );
};

export default FakeCheckbox;
