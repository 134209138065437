import styled from "styled-components";
import { colors } from "../../shared/colors";
import { down } from "../../shared/media";
import { H6Style } from "../../shared/mixins";

export const PropertyDetailsPageLoader = styled.div`
  position: absolute;
  top: 64px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${down("mobile")} {
    top: 56px;
  }
`;

export const PropertyDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${colors.grayscale._200};
`;

export const PropertyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1088px;
  width: 100%;
  flex: 1;
  margin-bottom: 91px;

  ${down("tablet")} {
    max-width: 812px;
  }
`;

export const PropertyDetailsBackContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  cursor: pointer;

  ${down("mobile")} {
    margin-left: 24px;
  }
`;

export const PropertyDetailsBackDummyView = styled.div`
  height: 34px;

  ${down("tablet")} {
    height: 8px;
  }
`;

export const PropertyDetailsBackImageContainer = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: ${colors.primary.pure_white};
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${down("mobile")} {
    margin-right: 8px;
  }
`;

export const PropertyDetailsBackSpan = styled.span`
  ${H6Style()};
  color: ${colors.primary.frosted_black};
`;

export const GalleryContainer = styled.div`
  margin-top: 0px;
  z-index: 2;
  height: 500px;
  background-color: red;
`;

export const ContentContainer = styled.div`
  z-index: 2;

  ${down("mobile")} {
    border-radius: 24px 24px 0px 0px;
    overflow: hidden;
    transform: translateY(-32px);
  }
`;

export const PropertyDetailsContent = styled.div`
  border-radius: 16px;
  min-width: 710px;
  max-width: 720px;
  margin-top: 24px;

  ${down("tablet")} {
    align-items: center;
    width: calc(100% - 112px);
    margin-left: 56px;
    margin-right: 56px;
    max-width: unset;
    min-width: unset;
  }

  ${down("mobile")} {
    width: 100%;
    margin-left: unset;
    margin-right: unset;

    /* To remove transform margin: */
    margin-bottom: -32px;
  }
`;

export const PropertyDetailsFooterContainer = styled.div`
  width: 100%;
  ${down("tablet")} {
    padding-bottom: 64px;
  }
`;

export const PropertyDetailsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 32px 40px;
  background: ${colors.primary.pure_white};
  margin-bottom: 8px;
  max-width: 720px;
  box-sizing: border-box;

  ${down("tablet")} {
    width: 100%;
    max-width: 812px;
  }

  ${down("mobile")} {
    padding: 32px 24px;
  }
`;

export const AgentDetailsSectionWrapper = styled.div`
  position: sticky;
  height: 100%;
  width: 352px;
  border-radius: 16px;
  margin-left: 16px;
  margin-top: 58px;
  margin-bottom: 8px;
  top: calc(64px + 24px); //Navbar height + space after scroll.
  z-index: 15;

  ${down("tablet")} {
    position: fixed;
    top: unset;
    bottom: 0px;
    height: 64px;
    border-radius: 0px;
    margin: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
  }
`;

export const PropertyDetailsSectionHeading = styled.h4`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: ${colors.primary.frosted_black};
`;

export const PropertyDetailsLocationSectionHeading = styled.span`
  line-height: 24px;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 4px;
  color: ${colors.primary.frosted_black};
`;

export const PropertyDetailsSectionSeparator = styled.span`
  width: 100%;
  height: 1px;
  background: ${colors.grayscale._300};
`;
