import React, { FunctionComponent } from "react";
import { Input } from "../../shared/components/Form/Input/Input";
import CollapsableSection from "./CollapsableSection";
import { FiltersInput, FiltersInputsContainer } from "./Filters.components";

type PriceRangeProps = {
  priceFrom?: number;
  priceTo?: number;
  onChange: (priceFrom?: number, priceTo?: number) => void;
};

const PriceRange: FunctionComponent<PriceRangeProps> = ({
  priceFrom,
  priceTo,
  onChange,
}) => {
  return (
    <CollapsableSection title="Price range" isExpandable={false}>
      <FiltersInputsContainer>
        <FiltersInput>
          <Input
            onChange={(e) => {
              const value = e.target.value;
              if (value) {
                const price = Number(value);
                onChange(isNaN(price) ? undefined : price, priceTo);
              } else {
                onChange(undefined, priceTo);
              }
            }}
            value={priceFrom === undefined ? "" : priceFrom}
            label="From"
            frontIcon="currency"
            type="number"
            inputSize="m"
          />
        </FiltersInput>
        <FiltersInput>
          <Input
            onChange={(e) => {
              const value = e.target.value;
              if (value) {
                const price = Number(value);
                onChange(priceFrom, isNaN(price) ? undefined : price);
              } else {
                onChange(priceFrom, undefined);
              }
            }}
            value={priceTo === undefined ? "" : priceTo}
            label="To"
            frontIcon="currency"
            type="number"
            inputSize="m"
          />
        </FiltersInput>
      </FiltersInputsContainer>
    </CollapsableSection>
  );
};

export default PriceRange;
