import React, { FunctionComponent } from "react";
import { IFileSelection } from "../FilesInput/IFileSelection";
import Hint from "../Hint/Hint";
import {
  FileItem,
  FileItemDelete,
  FileItemImage,
  FileItemName,
  FileItemNameContainer,
  FilesListContainer,
} from "./FilesList.components";

interface IFileUrlItem {
  name: string;
  url: string;
  id: string;
}

type FilesListProps = {
  allowDelete?: boolean;
  files?: IFileSelection[];
  names?: IFileUrlItem[];
  onDelete?: (selection: IFileSelection) => void;
};

const FilesList: FunctionComponent<FilesListProps> = ({
  files,
  names,
  allowDelete = true,
  onDelete,
}) => {
  return (
    <FilesListContainer>
      {names
        ? names.map((name) => (
            <FileItem key={name.id} href={name.url} target="_blank">
              <FileItemImage />
              <FileItemNameContainer>
                <Hint
                  children={<FileItemName>{name.name}</FileItemName>}
                  hint={name.name}
                />
              </FileItemNameContainer>
            </FileItem>
          ))
        : files?.map((selection) => (
            <FileItem
              key={selection.id}
              href={
                selection.url
                  ? selection.url
                  : URL.createObjectURL(selection.file!)
              }
              target="_blank"
            >
              <FileItemImage />
              <FileItemNameContainer>
                <Hint
                  children={<FileItemName>{selection.name}</FileItemName>}
                  hint={selection.name}
                />
              </FileItemNameContainer>
              {allowDelete && (
                <FileItemDelete
                  onClick={(e) => {
                    onDelete && onDelete(selection);
                    e.preventDefault();
                  }}
                />
              )}
            </FileItem>
          ))}
    </FilesListContainer>
  );
};

export default FilesList;
