import React, { FunctionComponent } from "react";
import {
  CopyrightSpan,
  FooterBottomWrapper,
  FooterContainer,
  FooterDetails,
  FooterDetailsWrapper,
  FooterInnerContainer,
  FooterOuterContainer,
  FooterPupularSearchesHeader,
  FooterQuickLinksWrapper,
  FooterSocialMediaIcon,
  FooterSocialMediaWrapper,
  FooterWrapper,
  PolicyLink,
  PolicyLinksWrapper,
  PolicySeparator,
  QuickLink,
  QuickLinksInnerWrapper,
  QuickLinksWrapper,
  ReachUsMessageInnerWrapper,
  ReachUsMessageWrapper,
  ReachUsPhoneWrapper,
  ReachUsSpan,
  ReachUsWrapper,
} from "./Footer.components";
import { ReactComponent as CommitLogo } from "../../images/commit_logo.svg";
import { ReactComponent as PhoneIcon } from "./icons/CallIcon.svg";
import { ReactComponent as MessageIcon } from "./icons/MessageIcon.svg";
import PopularSearches from "./PopularSearches";
import { useIsMediaDown } from "../../media";
import routesPaths from "../../../Routing/routesPaths";
import { blogPath } from "../../../../../../backend/src/shared/routes/routes";

const quickLinks = [
  {
    caption: "Blog",
    href: blogPath,
  },
  {
    caption: "About",
    href: `${routesPaths.HOME}#about-us`,
  },
  {
    caption: "FAQ",
    href: routesPaths.FAQ,
  },
  {
    caption: "Login",
    href: "/login",
  },
];

const mails = [
  "nathaniel@commit.properties",
  "hello@commit.properties",
  "support@commit.properties",
];

const socialUrls = {
  twitter: "https://twitter.com/commitproperty",
  linkedIn: "https://www.linkedin.com/company/commit-property",
  facebook: "https://fb.me/commitproperty",
};

const phoneNumber = "020 3151 2957";

const Footer: FunctionComponent = () => {
  const isTabletDown = useIsMediaDown("tablet");

  return (
    <FooterContainer id="footer">
      <FooterOuterContainer>
        <FooterInnerContainer>
          {!isTabletDown && <PopularSearches />}
          <FooterWrapper>
            <FooterDetailsWrapper>
              <CommitLogo />
              <FooterDetails>
                The new generation of commercial property platforms has arrived.
                With premium research tooling and baked-in location
                intelligence, finding the right commercial property has never
                been easier.
              </FooterDetails>
              <FooterSocialMediaWrapper>
                <FooterSocialMediaIcon
                  type="twitter"
                  href={socialUrls.twitter}
                  aria-label="Twitter url"
                />
                <FooterSocialMediaIcon
                  type="linkedin"
                  href={socialUrls.linkedIn}
                  aria-label="LinkedIn url"
                />
                <FooterSocialMediaIcon
                  type="facebook"
                  href={socialUrls.facebook}
                  aria-label="Facebook url"
                />
              </FooterSocialMediaWrapper>
            </FooterDetailsWrapper>
            {isTabletDown && <PopularSearches />}
            <FooterQuickLinksWrapper>
              <QuickLinksWrapper>
                <FooterPupularSearchesHeader>
                  Quick Links
                </FooterPupularSearchesHeader>
                <QuickLinksInnerWrapper>
                  {quickLinks.map((link) => (
                    <QuickLink key={link.caption} href={link.href}>
                      {link.caption}
                    </QuickLink>
                  ))}
                </QuickLinksInnerWrapper>
              </QuickLinksWrapper>
              <ReachUsWrapper>
                <FooterPupularSearchesHeader>
                  Reach us
                </FooterPupularSearchesHeader>
                <ReachUsPhoneWrapper>
                  <PhoneIcon />
                  <ReachUsSpan href={`tel:${phoneNumber}`}>
                    {phoneNumber}
                  </ReachUsSpan>
                </ReachUsPhoneWrapper>
                <ReachUsMessageWrapper>
                  <MessageIcon />
                  <ReachUsMessageInnerWrapper>
                    {mails.map((mail) => (
                      <ReachUsSpan key={mail} href={`mailto:${mail}`}>
                        {mail}
                      </ReachUsSpan>
                    ))}
                  </ReachUsMessageInnerWrapper>
                </ReachUsMessageWrapper>
              </ReachUsWrapper>
            </FooterQuickLinksWrapper>
          </FooterWrapper>
          <FooterBottomWrapper>
            <CopyrightSpan>
              © {new Date().getFullYear()} Commit Limited (13831313). All rights
              reserved
            </CopyrightSpan>

            <PolicyLinksWrapper>
              <PolicyLink href={routesPaths.TERMS_AND_CONDITIONS}>
                Terms & Conditions
              </PolicyLink>
              <PolicySeparator>|</PolicySeparator>
              <PolicyLink href={routesPaths.PRIVACY_POLICY}>
                Privacy Policy
              </PolicyLink>
              <PolicySeparator>|</PolicySeparator>
              <PolicyLink href={routesPaths.COOKIES}>Cookies</PolicyLink>
            </PolicyLinksWrapper>
          </FooterBottomWrapper>
        </FooterInnerContainer>
      </FooterOuterContainer>
    </FooterContainer>
  );
};

export default Footer;
