import React, { FunctionComponent } from "react";
import MainNavbar from "../../shared/components/Navbar/MainNavbar";
import { PublicLayoutContent } from "./PublicLayout.components";

const PublicLayout: FunctionComponent = ({ children }) => {
  return (
    <PublicLayoutContent>
      <MainNavbar />
      {children}
    </PublicLayoutContent>
  );
};

export default PublicLayout;
