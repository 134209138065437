import React, { FunctionComponent, useEffect, useState } from "react";
import Button from "../../shared/components/Button";
import { zIndexes } from "../../shared/constants";
import { useIsMediaDown } from "../../shared/media";
import Filters, {
  getFiltersCount,
  IPropertiesFilters,
} from "../Filters/Filters";
import { initialFilters } from "../Properties";
import {
  FiltersModalClear,
  FiltersModalContainer,
  FiltersModalContent,
  FiltersModalFooter,
  FiltersModalFooterButton,
  FiltersModalHeader,
  FiltersModalHeaderClose,
  FiltersModalHeaderContainer,
} from "./FiltersModal.components";
import IconClose from "./icon_close.svg";

type FiltersModalProps = {
  filters: IPropertiesFilters;
  onFiltersChange: (filters: IPropertiesFilters) => void;
  closeModal: () => void;
};

const FiltersModal: FunctionComponent<FiltersModalProps> = ({
  filters,
  onFiltersChange,
  closeModal,
}) => {
  const [nestedFilters, setNestedFilters] = useState(filters);
  useEffect(() => setNestedFilters(filters), [filters]);
  const filtersCount = getFiltersCount(nestedFilters, true);
  const [isVisible, setIsVisible] = useState(true);
  const isTabletDown = useIsMediaDown("tablet", true);

  useEffect(() => {
    if (!isTabletDown) closeModal();
  }, [closeModal, isTabletDown]);

  // Overlap navbar only for this modal.
  useEffect(() => {
    const modalContainer = document.getElementById("modal-container");
    const modalContainerBackground = document.getElementById(
      "modal-container-background",
    );
    if (modalContainer)
      modalContainer.style.zIndex = zIndexes.FILTERS_MODAL.toString();
    if (modalContainerBackground)
      modalContainerBackground.style.zIndex =
        zIndexes.FILTERS_MODA_OVERLAY.toString();
  }, []);

  return (
    <FiltersModalContainer visible={isVisible}>
      <FiltersModalHeaderContainer>
        <FiltersModalHeader>Filters</FiltersModalHeader>
        <FiltersModalHeaderClose
          src={IconClose}
          onClick={() => {
            setIsVisible(false);
            closeModal();
          }}
        />
        {filtersCount > 0 && (
          <FiltersModalClear
            onClick={() => {
              setNestedFilters({
                ...initialFilters,
                sortOrder: nestedFilters.sortOrder,
                location: nestedFilters.location,
                listingType: nestedFilters.listingType,
              });
            }}
          >
            Clear all({filtersCount})
          </FiltersModalClear>
        )}
      </FiltersModalHeaderContainer>
      <FiltersModalContent>
        <Filters filters={nestedFilters} onFiltersChange={setNestedFilters} />
      </FiltersModalContent>
      <FiltersModalFooter>
        <FiltersModalFooterButton>
          <Button
            size="block"
            height="l"
            onClick={() => {
              onFiltersChange(nestedFilters);
              setIsVisible(false);
              closeModal();
            }}
          >
            Show results
          </Button>
        </FiltersModalFooterButton>
      </FiltersModalFooter>
    </FiltersModalContainer>
  );
};

export default FiltersModal;
