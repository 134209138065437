import styled, { keyframes } from "styled-components";
import { colors } from "../../colors";
import { ButtonType } from "../Button/Button.components";

const spin = keyframes`
 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } 
`;

const getBackground = (type?: ButtonType) => {
  switch (type) {
    case "light":
    case "outline":
      return "rgba(42, 122, 234, 0.28)";
    case "dark":
    case "primary":
    default:
      return "rgba(255, 255, 255, 0.28)";
  }
};

const getTrackColor = (type?: ButtonType) => {
  switch (type) {
    case "light":
    case "outline":
      return colors.secondary.pure_blue;
    case "dark":
    case "primary":
    default:
      return colors.primary.pure_white;
  }
};

export const ButtonLoaderWrapper = styled.div<{ type: ButtonType }>`
  box-sizing: border-box;
  border: 2.5px solid ${(p) => getBackground(p.type)};
  border-top: 2.5px solid ${(p) => getTrackColor(p.type)};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 1.1s linear infinite;
`;
