import styled from "styled-components";
import { colors } from "../../../shared/colors";
import { HorizontalGap, VerticalGap } from "../../../shared/mixins";

export const PropertyDetailsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${VerticalGap(24)};
`;

export const InputsContainer = styled.div`
  display: flex;
  ${HorizontalGap(12)};
`;

export const SmallInputContainer = styled.div`
  max-width: 94px;
`;

export const LargeInputContainer = styled.div`
  flex: 1;
`;

export const Divider = styled.div`
  background-color: ${colors.grayscale._300};
  height: 1px;
`;

export const TextAreaContainer = styled.div`
  height: 210px;
`;
