import React, { FunctionComponent } from "react";
import {
  AreaHealthType,
  LevelType,
  ListingType,
  PropertyUseType,
} from "../../../generated/schema";
import { ISearchResult } from "../../shared/components/SearchInput/ISearchResult";
import CommitIntelligentFilters from "./CommitIntelligentFilters";
import {
  FiltersContainer,
  FiltersDivider,
  FitlersPropertyUseContainer,
} from "./Filters.components";
import ListingTypeFilter from "./ListingTypeFilter";
import LocationRange from "./LocationRange";
import PriceRange from "./PriceRange";
import PropertyUse from "./PropertyUse";
import SqFootage from "./SqFootage";

export enum SortOrder {
  DATE_DESC = "DATE_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
}

export interface IPropertiesFilters {
  locationRange: number;
  sortOrder: SortOrder;
  location?: ISearchResult;
  priceFrom?: number;
  priceTo?: number;
  sqFootageFrom?: number;
  sqFootageTo?: number;
  crimeRate?: LevelType;
  populationDensity?: LevelType;
  studentPopulation?: LevelType;
  areaHealth?: AreaHealthType;
  shoplifting?: LevelType;
  listingType: ListingType;
  propertyUseTypes: PropertyUseType[];
}

export const getFiltersCount = (
  filters: IPropertiesFilters,
  includePropertyUse?: boolean,
) => {
  let count = 0;
  if (filters.locationRange > 0) count++;
  if (filters.priceFrom !== undefined || filters.priceTo !== undefined) count++;
  if (filters.sqFootageFrom !== undefined || filters.sqFootageTo !== undefined)
    count++;
  if (filters.crimeRate) count++;
  if (filters.populationDensity) count++;
  if (filters.studentPopulation) count++;
  if (filters.areaHealth) count++;
  if (filters.shoplifting) count++;
  if (includePropertyUse && filters.propertyUseTypes.length > 0) count++;
  return count;
};

type FiltersProps = {
  filters: IPropertiesFilters;
  onFiltersChange: (filters: IPropertiesFilters) => void;
};

const Filters: FunctionComponent<FiltersProps> = ({
  filters,
  onFiltersChange,
  ...props
}) => {
  return (
    <FiltersContainer>
      <LocationRange
        range={filters.locationRange}
        onChange={(locationRange) =>
          onFiltersChange({ ...filters, locationRange })
        }
      />

      <FiltersDivider />

      <PriceRange
        priceFrom={filters.priceFrom}
        priceTo={filters.priceTo}
        onChange={(priceFrom, priceTo) =>
          onFiltersChange({ ...filters, priceFrom, priceTo })
        }
      />

      <SqFootage
        sqFootageFrom={filters.sqFootageFrom}
        sqFootageTo={filters.sqFootageTo}
        onChange={(sqFootageFrom, sqFootageTo) =>
          onFiltersChange({ ...filters, sqFootageFrom, sqFootageTo })
        }
      />

      <FiltersDivider />

      <CommitIntelligentFilters
        commitIntelligentFilters={{ ...filters }}
        onChange={(commitFilters) =>
          onFiltersChange({ ...filters, ...commitFilters })
        }
      />

      <FitlersPropertyUseContainer>
        <FiltersDivider />
        <PropertyUse
          useTypes={filters.propertyUseTypes}
          onChange={(propertyUseTypes) =>
            onFiltersChange({ ...filters, propertyUseTypes })
          }
        />
      </FitlersPropertyUseContainer>

      <FiltersDivider />

      <ListingTypeFilter
        listingType={filters.listingType}
        onChange={(listingType) => onFiltersChange({ ...filters, listingType })}
      />
    </FiltersContainer>
  );
};

export default Filters;
