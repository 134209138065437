import {
  FilterType,
  PropertyFilter,
  PropertySortKeys,
  PropertySortType,
  SortDirection,
} from "../../generated/schema";
import { IPropertiesFilters, SortOrder } from "./Filters/Filters";

export const getInitialPage = () => {
  const page = new URLSearchParams(window.location.search).get("page");
  const pageNumber = Number(page);
  return isNaN(pageNumber) ? 1 : Math.max(1, pageNumber);
};

export const buildApiFilters = (filters: IPropertiesFilters) => {
  const apiFilters: PropertyFilter[] = [];
  if (filters.areaHealth)
    apiFilters.push({ type: FilterType.AreaHealth, eq: filters.areaHealth });

  if (filters.crimeRate)
    apiFilters.push({ type: FilterType.CrimeRate, eq: filters.crimeRate });

  if (filters.listingType)
    apiFilters.push({
      type: FilterType.ListingType,
      eq: filters.listingType,
    });

  if (filters.populationDensity)
    apiFilters.push({
      type: FilterType.PopulationDensity,
      eq: filters.populationDensity,
    });

  if (filters.priceFrom !== undefined || filters.priceTo !== undefined)
    apiFilters.push({
      type: FilterType.Price,
      ge: filters.priceFrom,
      le: filters.priceTo,
    });

  if (filters.propertyUseTypes.length > 0)
    apiFilters.push({
      type: FilterType.PropertyUseType,
      in: filters.propertyUseTypes,
    });

  if (filters.shoplifting)
    apiFilters.push({
      type: FilterType.Shoplifting,
      eq: filters.shoplifting,
    });

  if (filters.sqFootageFrom !== undefined || filters.sqFootageTo !== undefined)
    apiFilters.push({
      type: FilterType.SqFootage,
      ge: filters.sqFootageFrom,
      le: filters.sqFootageTo,
    });

  if (filters.studentPopulation)
    apiFilters.push({
      type: FilterType.StudentPopulation,
      eq: filters.studentPopulation,
    });

  return apiFilters;
};

export const buildApiSort = (sortBy: SortOrder): PropertySortType => {
  switch (sortBy) {
    case SortOrder.DATE_DESC:
      return { direction: SortDirection.Desc, key: PropertySortKeys.UpdateAt };
    case SortOrder.PRICE_ASC:
      return { direction: SortDirection.Asc, key: PropertySortKeys.Price };
    case SortOrder.PRICE_DESC:
      return { direction: SortDirection.Desc, key: PropertySortKeys.Price };
  }
};
