import React, { FunctionComponent } from "react";
import {
  ContainerProps,
  ModalWrapper,
} from "../../Modal/ModalContainer.components";
import ModalHeader from "../../Modal/ModalHeader/ModalHeader";
import AccountDetailsModalForm from "./Form/AccountDetailsModalForm";

type Props = Partial<ContainerProps>;

const AccountDetailsModal: FunctionComponent<Props> = ({ status }) => {
  return (
    <ModalWrapper>
      <ModalHeader
        heading="Account details"
        description="Your name and bio will be public in the contact section"
      />
      <AccountDetailsModalForm />
    </ModalWrapper>
  );
};

export default AccountDetailsModal;
