import styled from "styled-components";
import { colors } from "../shared/colors";
import { down } from "../shared/media";

export const NotFoundContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NotFoundHeading = styled.h4`
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: ${colors.primary.frosted_black};
  margin-top: 40px;
`;

export const NotFoundDescription = styled.p`
  max-width: 400px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.grayscale._600};

  > a {
    color: ${colors.secondary.pure_blue};
  }

  ${down("mobile")} {
    max-width: 327px;
  }
`;

export const CopyrightSpan = styled.span`
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 400;
  font-size: 11px;
  line-height: 10px;
  letter-spacing: 0.01em;
  color: ${colors.grayscale._600};
  text-align: center;
`;
