import React, { useCallback, useState } from "react";
import { PROPERTY_USE_TYPE_OPTIONS } from "../../common/helpers/enumNamesHelper";
import { ListingType } from "../../generated/schema";
import Button from "../shared/components/Button";
import Footer from "../shared/components/Footer/Footer";
import SearchInput from "../shared/components/SearchInput/SearchInput";
import MultiSelect from "../shared/components/Select/MultiSelect/MultiSelect";
import { useIsMediaDown } from "../shared/media";
import {
  PropertiesContainer,
  PropertiesContent,
  PropertiesFilterContainer,
  PropertiesFilterHeader,
  PropertiesFilterHeaderContainer,
  PropertiesFiltersCount,
  PropertiesFooterContainer,
  PropertiesSearchInfo,
  PropertiesSearchResultsCount,
  PropertiesSearchResultsCountHeader,
  PropertiesSearchResultsCountSpan,
  PropertiesSearchSort,
  PropertiesTopBar,
  PropertiesTopBarContainer,
  PropertiesTopBarFilterButton,
  PropertiesTopBarFilterButtonImage,
  PropertiesTopBarFilters,
  PropertiesTopBarHeader,
  PropertiesTopBarInputsContainer,
  PropertiesTopBarPropertyType,
  PropertiesTopBarSearch,
  PropertiesTopBarSortButtonContainer,
} from "./Properties.components";
import FiltersIcon from "./icon_filters.svg";
import { useModal } from "../shared/components/Modal";
import FiltersModal from "./FiltersModal/FiltersModal";
import Filters, {
  getFiltersCount,
  IPropertiesFilters,
  SortOrder,
} from "./Filters/Filters";
import SortBy from "./SortBy/SortBy";
import { getPropertyFiltersQuery, getFiltersQuery } from "./queryHelper";
import { useSearchParams } from "react-router-dom";
import { ISearchResult } from "../shared/components/SearchInput/ISearchResult";
import PropertiesList from "./PropertiesList";
import { getInitialPage } from "./propertyHelpers";
import { getPropertiesCountText } from "../../common/helpers/paginationHelpers";

export const initialFilters: IPropertiesFilters = {
  locationRange: 0,
  listingType: ListingType.ForRent,
  sortOrder: SortOrder.DATE_DESC,
  propertyUseTypes: [],
  areaHealth: undefined,
  crimeRate: undefined,
  location: undefined,
  populationDensity: undefined,
  priceFrom: undefined,
  priceTo: undefined,
  shoplifting: undefined,
  sqFootageFrom: undefined,
  sqFootageTo: undefined,
  studentPopulation: undefined,
};

const Properties = () => {
  const hideLocationLabel = useIsMediaDown("tablet");
  const { openModal, closeModal } = useModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState(() =>
    getPropertyFiltersQuery(searchParams),
  );
  const isTabletDown = useIsMediaDown("tablet");
  const filtersCount = getFiltersCount(filters, isTabletDown);
  const [currentPage, setCurrentPage] = useState(getInitialPage());
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const showFilters = useCallback(() => {
    openModal(
      <FiltersModal
        filters={filters}
        onFiltersChange={setFilters}
        closeModal={closeModal}
      />,
      true,
    );
  }, [closeModal, filters, openModal]);

  const onLoactionChange = useCallback((location?: ISearchResult) => {
    setFilters((prev) => ({ ...prev, location }));
  }, []);

  const onPageChange = useCallback(
    (page: number) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      const queryFilters = getFiltersQuery(filters);
      setSearchParams({ ...queryFilters, page: page.toString() });
      setCurrentPage(page);
    },
    [filters, setSearchParams],
  );

  return (
    <>
      <PropertiesContainer>
        <PropertiesTopBar>
          <PropertiesTopBarContainer>
            <PropertiesTopBarHeader>
              Commercial property for{" "}
              {filters.listingType === ListingType.ForRent ? "rent" : "sale"}
            </PropertiesTopBarHeader>
            <PropertiesTopBarInputsContainer>
              <PropertiesTopBarSearch>
                <SearchInput
                  inputSize={isTabletDown ? "l" : "m"}
                  value={filters.location?.fullAddress}
                  onChange={onLoactionChange}
                  label={hideLocationLabel ? undefined : "Location"}
                />
              </PropertiesTopBarSearch>
              <PropertiesTopBarPropertyType>
                <MultiSelect
                  inputSize="m"
                  truncated
                  label="Property Use Type"
                  name="propertyUse"
                  optionClick={(value) => {
                    const option = PROPERTY_USE_TYPE_OPTIONS.find(
                      (o) => o.value === value,
                    )!;
                    const useTypes = filters.propertyUseTypes;
                    const propertyUseTypes = useTypes.includes(option.type)
                      ? useTypes.filter((t) => t !== option.type)
                      : useTypes.concat(option.type);
                    setFilters({ ...filters, propertyUseTypes });
                  }}
                  options={PROPERTY_USE_TYPE_OPTIONS.map((o) => o.value)}
                  selections={filters.propertyUseTypes.map(
                    (t) =>
                      PROPERTY_USE_TYPE_OPTIONS.find((o) => o.type === t)!
                        .value,
                  )}
                />
              </PropertiesTopBarPropertyType>

              <PropertiesTopBarFilters>
                <PropertiesTopBarFilterButton>
                  <Button size="block" height="m" onClick={showFilters}>
                    <PropertiesTopBarFilterButtonImage src={FiltersIcon} />
                    Filters{filtersCount > 0 && ` (${filtersCount})`}
                  </Button>
                </PropertiesTopBarFilterButton>
                <PropertiesTopBarSortButtonContainer>
                  <SortBy
                    sortOrder={filters.sortOrder}
                    onChange={(sortOrder) => {
                      setFilters({ ...filters, sortOrder });
                    }}
                  />
                </PropertiesTopBarSortButtonContainer>
              </PropertiesTopBarFilters>
            </PropertiesTopBarInputsContainer>
          </PropertiesTopBarContainer>
        </PropertiesTopBar>

        <PropertiesSearchInfo>
          <PropertiesSearchResultsCount>
            <PropertiesSearchResultsCountHeader>
              Search results
            </PropertiesSearchResultsCountHeader>
            <PropertiesSearchResultsCountSpan>
              {totalPages > 0 &&
                getPropertiesCountText(totalResults, currentPage, totalPages)}
            </PropertiesSearchResultsCountSpan>
          </PropertiesSearchResultsCount>
          <PropertiesSearchSort>
            <PropertiesSearchResultsCountSpan>
              Sort by
            </PropertiesSearchResultsCountSpan>
            <SortBy
              sortOrder={filters.sortOrder}
              onChange={(sortOrder) => {
                setFilters({ ...filters, sortOrder });
              }}
            />
          </PropertiesSearchSort>
        </PropertiesSearchInfo>

        <PropertiesContent>
          <PropertiesFilterContainer>
            <PropertiesFilterHeaderContainer>
              <PropertiesFilterHeader>Filters</PropertiesFilterHeader>
              {filtersCount > 0 && (
                <PropertiesFiltersCount
                  onClick={() => {
                    setFilters({
                      ...initialFilters,
                      propertyUseTypes: filters.propertyUseTypes,
                      location: filters.location,
                      sortOrder: filters.sortOrder,
                      listingType: filters.listingType,
                    });
                    onPageChange(1);
                  }}
                >
                  Clear all ({filtersCount})
                </PropertiesFiltersCount>
              )}
            </PropertiesFilterHeaderContainer>
            <Filters filters={filters} onFiltersChange={setFilters} />
          </PropertiesFilterContainer>

          <PropertiesList
            page={currentPage}
            filters={filters}
            changePage={onPageChange}
            paginationChanged={(count, total) => {
              setTotalPages(count);
              setTotalResults(total);
            }}
          />
        </PropertiesContent>
      </PropertiesContainer>
      <PropertiesFooterContainer>
        <Footer />
      </PropertiesFooterContainer>
    </>
  );
};

export default Properties;
