import React from "react";
import {
  AbsoluteBackgroundArea,
  HigherIndexWrapper,
  SeaerchHeaderImage,
  SearchHeaderContainer,
  SearchHeaderHeading,
  SearchHeaderOuterContainer,
} from "./SearchHeader.components";
import SearchHeaderImage from "./images/SearchHeaderImage.webp";
import SearchHeaderForm from "./Form/SearchHeaderForm";
import { useIsMediaDown } from "../../shared/media";
import { animated, Spring } from "react-spring";

const SearchHeader = () => {
  const isMobileDown = useIsMediaDown("mobile");

  const heading = isMobileDown ? (
    <SearchHeaderHeading>
      Discover your next <br />
      commercial property using our... <br />
      <span>free location intelligence search.</span>
    </SearchHeaderHeading>
  ) : (
    <SearchHeaderHeading>
      Discover your next commercial property using our{" "}
      <span>free location intelligence search.</span>
    </SearchHeaderHeading>
  );

  return (
    <SearchHeaderOuterContainer>
      <SearchHeaderContainer>
        <SeaerchHeaderImage>
          <Spring
            loop={{ reverse: true, delay: 2500 }}
            config={{ duration: 10000 }}
            from={{ scale: 1 }}
            to={{ scale: 1.3 }}
          >
            {(styles) => (
              <animated.img
                style={{
                  borderRadius: "0px 0px 16px 16px",
                  ...styles,
                }}
                width="100%"
                height="100%"
                src={SearchHeaderImage}
                alt="Building with sky in the background."
              />
            )}
          </Spring>
        </SeaerchHeaderImage>
        <HigherIndexWrapper>
          {heading}
          <SearchHeaderForm />
        </HigherIndexWrapper>
      </SearchHeaderContainer>
      <AbsoluteBackgroundArea />
    </SearchHeaderOuterContainer>
  );
};

export default SearchHeader;
