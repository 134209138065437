import { FormikProps } from "formik";
import React, { FunctionComponent } from "react";
import FilesDropdown from "../../shared/components/FilesDropdown/FilesDropdown";
import { Descriptions } from "../AddPropertyFormHints";
import { AddPropertyFormValues } from "../form";
import FormBox from "./FormBox/FormBox";

const PropertyPhotosSection: FunctionComponent<
  FormikProps<AddPropertyFormValues>
> = (props) => {
  const { errors, setFieldValue, values } = props;
  const photosError = errors.photos as string;
  const showError = props.submitCount > 0 && photosError;
  return (
    <FormBox section="PROPERTY_PHOTOS" description={Descriptions.photos}>
      <FilesDropdown
        maxFiles={10}
        maxFileSizeMB={2}
        initialValues={values.photos}
        error={showError ? photosError : undefined}
        filesChanged={(files) => {
          setFieldValue("photos", files);
        }}
        type="images"
      />
    </FormBox>
  );
};

export default PropertyPhotosSection;
