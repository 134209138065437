import React, { FunctionComponent } from "react";
import Button from "../../Button";
import { ButtonSize, ButtonType } from "../../Button/Button.components";
import ActionsModal from "../ActionsModal/ActionsModal";
import { ConfirmModalButtonContainer } from "./ConfirmModal.components";

const getButton = (
  buttonType: ButtonType,
  size: ButtonSize,
  text: string,
  onClick: () => void,
) => (
  <ConfirmModalButtonContainer>
    <Button buttonType={buttonType} onClick={onClick} size={size}>
      {text}
    </Button>
  </ConfirmModalButtonContainer>
);

type ConfirmModalProps = {
  description: string | JSX.Element;
  title: string | JSX.Element;
  loading?: boolean;
  primaryText: string;
  onPrimaryAction: () => void;
  secondaryText: string;
  onSecondaryAction: () => void;
  primaryButtonSize?: ButtonSize;
  secondaryButtonSize?: ButtonSize;
  primaryButtonType?: ButtonType;
  secondaryButtonType?: ButtonType;
};

const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({
  primaryText,
  onPrimaryAction,
  secondaryText,
  onSecondaryAction,
  description,
  title,
  primaryButtonSize = "block",
  secondaryButtonSize = "block",
  primaryButtonType = "primary",
  secondaryButtonType = "outline",
}) => {
  return (
    <ActionsModal
      description={description}
      title={title}
      leftButton={getButton(
        secondaryButtonType,
        secondaryButtonSize,
        secondaryText,
        onSecondaryAction,
      )}
      rightButton={getButton(
        primaryButtonType,
        primaryButtonSize,
        primaryText,
        onPrimaryAction,
      )}
    />
  );
};
export default ConfirmModal;
