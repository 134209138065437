import React, { FunctionComponent } from "react";
import PrivacyTerms from "../shared/components/PrivacyTerms/PrivacyTerms";
import {
  PrivacyTermsSectionWrapper,
  PrivacyTermsParagraph,
  PrivacyTermsHeading,
  PrivacyTermsHeadingSmall,
} from "../shared/components/PrivacyTerms/PrivacyTerms.components";

const PrivacyPolicy: FunctionComponent = () => {
  return (
    <PrivacyTerms heading="Privacy Policy">
      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>About this Privacy Policy</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          This privacy policy applies when you visit or use our websites, apps
          and other services, including events, that refer or link to this
          privacy policy (each, a “Service”). This privacy policy may be
          supplemented by additional privacy statements, terms or notices
          provided to you. The Commit Limited company that owns or administers
          the Service, as identified therein, is the primary controller of your
          personal information provided to, or collected by or for, the Service.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>
          What information do we collect?
        </PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          We collect information about you in three ways:
          <ul>
            <li>Directly from your input</li>
            <li>From third party sources</li>
            <li>Through automated technologies</li>
          </ul>
        </PrivacyTermsParagraph>
        <PrivacyTermsHeadingSmall>
          Data You Provide to Us
        </PrivacyTermsHeadingSmall>
        <PrivacyTermsParagraph>
          The types of personal information that we collect directly from you
          depends on how you interact with us and the Service and may include:
          <ul>
            <li>
              Contact details, such as your name and work email address, postal
              address and phone number;
            </li>
            <li>
              Account login credentials, such as usernames and passwords,
              password hints and similar security information;
            </li>
            <li>
              Other account registration and profile information, such as
              educational and professional background and photo;
            </li>
            <li>Payment information, such as a credit or debit card number;</li>
            <li>
              Comments, feedback and other information you provide to us,
              including search query data and questions or information you send
              to customer support; and/or
            </li>
            <li>
              Interests and communication preferences, including preferred
              language.
            </li>
          </ul>
        </PrivacyTermsParagraph>

        <PrivacyTermsHeadingSmall>
          Data From Your Organisation
        </PrivacyTermsHeadingSmall>
        <PrivacyTermsParagraph>
          We may obtain information about you from the organisation with which
          you are employed or affiliated in order to activate and manage your
          access to and use of the organisation’s subscription to the Service,
          including:
          <ul>
            <li>
              Contact details, such as your name and work email address, postal
              address and phone number;
            </li>
            <li>
              Other account registration information, such as job title; and/or
            </li>
            <li>Organisational user ID.</li>
          </ul>
        </PrivacyTermsParagraph>

        <PrivacyTermsHeadingSmall>
          Data From Other Sources
        </PrivacyTermsHeadingSmall>
        <PrivacyTermsParagraph>
          We also may obtain contact details and other information about you
          from our affiliates and from other third parties, including:
          <ul>
            <li>
              Service providers that help us determine a location in order to
              customise certain products to your location;
            </li>
            <li>
              Partners with which we offer co-branded services or engage in
              joint marketing activities; and/or
            </li>
            <li>
              Publicly-available sources and data suppliers from which we obtain
              data to validate or supplement the information we hold.
            </li>
          </ul>
        </PrivacyTermsParagraph>

        <PrivacyTermsHeadingSmall>
          Data From Service Use
        </PrivacyTermsHeadingSmall>
        <PrivacyTermsParagraph>
          The Service may automatically collect information about how you and
          your device interact with the Service, including:
          <ul>
            <li>
              Computer, device and connection information, such as IP address,
              browser type and version, operating system and other software
              installed on your device, mobile platform and unique device
              identifier and other technical identifiers, error reports and
              performance data;
            </li>
            <li>
              Usage data, such as the features you used, the settings you
              selected, your URL clickstream data, including date and time stamp
              and referring and exit pages, and pages you visited on the
              Service; and/or
            </li>
            <li>
              For location-aware Services, the region, city or town where your
              device is located in order to provide you with more relevant
              content for where you are in the world.
            </li>
            <li>
              We collect this data through our servers and the use of cookies
              and other technologies. You can control cookies through your
              browser’s settings and other tools.
            </li>
          </ul>
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>How We Use Your Information</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          Depending on how you interact with us and the Service, we use your
          personal information to:
          <ul>
            <li>
              Provide, activate and manage your access to and use of the
              Service;
            </li>
            <li>
              Process and fulfil a request, order, download, subscription or
              other transaction;
            </li>
            <li>
              Provide technical, product and other support and to help keep the
              Service working, safe and secure;
            </li>
            <li>
              Enhance and improve the Service and our other products and
              services and to develop new products, services and benefits;
            </li>
            <li>
              Offer you customised content and other personalization to make the
              Service more relevant to your interests and geography;
            </li>
            <li>Respond to your requests, inquiries, comments and concerns;</li>
            <li>
              Notify you about changes, updates and other announcements related
              to the Service and our other products and services;
            </li>
            <li>
              Deliver targeted advertisements, promotional messages, notices and
              other information related to the Service and your interests;
            </li>
            <li>
              Provide you with promotional messages and other information about
              products, events and services of ours, our affiliates and third
              parties such as sponsors;
            </li>
            <li>
              Invite you to participate in user testing and surveys as well as
              sweepstakes, competitions and similar promotions;
            </li>
            <li>
              Identify usage trends and develop data analysis, including for
              purposes of research, audit, reporting and other business
              operations, including determining the effectiveness of our
              promotional campaigns and evaluating our business performance, or
              in other ways pursuant to a customer agreement; and/or
            </li>
            <li>
              Comply with our legal obligations, resolve disputes, and enforce
              our agreements
            </li>
            <li>
              If you are an administrator of an organisation with a subscription
              to the Service, we will use your details to communicate with you
              about your organisation’s subscription and related services.
              <ul>
                <li>
                  If you supply us contact information of your colleagues, we
                  may contact those individuals with communications about the
                  Service that may include reference to you.
                </li>
              </ul>
            </li>
          </ul>
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Sharing of Your Information</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          Depending on the Service provided, we may share personal information
          with:
          <ul>
            <li>
              Our service providers, suppliers, agents and representatives,
              including but not limited to, payment processors, customer
              support, email service providers, IT service providers, mailing
              houses and shipping agents;
            </li>
            <li>
              to process the information as necessary to provide the Service,
              complete a transaction or fulfil your request or otherwise on our
              behalf based on our instructions and in compliance with this
              privacy policy and any other appropriate confidentiality and
              security measures.
            </li>
          </ul>
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Your Choices</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          We share your personal information with our affiliates and with
          sponsors, joint venture partners and other third parties, including
          entities for which we are acting as an agent, licensee, application
          host or publisher, that wish to send you information about their
          products and services that may be of interest to you, as determined by
          your choices in managing your communications preferences and other
          settings. The Service may let you post and share personal information,
          comments, materials and other content. Any such contributions you
          disclose publicly may be collected and used by others, may be indexed
          by search engines, and might not be able to be removed. Please be
          careful when disclosing personal information in these public areas.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>For Legal Reasons</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          We also will disclose your personal information if we have a good
          faith belief that such disclosure is necessary to:
          <ul>
            <li>
              meet any applicable law, regulation, legal process or other legal
              obligation;
            </li>
            <li>
              detect, investigate and help prevent security, fraud or technical
              issues; and/or
            </li>
            <li>
              protect the rights, property or safety of Commit Solutions, our
              users, employees or others;
            </li>
            <li>
              and as part of a corporate transaction, such as a transfer of
              assets or an acquisition by or merger with another company.
            </li>
          </ul>
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>
          Your Communications Preferences
        </PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          You can customise and manage your communications preferences and other
          settings when you register with the Service, by updating your account
          features and preferences, by using the “opt-out” or unsubscribe
          mechanism or other means provided within the communications that you
          receive, or by contacting us. We reserve the right to notify you of
          changes or updates to the Service whenever necessary.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>
          Accessing and Updating Your Information
        </PrivacyTermsHeading>
        <PrivacyTermsHeadingSmall>Your Account</PrivacyTermsHeadingSmall>
        <PrivacyTermsParagraph>
          The Service may allow registered users to directly access and review
          their account information and make corrections or updates upon login
          at any time. Keeping such information up to date is solely the
          responsibility of the user. Registered users may also close their
          account directly through the Service or by contacting the Service’s
          customer support.
        </PrivacyTermsParagraph>
        <PrivacyTermsHeadingSmall>Your Rights</PrivacyTermsHeadingSmall>
        <PrivacyTermsParagraph>
          You have the right under privacy and data protection laws, as may be
          applicable, to request free of charge:
          <ul>
            <li>
              access to and correction or deletion of your personal information;
            </li>
            <li>
              restriction of our processing of your personal information, or to
              object to our processing; and
            </li>
            <li>portability of your personal information.</li>
          </ul>
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          If you wish to exercise any of these rights, please contact us.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          You may contact our DPO on dpo@commit.properties or write to us at 33
          Curling Vale, Guildford, England, GU2 7PJ.
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          We will respond to your request consistent with applicable laws. To
          protect your privacy and security, we may require you to verify your
          identity.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Data Retention</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          We retain your personal information for as long as necessary to
          provide the Service and fulfil the transactions you have requested, or
          for other essential purposes such as complying with our legal
          obligations, maintaining business and financial records, resolving
          disputes, maintaining security, detecting and preventing fraud and
          abuse, and enforcing our agreements. If you access the Service through
          a subscription administered or sponsored by your organisation, we
          retain your organisational contact details after the termination of
          your organisation’s subscription to continue to communicate with you
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Children’s Privacy</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          We do not knowingly collect information from children under the age of
          13 or target the Service to children under 13.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Data Security</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          We use a variety of administrative, physical and technical security
          measures to help safeguard your personal information.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Locations of Processing</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          Your personal information may be stored and processed in your region
          or another country where Commit and our service providers maintain
          servers and facilities, including (but not limited to) Australia,
          Brazil, France, Germany, Iceland, Israel, India, Italy, Ireland, the
          Netherlands, the Philippines, Singapore, South Africa, the United
          Kingdom, and the United States. We take steps, including through
          contracts, intended to ensure that the information continues to be
          protected wherever it is located in a manner consistent with the
          standards of protection required under applicable law.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Grounds for Processing</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          When we collect from you any personal information within the scope of
          European data protection laws, we do so:
          <ul>
            <li>
              where necessary to provide the Service, fulfil a transaction or
              otherwise perform a contract with you or at your request prior to
              entering into a contract;
            </li>
            <li>
              where necessary for our compliance with applicable law or other
              legal obligation;
            </li>
            <li>
              where necessary for the performance of a task carried out in the
              public interest;
            </li>
            <li>where applicable, with your consent; and/or</li>
            <li>
              as necessary to operate our business, protect the security of our
              systems, customers and users, detect or prevent fraud, enable our
              customers to comply with legal obligations, or fulfil our other
              legitimate interests as described in the “Information We Collect,”
              “How We Use Your Information” and “Sharing of Your Information”
              clauses above, except where our interests are overridden by your
              privacy rights.
            </li>
          </ul>
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          Where we rely on your consent to process personal information, you
          have the right to withdraw your consent at any time, and where we rely
          on legitimate interests, you may have the right to object to our
          processing.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Changes</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          We will update this privacy policy from time to time. Any changes will
          be posted on this page with an updated revision date. If we make any
          material changes, we will provide notice through the Service or by
          other means.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>

      <PrivacyTermsSectionWrapper>
        <PrivacyTermsHeading>Contact</PrivacyTermsHeading>
        <PrivacyTermsParagraph>
          If you have any questions, comments, complaints or requests regarding
          this privacy policy or our processing of your information, please
          contact us online (dpo@commit.properties) or in writing:
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          Data Protection Officer
          <br />
          Commit Limited
          <br />
          33 Curling Vale,
          <br />
          Guildford,
          <br />
          England,
          <br />
          GU2 7PJ
        </PrivacyTermsParagraph>
        <PrivacyTermsParagraph>
          You may also lodge a complaint with the data protection authority in
          the applicable jurisdiction.
        </PrivacyTermsParagraph>
      </PrivacyTermsSectionWrapper>
    </PrivacyTerms>
  );
};

export default PrivacyPolicy;
