import React, { FunctionComponent } from "react";
import { Container, NameSpan, Radio } from "./RadioButton.components";

type RadioButtonProps = {
  value: string;
  color: "normal" | "dark";
  checked: boolean;
  disabled?: boolean;
  onCheck: () => void;
  size?: "s" | "m" | "l";
};

const RadioButton: FunctionComponent<RadioButtonProps> = ({
  value,
  checked,
  color,
  size = "l",
  onCheck,
  disabled = false,
}) => {
  return (
    <Container
      size={size}
      color={color}
      tabIndex={0}
      checked={checked}
      disabled={disabled}
      onClick={() => !disabled && onCheck()}
      onKeyDown={(e) => {
        if (!disabled) {
          if (e.key === "Enter" || e.key === "Space") {
            onCheck();
          }
        }
      }}
    >
      <Radio checked={checked} disabled={disabled} />
      <NameSpan checked={checked} color={color} size={size}>
        {value}
      </NameSpan>
    </Container>
  );
};

export default RadioButton;
