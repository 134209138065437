import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  GalleryArrowButton,
  GalleryContainer,
  GalleryCounter,
  GalleryImage,
  GalleryImageContainer,
  GalleryThumbnail,
  GalleryThumbnailImage,
  GalleryThumbnails,
  GalleryThumbnailsContainer,
  ImageContainer,
} from "./Gallery.components";
import ArrowLeft from "./arrow_left.svg";
import ArrowRight from "./arrow_right.svg";
import { useIsMediaDown } from "../../media";

type GalleryProps = {
  propertyPhotos: string[];
};

const Gallery: FunctionComponent<GalleryProps> = ({ propertyPhotos }) => {
  const [galleryHeight, setGalleryHeight] = useState(405);
  const [thumbnailHeight, setThumbnailHeight] = useState(99);
  const [currentSlide, setCurrentSlide] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider>(null);
  const thumbsSliderRef = useRef<Slider>(null);
  const isMobile = useIsMediaDown("mobile");
  const [isSwipping, setIsSwipping] = useState(false);
  const [thumbnailMaxWidth, setThumbnailMaxWidth] = useState(0);

  useEffect(() => {
    const width = thumbnailHeight / 0.55;
    setThumbnailMaxWidth(width * propertyPhotos.length);
  }, [thumbnailHeight, propertyPhotos]);

  useEffect(() => {
    const handleResize = () => {
      const width = containerRef.current!.clientWidth;
      const height = width * (isMobile ? 0.75 : 0.57);
      setGalleryHeight(height);

      const thumbnailHeight = (width * 0.55) / 4;
      setThumbnailHeight(thumbnailHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const openImage = useCallback(
    (image: string) => {
      if (!isSwipping) window.open(image, "_blank");
    },
    [isSwipping],
  );

  const changeSlide = (slide: number) => {
    sliderRef.current?.slickGoTo(slide);
  };

  const hideControlls = propertyPhotos.length < 2;

  return (
    <GalleryContainer ref={containerRef}>
      <GalleryImageContainer>
        <Slider
          onSwipe={() => setIsSwipping(true)}
          arrows={false}
          afterChange={(index) => {
            setCurrentSlide(index);
            setIsSwipping(false);
          }}
          ref={sliderRef}
          asNavFor={thumbsSliderRef.current!}
        >
          {propertyPhotos.map((image) => (
            <ImageContainer
              draggable={false}
              height={galleryHeight}
              key={image}
              onClick={() => openImage(image)}
            >
              <GalleryImage key={image} src={image} />
            </ImageContainer>
          ))}
        </Slider>
        <GalleryArrowButton
          hide={hideControlls}
          direction="left"
          onClick={() => changeSlide(currentSlide - 1)}
        >
          <img src={ArrowLeft} alt="Previous" />
        </GalleryArrowButton>
        <GalleryArrowButton
          hide={hideControlls}
          direction="right"
          onClick={() => changeSlide(currentSlide + 1)}
        >
          <img src={ArrowRight} alt="Next" />
        </GalleryArrowButton>
        <GalleryCounter>{`${currentSlide + 1}/${
          propertyPhotos.length
        }`}</GalleryCounter>
      </GalleryImageContainer>
      <GalleryThumbnailsContainer
        hide={hideControlls}
        maxWidth={thumbnailMaxWidth}
      >
        <GalleryThumbnails>
          <Slider
            afterChange={setCurrentSlide}
            asNavFor={sliderRef.current!}
            arrows={false}
            slidesToShow={Math.min(4, propertyPhotos.length)}
            ref={thumbsSliderRef}
            swipeToSlide={true}
            focusOnSelect={true}
          >
            {propertyPhotos.map((image, index) => (
              <GalleryThumbnail
                onClick={() => sliderRef.current?.slickGoTo(index)}
                height={thumbnailHeight}
                selected={index === currentSlide}
                key={index.toString()}
              >
                <GalleryThumbnailImage src={image} />
              </GalleryThumbnail>
            ))}
          </Slider>
        </GalleryThumbnails>
      </GalleryThumbnailsContainer>
    </GalleryContainer>
  );
};

export default Gallery;
