import { PropertyAction } from "../../modules/shared/components/PropertyCard/PropertyActions/PropertyActions";
import useCurrentAccountType from "./useCurrentAccountType";
import useListingsStatus from "./useListingsStatus";

const useAvailablePropertyActions = () => {
  const status = useListingsStatus();
  const accountType = useCurrentAccountType();

  if (!accountType) return [];
  if (accountType === "ESTATE_AGENT") {
    if (status === "ACTIVE")
      return [PropertyAction.EDIT, PropertyAction.DELETE];
  }
  if (accountType === "ADMIN") {
    if (status === "PENDING")
      return [
        PropertyAction.ACCEPT,
        PropertyAction.VIEW,
        PropertyAction.DECLINE,
      ];
    else if (status === "ACTIVE")
      return [PropertyAction.VIEW, PropertyAction.DELETE];
  }

  return [];
};

export default useAvailablePropertyActions;
