export interface CurrentUser {
  email: string;
  name: string;
  phone: string;
  companyName: string;
  bio: string;
}

export const currentUserFromResponse = (response: any): CurrentUser => {
  return {
    email: response ? response.email : null,
    name: response ? response.name : null,
    phone: response ? response.phone : null,
    companyName: response ? response.companyName : null,
    bio: response ? response.bio : null,
  };
};
