import React, { FunctionComponent, useCallback } from "react";
import routesPaths from "../../../Routing/routesPaths";
import {
  AddPropertyButtonsContainer,
  AddPropertyButtonWrapper,
  AddressSeparator,
  AddressSpan,
  BackIconImage,
  BackLink,
  BackLinkSpan,
  Container,
  Content,
} from "./Navbar.components";
import BackIcon from "./icons/icon_back.svg";
import Button from "../Button";
import useAddPropertyContext from "../../../Layouts/AddPropertyLayout/AddPropertyContext/useAddPropertyContext";
import { AddPropertyMode } from "../../../AddProperty/AddPropertyFormContent";
import { FormikProps } from "formik";
import { AddPropertyFormValues } from "../../../AddProperty/form";
import { useModal } from "../Modal";
import ConfirmModal from "../Modals/ConfirmModal/ConfirmModal";
import { useNavigate } from "react-router";
import { modalsData } from "../Modals/modalsData";

const getLeavingModal = (onLeave: () => void, onStay: () => void) => (
  <ConfirmModal
    {...modalsData.leaving}
    primaryButtonSize="medium"
    primaryText="Leave without saving"
    secondaryText="Stay"
    onPrimaryAction={onLeave}
    onSecondaryAction={onStay}
  />
);

type AddPropertyNavarProps = FormikProps<AddPropertyFormValues> & {
  onError: (error: string) => void;
};

const AddPropertyNavbar: FunctionComponent<AddPropertyNavarProps> = ({
  values,
  isSubmitting,
  validateForm,
  setErrors,
  submitForm,
  setStatus,
  onError,
  ...props
}) => {
  const { address } = values;
  const navigate = useNavigate();

  const { mode } = useAddPropertyContext();
  const { openModal, closeModal } = useModal();

  const onSubmit = useCallback(async () => {
    const validationResult = await validateForm(values);
    const isValid = Object.keys(validationResult).length === 0;

    if (!isValid) {
      onError(
        "It seems like we miss some information. Please fill out all required fields.",
      );
    }

    window.scrollTo({ top: 0, behavior: "smooth" });

    submitForm();
  }, [onError, submitForm, validateForm, values]);

  return (
    <Container>
      <Content>
        <BackLink
          onClick={() => {
            const modal = getLeavingModal(
              () => {
                closeModal();
                window.scrollTo({ top: 0, behavior: "smooth" });
                navigate(`${routesPaths.MY_PROPERTIES_ROOT}/active}`);
              },
              () => closeModal(),
            );
            openModal(modal);
          }}
        >
          <BackIconImage src={BackIcon} />
          <BackLinkSpan>Back to my listings</BackLinkSpan>
        </BackLink>

        {address && <AddressSeparator />}

        <AddressSpan>{address}</AddressSpan>

        <AddPropertyButtonsContainer>
          {mode === AddPropertyMode.EDIT ? (
            <AddPropertyButtonWrapper>
              <Button type="button" onClick={onSubmit}>
                Edit
              </Button>
            </AddPropertyButtonWrapper>
          ) : (
            <AddPropertyButtonWrapper>
              <Button
                type="button"
                onClick={onSubmit}
                isLoading={isSubmitting}
                size="block"
              >
                Submit for review
              </Button>
            </AddPropertyButtonWrapper>
          )}
        </AddPropertyButtonsContainer>
      </Content>
    </Container>
  );
};

export default AddPropertyNavbar;
