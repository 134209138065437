import styled from "styled-components";
import { colors } from "../../../../../../shared/colors";
import { LeadSmallStyle } from "../../../../../../shared/mixins";

export const AreaProsperityContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  @media (max-width: 683px) {
    margin-left: 0px;
    margin-top: 24px;
  }
`;

export const AreaProsperityChartWrapper = styled.div`
  display: flex;
  padding: 16px 24px 24px;
  border-radius: 16px;
  border: 1px solid ${colors.grayscale._300};
  box-sizing: border-box;

  @media (max-width: 683px) {
    width: 100%;
    justify-content: center;
  }
`;

export const FakeChartWrapper = styled.div`
  position: relative;
  width: 180px;
  height: 180px;

  > h5 {
    position: absolute;
    z-index: 11;
    margin: 0;
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    color: ${colors.primary.pure_white};
    left: 50%;
    transform: translateX(-50%);
    top: 35%;
    > span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
  > span {
    position: absolute;
    ${LeadSmallStyle()};
    z-index: 11;
    color: ${colors.primary.pure_white};
    left: 50%;
    transform: translateX(-50%);
    top: 55%;
    text-align: center;
    white-space: nowrap;
  }
`;

export const FakeChartBackground = styled.div`
  width: 160px;
  height: 160px;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
  box-shadow: 0px 15.1951px 17.7276px rgba(52, 149, 239, 0.25);
`;

export const FluidChart = styled.div`
  position: absolute;
  z-index: 10;
`;
