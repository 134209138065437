import { UploadCategory } from "../../../generated/schema";
import { AddPropertyFormValues } from "../form";

export interface ICategorizedFile {
  category: UploadCategory;
  file: File;
  name: string;
}

const mapFilesToCategories = (values: AddPropertyFormValues) => {
  const propertyImages = values.photos
    .filter((f) => f.file) //Get only new files excluding urls.
    .map((file) => {
      const { file: inputFile } = file;
      return {
        category: UploadCategory.PropertyImages,
        file: inputFile!,
        name: file.name,
      };
    });
  const documents = values.documents
    .filter((f) => f.file) //Get only new files excluding urls.
    .map((file) => {
      const { file: inputFile } = file;
      return {
        category: UploadCategory.Documents,
        file: inputFile!,
        name: file.name,
      };
    });

  const floorPlans = values.floorPlans
    .filter((f) => f.file) //Get only new files excluding urls.
    .map((file) => {
      const { file: inputFile } = file;
      return {
        category: UploadCategory.FloorPlans,
        file: inputFile!,
        name: file.name,
      };
    });

  return [...propertyImages, ...documents, ...floorPlans];
};

export default mapFilesToCategories;
