import React, { FunctionComponent } from "react";
import { FormikProps, Form } from "formik";
import InputField from "../../../modules/shared/components/Form/Input/InputField";
import Password from "../../../modules/shared/components/Form/Password";
import { LoginFormValues } from "./form";
import {
  ButtonContainer,
  ButtonRegisterContainer,
  CommitLogo,
  Description,
  ErrorSpan,
  ForgotPasswordLink,
  FormContainer,
  FormContent,
  Header,
  InputContainer,
  RegisterLink,
  RegisterSpan,
} from "../Login.components";
import CommigLogoBlue from "../../shared/images/commit_logo_blue.svg";
import routesPaths from "../../Routing/routesPaths";
import Button from "../../shared/components/Button";
import useQueryParameters from "../../../common/helpers/useQueryParameters";
import { useNavigate } from "react-router-dom";

type Props = FormikProps<LoginFormValues> & {
  message?: string;
  error?: string;
  onChange?: () => void;
};

const getHeader = (accountClaimed: boolean, passwordChanged: boolean) => {
  return accountClaimed
    ? "Wonderful! Your email has been verified."
    : passwordChanged
    ? "Password changed successfully"
    : "Welcome back to Commit";
};

const getDescription = (accountClaimed: boolean, passwordChanged: boolean) => {
  return accountClaimed
    ? "Now you can login to your account."
    : passwordChanged
    ? "You can log in using your new password."
    : "Log in to use your account and manage your properties";
};

const LoginFormContent: FunctionComponent<Props> = ({
  isSubmitting,
  isValid,
  submitCount,
  error,
  onChange,
  values,
  ...props
}) => {
  const accountClaimed = useQueryParameters().get("accountActivated") !== null;
  const passwordChanged = useQueryParameters().get("passwordChanged") !== null;
  const navigate = useNavigate();
  return (
    <FormContainer>
      <Form onChange={() => onChange && onChange()}>
        <FormContent>
          <CommitLogo
            alt="Commit logo"
            src={CommigLogoBlue}
            onClick={() => navigate(routesPaths.HOME)}
          />
          <Header>{getHeader(accountClaimed, passwordChanged)}</Header>

          <Description>
            {getDescription(accountClaimed, passwordChanged)}
          </Description>

          <InputContainer>
            <InputField
              label="Email"
              autoComplete="email"
              name="email"
              type="email"
            />
          </InputContainer>
          <InputContainer>
            <Password
              name="password"
              {...props}
              autoComplete="current-password"
            />
          </InputContainer>
          <ForgotPasswordLink to={routesPaths.RESET_PASSWORD}>
            Forgot Password?
          </ForgotPasswordLink>
          <ButtonRegisterContainer>
            <ErrorSpan>{error}</ErrorSpan>
            <ButtonContainer>
              <Button type="submit" size="block" isLoading={isSubmitting}>
                Login
              </Button>
            </ButtonContainer>
            <RegisterSpan>
              Don’t have an account?{" "}
              <RegisterLink to={routesPaths.REGISTER}>Register</RegisterLink>
            </RegisterSpan>
          </ButtonRegisterContainer>
        </FormContent>
      </Form>
    </FormContainer>
  );
};
export default LoginFormContent;
