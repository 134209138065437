import * as Yup from "yup";
import { ListingType, PropertyUseType } from "../../../../generated/schema";

export interface SearchHeaderFormValues {
  searchType: ListingType;
  address: string | undefined;
  lat: number | undefined;
  lng: number | undefined;
  propertyUseType: PropertyUseType[];
}

export const searchHeaderInitialValues: SearchHeaderFormValues = {
  searchType: ListingType.ForRent,
  lat: undefined,
  lng: undefined,
  address: undefined,
  propertyUseType: [],
};

export const searchHeaderValidationSchema = Yup.object({
  lat: Yup.number().required("Required"),
  lng: Yup.number().required("Required"),
  address: Yup.string().required(""),
});
