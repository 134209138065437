import styled, { css } from "styled-components";
import { colors } from "../../../colors";
import { down } from "../../../media";

interface StyledInputProps {
  isError?: boolean;
  hasIcon?: boolean;
  hasFrontIcon?: boolean;
  inputSize?: "m" | "l";
}

export const StyledInput = styled.input<StyledInputProps>`
  /* Remove autofill background color. */
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
  -webkit-appearance: none;

  width: 100%;
  box-sizing: border-box;
  height: ${(p) => (p.inputSize === "l" ? 48 : 40)}px;
  background: ${colors.primary.pure_white};
  border: 1px solid ${colors.grayscale._400};
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 12px;
  ${(p) =>
    p.hasFrontIcon &&
    css`
      padding-left: 32px;
    `}

  ${(p) =>
    p.hasIcon &&
    css`
      padding-right: 40px;
    `}

  color: ${colors.primary.frosted_black};
  font-weight: normal;
  transition: border 0.5s ease;

  ${down("mobile")} {
    height: 48px;
    font-size: 16px;
  }

  border: 1px solid
    ${(p) => (p.isError ? colors.semantic.red_dark : colors.grayscale._400)};

  &::placeholder {
    color: ${colors.grayscale._500};
  }
  :hover:enabled {
    border: 1px solid
      ${(p) =>
        p.isError ? colors.semantic.red_dark : colors.secondary.pure_blue};
  }

  &:focus,
  &:active {
    outline: none;
    border: 1px solid
      ${(p) =>
        p.isError ? colors.semantic.red_dark : colors.secondary.pure_blue};
    ${(p) =>
      !p.isError &&
      css`
        box-shadow: 0px 0px 0px 3px rgba(59, 122, 247, 0.1);
      `}
  }

  &:disabled {
    color: ${colors.grayscale._700};
    cursor: not-allowed;
    background: ${colors.grayscale._100};
  }
`;

export const InputComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FrontIconWrapper = styled.div<StyledInputProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  left: 0px;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 26px;

  ${(p) =>
    p.isError &&
    css`
      > svg {
        > path {
          fill: ${colors.semantic.red_dark};
        }
      }
    `};
`;

export const IconWrapper = styled.div<StyledInputProps>`
  position: absolute;
  width: 16px;
  height: 16px;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  > svg {
    transition: transform 0.5s ease;
  }

  :hover {
    > svg {
      transform: scale(1.1);
    }
  }

  ${(p) =>
    p.isError &&
    css`
      > svg {
        > path {
          fill: ${colors.semantic.red_dark};
        }
      }
    `};
`;
