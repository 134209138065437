import styled from "styled-components";

export const SearchInputContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const SearchInputTextContainer = styled.div`
  width: 100%;
`;
