import { TransitionStatus } from "react-transition-group/Transition";
import styled, { css } from "styled-components";
import { colors } from "../../colors";
import { zIndexes } from "../../constants";
import { down } from "../../media";
import { ScrollBarStyle } from "../../mixins";

export interface ContainerProps {
  status: TransitionStatus;
}

const INITIAL_POSITION = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

export const MODAL_TRANSITION_TIME_MS = 330;

export const ModalContainerChild = styled.div<{ isOpen: boolean }>`
  height: 100%;
  ${(p) =>
    p.isOpen &&
    css`
      overflow-y: hidden;
    `}
`;

export const Container = styled.div.attrs({
  "data-cy": "modal-container",
  id: "modal-container",
})<ContainerProps>`
  position: fixed;
  ${INITIAL_POSITION};
  display: flex;
  opacity: 0;
  pointer-events: none;
  transition: opacity ${MODAL_TRANSITION_TIME_MS}ms ease-in-out;
  z-index: ${zIndexes.MODAL_CONTAINER};

  ${(p) =>
    (p.status === "entering" || p.status === "entered") &&
    css`
      opacity: 1;
      pointer-events: all;
    `};

  ${down("mobile")} {
    z-index: ${zIndexes.MOBILE_MODAL_CONTAINER};
  }
`;

export const ModalWrapper = styled.div`
  transition: transform ${MODAL_TRANSITION_TIME_MS}ms ease-in-out;
  display: flex;
  flex-direction: column;
  background: white;
  margin: auto;
  padding: 40px 56px !important;
  box-sizing: border-box;
  border-radius: 16px;
  width: 720px;
  max-width: 720px;
  ${ScrollBarStyle()}

  ${down("tablet")} {
    width: 532px;
  }

  ${down("mobile")} {
    width: 100%;
    border-radius: 0px;
    height: calc(100% - 56px);
    padding: 35px 24px;
    overflow-y: scroll;
    margin-top: 56px;
  }
`;

export const Background = styled.div.attrs({
  id: "modal-container-background",
})<{
  status: TransitionStatus;
}>`
  position: fixed;
  ${INITIAL_POSITION};
  background: ${colors.primary.frosted_black};
  opacity: 0;
  z-index: ${zIndexes.MODAL_OVERLAY};
  transition: opacity ${MODAL_TRANSITION_TIME_MS}ms ease-in;
  ${(p) =>
    (p.status === "entering" || p.status === "entered") &&
    css`
      opacity: 0.7;
    `};

  ${down("mobile")} {
    opacity: 0;
    display: none;
    z-index: ${zIndexes.MOBILE_MODAL_OVERLAY};
  }
`;
