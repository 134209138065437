import { Form } from "formik";
import styled from "styled-components";
import { colors } from "../../../shared/colors";
import { down } from "../../../shared/media";

export const SearchHeaderFormContentContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;

  ${down("mobile")} {
    margin-top: 24px;
  }
`;

export const SearchHeaderRadioWrapper = styled.div`
  display: flex;

  > :last-child {
    margin-left: 8px;
  }

  > div {
    width: 168px;
    justify-content: center;
  }

  ${down("tablet")} {
    > div {
      width: 147px;
    }
  }

  ${down("mobile")} {
    justify-content: center;
    background: ${colors.primary.pure_white};
    border-radius: 16px 16px 0 0;
    padding: 32px 16px 0;

    > div {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const SearchHeaderInputsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 40px;
  background: ${colors.primary.pure_white};
  margin-top: 8px;
  width: 100%;
  max-width: 996px;
  border-radius: 8px;
  box-sizing: border-box;

  ${down("tablet")} {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
  }

  ${down("mobile")} {
    border-radius: 0 0 8px 8px;
    padding: 24px 16px 16px 16px;
    margin-top: 0px;
  }
`;
export const SearchHeaderInputsInnerWrapper = styled.div`
  display: flex;
  width: 100%;

  ${down("mobile")} {
    flex-direction: column;
  }
`;

export const SearchHeaderLocationInputWrapper = styled.div`
  max-width: 372px;
  width: 100%;
  ${down("tablet")} {
    max-width: 100%;
  }
`;

export const SearchHeaderPropertyUseWrapper = styled.div`
  max-width: 372px;
  width: 100%;
  margin: 0px 16px;
  ${down("tablet")} {
    max-width: 100%;
    margin: 0 0 0 16px;
  }
  ${down("mobile")} {
    margin: 0;
    margin-top: 24px;
  }
`;

export const SearchHeaderButtonWrapper = styled.div`
  display: flex;
  align-self: flex-end;

  ${down("tablet")} {
    align-self: flex-start;
    margin-top: 16px;
  }

  ${down("mobile")} {
    margin-top: 40px;
    margin-bottom: 14px;
  }
`;
