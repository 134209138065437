import Button from "../../../modules/shared/components/Button";
import InputField from "../../../modules/shared/components/Form/Input/InputField";
import TextareaField from "../../../modules/shared/components/Form/Textarea/TextareaField";
import { FormikProps } from "formik";
import React, { FunctionComponent } from "react";
import { SeoFormValues } from "./form";
import {
  SeoFormErrorMessage,
  SeoFormHorizontalInputWrapper,
  SeoFormHorizontalWrapper,
  SeoFormInnerWrapper,
  SeoFormTextareaWrapper,
} from "./SeoForm.components";

type Props = FormikProps<SeoFormValues> & {
  error?: string;
};

const SeoFormContent: FunctionComponent<Props> = ({
  submitForm,
  values,
  isSubmitting,
  error,
}) => {
  return (
    <SeoFormInnerWrapper>
      <SeoFormHorizontalWrapper>
        <SeoFormHorizontalInputWrapper>
          <InputField
            label="Title"
            autoComplete="title"
            name="title"
            type="text"
            maxLength={255}
          />
        </SeoFormHorizontalInputWrapper>
        <SeoFormHorizontalInputWrapper>
          <InputField
            label="URL Slug"
            autoComplete="urlSlug"
            name="urlSlug"
            type="text"
            maxLength={255}
          />
        </SeoFormHorizontalInputWrapper>
      </SeoFormHorizontalWrapper>
      <InputField
        label="Link"
        autoComplete="link"
        name="link"
        type="text"
        maxLength={255}
      />
      <SeoFormTextareaWrapper>
        <TextareaField
          name="description"
          label="Description"
          maxLength={250}
          rows={3}
          value={values.description}
        />
      </SeoFormTextareaWrapper>

      <SeoFormHorizontalWrapper>
        <Button
          size="medium"
          type="submit"
          isLoading={isSubmitting}
          onClick={() => submitForm()}
        >
          Add
        </Button>
        {error && <SeoFormErrorMessage>{error}</SeoFormErrorMessage>}
      </SeoFormHorizontalWrapper>
    </SeoFormInnerWrapper>
  );
};

export default SeoFormContent;
