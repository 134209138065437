import ConfirmModal from "./ConfirmModal/ConfirmModal";
import { modalsData } from "./modalsData";

const getDeleteModal = (
  title: string,
  description: string | JSX.Element,
  buttonText: "Delete" | "Decline",
  onDelete: () => void,
  onClose: () => void,
) => (
  <ConfirmModal
    title={title}
    description={description}
    primaryButtonType="red"
    secondaryButtonType="outline_dark"
    primaryText={buttonText}
    secondaryText="Cancel"
    onPrimaryAction={onDelete}
    onSecondaryAction={onClose}
  />
);

export const getPropertyDeleteModal = (
  address: string,
  onDelete: () => void,
  onClose: () => void,
) => {
  const title = modalsData.delete_property.title;
  const description = modalsData.delete_property.description(address);
  return getDeleteModal(title, description, "Delete", onDelete, onClose);
};

export const getPropertyDeclineModal = (
  address: string,
  onDelete: () => void,
  onClose: () => void,
) => {
  const title = modalsData.decline_property.title;
  const description = modalsData.decline_property.description(address);
  return getDeleteModal(title, description, "Decline", onDelete, onClose);
};

export const getPropertyEditModal = (
  onEdit: () => void,
  onClose: () => void,
) => (
  <ConfirmModal
    {...modalsData.verification}
    primaryText="Edit"
    secondaryText="Cancel"
    onPrimaryAction={onEdit}
    onSecondaryAction={onClose}
  />
);
