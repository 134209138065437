import React, { FunctionComponent } from "react";
import { RouteProps, Navigate } from "react-router";
import routesPaths from "./routesPaths";
import useAppContext from "../App/AppContext/useAppContext";
import SignInLayout from "../Layouts/SignInLayout";
import { AccountType } from "../../generated/schema";

export const SignInRoute: FunctionComponent<RouteProps> = ({
  children,
  ...routeProps
}) => {
  // token means that user is logged in
  const { authToken } = useAppContext();
  const hasToken = !!authToken;

  if (!hasToken) return <SignInLayout>{children}</SignInLayout>;

  return (
    <Navigate
      to={{
        pathname:
          (authToken.accountType as AccountType) === "ADMIN"
            ? routesPaths.LISTINGS
            : routesPaths.MY_PROPERTIES,
      }}
    />
  );
};

export default SignInRoute;
