import React, { FunctionComponent, useState } from "react";
import {
  CollapsableSectionContainer,
  CollapsableSectionExpandButton,
  CollapsableSectionExpandButtonContent,
  CollapsableSectionHeader,
  CollapsableSectionHeaderContainer,
} from "./Filters.components";
import { ReactComponent as IconCollapse } from "./icon_collapse.svg";
import AnimateHeight from "../../shared/components/AnimateHeight";
import { isMediaDown } from "../../shared/media";

type CollapsableSectionProps = {
  title: string;
  isExpandable: boolean;
};

const CollapsableSection: FunctionComponent<CollapsableSectionProps> = ({
  title,
  isExpandable,
  children,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isTabletDown = isMediaDown("tablet");

  return (
    <CollapsableSectionContainer>
      <CollapsableSectionHeaderContainer
        isExpandable={isExpandable && isTabletDown}
        onClick={() =>
          isExpandable && isTabletDown && setIsExpanded(!isExpanded)
        }
      >
        <CollapsableSectionHeader>{title}</CollapsableSectionHeader>
        {isExpandable && isTabletDown && (
          <CollapsableSectionExpandButton>
            <CollapsableSectionExpandButtonContent isExpanded={isExpanded}>
              <IconCollapse />
            </CollapsableSectionExpandButtonContent>
          </CollapsableSectionExpandButton>
        )}
      </CollapsableSectionHeaderContainer>
      <AnimateHeight
        height={isExpandable && isTabletDown && !isExpanded ? 0 : "auto"}
      >
        {children}
      </AnimateHeight>
    </CollapsableSectionContainer>
  );
};

export default CollapsableSection;
