import styled from "styled-components";
import { colors } from "../../../colors";
import { down } from "../../../media";
import { DefaultScaleTransform } from "../../../mixins";
import { PropertyAction } from "./PropertyActions";

export const PropertyActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  ${down("tablet")} {
    width: 40px;
  }

  ${down("mobile")} {
    display: none;
  }
`;

export const PropertyActionButton = styled.button<{
  actionType: PropertyAction;
}>`
  position: relative;
  border: none;
  display: inline-flex;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 0 12px;
  cursor: pointer;
  transition: width 0.2s ease;

  background: ${(p) =>
    p.actionType === PropertyAction.EDIT
      ? colors.primary.frosted_black
      : colors.primary.pure_white};

  color: ${(p) =>
    p.actionType === PropertyAction.EDIT
      ? colors.primary.pure_white
      : colors.primary.frosted_black};

  > svg {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  > span {
    margin-left: 0px;
    text-transform: uppercase;
    max-width: 0;
    transition: max-width 0.2s ease, margin-left 0.2s ease;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
  }

  :hover {
    width: 100%;
    > span {
      margin-left: 8px;
      max-width: 100%;
    }
  }

  ${down("tablet")} {
    :hover {
      ${DefaultScaleTransform()};
      > span {
        max-width: 0;
        margin-left: 0;
      }
    }
  }
`;
