import { TransitionStatus } from "react-transition-group";
import styled, { css } from "styled-components";
import { colors } from "../../colors";
import { down } from "../../media";
import { H4Style } from "../../mixins";

export const CookiesBoxButtonWrapper = styled.div`
  width: 96px;
  margin-left: 24px;

  ${down("tablet")} {
    width: 100%;
    margin-left: unset;
  }
`;

export const CookiesHeader = styled.p`
  display: none;

  ${down("tablet")} {
    display: block;
    ${H4Style}
    color: ${colors.primary.pure_white};
    margin-top: 32px;
    margin-bottom: 4px;
  }
`;

export const CookiesDescription = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.primary.frosted_black};
  max-width: 332px;
  margin-left: 10px;
  > a {
    text-decoration: none;
    color: ${colors.secondary.pure_blue};
  }

  ${down("tablet")} {
    flex: 1;
    max-width: unset;
    margin-left: unset;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: ${colors.grayscale._300};

    > a {
      font-weight: 500;
      color: ${colors.primary.pure_white};
    }
  }
`;

export const CookiesImageContainer = styled.div`
  width: 36px;
  height: 36px;
  background-color: ${colors.secondary.pure_blue_transparent};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  ${down("tablet")} {
    background-color: ${colors.grayscale._100};
  }
`;

export const CookiesImage = styled.img`
  width: 20px;
  height: 20px;
`;

export const CookiesBoxWrapper = styled.div<{ status: TransitionStatus }>`
  display: flex;
  position: fixed;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.76);
  box-shadow: 0px 8px 32px rgba(27, 110, 218, 0.1);
  backdrop-filter: blur(8px);
  border-radius: 20px;
  padding: 14px 24px;
  box-sizing: border-box;
  align-items: center;
  transition: bottom 0.5s ease;
  z-index: 10;

  ${down("tablet")} {
    height: 406px;
    bottom: -414px;
    max-width: 359px;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    box-sizing: border-box;
    background: linear-gradient(154.48deg, #1b6eda 1.52%, #5598f6 88.94%);
    box-shadow: 0px -8px 48px rgba(0, 43, 100, 0.25);
    border-radius: 12px;
    overflow: hidden;
    margin: 8px 8px;
    left: 0;
    transform: translateY(0);
  }

  ${down("mobile")} {
    max-width: unset;
  }

  ${(p) =>
    p.status === "entered" &&
    css`
      bottom: 24px;
      ${down("tablet")} {
        bottom: 0px;
      }
    `};
`;
