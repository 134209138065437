import { Formik } from "formik";
import React, { FunctionComponent, useCallback, useState } from "react";
import {
  AccountDetailsModalFormValues,
  accountDetailsModalInitialValues,
  accountDetailsModalValidationSchema,
} from "./form";
import AccountDetailsModalFormContent from "./AccountDetailsModalFormContent";
import { useUpdateUserMutation } from "../../../../../../generated/schema";
import { useModal } from "../../../Modal";
import useAppContext from "../../../../../App/AppContext/useAppContext";

const AccountDetailsModalForm: FunctionComponent = () => {
  const { closeModal } = useModal();
  const { currentUser, refetch } = useAppContext();
  const [error, setError] = useState("");

  const [updateUser] = useUpdateUserMutation();

  const handleSubmit = useCallback(
    async (values: AccountDetailsModalFormValues) => {
      setError("");
      try {
        const { data } = await updateUser({
          variables: {
            input: {
              ...values,
              phone: values.phone.toString(),
            },
          },
        });

        switch (data?.updateUser.__typename) {
          case "UpdateUserSuccessfulResponse":
            closeModal();
            setTimeout(() => refetch(), 200);
            return;
          case "UpdateUserInvalidResponse":
            setError("Unexpected error.");
            break;
        }
      } catch (e) {
        setError("Unexpected error.");
        console.log(e);
      }
    },
    [updateUser, closeModal, refetch],
  );

  return (
    <Formik
      initialValues={
        currentUser
          ? {
              name: currentUser.name || "",
              phone: currentUser.phone || "",
              companyName: currentUser.companyName || "",
              bio: currentUser.bio || "",
            }
          : accountDetailsModalInitialValues
      }
      validationSchema={accountDetailsModalValidationSchema}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <AccountDetailsModalFormContent {...formProps} error={error} />
      )}
    </Formik>
  );
};

export default AccountDetailsModalForm;
