import { Form, useFormikContext } from "formik";
import React, { FunctionComponent, useCallback, useEffect } from "react";
import AddPropertyNavbar from "../shared/components/Navbar/AddPropertyNavbar";
import AddPropertyFormContent, {
  AddPropertyFormContentProps,
} from "./AddPropertyFormContent";

const AddPropertyForm: FunctionComponent<
  AddPropertyFormContentProps & {
    error?: string;
    loading: boolean;
    onError: (errror: string) => void;
  }
> = ({ error, loading, onError, ...formProps }) => {
  const onKeyDown = useCallback((keyEvent: React.KeyboardEvent) => {
    if (
      keyEvent.key === "Enter" &&
      !(keyEvent.target instanceof HTMLTextAreaElement)
    ) {
      keyEvent.preventDefault();
    }
  }, []);

  const { errors } = useFormikContext();

  useEffect(() => {
    const isValid = Object.keys(errors).length === 0;
    if (isValid) onError("");
  }, [errors, onError]);

  return (
    <Form onKeyPress={onKeyDown}>
      <AddPropertyNavbar onError={onError} {...formProps} />
      <AddPropertyFormContent
        {...formProps}
        error={error}
        isLoading={loading}
      />
    </Form>
  );
};

export default AddPropertyForm;
