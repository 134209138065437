import React, { FunctionComponent } from "react";
import FormBox from "../FormBox/FormBox";
import { AddPropertyFormContentProps } from "../../AddPropertyFormContent";
import {
  ContactSectionContainer,
  InputContainer,
} from "./ContactSection.components";
import InputField from "../../../shared/components/Form/Input/InputField";
import TextareaField from "../../../shared/components/Form/Textarea/TextareaField";
import { AddPropertyDivider } from "../../../Layouts/AddPropertyLayout/AddPropertyLayout.components";
import { Descriptions, Hints } from "../../AddPropertyFormHints";

const ContactSection: FunctionComponent<AddPropertyFormContentProps> = (
  props,
) => {
  return (
    <FormBox section="CONTACT" description={Descriptions.contact}>
      <ContactSectionContainer>
        <InputContainer>
          <InputField
            name="email"
            type="email"
            label="Email"
            inputSize="l"
            hint={Hints.email}
          />
        </InputContainer>
        <InputContainer>
          <InputField
            name="name"
            type="text"
            label="Name"
            inputSize="l"
            hint={Hints.name}
          />
        </InputContainer>
        <AddPropertyDivider />
        <TextareaField name="bio" label="Bio" hint={Hints.bio} />
      </ContactSectionContainer>
    </FormBox>
  );
};

export default ContactSection;
