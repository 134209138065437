import { Formik } from "formik";
import React, { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router";
import { SortOrder } from "../../../Properties/Filters/Filters";
import routesPaths from "../../../Routing/routesPaths";
import {
  SearchHeaderFormValues,
  searchHeaderInitialValues,
  searchHeaderValidationSchema,
} from "./form";
import AccountDetailsModalFormContent from "./SearchHeaderFormContent";

const SearchHeaderForm: FunctionComponent = () => {
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    async (values: SearchHeaderFormValues) => {
      let params = `listingType=${values.searchType}&locationRange=0&sortOrder=${SortOrder.DATE_DESC}`;
      if (values.lat && values.lng) {
        params += `&lat=${values.lat}&lng=${values.lng}&address=${values.address}`;
      }
      values.propertyUseType.forEach((useType) => {
        params += `&useType=${useType}`;
      });
      navigate(`${routesPaths.PROPERTIES}?${params}&page=1`);
    },
    [navigate],
  );

  return (
    <Formik
      initialValues={searchHeaderInitialValues}
      validationSchema={searchHeaderValidationSchema}
      onSubmit={handleSubmit}
    >
      {(formProps) => <AccountDetailsModalFormContent {...formProps} />}
    </Formik>
  );
};

export default SearchHeaderForm;
