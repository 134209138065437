import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../shared/colors";
import { down } from "../shared/media";
import {
  BodyMediumStyle,
  DefaultScaleTransform,
  H4Style,
  TinyStyle,
} from "../shared/mixins";

export const FormContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;

  > form {
    display: flex;
    height: 100%;
    flex: 1;
  }
`;

export const Header = styled.h4`
  ${H4Style};
`;

export const Description = styled.span`
  ${BodyMediumStyle};
  max-width: 280px;
`;

export const ErrorSpan = styled.span`
  ${TinyStyle}
  align-self: flex-start;
  color: ${colors.semantic.red_dark};
  margin: 8px 0px;
  display: flex;
  min-height: 16px;
  align-items: flex-end;
`;

export const FormContent = styled.div`
  margin: 80px 108px;
  flex: 1;
  display: flex;
  flex-direction: column;

  ${down("tablet")} {
    margin: 40px 72px 48px 72px;
  }

  ${down("mobile")} {
    margin: 24px;
  }
`;

export const InputContainer = styled.div`
  height: 72px;
  margin-top: 24px;

  :nth-of-type(2) {
    margin-top: 0px;

    ${down("mobile")} {
      margin-top: 16px;
    }
  }
`;

export const ButtonRegisterContainer = styled.span`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${down("mobile")} {
    align-items: center;
  }
`;

export const ButtonContainer = styled.div`
  width: 168px;
  height: 40px;

  ${down("mobile")} {
    width: 100%;
    height: 48px;
  }
`;

export const CommitLogo = styled.img`
  width: 65px;
  height: 24px;
  display: none;

  ${down("tablet")} {
    display: block;
    margin-bottom: 20px;
    ${DefaultScaleTransform()};
  }

  ${down("mobile")} {
    margin-bottom: 36px;
  }
`;

export const ForgotPasswordLink = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.01em;
  color: ${colors.grayscale._600};
  margin-top: 8px;
  width: fit-content;
  align-self: flex-end;

  :hover {
    text-decoration: none;
  }
`;

export const RegisterSpan = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${colors.grayscale._600};
  margin-top: 24px;
  display: inline-block;
`;

export const RegisterLink = styled(Link)`
  color: ${colors.secondary.pure_blue};

  :hover {
    text-decoration: none;
  }
`;
