import React, {
  Dispatch,
  useCallback,
  FunctionComponent,
  SetStateAction,
  useState,
} from "react";
import { Formik } from "formik";
import RegisterFormContent from "./RegisterFormContent";
import { useRegisterMutation } from "../../../generated/schema";
import {
  RegisterFormValues,
  registerInitialValues,
  registerValidationSchema,
} from "./form";

type Props = {
  setRegistered: Dispatch<SetStateAction<string>>;
};
const RegisterForm: FunctionComponent<Props> = ({ setRegistered }) => {
  const [error, setError] = useState("");
  const [registerUser] = useRegisterMutation();

  const handleSubmit = useCallback(
    async (values: RegisterFormValues) => {
      setError("");
      try {
        const { data: registerUserData } = await registerUser({
          variables: {
            input: {
              email: values.email,
              password: values.password,
            },
          },
        });
        switch (registerUserData?.register.__typename) {
          case "RegisterSuccessfulResponse":
            setRegistered(values.email);
            return;
          case "RegisterInvalidResponse":
            setError("This account already exists.");
            break;
        }
      } catch (e) {
        setError("There was an error when creating the account.");
        console.error(e);
      }
    },
    [registerUser, setRegistered],
  );

  return (
    <Formik
      initialValues={registerInitialValues}
      validationSchema={registerValidationSchema}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <RegisterFormContent
          {...formProps}
          error={error}
          onChange={() => setError("")}
        />
      )}
    </Formik>
  );
};
export default RegisterForm;
