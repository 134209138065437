import styled from "styled-components";
import { colors } from "../../colors";
import { down } from "../../media";

export const PrivacyTermsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.grayscale._200};
`;

export const PrivacyTermsHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 173px;
  background: ${colors.primary.pure_white};
  border-radius: 0px 0px 40px 40px;

  ${down("mobile")} {
    height: 144px;
  }
`;

export const FindOutMoreText = styled.h5`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: ${colors.secondary.pure_blue};

  ${down("mobile")} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const PrivacyTermsMainHeading = styled.h2`
  margin: 0;
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  color: ${colors.primary.frosted_black};
  margin-top: 8px;

  ${down("mobile")} {
    font-size: 32px;
    line-height: 44px;
  }
`;

export const PrivacyTermsIndicator = styled.div`
  position: absolute;
  width: 120px;
  height: 3px;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: ${colors.secondary.pure_blue};
`;

export const PrivacyTermsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 44px 48px 8px;
  box-sizing: border-box;
  max-width: 904px;
  width: 100%;
  background: ${colors.primary.pure_white};
  border-radius: 16px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 104px;

  ${down("tabletSmall")} {
    max-width: unset;
    padding: 44px 56px 8px;
    margin-top: 24px;
  }

  ${down("mobile")} {
    padding: 36px 24px;
  }
`;

export const PrivacyTermsHeading = styled.h4`
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: ${colors.primary.frosted_black};
  max-width: 720px;

  ${down("tabletSmall")} {
    max-width: unset;
  }

  ${down("mobile")} {
    font-size: 18px;
    line-height: 24px;
  }
`;
export const PrivacyTermsHeadingSmall = styled.h5`
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.secondary.pure_blue_dark};
  margin-top: 16px;

  ${down("mobile")} {
    font-size: 16px;
    line-height: 16px;
  }
`;
export const PrivacyTermsParagraph = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: ${colors.grayscale._600};
  margin-top: 8px;
  max-width: 720px;
  > ul {
    margin-top: 8px;
    margin-bottom: 0px;
  }

  ${down("tabletSmall")} {
    max-width: unset;
  }
  ${down("mobile")} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const PrivacyTermsSectionWrapper = styled.div`
  margin-bottom: 32px;
  ${down("mobile")} {
    margin-bottom: 24px;
  }
`;
