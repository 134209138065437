import React, { FunctionComponent } from "react";
import { Radio } from "../../shared/components/RadioButotn/RadioButton.components";
import CollapsableSection from "./CollapsableSection";
import {
  LocationRangeContainer,
  LocationRangeRadio,
  LocationRangeRadioText,
} from "./Filters.components";

const RANGES = [0, 5, 0.5, 10, 3, 15];

type LocationRangeProps = {
  range: number;
  onChange: (range: number) => void;
};

const LocationRange: FunctionComponent<LocationRangeProps> = ({
  range,
  onChange,
  ...props
}) => {
  return (
    <CollapsableSection title="Location Range" isExpandable>
      <LocationRangeContainer>
        {RANGES.map((value) => (
          <LocationRangeRadio
            checked={value === range}
            key={value.toString()}
            onClick={() => onChange(value)}
          >
            <Radio checked={value === range} disabled={false} />
            <LocationRangeRadioText>+ {value} miles</LocationRangeRadioText>
          </LocationRangeRadio>
        ))}
      </LocationRangeContainer>
    </CollapsableSection>
  );
};

export default LocationRange;
