import React, { FunctionComponent } from "react";
import { Input } from "../../shared/components/Form/Input/Input";
import CollapsableSection from "./CollapsableSection";
import { FiltersInput, FiltersInputsContainer } from "./Filters.components";

type SqFootageProps = {
  sqFootageFrom?: number;
  sqFootageTo?: number;
  onChange: (sqFootageFrom?: number, sqFootageTo?: number) => void;
};

const SqFootage: FunctionComponent<SqFootageProps> = ({
  sqFootageFrom,
  sqFootageTo,
  onChange,
}) => {
  return (
    <CollapsableSection title="Sq Footage" isExpandable={false}>
      <FiltersInputsContainer>
        <FiltersInput>
          <Input
            onChange={(e) => {
              const value = e.target.value;
              if (value) {
                const footage = Number(value);
                onChange(isNaN(footage) ? undefined : footage, sqFootageTo);
              } else {
                onChange(undefined, sqFootageTo);
              }
            }}
            value={sqFootageFrom === undefined ? "" : sqFootageFrom}
            label="From"
            type="number"
            inputSize="m"
          />
        </FiltersInput>
        <FiltersInput>
          <Input
            onChange={(e) => {
              const value = e.target.value;
              if (value) {
                const footage = Number(value);
                onChange(sqFootageFrom, isNaN(footage) ? undefined : footage);
              } else {
                onChange(sqFootageFrom, undefined);
              }
            }}
            value={sqFootageTo === undefined ? "" : sqFootageTo}
            label="To"
            type="number"
            inputSize="m"
          />
        </FiltersInput>
      </FiltersInputsContainer>
    </CollapsableSection>
  );
};

export default SqFootage;
