import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import urls from "../../common/constants/urls";
import introspectionResult from "../../generated/introspection-result";
import authFlowLink from "./authFlowLink";

const httpLink = createUploadLink({
  uri: urls.API_URL,
});

const link = authFlowLink.concat(httpLink as any);

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
});

const client = new ApolloClient({
  link: link as unknown as ApolloLink,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
  },
});

export default client;
