import * as Yup from "yup";
import { PHONE_FORMAT } from "../../../Form/Input/Input.helpers";

export interface AccountDetailsModalFormValues {
  name: string;
  phone: string;
  companyName: string;
  bio: string;
}

export const accountDetailsModalInitialValues: AccountDetailsModalFormValues = {
  name: "",
  phone: "",
  companyName: "",
  bio: "",
};

export const accountDetailsModalValidationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().trim().matches(PHONE_FORMAT, "Wrong format").optional(),
  companyName: Yup.string(),
  bio: Yup.string().required("Bio is required"),
});
