import React, { FunctionComponent } from "react";
import { HintHeaderContainer, HintImage } from "./HintHeader.components";
import HintIcon from "./icon_hint.svg";
import Hint from "../../../shared/components/Hint/Hint";

const HintHeader: FunctionComponent<{ hint?: string }> = (props) => {
  return (
    <HintHeaderContainer>
      {props.children}
      {props.hint && (
        <Hint
          hint={props.hint}
          children={<HintImage src={HintIcon} />}
          placement="right"
        />
      )}
    </HintHeaderContainer>
  );
};

export default HintHeader;
