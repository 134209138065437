import React, { FunctionComponent } from "react";
import {
  PriceTrendChartBar,
  PriceTrendChartHeading,
  PriceTrendChartHeadingWrapper,
  PriceTrendChartPercentageBar,
  PriceTrendChartWrapper,
} from "./PriceTrend.components";
import { ReactComponent as ArrowRed } from "../icons/ArrowRed.svg";
import { ReactComponent as ArrowGreen } from "../icons/ArrowGreen.svg";
import { Transition } from "react-transition-group";
import { GrowthIndexType } from "../../../../../../../generated/schema";

export type ChartType = "YEAR" | "5_YEARS" | "10_YEARS";

type PriceTrendChartProps = {
  type: ChartType;
  growthIndex: GrowthIndexType;
  percentage: number;
};

const PriceTrendChart: FunctionComponent<PriceTrendChartProps> = ({
  type,
  growthIndex,
  percentage,
}) => {
  const getGrowthIcon = () => {
    switch (growthIndex) {
      case GrowthIndexType.Decrease:
        return <ArrowRed />;
      case GrowthIndexType.Increase:
        return <ArrowGreen />;
    }
  };

  const getChartLabel = () => {
    switch (type) {
      case "YEAR":
        return "Over a year";
      case "5_YEARS":
        return "Over 5 years";
      case "10_YEARS":
        return "Over 10 years";
    }
  };

  return (
    <PriceTrendChartWrapper>
      <PriceTrendChartHeading>
        <PriceTrendChartHeadingWrapper>
          <span>{getChartLabel()}</span>
          {getGrowthIcon()}
        </PriceTrendChartHeadingWrapper>
        <span>{percentage}%</span>
      </PriceTrendChartHeading>
      <PriceTrendChartBar>
        <Transition timeout={500} mountOnEnter unmountOnExit appear in>
          {(status) => (
            <PriceTrendChartPercentageBar
              type={type}
              status={status}
              percentage={percentage}
            />
          )}
        </Transition>
      </PriceTrendChartBar>
    </PriceTrendChartWrapper>
  );
};

export default PriceTrendChart;
