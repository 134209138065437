import styled, { css } from "styled-components";
import { colors } from "../../colors";
import { down } from "../../media";
import { ButtonSmallStyle } from "../../mixins";

export const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.primary.pure_white};
  border-radius: 16px;
  margin-bottom: 8px;
  box-sizing: border-box;
  overflow: hidden;
  will-change: transform; //To fix missing radius on safari.

  ${down("tablet")} {
    width: unset;
  }
`;

export const GalleryImageContainer = styled.div`
  position: relative;
  cursor: pointer;

  :active {
    cursor: grabbing;
  }

  .slick-slide {
    margin-bottom: -4px;
  }

  .slick-slide div {
    outline: none !important;
  }
`;

export const GalleryArrowButton = styled.button<{
  hide: boolean;
  direction: "left" | "right";
}>`
  position: absolute;
  margin: auto 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  bottom: 0px;
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  border-radius: 16px;
  cursor: pointer;
  outline: none;
  border: none;

  ${(p) =>
    p.direction === "left" &&
    css`
      left: 16px;
      padding-left: 4px;
      padding-top: 2px;
    `}

  ${(p) =>
    p.direction === "right" &&
    css`
      right: 16px;
      padding-right: 4px;
      padding-top: 2px;
    `}

    ${(p) =>
    p.hide &&
    css`
      display: none;
    `}

    ${down("mobile")} {
    transform: translateY(-16px);
  }

  > img {
    margin: auto;
    position: absolute;
    right: ${(p) => (p.direction === "left" ? 2 : 0)}px;
    left: ${(p) => (p.direction === "left" ? 0 : 2)}px;
  }
`;

export const GalleryCounter = styled.span`
  position: absolute;
  bottom: 16px;
  right: 16px;
  padding: 2px 12px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  ${ButtonSmallStyle()};

  ${down("mobile")} {
    transform: translateY(-24px);
  }
`;

export const ImageContainer = styled.div<{ height: number }>`
  height: ${(p) => p.height}px;
  max-height: 405px;

  ${down("tablet")} {
    max-height: 500px;
  }
`;

export const GalleryImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const GalleryThumbnailsContainer = styled.div<{
  hide: boolean;
  maxWidth: number;
}>`
  height: 100%;
  max-width: ${(p) => p.maxWidth}px;
  ${(p) =>
    p.hide &&
    css`
      display: none;
    `}

  ${down("mobile")} {
    display: none;
  }
`;

export const GalleryThumbnails = styled.div`
  height: 99px;
  margin: 15px 8px 15px 8px;

  ${down("tablet")} {
    height: unset;
  }

  .slick-slide > div {
    margin: 0 6px;
  }
`;

export const GalleryThumbnail = styled.div<{
  selected: boolean;
  height: number;
}>`
  background-color: ${colors.grayscale._400};
  max-height: 99px;
  height: ${(p) => p.height}px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  transition: border 250ms ease-in-out;
  border: 2px solid white;
  cursor: pointer;

  ${(p) =>
    p.selected &&
    css`
      border: 2px solid ${colors.secondary.pure_blue_light};
    `}

  :active, :focus {
    border: none;
    outline: none;
    border: 2px solid ${colors.secondary.pure_blue_light};
  }

  ${down("tablet")} {
    max-height: 120px;
  }
`;

export const GalleryThumbnailImage = styled.img`
  object-fit: cover;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;
