import React, { FunctionComponent, useState } from "react";
import InputField from "../Input/InputField";
import { ReactComponent as ShowPasswordIcon } from "./ShowPasswordIcon.svg";
import { ReactComponent as HidePasswordIcon } from "./HidePasswordIcon.svg";
import { InputProps } from "../Input/Input";

type Props = {
  name: string;
  placeholder?: string;
  error?: string;
} & Pick<InputProps, "autoComplete">;

const Password: FunctionComponent<Props> = ({
  name,
  placeholder,
  autoComplete,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <InputField
      label="Password"
      placeholder={placeholder}
      name={name}
      autoComplete={autoComplete}
      type={!isVisible ? "password" : "text"}
      icon={isVisible ? <ShowPasswordIcon /> : <HidePasswordIcon />}
      onIconClick={() => setIsVisible(!isVisible)}
    />
  );
};

export default Password;
