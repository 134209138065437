import React, { FunctionComponent, useEffect, useState } from "react";
import useTokenChangeHandler from "../../../common/eventHandlers/useTokenChangeHandler";
import {
  CurrentUser,
  currentUserFromResponse,
} from "../../../common/helpers/currentUser";
import { useGetCurrentUserLazyQuery } from "../../../generated/schema";
import AppContext from "./AppContext";

export const AppProvider: FunctionComponent = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [token] = useTokenChangeHandler();

  const [getCurrentUser, { data, refetch }] = useGetCurrentUserLazyQuery();

  useEffect(() => {
    if (token) {
      getCurrentUser();
    } else {
      setCurrentUser(null);
    }
  }, [token, getCurrentUser]);

  useEffect(() => {
    if (data && data.currentUser)
      setCurrentUser(currentUserFromResponse(data.currentUser));
  }, [data]);
  return (
    <AppContext.Provider value={{ authToken: token, currentUser, refetch }}>
      {children}
    </AppContext.Provider>
  );
};
