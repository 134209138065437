import { useAddSeoLinkMutation } from "../../../generated/schema";
import { Formik } from "formik";
import React, { FunctionComponent, useCallback, useState } from "react";
import {
  seoFormInitialValues,
  seoFormValidationSchema,
  SeoFormValues,
} from "./form";
import { SeoFormHeading, SeoFormWrapper } from "./SeoForm.components";
import SeoFormContent from "./SeoFormContent";

type SeoFormProps = {
  refetch: () => void;
};

const SeoForm: FunctionComponent<SeoFormProps> = ({ refetch }) => {
  const [error, setError] = useState("");

  const [addSeoLink] = useAddSeoLinkMutation();

  const handleSubmit = useCallback(
    async (values: SeoFormValues) => {
      const sanitizedSlug = values.urlSlug.startsWith("/")
        ? values.urlSlug.slice(1, values.urlSlug.length)
        : values.urlSlug;

      try {
        const response = await addSeoLink({
          variables: {
            input: {
              ...values,
              urlSlug: sanitizedSlug.replace(/\s/g, ""),
            },
          },
        });

        switch (response.data?.addSeoLink.__typename) {
          case "AddSeoLinkSuccessfulResponse":
            refetch();
            setError("");
            break;
          case "AddSeoLinkInvalidResponse":
            setError("There is a seo link with same url-slug");
            break;
        }
      } catch (e) {
        console.log(e);
        setError("Api problem. Try again later.");
      }
    },
    [addSeoLink, refetch],
  );

  return (
    <SeoFormWrapper>
      <SeoFormHeading>Add new SEO link</SeoFormHeading>
      <Formik
        initialValues={seoFormInitialValues}
        validationSchema={seoFormValidationSchema}
        onSubmit={handleSubmit}
      >
        {(formProps) => <SeoFormContent {...formProps} error={error} />}
      </Formik>
    </SeoFormWrapper>
  );
};

export default SeoForm;
