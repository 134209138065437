import { createContext } from "react";
import { ModalContentType } from "./ModalProvider";

export interface ModalContextType {
  openModal(node: ModalContentType, dismissable?: boolean): void;
  closeModal(): void;
}

const ModalContext = createContext<ModalContextType>({
  openModal: () => {
    throw new Error("You probably forgot to put <ModalContext.Provider>!");
  },
  closeModal: () => {
    throw new Error("You probably forgot to put <ModalContext.Provider>!");
  },
});

export default ModalContext;
