import React, { FunctionComponent } from "react";
import { Transition } from "react-transition-group";
import {
  MODAL_TRANSITION_TIME_MS,
  Background,
  Container,
} from "./ModalContainer.components";
import { ModalContentType } from "./ModalContext/ModalProvider";

interface ModalContainerProps {
  isOpen: boolean;
  onBackgroundClick: (event: React.MouseEvent) => void;
  children?: ModalContentType;
}

const ModalContainer: FunctionComponent<ModalContainerProps> = ({
  children,
  isOpen,
  onBackgroundClick,
}) => {
  return (
    <Transition
      timeout={MODAL_TRANSITION_TIME_MS}
      mountOnEnter={true}
      unmountOnExit={true}
      appear={true}
      in={isOpen}
    >
      {(status) => (
        <>
          <Background status={status} onClick={onBackgroundClick} />
          <Container status={status} onClick={onBackgroundClick}>
            {children && <children.type {...children.props} status={status} />}
          </Container>
        </>
      )}
    </Transition>
  );
};

export default ModalContainer;
