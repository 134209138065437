import styled, { css } from "styled-components";
import { colors } from "../../../colors";
import { down } from "../../../media";
import { ScrollBarStyle } from "../../../mixins";

interface StyledTextareaProps {
  isError?: boolean;
}

export const TextareaWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledTextarea = styled.textarea<StyledTextareaProps>`
  display: flex;
  height: 100%;
  width: 100%;
  outline: none;
  resize: none;
  border: 1px solid ${colors.grayscale._400};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: ${colors.grayscale._600};
  transition: border 0.5s ease;
  -webkit-appearance: none;

  :hover {
    border: 1px solid
      ${(p) =>
        p.isError ? colors.semantic.red_dark : colors.secondary.pure_blue};
  }

  :focus {
    color: ${colors.primary.frosted_black};
    border: 1px solid
      ${(p) =>
        p.isError ? colors.semantic.red_dark : colors.secondary.pure_blue};
    box-shadow: 0px 0px 0px 3px rgba(59, 122, 247, 0.1);
  }

  :disabled {
    background: ${colors.grayscale._100};
    border: 1px solid ${colors.grayscale._400};
    cursor: not-allowed;
  }

  ${(p) =>
    p.isError &&
    css`
      border: 1px solid ${colors.semantic.red_dark};
    `}

  ${down("mobile")} {
    font-size: 16px;
  }

  ${ScrollBarStyle}
`;

export const Counter = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: ${colors.grayscale._600};
  font-size: 11px;
  line-height: 10px;
`;

export const TextareaErrorWrapper = styled.div`
  height: 16px;
`;
