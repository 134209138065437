import { FormikProps } from "formik";
import React, { FunctionComponent } from "react";
import { BillingType } from "../../../../generated/schema";
import InputField from "../../../shared/components/Form/Input/InputField";
import SingleSelect from "../../../shared/components/Select/SingleSelect/SingleSelect";
import { AddPropertyFormValues } from "../../form";
import {
  CheckSpan,
  InputsContainer,
  InputsSectionContainer,
  LargeInputContainer,
  MediumInputContainer,
  SmallInputContainer,
  CheckboxContainer,
} from "./ListingDetailsSection.components";
import { startOfYear, format, addMonths } from "date-fns";
import FakeCheckbox from "../../../shared/components/Form/FakeCheckbox";
import HintHeader from "../HintHeader/HintHeader";
import { AddPropertyDivider } from "../../../Layouts/AddPropertyLayout/AddPropertyLayout.components";
import { BILLING_TYPE_OPTIONS } from "../../../../common/helpers/enumNamesHelper";

const MONTHS = Array.from(Array(12).keys()).map((index) =>
  format(addMonths(startOfYear(new Date()), index), "MMMM"),
);

const ForRentListing: FunctionComponent<FormikProps<AddPropertyFormValues>> = ({
  submitCount,
  ...props
}) => {
  const { values, errors, setFieldValue, setValues } = props;

  return (
    <>
      <InputsSectionContainer>
        <InputsContainer>
          <LargeInputContainer>
            <SingleSelect
              error={errors.billingType}
              submitCount={submitCount}
              name="billingType"
              label="Rent price"
              options={BILLING_TYPE_OPTIONS.map((o) => o.value)}
              selection={BILLING_TYPE_OPTIONS.findIndex(
                (o) => o.type === values.billingType,
              )}
              optionClick={(selection) => {
                const billingType =
                  selection < 0
                    ? undefined
                    : BILLING_TYPE_OPTIONS[selection].type;
                setValues({
                  ...values,
                  billingType: billingType,
                  price: [undefined, BillingType.OnRequest].includes(
                    billingType,
                  )
                    ? undefined
                    : values.price,
                });
              }}
            />
          </LargeInputContainer>

          <MediumInputContainer>
            <InputField
              isDisabled={[undefined, BillingType.OnRequest].includes(
                values.billingType,
              )}
              showLabel={true}
              name="price"
              inputSize="l"
              frontIcon="currency"
              type="number"
            />
          </MediumInputContainer>
        </InputsContainer>
      </InputsSectionContainer>

      <AddPropertyDivider />

      <InputsSectionContainer>
        <HintHeader
          hint={`For how long would you like to rent your property? If you're not sure plese select "negotiable" option.`}
        >
          <span>Minimal rental length</span>
        </HintHeader>
        <InputsContainer>
          <LargeInputContainer>
            <SingleSelect
              options={MONTHS}
              error={errors.month}
              submitCount={submitCount}
              selection={values.month}
              name="month"
              isDisabled={values.isRentalNegotiable}
              label="Month"
              optionClick={(index) => setFieldValue("month", index)}
            />
          </LargeInputContainer>
          <SmallInputContainer>
            <InputField
              name="year"
              inputSize="l"
              label="Year"
              type="number"
              isDisabled={values.isRentalNegotiable}
            />
          </SmallInputContainer>
        </InputsContainer>

        <CheckboxContainer>
          <FakeCheckbox
            checked={values.isRentalNegotiable ?? false}
            onClick={() => {
              setValues({
                ...values,
                isRentalNegotiable: !values.isRentalNegotiable,
                year: undefined,
                month: undefined,
              });
            }}
          >
            <CheckSpan>Negotiable</CheckSpan>
          </FakeCheckbox>
        </CheckboxContainer>
      </InputsSectionContainer>
    </>
  );
};

export default ForRentListing;
