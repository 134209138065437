import styled, { keyframes } from "styled-components";
import { colors } from "../../colors";
import { DefaultScaleTransform } from "../../mixins";

const ImageAnimation = keyframes`
  0%, 100% {
    transform: translateX(-50%) scaleX(1);
  }
  50% {
    transform: translateX(-50%) scaleX(1.2);
  }
`;

export const ResolutionNotSupportedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const ResolutionNotSupportedDot = styled.div`
  position: relative;
  display: flex;
  width: 128px;
  height: 128px;
  background-color: ${colors.primary.pure_white};
  border-radius: 50%;

  > svg {
    position: absolute;
    top: -20px;
    left: 50%;
    animation: ${ImageAnimation} 3s linear infinite;
    transform: translate(-50%);
  }
`;

export const ResolutionNotSupportedHeading = styled.h4`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.primary.frosted_black};
  margin-top: 16px;
  max-width: 271px;
  text-align: center;
  > span {
    color: ${colors.secondary.pure_blue};
  }
`;

export const ResolutionNotSupportedDescription = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.grayscale._600};
  margin-top: 8px;
  max-width: 271px;
  text-align: center;
`;

export const ResolutionNotSupportedUrl = styled.a`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.secondary.pure_blue};
  margin-top: 8px;
  text-align: center;
  text-decoration: underline;

  ${DefaultScaleTransform()};
`;
