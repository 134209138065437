import React, { FunctionComponent } from "react";
import { RouteProps } from "react-router";
import PublicLayout from "../Layouts/PublicLayout";

type Props = RouteProps;

export const PublicRoute: FunctionComponent<Props> = ({ children }) => {
  return <PublicLayout>{children}</PublicLayout>;
};

export default PublicRoute;
