import React, { useCallback, FunctionComponent, useState } from "react";
import { Formik } from "formik";
import { ResetPasswordFormValues, resetPasswordValidationSchema } from "./form";
import RequestResetPasswordFormContent from "./RequestResetPasswordFormContent";
import { useRequestPasswordResetMutation } from "../../../generated/schema";

type Props = {
  onResetRequested: React.Dispatch<React.SetStateAction<string>>;
};

const RequestResetPasswordForm: FunctionComponent<Props> = ({
  onResetRequested,
}) => {
  const [error, setError] = useState("");
  const [requestResetPassword] = useRequestPasswordResetMutation();
  const handleSubmit = useCallback(
    async (values: ResetPasswordFormValues) => {
      setError("");
      try {
        await requestResetPassword({
          variables: {
            input: {
              email: values.email,
            },
          },
        });
        onResetRequested(values.email);
      } catch (e) {
        setError("Unexpected error. Try again.");
      }
    },
    [onResetRequested, requestResetPassword],
  );

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={resetPasswordValidationSchema}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <RequestResetPasswordFormContent
          {...formProps}
          error={error}
          onChange={() => setError("")}
        />
      )}
    </Formik>
  );
};
export default RequestResetPasswordForm;
