import React, { FunctionComponent } from "react";
import { PropertyStatus } from "../../../../generated/schema";
import {
  NothingHereContainer,
  NothingHereDescription,
  NothingHereHeader,
} from "./NothingHere.components";
import { ReactComponent as NothingHereImage } from "./NothingHereImage.svg";

type NothingHereProps = {
  status?: PropertyStatus;
};

const NothingHere: FunctionComponent<NothingHereProps> = ({ status }) => {
  return (
    <NothingHereContainer>
      <NothingHereImage />
      <NothingHereHeader>Nothings here</NothingHereHeader>
      <NothingHereDescription>
        You don't have {status ? status?.toLocaleLowerCase() : "any"} listings.
      </NothingHereDescription>
    </NothingHereContainer>
  );
};

export default NothingHere;
