import * as Yup from "yup";

export interface SetNewPasswordFormValues {
  password: string;
}

export const initialValues: SetNewPasswordFormValues = {
  password: "",
};

export const setNewPasswordValidationSchema = Yup.object({
  password: Yup.string()
    .min(8, "Passwords must contain at least 8 characters")
    .required("Passwords must contain at least 8 characters"),
});
