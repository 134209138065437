import { colors } from "../../../modules/shared/colors";
import { down } from "../../../modules/shared/media";
import { H5Style } from "../../../modules/shared/mixins";
import { Form } from "formik";
import styled from "styled-components";

export const SeoFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.primary.pure_white};
  border-radius: 16px;
  padding: 32px 40px;
  max-width: 812px;
  box-sizing: border-box;

  ${down("tablet")} {
    max-width: 100%;
    padding: 32px 32px 40px;
  }
`;

export const SeoFormHeading = styled.h5`
  display: flex;
  align-items: center;
  position: relative;
  ${H5Style()}
  font-weight: 500;
  padding-left: 20px;
  ::before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${colors.secondary.pure_blue};
    left: 0;
  }
`;

export const SeoFormInnerWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
`;

export const SeoFormHorizontalWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;

  > :last-child:not(button) {
    margin-left: 10px;
  }

  > span {
    margin-left: auto !important;
    justify-self: flex-end;
  }
`;

export const SeoFormHorizontalInputWrapper = styled.div`
  max-width: 280px;
  width: 100%;
  margin-bottom: 24px;
`;

export const SeoFormTextareaWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const SeoFormErrorMessage = styled.span`
  font-size: 14px;
  color: ${colors.semantic.red};
`;
