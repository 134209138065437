import { uniqueId } from "lodash";
import { getFileName } from "../../../../common/helpers/fileNameHelpers";

export interface IFileSelection {
  file?: File;
  url?: string;
  id: string;
  name: string;
}

export const urlToFileSelection = (url: string): IFileSelection => ({
  id: uniqueId(),
  url: url,
  name: getFileName(url)!,
});

export const toFileSelection = (file: File): IFileSelection => ({
  file: file,
  id: uniqueId(),
  name: file.name,
});
