import styled from "styled-components";
import { colors } from "../../../colors";
import { BodyMediumStyle, H4Style } from "../../../mixins";

export const ActionsModalContainer = styled.div`
  display: flex;
  padding: 40px 56px;
  width: calc(432px - 112px);
  height: fit-content;
  margin: auto;
  flex-direction: column;
  background-color: ${colors.primary.pure_white};
  border-radius: 16px;
`;

export const ActionsModalButtonsContainer = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  > :first-child {
    margin-right: 16px;
  }
`;

export const ActionsModalHeader = styled.h4`
  ${H4Style()}
`;

export const ActionsModalDescription = styled.span`
  ${BodyMediumStyle()}
  margin-top: 4px;
  > b {
    font-weight: 500;
  }
`;

export const ActionsModalDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 16px 0px;
  background-color: ${colors.grayscale._300};
`;
