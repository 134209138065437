import React, { FunctionComponent } from "react";
import {
  ListingAggregatedButton,
  ListingAggregatedWrapper,
} from "./ListingAggregated.components";
import { ReactComponent as ArrowRight } from "./Arrow.svg";

type ListingAggregatedProps = {
  externalPropertyUrl: string | undefined;
};

const ListingAggregated: FunctionComponent<ListingAggregatedProps> = ({
  externalPropertyUrl,
}) => {
  return (
    <ListingAggregatedWrapper>
      <span>
        This listing is aggregated from another source, you can view the
        original source using the link below.
      </span>
      <ListingAggregatedButton href={externalPropertyUrl} target="_blank">
        <ArrowRight />
        <span>See original listing page</span>
      </ListingAggregatedButton>
    </ListingAggregatedWrapper>
  );
};

export default ListingAggregated;
