import React, { FunctionComponent } from "react";
import { Field, FieldProps, FieldAttributes } from "formik";
import { Input, InputProps } from "./Input";

export type InputFieldProps = FieldAttributes<{
  icon?: JSX.Element;
  onIconClick?: () => void;
  label?: string;
  frontIcon?: "currency";
  inputSize?: "l" | "m";
  showLabel?: boolean;
  hint?: string | JSX.Element;
}> &
  Pick<
    InputProps,
    | "maxLength"
    | "placeholder"
    | "type"
    | "isDisabled"
    | "onFocus"
    | "min"
    | "max"
    | "showError"
  >;

const isValueValid = (value: any) => value !== null && value !== undefined;

const InputField: FunctionComponent<InputFieldProps> = ({
  placeholder,
  type,
  isDisabled,
  showLabel,
  onFocus,
  min,
  max,
  icon,
  hint,
  maxLength,
  frontIcon,
  onIconClick,
  showError,
  label,
  inputSize = "m",
  ...fieldProps
}) => (
  <>
    <Field {...fieldProps}>
      {({ field, meta }: FieldProps) => (
        <>
          <Input
            hint={hint}
            frontIcon={frontIcon}
            autoFocus={fieldProps.autoFocus}
            autoComplete={fieldProps.autoComplete}
            inputSize={inputSize}
            label={label}
            min={min}
            max={max}
            placeholder={placeholder}
            type={type}
            showLabel={showLabel}
            isDisabled={isDisabled}
            onFocus={onFocus}
            showError={showError}
            error={meta.touched && meta.error ? meta.error : undefined}
            icon={icon}
            onIconClick={onIconClick}
            {...field}
            maxLength={maxLength}
            value={isValueValid(field.value) ? field.value : ""}
          />
        </>
      )}
    </Field>
  </>
);

export default InputField;
