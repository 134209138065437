import React, { FunctionComponent } from "react";
import {
  PropertyActionButton,
  PropertyActionsContainer,
} from "./PropertyActions.components";
import { ReactComponent as AcceptIcon } from "./icons/AcceptIcon.svg";
import { ReactComponent as DeclineIcon } from "./icons/DeclineIcon.svg";
import { ReactComponent as EditIcon } from "./icons/EditIcon.svg";
import { ReactComponent as ViewIcon } from "./icons/ViewIcon.svg";

export enum PropertyAction {
  EDIT,
  DELETE,
  ACCEPT,
  VIEW,
  DECLINE,
}

type PropertyActionsProps = {
  actions: PropertyAction[];
  onActionClick: (action: PropertyAction) => void;
  loading: boolean;
};

const getActionIcon = (action: PropertyAction) => {
  switch (action) {
    case PropertyAction.EDIT:
      return <EditIcon />;
    case PropertyAction.VIEW:
      return <ViewIcon />;
    case PropertyAction.ACCEPT:
      return <AcceptIcon />;
    case PropertyAction.DELETE:
    case PropertyAction.DECLINE:
      return <DeclineIcon />;
  }
};

const getActionText = (action: PropertyAction) => {
  switch (action) {
    case PropertyAction.EDIT:
      return "Edit";
    case PropertyAction.VIEW:
      return "View";
    case PropertyAction.ACCEPT:
      return "Accept";
    case PropertyAction.DELETE:
      return "Delete";
    case PropertyAction.DECLINE:
      return "Decline";
  }
};

const PropertyActions: FunctionComponent<PropertyActionsProps> = ({
  actions,
  loading,
  onActionClick,
}) => {
  if (actions.length === 0) return null;
  return (
    <PropertyActionsContainer>
      {actions.map((action, index) => (
        <PropertyActionButton
          disabled={loading}
          key={`${action}-${index}`}
          actionType={action}
          onClick={() => onActionClick(action)}
        >
          {getActionIcon(action)}
          <span>{getActionText(action)}</span>
        </PropertyActionButton>
      ))}
    </PropertyActionsContainer>
  );
};

export default PropertyActions;
