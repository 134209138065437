import { TokenSuccessfulResponse } from "../../generated/schema";
import { localStorageKeys } from "../constants/localStorageKeys";
import AppEventTarget from "../events/AppEventTarget";
import TokenChangeEvent from "../events/TokenChangeEvent";

export interface AuthToken {
  issuedAt: Date;
  expiresAt: Date;
  token: string;
  accountType: string;
}

export const authTokenFromResponse = (
  response: TokenSuccessfulResponse,
): AuthToken => {
  return {
    issuedAt: new Date(response.issuedAt),
    expiresAt: new Date(response.expiresAt),
    token: response.token,
    accountType: response.accountType,
  };
};

export const setAuthToken = (authToken: AuthToken | null) => {
  localStorage.setItem(localStorageKeys.AUTH_TOKEN, JSON.stringify(authToken));
  AppEventTarget.dispatchEvent(new TokenChangeEvent(authToken));
};

export const getAuthToken = (): AuthToken | null => {
  const token = localStorage.getItem(localStorageKeys.AUTH_TOKEN);

  try {
    const authToken: any = JSON.parse(token ?? "null");
    if (authToken === null) return null;

    ["expiresAt", "token", "issuedAt", "accountType"].forEach((key) => {
      if (!(key in authToken)) {
        throw new Error(`Missing key: ${key}`);
      }
    });

    authToken.issuedAt = new Date(authToken.issuedAt);
    if (isNaN(authToken.issuedAt)) throw new Error("Invalid issue date");

    authToken.expiresAt = new Date(authToken.expiresAt);
    if (isNaN(authToken.expiresAt)) throw new Error("Invalid expiration date");

    return authToken;
  } catch (err: any) {
    console.error(`Error parsing user token ${err.message}`);
    deleteAuthToken();
    return null;
  }
};

export const deleteAuthToken = () => {
  setAuthToken(null);
};
