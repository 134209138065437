import styled from "styled-components";
import { colors } from "../../../../shared/colors";
import { down } from "../../../../shared/media";

export const PropertyDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  white-space: pre-wrap;
  color: ${colors.grayscale._600};
  max-width: 587px;
  margin-top: 8px;
  padding-bottom: 24px;

  ${down("tablet")} {
    max-width: 750px;
  }
`;

export const PropertyDescriptionDetails = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  margin-top: 24px;
  margin-bottom: 8px;
  align-items: flex-start;
`;

export const PropertyDescriptionDetail = styled.li`
  display: flex;
  position: relative;
  padding-left: 16px;
  margin-bottom: 16px;

  ::before {
    content: "";
    border-radius: 50%;
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: ${colors.secondary.pure_blue_light};
    top: 8px;
    left: 0px;
  }

  > :last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: ${colors.grayscale._600};
    margin-left: 32px;
    text-transform: capitalize;
  }

  ${down("mobile")} {
    align-items: flex-start;
    margin-bottom: 8px;
  }
`;

export const PropertyDescriptionDetailTitle = styled.span`
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${colors.primary.frosted_black};
  min-width: 105px;
`;

export const PropertyAmenitiesHeading = styled.h5`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.primary.frosted_black};
  margin-top: 24px;
`;

export const PropertyAmenitiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
  max-width: 510px;
`;

export const PropertyAmenity = styled.span`
  padding: 4px 8px 4px 12px;
  background-color: ${colors.secondary.pure_blue_transparent};
  border-radius: 20px;
  white-space: nowrap;
  margin-right: 4px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;
