const uploadFile = async (url: string, file: File) => {
  try {
    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        "x-amz-acl": "public-read",
      },
      body: file,
    });
  } catch (e) {
    throw e;
  }
};

export default uploadFile;
