import Homepage from "../Homepage/Homepage";
import Login from "../Login";
import Register from "../Register";
import ResetPassword from "../ResetPassword/ResetPassword";
import MyProperties from "../MyProperties";
import Properties from "../Properties/Properties";
import { FunctionComponent } from "react";
import { AuthedRoutePermissions } from "./AuthedRoute";
import routesPaths from "./routesPaths";
import Listings from "../Listings";
import TermsAndConditions from "../TermsAndConditions";
import PrivacyPolicy from "../PrivacyPolicy";
import Cookies from "../Cookies";
import { AccountType } from "../../generated/schema";
import AddProperty from "../AddProperty";
import PropertyPreview from "../PropertyPreview/AuthedPropertyPreview/AuthedPropertyPreview";
import PublicPropertyPreview from "../PropertyPreview/PublicPropertyPreview/PublicPropertyPreview";
import Seo from "../Seo/Seo";
import FAQ from "../FAQ";

interface BaseRouteConfig {
  path: string;
  title: string;
  element: JSX.Element | FunctionComponent;
}

type RouteType =
  | ({ type: "authed" } & AuthedRoutePermissions)
  | { type: "sign_in" }
  | { type: "public" };

export type RouteConfig = BaseRouteConfig & RouteType;

const publicRoutes: RouteConfig[] = [
  {
    path: routesPaths.HOME,
    type: "public",
    title: "Homepage",
    element: Homepage,
  },
  // --- PROPERTIES ---
  {
    path: routesPaths.PROPERTIES,
    title: "Properties",
    type: "public",
    element: Properties,
  },
  {
    path: routesPaths.PROPERTY_DETAILS,
    title: "Properties",
    type: "public",
    element: PublicPropertyPreview,
  },
  // --- LEGAL ---
  {
    path: routesPaths.TERMS_AND_CONDITIONS,
    title: "Terms & Conditions",
    type: "public",
    element: TermsAndConditions,
  },
  {
    path: routesPaths.PRIVACY_POLICY,
    title: "Privacy Policy",
    type: "public",
    element: PrivacyPolicy,
  },
  {
    path: routesPaths.COOKIES,
    title: "Cookies",
    type: "public",
    element: Cookies,
  },
  {
    path: routesPaths.FAQ,
    title: "FAQ",
    type: "public",
    element: FAQ,
  },
];

const authedRoutes: RouteConfig[] = [
  {
    path: routesPaths.MY_PROPERTIES_ROOT,
    type: "authed",
    title: "My properties",
    accountType: [AccountType.EstateAgent],
    element: MyProperties,
  },
  {
    path: routesPaths.MY_PROPERTIES,
    type: "authed",
    title: "My properties",
    accountType: [AccountType.EstateAgent],
    element: MyProperties,
  },
  {
    path: routesPaths.ADD_PROPERTY,
    type: "authed",
    title: "Add property",
    accountType: [AccountType.EstateAgent],
    element: AddProperty,
    layoutType: "add_property",
  },
  {
    path: `${routesPaths.PROPERTY_PREVIEW}`,
    title: "Property",
    type: "authed",
    element: PropertyPreview,
  },
  // ADMIN ROUTES
  {
    path: routesPaths.LISTINGS_ROOT,
    type: "authed",
    title: "Listings",
    accountType: [AccountType.Admin],
    element: Listings,
  },
  {
    path: `${routesPaths.PROPERTY_ACCEPT}`,
    type: "authed",
    title: "Listings",
    accountType: [AccountType.Admin],
    element: PropertyPreview,
  },
  {
    path: routesPaths.LISTINGS,
    type: "authed",
    title: "Listings",
    accountType: [AccountType.Admin],
    element: Listings,
  },
  {
    path: routesPaths.SEO,
    type: "authed",
    title: "Seo links",
    accountType: [AccountType.Admin],
    element: Seo,
  },
];

const signInRoutes: RouteConfig[] = [
  // --- AUTH FLOW ---
  {
    path: routesPaths.LOGIN,
    title: "Login",
    type: "sign_in",
    element: Login,
  },
  {
    path: routesPaths.REGISTER,
    title: "Register",
    type: "sign_in",
    element: Register,
  },
  {
    path: routesPaths.RESET_PASSWORD,
    title: "ResetPassword",
    type: "sign_in",
    element: ResetPassword,
  },
];

export const routes: RouteConfig[] = [
  ...publicRoutes,
  ...authedRoutes,
  ...signInRoutes,
];
export default routes;
