import React, { FunctionComponent } from "react";
import { useIsMediaDown } from "../../media";
import {
  Page,
  PaginationArrow,
  PaginationDots,
  PaginationMobileInnerWrapper,
  PaginationMobileWrapper,
  PaginationPillsWrapper,
  PaginationWrapper,
} from "./Pagination.components";
import { DOTS, usePagination } from "./usePagination";

type PaginationProps = {
  totalCount: number;
  currentPage: number;
  pageSize: number;
  onPagechange: (page: number) => void;
};

const Pagination: FunctionComponent<PaginationProps> = ({
  totalCount,
  currentPage,
  pageSize,
  onPagechange,
}) => {
  const paginationRange = usePagination({
    totalCount,
    currentPage,
    pageSize,
  });
  const isTabletSmall = useIsMediaDown("tabletSmall");

  if (currentPage === 0 || paginationRange!.length < 2) return null;
  const lastPage = paginationRange![paginationRange!.length - 1];

  const onNext = () => {
    onPagechange(currentPage + 1);
  };

  const onPrevious = () => {
    onPagechange(currentPage - 1);
  };

  const backArrow = () => (
    <PaginationArrow onClick={onPrevious} disabled={currentPage === 1}>
      {"< Back"}
    </PaginationArrow>
  );

  const pages = () => (
    <PaginationPillsWrapper>
      {paginationRange?.map((page, index) =>
        page === DOTS ? (
          <PaginationDots key={`${page}-${index}`}>&#8230;</PaginationDots>
        ) : (
          <Page
            key={`${page}-${index}`}
            onClick={() => onPagechange(Number(page))}
            active={Number(page) === currentPage}
            additionalPadding={Number(page) > 99}
          >
            {page}
          </Page>
        ),
      )}
    </PaginationPillsWrapper>
  );

  const nextArrow = () => (
    <PaginationArrow onClick={onNext} disabled={currentPage === lastPage}>
      {"Next >"}
    </PaginationArrow>
  );

  return (
    <PaginationWrapper>
      {!isTabletSmall ? (
        <>
          {backArrow()}
          {pages()}
          {nextArrow()}
        </>
      ) : (
        <PaginationMobileWrapper>
          {pages()}
          <PaginationMobileInnerWrapper>
            {backArrow()} {nextArrow()}
          </PaginationMobileInnerWrapper>
        </PaginationMobileWrapper>
      )}
    </PaginationWrapper>
  );
};

export default Pagination;
