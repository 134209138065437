import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import TopBar from "../shared/components/TopBar/TopBar";
import PropertyCard from "../shared/components/PropertyCard";
import InfoBadge from "../shared/components/InfoBadge";
import {
  getBadgeType,
  PropertyBadgeType,
} from "../shared/components/PropertyCard/PropertyBadge/PropertyBadge";
import {
  ListingLoaderContainer,
  ListingResultsLoadingContainer,
  ListingsContainer,
  ListingsFooterContainer,
  ListingsInnerContainer,
  ListingsNoResultsContainer,
  ListingsPaginationWrapper,
  ListingsResultDetails,
} from "../shared/components/Listings/Listings.components";
import useAvailablePropertyActions from "../../common/helpers/useAvailablePropertyActions";
import useListingsStatus from "../../common/helpers/useListingsStatus";
import Pagination from "../shared/components/Pagination";
import { PropertyAction } from "../shared/components/PropertyCard/PropertyActions/PropertyActions";
import { useNavigate } from "react-router";
import routesPaths from "../Routing/routesPaths";
import {
  PropertyStatus,
  useDeletePropertyMutation,
  useGetUserPropertiesLazyQuery,
} from "../../generated/schema";
import NothingHere from "../shared/components/NothingHere";
import { useSearchParams } from "react-router-dom";
import PageLoader from "../shared/components/PageLoader/PageLoader";
import { scrollToElement } from "../../common/helpers/scrollHelpers";
import {
  getPropertiesCountText,
  useCurrentPage,
  usePaginatedResponse,
} from "../../common/helpers/paginationHelpers";
import { useModal } from "../shared/components/Modal";
import { usePaginationInput } from "../../common/helpers/usePropertyPaginationInput";
import Footer from "../shared/components/Footer/Footer";
import {
  getPropertyPreviewDetailsRoute,
  getPropertyRoute,
  getPublicPropertyDetailsRoute,
} from "../../common/helpers/navigationHelpers";
import {
  getPropertyDeleteModal,
  getPropertyEditModal,
} from "../shared/components/Modals/modalsHelpers";
import useAppContext from "../App/AppContext/useAppContext";

const MyProperties: FunctionComponent = () => {
  //General variables:
  const [currentPage, setCurrentPage] = useCurrentPage();
  const { openModal, closeModal } = useModal();
  const status = useListingsStatus();
  const availableActions = useAvailablePropertyActions();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const { authToken } = useAppContext();

  //Get/delete properties variables:
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [getUserProperties, { data, loading, refetch, called }] =
    useGetUserPropertiesLazyQuery();
  const [deletePropertyMutation] = useDeletePropertyMutation();

  //Pagination variables:
  const { isEmpty, pagesCount, totalCount, pageSize } = usePaginatedResponse(
    data?.getUserProperties,
  );
  const paginationInput = usePaginationInput(pageSize, currentPage);

  const getProperties = useCallback(async () => {
    if (called) {
      setRefetchLoading(true);
      await refetch({ input: { ...paginationInput, status } });
      setRefetchLoading(false);
    } else {
      getUserProperties({
        variables: { input: { ...paginationInput, status } },
      });
    }
  }, [called, getUserProperties, paginationInput, refetch, status]);

  const openProperty = useCallback(
    (uid: string, slug: string) => {
      if (status === PropertyStatus.Active) {
        navigate(getPublicPropertyDetailsRoute(slug, uid));
      } else if (
        [PropertyStatus.Declined, PropertyStatus.Pending].includes(status)
      ) {
        navigate(getPropertyPreviewDetailsRoute(uid));
      }
    },
    [navigate, status],
  );

  useEffect(() => {
    getProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInput, status]);

  const deleteProperty = useCallback(
    async (uid: string) => {
      setRefetchLoading(true);
      await deletePropertyMutation({ variables: { uid } });
      closeModal();
      await getProperties();
      setRefetchLoading(false);
    },
    [deletePropertyMutation, closeModal, getProperties],
  );

  const handleEditProperty = useCallback(
    (uid: string) => {
      const modal = getPropertyEditModal(
        () => {
          closeModal();
          navigate(`${routesPaths.ADD_PROPERTY}?action=edit&uid=${uid}`);
        },
        () => closeModal(),
      );

      openModal(modal);
    },
    [closeModal, navigate, openModal],
  );

  const handleDeleteProperty = useCallback(
    (uid: string) => {
      const property = data?.getUserProperties.data.find((p) => p.uid === uid)!;
      const modal = getPropertyDeleteModal(
        property.address,
        () => deleteProperty(property.uid),
        () => closeModal(),
      );

      openModal(modal);
    },
    [closeModal, data?.getUserProperties.data, deleteProperty, openModal],
  );

  const onPropertyActionClick = useCallback(
    (action: PropertyAction, uid: string, slug: string) => {
      if (action === PropertyAction.EDIT) {
        handleEditProperty(uid);
      } else if (action === PropertyAction.DELETE) {
        handleDeleteProperty(uid);
      } else if (action === PropertyAction.VIEW) {
        openProperty(uid, slug);
      }
    },
    [handleDeleteProperty, handleEditProperty, openProperty],
  );

  const onPageChange = useCallback(
    (page: number) => {
      scrollToElement("listingsContainer");
      setSearchParams({ page: page.toString() });
      setCurrentPage(page);
    },
    [setCurrentPage, setSearchParams],
  );

  // Check if user is out of paging and redirect to last page.
  useEffect(() => {
    const count = data?.getUserProperties.itemsCount ?? 0;
    const pages = Math.ceil(count / pageSize);
    const outOfPaging = currentPage > pages;
    if (!loading && outOfPaging && count > 0) {
      onPageChange(pages);
    }
  }, [currentPage, data, loading, onPageChange, pageSize]);

  return (
    <>
      <ListingsContainer id="listingsContainer">
        <TopBar heading="My properties" status={status} />
        <ListingsInnerContainer>
          {refetchLoading && (
            <ListingResultsLoadingContainer>
              <PageLoader />
            </ListingResultsLoadingContainer>
          )}
          {loading ? (
            <ListingLoaderContainer>
              <PageLoader />
            </ListingLoaderContainer>
          ) : isEmpty && !loading && !refetchLoading ? (
            <ListingsNoResultsContainer>
              <NothingHere status={status} />
            </ListingsNoResultsContainer>
          ) : (
            <>
              <InfoBadge type={status} />
              <ListingsResultDetails smallerMargin={status !== "ACTIVE"}>
                {getPropertiesCountText(totalCount, currentPage, pagesCount)}
              </ListingsResultDetails>
              {data?.getUserProperties.data.map((property, index) => (
                <PropertyCard
                  {...property}
                  url={getPropertyRoute(
                    property.status,
                    property.slug,
                    property.uid,
                    authToken?.accountType,
                  )}
                  loading={loading || refetchLoading}
                  name={property.address}
                  key={property.uid + index}
                  onActionClick={(action) =>
                    onPropertyActionClick(action, property.uid, property.slug)
                  }
                  availableActions={availableActions}
                  // Field is required so the first one should always be present
                  img={property.propertyPhotos[0]}
                  badgeType={
                    status === "DECLINED"
                      ? PropertyBadgeType.DECLINED
                      : getBadgeType(property.propertyInsight)
                  }
                />
              ))}

              <ListingsPaginationWrapper>
                <Pagination
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={pageSize}
                  onPagechange={onPageChange}
                />
              </ListingsPaginationWrapper>
            </>
          )}
        </ListingsInnerContainer>
      </ListingsContainer>
      <ListingsFooterContainer>
        <Footer />
      </ListingsFooterContainer>
    </>
  );
};

export default MyProperties;
