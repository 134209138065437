import { useEffect, useState } from "react";
import AppEventTarget, { appEventTypes } from "../events/AppEventTarget";
import TokenChangeEvent from "../events/TokenChangeEvent";
import { AuthToken, getAuthToken } from "../helpers/authToken";

const useTokenChangeHandler = () => {
  const [token, setToken] = useState<AuthToken | null>(getAuthToken());

  useEffect(() => {
    const handler = ({ detail: { authToken } }: TokenChangeEvent) => {
      setToken(authToken);
    };

    AppEventTarget.addEventListener(appEventTypes.TOKEN_CHANGE, handler);
    return () => {
      AppEventTarget.removeEventListener(appEventTypes.TOKEN_CHANGE, handler);
    };
  }, []);

  return [token];
};

export default useTokenChangeHandler;
