import React, { FunctionComponent } from "react";
import Footer from "../Footer/Footer";
import {
  FindOutMoreText,
  PrivacyTermsContainer,
  PrivacyTermsContentWrapper,
  PrivacyTermsHeader,
  PrivacyTermsIndicator,
  PrivacyTermsMainHeading,
} from "./PrivacyTerms.components";

type PrivacyTermsProps = {
  heading: string;
  isFaqView?: boolean;
};

const PrivacyTerms: FunctionComponent<PrivacyTermsProps> = ({
  heading,
  isFaqView,
  children,
}) => {
  return (
    <PrivacyTermsContainer>
      <PrivacyTermsHeader>
        <FindOutMoreText>
          {!isFaqView
            ? "Find out more about our.."
            : "Need answers? Find them here"}
        </FindOutMoreText>
        <PrivacyTermsMainHeading>{heading}</PrivacyTermsMainHeading>
        <PrivacyTermsIndicator />
      </PrivacyTermsHeader>
      {!isFaqView ? (
        <PrivacyTermsContentWrapper>{children}</PrivacyTermsContentWrapper>
      ) : (
        children
      )}
      <Footer />
    </PrivacyTermsContainer>
  );
};

export default PrivacyTerms;
