import React, { FunctionComponent, useCallback } from "react";
import SeoForm from "./Form/SeoForm";
import {
  DeleteSeoRecordButton,
  SeoContainer,
  SeoInnerContainer,
  SeoRecord,
  SeoRecordDescription,
  SeoRecordHeading,
  SeoRecordsWrapper,
  SeoRecordUrl,
  SeoRecordWrapper,
} from "./Seo.components";
import { ReactComponent as DeleteIcon } from "./DeleteIcon.svg";
import Footer from "../shared/components/Footer/Footer";
import {
  useGetSeoLinksQuery,
  useRemoveSeoLinkMutation,
} from "../../generated/schema";

const Seo: FunctionComponent = () => {
  const { data, loading, refetch } = useGetSeoLinksQuery();

  const [removeSeoLinkMutation] = useRemoveSeoLinkMutation();

  const removeSeoLink = useCallback(
    async (id: number) => {
      await removeSeoLinkMutation({
        variables: {
          id,
        },
      });
      refetch();
    },
    [removeSeoLinkMutation, refetch],
  );

  return (
    <>
      <SeoContainer>
        <SeoInnerContainer>
          <SeoForm refetch={refetch} />
          {!loading && (
            <SeoRecordsWrapper>
              {data?.getSeoLinks.map((seoLink) => (
                <SeoRecord key={seoLink.urlSlug}>
                  <SeoRecordWrapper>
                    <SeoRecordHeading>
                      {seoLink.title} - <span>/{seoLink.urlSlug}</span>
                    </SeoRecordHeading>
                    <SeoRecordUrl href={seoLink.link}>
                      {seoLink.link}
                    </SeoRecordUrl>
                    <SeoRecordDescription>
                      {seoLink.description}
                    </SeoRecordDescription>
                  </SeoRecordWrapper>
                  <DeleteSeoRecordButton
                    onClick={() => removeSeoLink(seoLink.id)}
                  >
                    <DeleteIcon />
                  </DeleteSeoRecordButton>
                </SeoRecord>
              ))}
            </SeoRecordsWrapper>
          )}
        </SeoInnerContainer>
      </SeoContainer>
      <Footer />
    </>
  );
};

export default Seo;
