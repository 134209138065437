import styled, { css } from "styled-components";
import { colors } from "../../../shared/colors";
import { BodySmallStyle, H5Style } from "../../../shared/mixins";

export const FormBoxContainer = styled.div`
  width: 100%;
  background-color: ${colors.primary.pure_white};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const FormBoxHeader = styled.h5`
  ${H5Style};
  margin: 24px 24px 0px 24px;
  display: flex;
  align-items: center;
`;

export const FormBoxHeaderIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

export const FormBoxContent = styled.div<{ hasDescription?: boolean }>`
  margin: 32px 48px;

  ${(p) =>
    p.hasDescription &&
    css`
      margin-top: 8px;
    `}
`;

export const FormBoxSectionHeader = styled.div`
  ${BodySmallStyle};
  color: ${colors.grayscale._600};
  margin-bottom: 32px;
`;
