import React, { FunctionComponent, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import {
  SortByContainer,
  SortByDropdown,
  SortByDropdownButton,
  SortByIcon,
  SortByMobileIcon,
  SortByText,
} from "./SortBy.components";
import OpenIcon from "./open_icon.svg";
import MobileOpenIcon from "./mobile_open_icon.svg";
import { SortOrder } from "../Filters/Filters";

type SortByProps = {
  sortOrder: SortOrder;
  onChange: (sortOrder: SortOrder) => void;
};

const getSortByName = (sortOrder: SortOrder) => {
  switch (sortOrder) {
    case SortOrder.DATE_DESC:
      return "Most recent";
    case SortOrder.PRICE_ASC:
      return "Lowest price";
    case SortOrder.PRICE_DESC:
      return "Highest price";
  }
};

const SortBy: FunctionComponent<SortByProps> = ({ sortOrder, onChange }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setMenuVisible(false)}>
      <SortByContainer
        onClick={() => setMenuVisible(!menuVisible)}
        isOpened={menuVisible}
      >
        <SortByMobileIcon src={MobileOpenIcon} isOpened={menuVisible} />
        <SortByText>{getSortByName(sortOrder)}</SortByText>
        <SortByIcon src={OpenIcon} isOpened={menuVisible} />
        {menuVisible && (
          <SortByDropdown>
            <SortByDropdownButton
              selected={sortOrder === SortOrder.DATE_DESC}
              onClick={() => onChange(SortOrder.DATE_DESC)}
            >
              Most recent
            </SortByDropdownButton>
            <SortByDropdownButton
              selected={sortOrder === SortOrder.PRICE_DESC}
              onClick={() => onChange(SortOrder.PRICE_DESC)}
            >
              Highest price
            </SortByDropdownButton>
            <SortByDropdownButton
              selected={sortOrder === SortOrder.PRICE_ASC}
              onClick={() => onChange(SortOrder.PRICE_ASC)}
            >
              Lowest price
            </SortByDropdownButton>
          </SortByDropdown>
        )}
      </SortByContainer>
    </ClickAwayListener>
  );
};

export default SortBy;
