import React, { FunctionComponent, useCallback } from "react";
import { FileRejection, useDropzone, ErrorCode } from "react-dropzone";
import {
  BlueSpan,
  DescriptionSpan,
  ExtensionsSpan,
  FilesInputContainer,
  FilesInputContent,
  UploadImage,
} from "./FilesInput.components";
import IconUpload from "./icon_upload.svg";
import { IFileSelection, toFileSelection } from "./IFileSelection";
import imageCompression from "browser-image-compression";
import { getMimes } from "../FilesDropdown/FilesDropdown";

type FilesInputProps = {
  mimes: string[];
  description?: string;
  maxSizeMB?: number;
  multiple?: boolean;
  maxFiles?: number;
  onFileRejected?: (message: string) => void;
  onFiles: (files: IFileSelection[]) => void;
};

const errorCodeToMessage = (code: ErrorCode | string) => {
  switch (code) {
    case ErrorCode.FileInvalidType:
      return "Not supported file type.";
    case ErrorCode.FileTooLarge:
      return "File too large.";
    case ErrorCode.FileTooSmall:
      return "File too small.";
    case ErrorCode.TooManyFiles:
      return "Too many files.";
    default:
      return "File not supported";
  }
};

const FilesInput: FunctionComponent<FilesInputProps> = ({
  mimes,
  description,
  onFiles,
  onFileRejected,
  multiple = true,
  maxSizeMB = 2,
  maxFiles = 10,
}) => {
  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      const imagesTypes = getMimes("images");
      const compressedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          if (imagesTypes.includes(file.type)) {
            try {
              const compressedFile = await imageCompression(file, {
                maxWidthOrHeight: 800,
              });

              return compressedFile;
            } catch (e) {
              console.log(e);
              return file;
            }
          } else return file;
        }),
      );

      onFiles(
        compressedFiles.map((file) => {
          return toFileSelection(file);
        }),
      );
    },
    [onFiles],
  );

  const onDropRejected = useCallback(
    (rejections: FileRejection[]) => {
      if (!onFileRejected) return;
      const firstRejection = rejections[0];
      if (!firstRejection) return;
      const firstError = firstRejection.errors[0];
      if (!firstError) return;

      onFileRejected && onFileRejected(errorCodeToMessage(firstError.code));
    },
    [onFileRejected],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    multiple,
    maxSize: maxSizeMB * 1048576, //MB to bytes (1024^2).
    accept: mimes,
    maxFiles: maxFiles,
    onDropRejected,
  });

  return (
    <FilesInputContainer {...getRootProps({ isDragActive })}>
      <FilesInputContent>
        <UploadImage src={IconUpload} />
        <DescriptionSpan>
          Drop some files here or <BlueSpan>click to select files</BlueSpan>
        </DescriptionSpan>
        <ExtensionsSpan>{description}</ExtensionsSpan>
      </FilesInputContent>
      <input {...getInputProps()}></input>
    </FilesInputContainer>
  );
};

export default FilesInput;
