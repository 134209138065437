import React, { FunctionComponent, useState } from "react";
import {
  CommitLogo,
  CommitLogoLink,
  Container,
  Content,
  ListPropertyButtonContainer,
  MenuContainer,
  MenuDropdown,
  OptionsContainer,
  Overlay,
  RighMenuContainer,
} from "../Navbar.components";
import CommitLogoBlack from "../../../images/commit_logo_black.svg";
import routesPaths from "../../../../Routing/routesPaths";
import NavbarOption from "./NavbarOption/NavbarOption";
import { useLocation, useNavigate } from "react-router";
import Button from "../../Button";
import { Transition } from "react-transition-group";
import {
  MenuItem,
  MenuItemsDivider,
} from "./NavbarOption/NavbarOption.components";
import { INavbarOption } from "../MainNavbar";
import MenuButton from "./MenuButton";

const PublicNavbar: FunctionComponent<{ elements: INavbarOption[] }> = ({
  elements,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <>
      <Transition
        in={menuVisible}
        timeout={250}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {(status) => (
          <Overlay onClick={() => setMenuVisible(false)} status={status} />
        )}
      </Transition>
      <Container>
        <Content>
          <CommitLogoLink to={routesPaths.HOME}>
            <CommitLogo src={CommitLogoBlack} alt="Commit logo" />
          </CommitLogoLink>

          <OptionsContainer>
            {elements
              .filter((e) => e.align !== "right")
              .map((e) => (
                <NavbarOption
                  key={e.name}
                  title={e.name}
                  path={e.path}
                  url={e.url}
                />
              ))}
          </OptionsContainer>

          <MenuContainer>
            <MenuButton
              visible={menuVisible}
              onClick={() => setMenuVisible(!menuVisible)}
            />
            <Transition
              in={menuVisible}
              timeout={200}
              mountOnEnter={true}
              unmountOnExit={true}
            >
              {(status) => (
                <MenuDropdown status={status}>
                  {elements.map((e) => (
                    <MenuItem
                      key={e.name}
                      selected={location.pathname === e.path}
                      onClick={() => {
                        if (e.url) {
                          window.open(e.url, e.target ?? "_blank");
                        } else {
                          window.scrollTo(0, 0);
                          navigate(e.path!);
                        }
                        setMenuVisible(false);
                      }}
                    >
                      {e.icon}
                      {e.name}
                    </MenuItem>
                  ))}

                  <MenuItemsDivider />

                  <ListPropertyButtonContainer>
                    <Button
                      size="block"
                      onClick={() => navigate(routesPaths.REGISTER)}
                    >
                      List your property
                    </Button>
                  </ListPropertyButtonContainer>
                </MenuDropdown>
              )}
            </Transition>
          </MenuContainer>

          <RighMenuContainer>
            <ListPropertyButtonContainer>
              <Button
                size="block"
                onClick={() => navigate(routesPaths.REGISTER)}
              >
                List your property
              </Button>
            </ListPropertyButtonContainer>
          </RighMenuContainer>
        </Content>
      </Container>
    </>
  );
};

export default PublicNavbar;
