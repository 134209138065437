import styled from "styled-components";
import { colors } from "../../../../shared/colors";
import { down } from "../../../../shared/media";
import { ButtonSmallStyle } from "../../../../shared/mixins";

export const MessageSentContainer = styled.div`
  display: flex;
  padding: 12px 24px 12px 42px;
  box-sizing: border-box;
  background: ${colors.primary.pure_white};
  border-radius: 16px;
  > svg {
    min-width: 16px;
    min-height: 16px;
    margin-top: 2px;
  }
  > span {
    margin-left: 8px;
    ${ButtonSmallStyle()};
    color: ${colors.semantic.green_dark};
  }

  ${down("tablet")} {
    padding: 12px 50px 12px 34px;
    margin-bottom: 8px;
    margin-top: 16px;
  }

  ${down("mobile")} {
    padding: 12px 50px 36px 26px;
    margin-bottom: -24px;
    border-radius: 0;
  }
`;
