import React, { FunctionComponent } from "react";
import FormBox from "../FormBox/FormBox";
import {
  InputsContainer,
  InputsSectionContainer,
  ListingDetailsSectionContainer,
  MediumInputContainer,
} from "./ListingDetailsSection.components";
import { AddPropertyFormContentProps } from "../../AddPropertyFormContent";
import RadioButton from "../../../shared/components/RadioButotn/RadioButton";
import { ListingType, TenancyType } from "../../../../generated/schema";
import Label from "../../../shared/components/Form/Input/Label";
import ForRentListing from "./ForRentListing";
import ForSaleListing from "./ForSaleListing";
import { AddPropertyDivider } from "../../../Layouts/AddPropertyLayout/AddPropertyLayout.components";
import { Hints } from "../../AddPropertyFormHints";

const ListingDetailsSection: FunctionComponent<AddPropertyFormContentProps> = (
  props,
) => {
  const { values, setValues, setFieldValue, setTouched } = props;
  return (
    <FormBox section="LISTING_DETAILS">
      <ListingDetailsSectionContainer>
        <InputsSectionContainer>
          <Label>Listing Type</Label>
          <InputsContainer>
            <MediumInputContainer>
              <RadioButton
                value="For sale"
                checked={values.listingType === ListingType.ForSale}
                onCheck={() => {
                  setValues({
                    ...values,
                    listingType: ListingType.ForSale,
                    billingType: undefined,
                    price: undefined,
                    netIncome: undefined,
                    isRentalNegotiable: undefined,
                    isPriceNegotiable: false,
                  });
                  setFieldValue("year", undefined);
                  setTouched({
                    price: false,
                    netIncome: false,
                  });
                }}
                color="normal"
              />
            </MediumInputContainer>

            <MediumInputContainer>
              <RadioButton
                value="For rent"
                checked={values.listingType === ListingType.ForRent}
                onCheck={() => {
                  setValues({
                    ...values,
                    listingType: ListingType.ForRent,
                    billingType: undefined,
                    price: undefined,
                    netIncome: undefined,
                    isRentalNegotiable: false,
                    isPriceNegotiable: undefined,
                  });
                  setTouched({
                    price: false,
                    netIncome: false,
                  });
                }}
                color="normal"
              />
            </MediumInputContainer>
          </InputsContainer>
        </InputsSectionContainer>

        <AddPropertyDivider />

        {values.listingType === ListingType.ForRent && (
          <ForRentListing {...props} />
        )}
        {values.listingType === ListingType.ForSale && (
          <ForSaleListing {...props} />
        )}

        <AddPropertyDivider />

        <InputsSectionContainer>
          <Label hint={Hints.tenancy}>Tenancy</Label>
          <InputsContainer>
            <MediumInputContainer>
              <RadioButton
                checked={values.tenancyType === TenancyType.Single}
                color="normal"
                value="Single"
                onCheck={() => {
                  setFieldValue("tenancyType", TenancyType.Single);
                }}
              />
            </MediumInputContainer>

            <MediumInputContainer>
              <RadioButton
                checked={values.tenancyType === TenancyType.Multiple}
                color="normal"
                value="Multiple"
                onCheck={() => {
                  setFieldValue("tenancyType", TenancyType.Multiple);
                }}
              />
            </MediumInputContainer>
          </InputsContainer>
        </InputsSectionContainer>
      </ListingDetailsSectionContainer>
    </FormBox>
  );
};

export default ListingDetailsSection;
