import React, { FunctionComponent } from "react";
import { RouteProps, Navigate } from "react-router";
import { AccountType } from "../../generated/schema";
import { useAccountType } from "../../common/helpers/useAccountType";
import AuthedLayout from "../Layouts/AuthedLayout";
import routesPaths from "./routesPaths";
import useAppContext from "../App/AppContext/useAppContext";
import AddPropertyLayout from "../Layouts/AddPropertyLayout";
export interface AuthedRoutePermissions {
  accountType?: AccountType[];
  layoutType?: "authed" | "add_property" | "accept_property";
}

type Props = RouteProps & AuthedRoutePermissions;

export const AuthedRoute: FunctionComponent<Props> = ({
  accountType,
  children,
  layoutType = "authed",
  ...routeProps
}) => {
  // token means that user is logged in
  const hasToken = !!useAppContext().authToken;
  const hasCorrectAccountType = useAccountType(accountType);

  const authedRouteCondition = hasToken && hasCorrectAccountType;

  return !authedRouteCondition ? (
    <Navigate to={{ pathname: routesPaths.LOGIN }} />
  ) : layoutType === "add_property" ? (
    <AddPropertyLayout>{children}</AddPropertyLayout>
  ) : (
    <AuthedLayout>{children}</AuthedLayout>
  );
};

export default AuthedRoute;
