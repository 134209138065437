import { useCallback, useEffect } from "react";

export const useDropdownKeyboardListener = (
  isDisabled: boolean,
  className: string,
  callback: React.Dispatch<boolean>,
) => {
  const keyDownCallback = useCallback(
    (event: KeyboardEvent) => {
      if (isDisabled) return;

      if (event.target instanceof HTMLElement) {
        const target = event.target as HTMLElement;
        const isParentCliked = target.classList.contains(className);
        const isEnterClicked = event.code === "Enter";
        const isEscClicked = event.key === "Escape";
        const isChildClicked = target.classList.contains(`${className}-button`);
        const isArrowUpClicked = event.key === "ArrowUp";
        const isArrowDownClicked = event.key === "ArrowDown";

        if (isArrowUpClicked) {
          (event.target.previousSibling as HTMLButtonElement)?.focus();
          event.stopPropagation();
        } else if (isArrowDownClicked) {
          (event.target.nextSibling as HTMLButtonElement)?.focus();
          event.stopPropagation();
        } else if (isChildClicked && isEnterClicked) {
          callback(true);
          event.target.click();
        } else if (isParentCliked && isEnterClicked) {
          callback(true);
          event.stopPropagation();
        } else if ((isParentCliked || isChildClicked) && isEscClicked) {
          callback(false);
          event.stopPropagation();
        }
      }
    },
    [callback, className, isDisabled],
  );

  useEffect(() => {
    document.addEventListener("keydown", keyDownCallback, false);
    return () =>
      document.removeEventListener("keydown", keyDownCallback, false);
  });
};
