import React from "react";
import CookiesBox from "../shared/components/CookiesBox/CookiesBox";
import Footer from "../shared/components/Footer/Footer";
import AboutUs from "./AboutUs/AboutUs";
import { HomepageContainer } from "./Homepage.components";
import ListingProperties from "./ListingProperties/ListingProperties";
import SearchHeader from "./SearchHeader";

const Homepage = () => {
  return (
    <HomepageContainer>
      <SearchHeader />
      <ListingProperties />
      <AboutUs />
      <Footer />
      <CookiesBox />
    </HomepageContainer>
  );
};

export default Homepage;
