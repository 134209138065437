import React, { FunctionComponent } from "react";
import FormBox from "../FormBox/FormBox";
import { AddPropertyFormContentProps } from "../../AddPropertyFormContent";
import {
  Divider,
  InputsContainer,
  LargeInputContainer,
  PropertyDetailsSectionContainer,
  SmallInputContainer,
  TextAreaContainer,
} from "./PropertyDetailsSection.components";
import InputField from "../../../shared/components/Form/Input/InputField";
import TextareaField from "../../../shared/components/Form/Textarea/TextareaField";
import { Hints } from "../../AddPropertyFormHints";

const PropertyDetailsSection: FunctionComponent<AddPropertyFormContentProps> = (
  props,
) => {
  return (
    <FormBox section="PROPERTY_DETAILS">
      <PropertyDetailsSectionContainer>
        <InputsContainer>
          <SmallInputContainer>
            <InputField
              name="yearBuilt"
              label="Year Built (opt.)"
              hint={Hints.yearBuilt}
              type="number"
              inputSize="l"
            />
          </SmallInputContainer>
          <SmallInputContainer>
            <InputField
              inputSize="l"
              hint={Hints.yearLastRenovated}
              type="number"
              name="yearLastRenovated"
              label="Year 'Last' Renovated (opt.)"
            />
          </SmallInputContainer>
        </InputsContainer>
        <InputsContainer>
          <SmallInputContainer>
            <InputField
              inputSize="l"
              hint={Hints.parkingSpaces}
              name="parkingSpaces"
              type="number"
              label="Parking Spaces"
            />
          </SmallInputContainer>
          <LargeInputContainer>
            <InputField
              hint={Hints.parkingDetails}
              maxLength={100}
              inputSize="l"
              label="Parking Details (opt.)"
              name="parkingDetails"
            />
          </LargeInputContainer>
        </InputsContainer>
        <Divider />
        <TextAreaContainer>
          <TextareaField
            maxLength={2000}
            hint={Hints.propertyDescriptions}
            name="propertyDescriptions"
            label="Property Descriptions"
          />
        </TextAreaContainer>
      </PropertyDetailsSectionContainer>
    </FormBox>
  );
};

export default PropertyDetailsSection;
