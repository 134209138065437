export const modalsData = {
  verification: {
    title: "Re-verification",
    description:
      "Editing offer requires re-confirmation by the administrator. Your listing will be pending and hidden from the search page.",
  },
  leaving: {
    title: "Unsaved changes",
    description:
      "You have unsaved changes. Are you sure you want to continue? If you leave this page, you will lose your data.",
  },
  delete_property: {
    title: "Delete property?",
    description: (name: string) => (
      <>
        Are you sure you want to delete <b>{name}</b>? You won't be able to
        restore it.
      </>
    ),
  },
  decline_property: {
    title: "Decline property?",
    description: (name: string) => (
      <>
        Are you sure you want to decline <b>{name}</b>?
      </>
    ),
  },
};
