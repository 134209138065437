import React, { FunctionComponent } from "react";
import { formatBilling } from "../../../../../common/helpers/formatBilling";
import formatPrice from "../../../../../common/helpers/formatPrice";
import { BillingType, ListingType } from "../../../../../generated/schema";
import {
  PropertyPriceLabel,
  PropertyPriceSpan,
} from "./PropertyPrice.components";

type PropertyPriceProps = {
  price: number;
  listingType: ListingType;
  billingType?: BillingType | null;
};
const PopertyPrice: FunctionComponent<PropertyPriceProps> = ({
  listingType,
  billingType,
  price,
}) => {
  const getPriceLabel = () => {
    switch (listingType) {
      case ListingType.ForRent:
        return (
          <>
            {formatPrice(price)}
            <PropertyPriceSpan>{formatBilling(billingType)}</PropertyPriceSpan>
          </>
        );
      case ListingType.ForSale:
        return `${formatPrice(price)}`;
    }
  };

  return <PropertyPriceLabel>{getPriceLabel()}</PropertyPriceLabel>;
};

export default PopertyPrice;
