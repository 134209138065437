import { FormikProps } from "formik";
import React, { FunctionComponent } from "react";
import useAppContext from "../../../../../App/AppContext/useAppContext";
import Button from "../../../Button";
import InputField from "../../../Form/Input/InputField";
import TextareaField from "../../../Form/Textarea/TextareaField";
import { useModal } from "../../../Modal";
import {
  AccountDetailsModalFormContainer,
  AccountDetailsInputsWrapper,
  AccountDetailsButtonsWrapper,
  AccountDetailsTextareaWrapper,
  AccountDetailsButtonWrapper,
  AccountDetailsForm,
  AccountDetailsError,
  AccountDetailsButtonsContainer,
} from "../AccountDetailsModel.components";
import { AccountDetailsModalFormValues } from "./form";

type Props = FormikProps<AccountDetailsModalFormValues> & {
  error?: string;
};

const AccountDetailsModalFormContent: FunctionComponent<Props> = ({
  submitForm,
  values,
  error,
}) => {
  const { currentUser } = useAppContext();
  const { closeModal } = useModal();

  return (
    <AccountDetailsForm>
      <AccountDetailsModalFormContainer>
        <AccountDetailsInputsWrapper>
          <InputField
            label="Name"
            autoComplete="name"
            name="name"
            type="text"
            maxLength={255}
          />
          <InputField
            label="Phone (opt.)"
            autoComplete="phone"
            name="phone"
            type="tel"
            value={values.phone}
          />
          <InputField
            label="Company name [opt.]"
            autoComplete="compane"
            name="companyName"
            type="text"
          />
        </AccountDetailsInputsWrapper>
        <AccountDetailsTextareaWrapper>
          <TextareaField
            name="bio"
            label="Bio"
            maxLength={250}
            rows={6}
            value={values.bio}
          />
        </AccountDetailsTextareaWrapper>
      </AccountDetailsModalFormContainer>
      <AccountDetailsButtonsContainer>
        <AccountDetailsError>{error}</AccountDetailsError>
        <AccountDetailsButtonsWrapper singleButton={!currentUser?.name}>
          {currentUser?.name && (
            <AccountDetailsButtonWrapper>
              <Button
                size="block"
                buttonType="outline"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
            </AccountDetailsButtonWrapper>
          )}
          <AccountDetailsButtonWrapper>
            <Button size="block" type="submit" onClick={() => submitForm()}>
              {currentUser?.name ? "Save" : "Confirm"}
            </Button>
          </AccountDetailsButtonWrapper>
        </AccountDetailsButtonsWrapper>
      </AccountDetailsButtonsContainer>
    </AccountDetailsForm>
  );
};

export default AccountDetailsModalFormContent;
