import styled from "styled-components";
import { colors } from "../../colors";
import { down } from "../../media";

export const NothingHereContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 96px;
  align-items: center;
  margin-bottom: 161px;

  ${down("tablet")} {
    margin: 120px 0;
  }
`;

export const NothingHereHeader = styled.h4`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  margin-top: 40px;
  color: ${colors.primary.frosted_black};
`;

export const NothingHereDescription = styled.span`
  margin-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.grayscale._600};
`;
